import React from 'react';
import { DataSource } from 'models/api/response.types';
import { DataRow } from 'utils/sources';

const SourceFileName: React.FC<{
  source: DataRow | DataSource;
}> = ({ source }) => {
  const meta = JSON.parse(source.meta_json);
  if (['url', 'video'].includes(source.type)) {
    return (
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
        <a
          href={meta.url}
          target="_blank"
          rel="noreferrer"
          className="text-sm hover:underline text-secondary"
          onClick={(e) => e.stopPropagation()}
        >
          {meta.url}
        </a>
      </div>
    );
  }

  if (source.type === 'qa') {
    return (
      <div>
        <p className="text-sm overflow-hidden overflow-ellipsis whitespace-nowrap">{meta.question}</p>
        <p className="text-sm text-muted-foreground overflow-hidden overflow-ellipsis whitespace-nowrap">
          {meta.answer}
        </p>
      </div>
    );
  }
  if (source.type === 'google-drive') {
    return (
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
        {meta?.webViewLink ? (
          <a
            onClick={(e) => e.stopPropagation()}
            href={meta.webViewLink}
            target="_blank"
            rel="noreferrer"
            className="text-sm hover:underline text-secondary"
          >
            {source.file_name}
          </a>
        ) : (
          <p className="text-sm">{source.file_name}</p>
        )}
      </div>
    );
  }
  return <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{source.file_name}</div>;
};

export default SourceFileName;
