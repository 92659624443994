import { Bot, UserRound } from 'lucide-react';
import React, { useState } from 'react';
import { IUserMessageColors, WidgetSize, chatSizeVariants } from 'utils/bot';
import { cn } from 'utils/cn';

const ChatAvatar: React.FC<{
  size: WidgetSize;
  userMessageColors?: IUserMessageColors;
  hidden?: boolean;
  imgUrlPath?: string;
  isHumanMessage?: boolean;
}> = ({ imgUrlPath, size, userMessageColors, hidden, isHumanMessage }) => {
  const [imageRenderError, setImageRenderError] = useState<boolean>(false);

  if (imgUrlPath && !imageRenderError) {
    return (
      <img
        className={cn(
          'rounded-full',
          chatSizeVariants.chat.messageAvatarContainer[size],
          hidden ? 'opacity-0' : '',
        )}
        src={imgUrlPath}
        alt="Avatar"
        onError={() => setImageRenderError(true)}
      />
    );
  }

  return (
    <div
      className={cn(
        'flex items-center justify-center rounded-full transition-all',
        chatSizeVariants.chat.messageAvatarContainer[size],
        hidden ? 'opacity-0' : '',
      )}
      style={
        userMessageColors
          ? {
              background: userMessageColors.backgroundColor,
              color: userMessageColors.textColor,
            }
          : {
              background: '#367DF1',
              color: '#fff',
            }
      }
    >
      {isHumanMessage ? (
        <UserRound strokeWidth={1.75} className={chatSizeVariants.chat.messageAvatarIcon[size]} />
      ) : (
        <Bot strokeWidth={1.75} className={chatSizeVariants.chat.messageAvatarIcon[size]} />
      )}
    </div>
  );
};

export default ChatAvatar;
