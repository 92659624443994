import React, { Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { aiModelsGuide } from 'utils/agent';
import { cn } from 'utils/cn';

const AIModelsGuideDialog: React.FC<{
  show: boolean;
  close: () => void;
}> = ({ show, close }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full overflow-hidden h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full flex flex-col max-w-3xl max-h-[900px] transform rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all overflow-hidden">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  AI Models Description
                </DialogTitle>
                <div className="mt-2 flex flex-col overflow-hidden">
                  <div className="border border-blue-500 bg-blue-50 p-2 text-sm rounded-md mt-2">
                    Tag and tag descriptions, variables and variable descriptions, as well as function
                    descriptions all count towards LLM token limit. If you exceed the limit, the query will
                    not be successfully executed.
                  </div>
                  <div className="border rounded-md flex flex-col mt-2 overflow-auto flex-1">
                    <div className="flex items-center text-sm font-medium whitespace-nowrap border-b">
                      <div className="min-w-[200px] w-[200px] px-4 py-1 text-center">Model</div>
                      <div className="flex-1 min-w-[350px] border-r border-l px-4 py-1 text-center">
                        Description
                      </div>
                      <div className="min-w-[150px] w-[150px] px-4 py-1 text-center">Credit Cost</div>
                    </div>
                    {aiModelsGuide.map((model, index) => (
                      <div
                        className={cn(
                          'flex items-center text-sm',
                          index + 1 < aiModelsGuide.length && 'border-b',
                        )}
                        key={model.model_name}
                      >
                        <div className="min-w-[200px] w-[200px] px-4 py-1 text-center">
                          {model.model_name}
                        </div>
                        <div className="flex-1 min-w-[350px] border-l border-r px-4 py-1">
                          {model.description}
                        </div>
                        <div className="min-w-[150px] w-[150px] px-4 py-1 text-center">
                          {model.credit_cost} <span className="text-xs text-muted-foreground">/message</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={close}>Got it</Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AIModelsGuideDialog;
