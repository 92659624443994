import { Badge } from 'components/ui/badge';
import { Book, Headphones, Gauge, Settings2, Shield, X, Inbox } from 'lucide-react';
import React, { ReactNode } from 'react';
import { cn } from 'utils/cn';

export type PermissionType = 'agent' | 'meta' | 'dashboard' | 'sources' | 'chats-leads' | 'admin';

export interface CollaboratorPermission {
  type: PermissionType;
  title: string;
  description: string;
  icon: any;
}

export const adminPermissions: CollaboratorPermission[] = [
  {
    type: 'admin',
    title: 'Admin',
    description: 'Owner of the chatbot.',
    icon: (className?: string) => (
      <Shield strokeWidth={1.75} className={cn('w-3 min-w-[12px] h-3', className)} />
    ),
  },
];

export const collaboratorsPermissions: CollaboratorPermission[] = [
  {
    type: 'dashboard',
    title: 'Dashboard',
    description:
      'Provides access to dashboard. Collaborator can view relevant information and data summaries.',
    icon: (className?: string) => (
      <Gauge strokeWidth={1.75} className={cn('w-3 min-w-[12px] h-3', className)} />
    ),
  },
  {
    type: 'chats-leads',
    title: 'Inbox',
    description: `Enables collaborators to interact with chats, allowing actions such 
    as deleting messages, exporting data, and reviewing all chat-related information.`,
    icon: (className?: string) => (
      <Inbox strokeWidth={1.75} className={cn('w-3 min-w-[12px] h-3', className)} />
    ),
  },
  {
    type: 'sources',
    title: 'Sources',
    description: `Grants collaborators the ability to navigate the sources tab, read existing sources, 
    add new ones, and perform various management actions like deletion and editing`,
    icon: (className?: string) => (
      <Book strokeWidth={1.75} className={cn('w-3 min-w-[12px] h-3', className)} />
    ),
  },
  {
    type: 'agent',
    title: 'AI Agents',
    description: `Allows collaborators to add and customize AI agents, providing a suite of tools designed for tailoring and configuring AI capabilities and settings.`,
    icon: (className?: string) => (
      <Headphones strokeWidth={1.75} className={cn('w-3 min-w-[12px] h-3', className)} />
    ),
  },
  {
    type: 'meta',
    title: 'Settings / Appearance',
    description: `Provides collaborators with the tools to customize the bot, 
      configuring general and other settings for a personalized experience.`,
    icon: (className?: string) => (
      <Settings2 strokeWidth={1.75} className={cn('w-3 min-w-[12px] h-3', className)} />
    ),
  },
];

export const permissionTypes: {
  [key: string]: { title: string; icon: () => ReactNode };
} = {
  admin: {
    title: 'Admin',
    icon: () => {
      return <Shield strokeWidth={1.75} className="w-3 min-w-[12px] h-3 mr-2" />;
    },
  },
  dashboard: {
    title: 'Dashboard',
    icon: () => {
      return <Gauge strokeWidth={1.75} className="w-3 min-w-[12px] h-3 mr-2" />;
    },
  },
  agent: {
    title: 'AI Agents',
    icon: () => {
      return <Headphones strokeWidth={1.75} className="w-3 min-w-[12px] h-3 mr-2" />;
    },
  },
  'chats-leads': {
    title: 'Inbox',
    icon: () => {
      return <Inbox strokeWidth={1.75} className="w-3 min-w-[12px] h-3 mr-2" />;
    },
  },
  sources: {
    title: 'Sources',
    icon: () => {
      return <Book strokeWidth={1.75} className="w-3 min-w-[12px] h-3 mr-2" />;
    },
  },
  meta: {
    title: 'Appearance/Settings',
    icon: () => {
      return <Settings2 strokeWidth={1.75} className="w-3 min-w-[12px] h-3 mr-2" />;
    },
  },
};

const CollaboratorPermissionBadge: React.FC<{
  permission: CollaboratorPermission;
  disabled?: boolean;
  showCloseIcon?: boolean;
  onClick?: () => void;
}> = ({ permission, disabled, showCloseIcon, onClick }) => {
  return (
    <Badge
      variant="outline"
      key={permission.type}
      className={cn(
        'w-fit py-1 h-fit flex gap-2 items-center whitespace-nowrap',
        !disabled && 'cursor-pointer hover:bg-accent',
      )}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
    >
      {permission.icon()}
      {permission.title}
      {showCloseIcon && <X strokeWidth={1.75} className="w-3 h-3" />}
    </Badge>
  );
};

export default CollaboratorPermissionBadge;
