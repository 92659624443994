/* eslint-disable no-new */
import AttentionDialog from 'components/Dialogs/User/AttentionDialog';
import { Button } from 'components/ui/button';
import { formatDistanceToNow } from 'date-fns';
import useAppVisibility from 'hooks/useAppVisibility';
import useChatbots from 'hooks/useChatbots';
import {
  AlertTriangle,
  Ban,
  Bell,
  CircleCheckBig,
  Info,
  MessageSquareDot,
  Rocket,
  Speech,
  X,
} from 'lucide-react';
import { Chatbot, HERequestNotification, MonitoredMessageNotification } from 'models/api/response.types';
import { Alert as AlertType, crossBrowserTabsNotifications } from 'providers/AlertProvider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';
import { selectSessionState, updateAppVerison } from 'store/reducers/session';
import { setSelectedChatbot } from 'store/reducers/ui';
import { cn } from 'utils/cn';

export const ToastCloseButton: React.FC<{ closeToast: (e: any) => void }> = ({ closeToast }) => {
  return (
    <X
      strokeWidth={1.75}
      className="w-4 h-4 min-w-[16px] cursor-pointer text-muted-foreground hover:text-foreground transition-colors"
      onClick={closeToast}
    />
  );
};

const titles: { [key: string]: string } = {
  error: 'Error',
  success: 'Success',
  warning: 'Attention',
  info: 'Information',
  app_updates_available: 'Update Now for a Better App Experience!',
  attention_needed: 'Attention: Important Update',
  new_features: 'Learn About New Updates',
};

const icons: { [key: string]: any } = {
  error: <Ban strokeWidth={2} className="w-4 h-4 min-w-4" />,
  success: <CircleCheckBig strokeWidth={2} className="w-4 min-w-4 h-4" />,
  warning: <AlertTriangle strokeWidth={2} className="w-4 min-w-4 h-4" />,
  info: <Info strokeWidth={2} className="w-4 min-w-4 h-4" />,
  app_updates_available: <Rocket strokeWidth={2} className="w-4 min-w-4 h-4" />,
  requested_human_escalation: <Speech strokeWidth={2} className="w-4 h-4 min-w-4" />,
  monitored_messages: <MessageSquareDot strokeWidth={2} className="w-4 h-4 min-w-4" />,
  new_features: <Bell strokeWidth={2} className="w-4 h-4 min-w-4" />,
  attention_needed: <AlertTriangle strokeWidth={2} className="w-4 min-w-4 h-4" />,
};

const iconsClasses: { [key: string]: any } = {
  error: 'bg-destructive text-white',
  success: 'bg-success text-white',
  warning: 'bg-warning text-white',
  attention_needed: 'bg-warning text-white',
  info: 'bg-secondary text-white',
  new_features: 'bg-secondary text-white',
  app_updates_available: 'bg-secondary text-white',
  requested_human_escalation: 'bg-indigo-600 text-white',
  monitored_messages: 'bg-yellow-300',
};

const ToastMessage: React.FC<{
  closeToast: () => void;
  alert: AlertType;
}> = ({ closeToast, alert }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isVisible = useAppVisibility();
  const navigate = useNavigate();
  const { chatbots } = useChatbots();
  const currentSessionState = useSelector(selectSessionState);
  const [openAttentionDialog, setOpenAttentionDialog] = useState<boolean>(false);

  const title = alert?.alertPayload?.chatbot_name || titles[alert.severity];
  const description = alert?.alertPayload?.message_query || alert.message;

  const sendNotification = (payload: HERequestNotification | MonitoredMessageNotification) => {
    const notification = new Notification(payload.chatbot_name, {
      body: payload.message_query,
    });
    notification.onclick = () => {
      window.focus();
    };
  };

  // check alerts if allows to send crosstab notifications
  useEffect(() => {
    if (crossBrowserTabsNotifications.includes(alert.severity)) {
      const payload = alert.alertPayload as HERequestNotification | MonitoredMessageNotification;
      if (payload?.sound_enabled) {
        const notificationAudio = new Audio('/audio/chat-notification.mp3');
        notificationAudio.muted = false;
        notificationAudio.play();
      }
      // if not visible means user in another tab, try to send notification through browser
      // if feature exists and permissions are granted
      if (!isVisible && 'Notification' in window) {
        if (Notification.permission === 'granted') {
          sendNotification(payload);
        } else {
          Notification.requestPermission().then((permission: NotificationPermission) => {
            if (permission === 'granted') {
              sendNotification(payload);
            }
          });
        }
      }
    }
  }, []);

  return (
    <>
      <div className="text-foreground">
        <div className="flex items-start gap-2">
          <div className={cn('p-[4px] rounded-md', iconsClasses[alert.severity])}>
            {icons[alert.severity]}
          </div>
          <h5 className="mb-2 text-base font-medium leading-5 tracking-normal">{title}</h5>
        </div>
        <p className="text-sm leading-5 font-normal tracking-normal mt-1">{description}</p>
        <div className="mt-2 w-full flex items-center gap-4">
          {['new_features', 'attention_needed'].includes(alert.severity) && (
            <Button
              className="ml-auto"
              variant="outline"
              size="sm"
              onClick={() => {
                if (alert.severity === 'new_features') {
                  window.open('https://gpt-trainer.com/changelog', '_blank');
                } else {
                  setOpenAttentionDialog(true);
                }
                closeToast();
              }}
            >
              Learn More
            </Button>
          )}
          {alert.severity === 'attention_needed' && (
            <Button
              className="ml-auto"
              variant="outline"
              size="sm"
              onClick={() => {
                window.open('https://gpt-trainer.com/changelog', '_blank');
                closeToast();
              }}
            >
              Learn More
            </Button>
          )}
          {alert.severity === 'app_updates_available' && (
            <Button
              className="ml-auto"
              variant="outline"
              size="sm"
              onClick={() => {
                window.location.reload();
                dispatch(updateAppVerison(currentSessionState));
              }}
            >
              Update Now
            </Button>
          )}
          {['monitored_messages', 'requested_human_escalation'].includes(alert.severity) && (
            <>
              <p className="text-xs text-muted-foreground">
                {formatDistanceToNow(
                  new Date(
                    (alert.alertPayload as HERequestNotification | MonitoredMessageNotification).created_at,
                  ),
                  {
                    addSuffix: true,
                  },
                )}
              </p>
              <Button
                className="ml-auto"
                variant="outline"
                size="sm"
                onClick={() => {
                  if (chatbots) {
                    const chatbotId = (
                      alert.alertPayload as HERequestNotification | MonitoredMessageNotification
                    ).chatbot_uuid;
                    const chatSessionId = (
                      alert.alertPayload as HERequestNotification | MonitoredMessageNotification
                    ).chat_session_uuid;
                    const chatbotToUse = chatbots?.find((c: Chatbot) => c.uuid === chatbotId);
                    if (chatbotToUse) {
                      dispatch(setSelectedChatbot(chatbotToUse));
                      navigate(`${routePaths.inbox}?session_uuid=${chatSessionId}`);
                      if (routePaths.inbox === pathname) {
                        window.location.reload();
                      }
                    }
                  }
                  closeToast();
                }}
              >
                Go to Chat
              </Button>
            </>
          )}
        </div>
      </div>
      <AttentionDialog
        show={openAttentionDialog}
        close={() => {
          setOpenAttentionDialog(false);
          closeToast();
        }}
      />
    </>
  );
};

export default ToastMessage;
