import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Chatbot, ChatbotDomain, ChatbotDomainList } from 'models/api/response.types';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { getSelectedChatbot } from 'store/reducers/ui';
import domainService from 'api/domain';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';

const DeleteDomainDialog: React.FC<{
  domain?: ChatbotDomain;
  close: () => void;
}> = ({ domain, close }) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const [show, setShow] = useState<boolean>(false);
  const domainsQueryKey = ['chatbot-domains', chatbot?.uuid];

  useEffect(() => {
    if (domain && !show) {
      setShow(true);
    }
  }, [domain]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  const deleteDomain = () => {
    if (domain) {
      domainService
        .deleteChatbotDomain(domain.uuid)
        .then(() => {
          hide();
          const domains: ChatbotDomainList | undefined = queryClient.getQueryData(domainsQueryKey);
          if (domains) {
            const newDomains = domains.filter((dom) => dom.uuid !== domain.uuid);
            queryClient.setQueryData(domainsQueryKey, newDomains);
          } else {
            queryClient.invalidateQueries({ queryKey: domainsQueryKey });
          }
        })
        .catch(() => {
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
          });
        });
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete {domain?.domain}
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you want to delete your chatbot&apos;s domain? This action cannot be undone.
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button variant="destructive" onClick={deleteDomain}>
                    Delete
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteDomainDialog;
