import React, { useState } from 'react';
import AceEditor from 'react-ace';

const defaultMetaData = `{
}`;

const MetadataEditor: React.FC<{
  metaJson?: string;
  onChange: (newMetaJson: string, errors: any) => void;
}> = ({ metaJson = defaultMetaData, onChange }) => {
  const [annotations, setAnnotations] = useState<any>([]);

  const validateJSON = (inputText: string): Array<any> => {
    try {
      JSON.parse(inputText);
      return [];
    } catch (e: any) {
      const lineMatch = e.message.match(/line (\d+)/);
      const line = lineMatch ? parseInt(lineMatch[1], 10) - 1 : 0;

      return [
        {
          row: line,
          column: 0,
          text: e.message,
          type: 'error',
        },
      ];
    }
  };

  const handleOnChange = (newText: string) => {
    const newAnnotations = validateJSON(newText);
    setAnnotations(newAnnotations);
    onChange(newText, newAnnotations);
  };

  return (
    <AceEditor
      mode="json"
      theme="textmate"
      name="jsonEditor"
      value={metaJson}
      onChange={handleOnChange}
      annotations={annotations}
      fontSize={14}
      showPrintMargin
      showGutter
      highlightActiveLine
      setOptions={{
        useWorker: false, // It's important to disable the default worker to manage errors manually
        showLineNumbers: true,
        tabSize: 2,
      }}
      style={{ width: '100%', height: '100%', minHeight: '110px' }}
    />
  );
};

export default MetadataEditor;
