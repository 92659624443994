import { useQuery, useQueryClient } from '@tanstack/react-query';
import userService from 'api/user';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import routePaths, { authPaths } from 'routes/routePaths';
import { getCurrentUser } from 'store/reducers/session';
import { renderAvatarStyles } from 'utils/avatar';

const useUserInfo = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const user = useSelector(getCurrentUser);
  const queryKey = ['user-info', user?.access_token];

  const {
    data: userInfo,
    error,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () => userService.getUserInfo(),
    enabled: !!user,
  });

  const userMetaData = useMemo(() => {
    if (userInfo) {
      return JSON.parse(userInfo.meta_json);
    }
    return undefined;
  }, [userInfo]);

  // if user is unauthorized navigate to login page
  useEffect(() => {
    if (isError) {
      if (!Object.values(authPaths).includes(pathname)) {
        if ((error as unknown as any)?.response?.data?.msg === 'Missing Authorization Header') {
          navigate(routePaths.login);
        } else {
          navigate(routePaths.logout);
        }
      }
    }
  }, [isError]);

  const refetchUser = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  // avatar fallback in cases where user doesn't have photo
  const avatarData = useMemo(() => {
    if (userInfo?.email) {
      return {
        ...renderAvatarStyles(userInfo.email),
        avatarSource: userInfo?.avatar ? `${userInfo.avatar}?t=${userInfo.modified_at}` : '',
      };
    }
    return {
      avatarSource: '',
      initials: '',
      backgroundColor: '',
      color: '',
    };
  }, [userInfo]);

  return { avatarData, userInfo, queryKey, refetchUser, userMetaData };
};

export default useUserInfo;
