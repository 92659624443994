/* eslint-disable react/jsx-props-no-spreading */
import { Button } from 'components/ui/button';
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';

const OnboardingTooltip: React.FC<TooltipRenderProps> = ({
  index,
  primaryProps,
  backProps,
  step,
  size,
  tooltipProps,
}) => {
  return (
    <div className="bg-background rounded-md p-6 max-w-[500px] w-full" {...tooltipProps}>
      {step.content}
      <div className="flex gap-2 mt-2 justify-between items-center">
        <Button
          variant="link"
          size="default"
          {...backProps}
          style={
            index < 1
              ? {
                  opacity: 0,
                }
              : {
                  opacity: 1,
                }
          }
        >
          Back
        </Button>
        {size > 1 && <p className="text-sm font-semibold">{`${index + 1}/${size}`}</p>}
        <Button size="default" {...primaryProps}>
          {index < size - 1 ? 'Next' : 'Got it'}
        </Button>
      </div>
    </div>
  );
};

export default OnboardingTooltip;
