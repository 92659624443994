/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chatbot } from 'models/api/response.types';
import { isMobileOnly } from 'react-device-detect';
import { IState } from '.';

export type ChartTime = 'week' | 'month' | '3months' | '6months' | 'year';

export type SourceColumn =
  | 'Title'
  | 'Link'
  | 'Resource'
  | 'Type'
  | 'Status'
  | 'Size'
  | 'Re-train'
  | 'Tokens'
  | 'Last Trained On';

export interface UIState {
  selectedChatbot?: Chatbot;
  showSidebar: boolean;
  hasUnsavedChanges: boolean;
  dashBoardChartTime: ChartTime;
  showSettingsSidebar: boolean;
  showSessionDetailsDrawer: boolean;
  showAgentRuleInvalidState: boolean;
  sourceTableColumsHidden: SourceColumn[];
  isChatPreviewOpen: boolean;
}

export const initialState: UIState = {
  selectedChatbot: undefined,
  showSidebar: false,
  hasUnsavedChanges: false,
  dashBoardChartTime: 'week',
  showSettingsSidebar: !isMobileOnly,
  showSessionDetailsDrawer: false,
  sourceTableColumsHidden: [],
  showAgentRuleInvalidState: false,
  isChatPreviewOpen: !isMobileOnly,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSelectedChatbot(state, action: PayloadAction<Chatbot | undefined>) {
      state.selectedChatbot = action.payload;
    },
    setShowSidebar(state, action: PayloadAction<boolean>) {
      state.showSidebar = action.payload;
    },
    setHasUnsavedChanges(state, action: PayloadAction<boolean>) {
      state.hasUnsavedChanges = action.payload;
    },
    setDashboardChartTime(state, action: PayloadAction<ChartTime>) {
      state.dashBoardChartTime = action.payload;
    },
    setShowSettingsSidebar(state, action: PayloadAction<boolean>) {
      state.showSettingsSidebar = action.payload;
    },
    setShowSessionDetailsDrawer(state, action: PayloadAction<boolean>) {
      state.showSessionDetailsDrawer = action.payload;
    },
    setSourceTableColumnsHidden(state, action: PayloadAction<SourceColumn[]>) {
      state.sourceTableColumsHidden = action.payload;
    },
    setShowAgentRuleInvalidState(state, action: PayloadAction<boolean>) {
      state.showAgentRuleInvalidState = action.payload;
    },
    setToggleChatPreview(state, action: PayloadAction<boolean>) {
      state.isChatPreviewOpen = action.payload;
    },
  },
});

export const { reducer } = uiSlice;

// redux actions
export const {
  setSelectedChatbot,
  setHasUnsavedChanges,
  setShowSidebar,
  setDashboardChartTime,
  setShowSettingsSidebar,
  setSourceTableColumnsHidden,
  setShowSessionDetailsDrawer,
  setShowAgentRuleInvalidState,
  setToggleChatPreview,
} = uiSlice.actions;

// redux selectors
export const getSelectedChatbot = (state: IState) => state.ui.selectedChatbot;
export const getShowSidebar = (state: IState) => state.ui.showSidebar;
export const getShowSettingsSidebar = (state: IState) => state.ui.showSettingsSidebar;
export const getShowAgentRuleInvalidState = (state: IState) => state.ui.showAgentRuleInvalidState;
export const getShowSessionDetailsDrawer = (state: IState) => state.ui.showSessionDetailsDrawer;
export const getHasUnsavedChanges = (state: IState) => state.ui.hasUnsavedChanges;
export const getDashboardChartTime = (state: IState) => state.ui.dashBoardChartTime;
export const getSourceTableColumnsHidden = (state: IState) => state.ui.sourceTableColumsHidden;
export const isChatPreviewOpen = (state: IState) => state.ui.isChatPreviewOpen;
