/* eslint-disable react/no-array-index-key */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { ChatBotMeta } from 'utils/bot';
import { Moon, Sliders, Sun, ChevronDown, CheckIcon, Power, PowerOff, Dock, Smartphone } from 'lucide-react';
import { Toggle } from 'components/ui/toggle';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react';
import { fonts } from 'utils/fonts';
import ChatbotLogoAppearance from 'components/helpers/ChatbotAppearance/ChatbotLogoAppearance';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot } from 'models/api/response.types';

interface GeneralApperanceProps extends React.HTMLProps<HTMLDivElement> {
  id: string;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const GeneralAppearance = React.forwardRef<HTMLDivElement, GeneralApperanceProps>(
  ({ id, chatInterface, setChatInterface }, ref) => {
    const chatbot = useSelector(getSelectedChatbot) as Chatbot;
    const [fontQuery, setFontQuery] = useState<string>('');

    useEffect(() => {
      const link = document.createElement('link');
      link.href =
        'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600&family=Inter:wght@300;400;500;600&family=Roboto:wght@300;400;500;600&family=Open+Sans:wght@300;400;500;600&family=Montserrat:wght@300;400;500;600&family=Poppins:wght@300;400;500;600&family=Source+Sans+Pro:wght@300;400;500;600&family=Nunito:wght@300;400;500;600&family=Raleway:wght@300;400;500;600&family=Merriweather:wght@300;400;500;600&family=Noto+Sans:wght@300;400;500;600&family=Ubuntu:wght@300;400;500;600&family=PT+Sans:wght@300;400;500;600&family=Playfair+Display:wght@300;400;500;600&family=Oswald:wght@300;400;500;600&family=Rubik:wght@300;400;500;600&family=Josefin+Sans:wght@300;400;500;600&family=Lora:wght@300;400;500;600&family=Quicksand:wght@300;400;500;600&family=Cabin:wght@300;400;500;600&family=Fira+Sans:wght@300;400;500;600&family=Dancing+Script:wght@300;400;500;600&family=Inconsolata:wght@300;400;500;600&family=Anton:wght@300;400;500;600&family=Work+Sans:wght@300;400;500;600&family=Alegreya:wght@300;400;500;600&family=Mulish:wght@300;400;500;600&family=Bitter:wght@300;400;500;600&family=Karla:wght@300;400;500;600&family=Arimo:wght@300;400;500;600&display=swap';
      link.rel = 'stylesheet';
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }, []);

    const fontsToShow = useMemo(() => {
      if (fontQuery) {
        return fonts.filter((font) => font.label.toLowerCase().includes(fontQuery.toLowerCase()));
      }
      return fonts;
    }, [fontQuery]);

    return (
      <Card id={id} ref={ref} className="relative">
        <CardHeader className="pb-6">
          <CardTitle className="flex items-center">
            <Sliders strokeWidth={1.75} className="w-6 h-6 mr-2" />
            General
          </CardTitle>
          <CardDescription>General UI settings of your chatbot.</CardDescription>
        </CardHeader>
        <CardContent className="grid gap-6 max-w-[1000px] overflow-hidden">
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="name"
            >
              Chat Name
            </Label>
            <p className="text-sm text-muted-foreground">
              Personalize your chat by entering a custom name, which will be prominently displayed.
            </p>
            <Input
              id="name"
              type="text"
              value={chatInterface.display_name}
              onChange={(e) => {
                setChatInterface({
                  ...chatInterface,
                  display_name: e.target.value,
                });
              }}
            />
          </div>
          <ChatbotLogoAppearance
            chatbot={chatbot}
            chatInterface={chatInterface}
            setChatInterface={setChatInterface}
          />
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <Label className="text-md font-medium leading-none tracking-tight" htmlFor="rate-limit">
                Dimensions
              </Label>
              <p className="text-sm text-muted-foreground">
                Configure the dimensions of your chat interface to fit your design needs. Adjust the width and
                height settings to customize how much space the chat window occupies on your page.
              </p>
              <span className="text-xs mt-1 text-warning block">
                Note: These size settings apply only to the Chat Widget integration.
              </span>
            </div>
            <div id="dimensions" className="gap-4 flex items-center flex-wrap text-sm">
              <div className="flex items-center gap-1">
                <span className="text-muted-foreground">Width</span>
                <Input
                  className="w-20 inline-block"
                  type="number"
                  value={chatInterface?.dimensions?.width ?? 450}
                  min={300}
                  max={1300}
                  onChange={(e) => {
                    const int = parseInt(e.target.value, 10);
                    setChatInterface({
                      ...chatInterface,
                      dimensions: {
                        ...chatInterface.dimensions,
                        width: int,
                      },
                    });
                  }}
                />
                <span className="text-muted-foreground">px</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-muted-foreground">Height</span>
                <Input
                  className="w-20 inline-block"
                  type="number"
                  value={chatInterface?.dimensions?.height ?? 800}
                  min={300}
                  max={1300}
                  onChange={(e) => {
                    const int = parseInt(e.target.value, 10);
                    setChatInterface({
                      ...chatInterface,
                      dimensions: {
                        ...chatInterface.dimensions,
                        height: int,
                      },
                    });
                  }}
                />
                <span className="text-muted-foreground">px</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="font"
            >
              Font
            </Label>
            <p className="text-sm text-muted-foreground">
              Select from a curated collection of popular fonts to personalize your design and elevate your
              visual style effortlessly.
            </p>
            <Combobox
              value={chatInterface?.font_style || fontQuery}
              onChange={(value: string) => {
                setChatInterface({
                  ...chatInterface,
                  font_style: value === 'None' ? '' : value,
                });
                setFontQuery('');
              }}
            >
              <div id="font" className="relative">
                <div className="relative">
                  <ComboboxInput
                    style={{ fontFamily: chatInterface?.font_style }}
                    placeholder="Inter"
                    className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    displayValue={() => {
                      return fontQuery || chatInterface?.font_style;
                    }}
                    onChange={(e) => {
                      setFontQuery(e.target.value);
                    }}
                  />
                  <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDown strokeWidth={1.75} className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </ComboboxButton>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ComboboxOptions className="absolute w-full z-[50] px-1 mt-1 max-h-60 overflow-auto rounded-md bg-background py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {fontsToShow.length === 0 ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        No available font.
                      </div>
                    ) : (
                      fontsToShow.map((font) => {
                        const isSelected =
                          chatInterface.font_style === font.label ||
                          (chatInterface.font_style === '' && font.label === 'None');
                        return (
                          <ComboboxOption
                            key={font.label}
                            className="relative flex hover:bg-accent cursor-pointer w-full select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-5"
                            value={font.label}
                          >
                            <span style={{ fontFamily: font.style }} className="block truncate ml-2">
                              {font.label}
                            </span>
                            {isSelected && (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                                <CheckIcon
                                  strokeWidth={1.75}
                                  className="h-4 w-4 text-success"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </ComboboxOption>
                        );
                      })
                    )}
                  </ComboboxOptions>
                </Transition>
              </div>
            </Combobox>
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="chat-size"
            >
              Content Size Adjustment
            </Label>
            <p className="text-sm text-muted-foreground">
              Scale the chat interface from XS to XL, ensuring optimal readability and comfort for your
              viewing preferences.
            </p>
            <div id="chat-size" className="flex items-center gap-2">
              <Toggle
                pressed={chatInterface?.chat_size === 'xs'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    chat_size: 'xs',
                  });
                }}
              >
                XS
              </Toggle>
              <Toggle
                pressed={chatInterface?.chat_size === 'sm'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    chat_size: 'sm',
                  });
                }}
              >
                SM
              </Toggle>
              <Toggle
                pressed={!chatInterface?.chat_size || chatInterface?.chat_size === 'md'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    chat_size: 'md',
                  });
                }}
              >
                MD
              </Toggle>
              <Toggle
                aria-label="light"
                pressed={chatInterface?.chat_size === 'lg'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    chat_size: 'lg',
                  });
                }}
              >
                LG
              </Toggle>
              <Toggle
                aria-label="light"
                pressed={chatInterface?.chat_size === 'xl'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    chat_size: 'xl',
                  });
                }}
              >
                XL
              </Toggle>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="theme"
            >
              Theme
            </Label>
            <div id="theme" className="flex items-center gap-2">
              <Toggle
                aria-label="light"
                pressed={chatInterface.theme === 'light'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    theme: 'light',
                  });
                }}
              >
                <Sun strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Light
              </Toggle>
              <Toggle
                aria-label="dark"
                pressed={chatInterface.theme === 'dark'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    theme: 'dark',
                  });
                }}
              >
                <Moon strokeWidth={1.75} className="mr-2 h-4 w-4" />
                Dark
              </Toggle>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label
              className="text-md font-medium leading-none tracking-tight flex items-center"
              htmlFor="chat-auto-open"
            >
              Chat Auto-Open
            </Label>
            <p className="text-sm text-muted-foreground">
              Chat window automatically appears upon accessing the platform.
            </p>
            <div id="open_by_default" className="flex items-center gap-2">
              <Toggle
                pressed={chatInterface?.open_by_default === 'off'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    open_by_default: 'off',
                  });
                }}
              >
                <PowerOff className="w-4 h-4 mr-2" />
                OFF
              </Toggle>
              <Toggle
                pressed={chatInterface?.open_by_default === 'on'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    open_by_default: 'on',
                  });
                }}
              >
                <Power className="w-4 h-4 mr-2" />
                ON
              </Toggle>
              <Toggle
                pressed={chatInterface?.open_by_default === 'computer'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    open_by_default: 'computer',
                  });
                }}
              >
                <Dock className="w-4 h-4 mr-2" />
                Computer
              </Toggle>
              <Toggle
                pressed={chatInterface?.open_by_default === 'mobile'}
                onPressedChange={() => {
                  setChatInterface({
                    ...chatInterface,
                    open_by_default: 'mobile',
                  });
                }}
              >
                <Smartphone className="w-4 h-4 mr-2" />
                Mobile
              </Toggle>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  },
);

export default GeneralAppearance;
