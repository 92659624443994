/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
import { format, subDays } from 'date-fns';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import React from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const getLabels = (range: string, today = new Date()) => {
  switch (range) {
    case 'week':
      return [...Array(7)]
        .map((_, i) => {
          const date = new Date(today);
          date.setDate(today.getDate() - i);
          const formattedDate = format(date, 'MMM d');
          const suffix = i === 0 ? ' (today)' : '';
          return `${formattedDate}${suffix}`;
        })
        .reverse();

    case 'month':
      return [...Array(30)]
        .map((_, i) => {
          const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
          const formattedDate = format(date, 'MMM d');
          const suffix = i === 0 ? ' (today)' : '';
          return `${formattedDate}${suffix}`;
        })
        .reverse();

    case '3months':
      return [...Array(12)]
        .map((_, i) => {
          const endDate = subDays(today, i * 7);
          const startDate = subDays(endDate, 6);
          const formattedStartDate = format(startDate, 'MMM d');
          const formattedEndDate = format(endDate, 'MMM d');
          const suffix = i === 0 ? ' (today)' : '';
          return `${formattedStartDate} - ${formattedEndDate}${suffix}`;
        })
        .reverse();

    case '6months':
      return [...Array(24)]
        .map((_, i) => {
          const endDate = subDays(today, i * 7);
          const startDate = subDays(endDate, 6);
          const formattedStartDate = format(startDate, 'MMM d');
          const formattedEndDate = format(endDate, 'MMM d');
          const suffix = i === 0 ? ' (today)' : '';
          return `${formattedStartDate} - ${formattedEndDate}${suffix}`;
        })
        .reverse();

    case 'year':
      return [...Array(12)]
        .map((_, i) => {
          const monthEndDate = new Date(today.getFullYear(), today.getMonth() - i, today.getDate());
          const monthStartDate = subDays(monthEndDate, 29);
          const formattedStartDate = format(monthStartDate, 'MMM d');
          const formattedEndDate = format(monthEndDate, 'MMM d');
          const suffix = i === 0 ? ' (today)' : '';
          return `${formattedStartDate} - ${formattedEndDate}${suffix}`;
        })
        .reverse();

    default:
      return [];
  }
};

const ConversationChart: React.FC<{
  range: string;
  data?: number[];
}> = ({ range, data = [] }) => {
  const { primaryColor } = useWhitelabelData();
  const labels = getLabels(range);

  const mappedData = data.map((value, index) => ({
    total: value,
    name: labels[index],
  }));

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={mappedData}>
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <div className="rounded-md border bg-background p-2 shadow-sm">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <span className="text-[0.70rem] uppercase text-muted-foreground">
                        {payload[0].value} conversations
                      </span>
                      <span className="font-bold">{label}</span>
                    </div>
                  </div>
                </div>
              );
            }

            return null;
          }}
        />
        <XAxis dataKey="name" stroke="#64748b" fontSize={12} />
        <YAxis stroke="#64748b" fontSize={12} tickLine={false} axisLine={false} />
        <Tooltip />
        <Line type="monotone" dataKey="total" stroke={primaryColor} strokeWidth={1.5} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ConversationChart;
