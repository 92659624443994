import React, { useEffect } from 'react';

interface DrivePickerProps {
  onFileSelected: (files: any) => void;
  accessToken: string;
  refresh: () => void;
}

const DrivePicker: React.FC<DrivePickerProps> = ({ onFileSelected, accessToken, refresh }) => {
  const pickerCallback = (data: any) => {
    if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
      onFileSelected(data[window.google.picker.Response.DOCUMENTS]);
    }
    if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.CANCEL) {
      refresh();
    }
  };

  const createPicker = () => {
    if (window.google && window.google.picker) {
      const picker = new window.google.picker.PickerBuilder()
        // .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId('465578204152-iv0rolmmgleg3ek5c3tdse74v0np6hhh.apps.googleusercontent.com')
        .setOAuthToken(accessToken)
        .setDeveloperKey('AIzaSyDzwShXMd_6ToJdvwvSuq2Z_mjntHE5z-4')
        .setCallback(pickerCallback)
        .addView(
          new window.google.picker.DocsView().setIncludeFolders().setSelectFolderEnabled().setMimeTypes(
            [
              'application/vnd.google-apps.document', // Google DOCX file
              'application/pdf', // PDF files
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX files
              'text/plain', // TXT files
              'text/markdown', // MD files
              'application/x-tex', // TEX files
              'image/png', // PNG images
              'image/jpg', // JPG images
              'image/jpeg', // JPEG images
              'image/webp', // WEBP images
            ].join(','),
          ),
        )
        .build();
      picker.setVisible(true);
    }
  };

  useEffect(() => {
    function loadGoogleApi(onLoad: () => void) {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.defer = true;
      script.onload = () => onLoad();
      document.body.appendChild(script);
    }

    loadGoogleApi(() => {
      window.gapi.load('client:picker', () => {
        createPicker();
      });
    });
  }, []);

  return null;
};

export default DrivePicker;
