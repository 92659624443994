import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Loader2 } from 'lucide-react';
import { AIAgent } from 'models/api/response.types';
import agentService from 'api/agent';
import useAiAgents from 'hooks/useAiAgents';
import { IntentToDelete } from './CreateEditAgentIntentDialog';

const ConfirmSessionDeleteionDialog: React.FC<{
  intent?: IntentToDelete;
  agent: AIAgent;
  close: () => void;
}> = ({ intent, agent, close }) => {
  const { updateAgent } = useAiAgents();
  const [show, setShow] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const { intents_json } = agent;

  useEffect(() => {
    if (intent) {
      setShow(true);
    }
  }, [intent]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  const deleteIntent = () => {
    if (intent) {
      setDeleting(true);

      const newIntents = JSON.parse(intents_json);
      if (!intent.is_locked) {
        newIntents.intents = newIntents.intents.filter((_intent: any, idx: number) => idx !== intent.index);
      } else {
        newIntents.locked_intents = newIntents.locked_intents.filter(
          (_intent: any, idx: number) => idx !== intent.index,
        );
      }

      agentService
        .updateAIAgent(agent.uuid, { intents_json: JSON.stringify(newIntents) })
        .then((res) => {
          updateAgent(res);
          hide();
        })
        .finally(() => setDeleting(false));
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete Intent
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you wish to delete this intent entirely?
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button disabled={deleting} variant="destructive" onClick={deleteIntent}>
                    {deleting && <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />}
                    Delete
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmSessionDeleteionDialog;
