import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Chatbot } from 'models/api/response.types';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import domainService from 'api/domain';
import { Button } from 'components/ui/button';
import { ChevronLeft, ChevronRight, ClipboardCopy } from 'lucide-react';
import { cn } from 'utils/cn';
import { useAlerts } from 'providers/AlertProvider';
import { defaultIntegrationDomain } from 'utils/domain';
import { alerts } from 'utils/alert';

const IframeIntegaration: React.FC<{
  isWix?: boolean;
  isFullWidth?: boolean;
}> = ({ isWix, isFullWidth }) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: customDomains } = useQuery({
    queryKey: ['chatbot-domains', chatbot?.uuid],
    queryFn: () => domainService.getChatbotDomains(chatbot.uuid),
  });

  const generateBubbleLink = (domain: string) => {
    return `<iframe
  src="https://${domain}/widget/${chatbot.uuid}${isWix ? '?wix=true' : ''}"
  width="100%"
  height="${isWix || isFullWidth ? '100%' : '500px'}"
  frameborder="0"
  allow="clipboard-read; clipboard-write"
></iframe>
`;
  };

  const domainsToUse: { domain: string; link: string }[] = useMemo(() => {
    if (customDomains) {
      return [
        ...customDomains
          .filter((domain) => domain.configuration_status === 'configured')
          .map((domain) => {
            return {
              domain: domain.domain,
              link: generateBubbleLink(domain.domain),
            };
          }),
        {
          domain: defaultIntegrationDomain,
          link: generateBubbleLink(defaultIntegrationDomain),
        },
      ];
    }
    return [
      {
        domain: defaultIntegrationDomain,
        link: generateBubbleLink(defaultIntegrationDomain),
      },
    ];
  }, [customDomains]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : domainsToUse.length - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex < domainsToUse.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <>
      {domainsToUse.length > 1 && (
        <div className="flex items-center gap-4 mt-4">
          <Button className="h-8 w-8 p-0" onClick={handlePrevClick} variant="outline" size="icon">
            <ChevronLeft strokeWidth={1.75} className="h-4 w-4" />
          </Button>
          <div className="flex-1 overflow-hidden">
            <div
              className="flex text-center flex-1 transition-all duration-300"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {domainsToUse.map((item) => (
                <div key={item.domain} className="flex-shrink-0 w-full">
                  <h3 className="font-semibold text-muted-foreground">{item.domain}</h3>
                </div>
              ))}
            </div>
          </div>
          <Button className="h-8 w-8 p-0" onClick={handleNextClick} variant="outline" size="icon">
            <ChevronRight strokeWidth={1.75} className="h-4 w-4" />
          </Button>
        </div>
      )}
      <div className={cn('w-full overflow-hidden', domainsToUse.length > 0 ? ' mt-2' : 'mt-4')}>
        <div
          className="flex transition-all duration-300"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {domainsToUse.map((item) => (
            <div key={item.domain} className="flex-shrink-0 flex w-full items-center gap-4">
              <pre className="relative w-full overflow-auto text-xs bg-secondary-background rounded p-3">
                <code>{item.link}</code>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0 absolute top-2 right-2 bg-background"
                  onClick={() => {
                    if ('clipboard' in navigator) {
                      navigator.clipboard.writeText(item.link);
                      addAlert({
                        severity: 'success',
                        message: alerts.IFRAME_COPIED,
                      });
                    }
                  }}
                >
                  <ClipboardCopy strokeWidth={1.75} className="w-4 h-4" />
                </Button>
              </pre>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default IframeIntegaration;
