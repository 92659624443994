import React, { Fragment, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { AIAgentTag } from 'models/api/response.types';
import { Label } from 'components/ui/label';
import { Asterisk } from 'lucide-react';
import { Input } from 'components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { SketchPicker } from 'react-color';

const CreateEditAgentTagDialog: React.FC<{
  tagToEdit?: AIAgentTag;
  currentTags: AIAgentTag[];
  close: (variable?: AIAgentTag) => void;
}> = ({ tagToEdit, currentTags, close }) => {
  const [name, setName] = useState<string>(tagToEdit?.name || '');
  const [criteria, setCriteria] = useState<string>(tagToEdit?.criteria || '');
  const [color, setColor] = useState<string>(tagToEdit?.color || '#0071F9');

  const existingTagNames: string[] = useMemo(() => {
    return currentTags.map((param: AIAgentTag) => param.name);
  }, [currentTags]);

  const isNameExistsError = useMemo(() => {
    return (
      name !== tagToEdit?.name &&
      existingTagNames.some((var_name) => var_name.toLocaleLowerCase() === name.toLocaleLowerCase())
    );
  }, [name]);

  const validToUpdate = useMemo(() => {
    return !isNameExistsError && name && criteria;
  }, [name, criteria]);

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => close()}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full overflow-hidden items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-h-full flex flex-col max-w-xl transform overflow-hidden rounded-md bg-background py-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900 px-6">
                  {tagToEdit ? 'Edit Tag' : 'Create Tag'}
                </DialogTitle>
                <div className="mt-4 flex flex-col gap-4 flex-1 overflow-auto px-6">
                  <div>
                    <Label className="flex items-center" htmlFor="tag-name">
                      Name
                      <Asterisk className="text-destructive w-4 h-4 ml-1" />
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      This is the displayed label of your tag. Please note that you cannot edit tags that have
                      already been assigned to conversations.
                    </p>
                    <Input
                      id="tag-name"
                      className="mt-2"
                      type="text"
                      autoFocus
                      value={name}
                      maxLength={50}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && validToUpdate) {
                          close({ name, criteria, color });
                        }
                      }}
                    />
                    {isNameExistsError && (
                      <p className="text-xs text-destructive ml-1">
                        Name already exists and used by another tag within your agent.
                      </p>
                    )}
                  </div>
                  <div>
                    <Label className="flex items-center" htmlFor="tag-criteria">
                      Criteria
                      <Asterisk className="text-destructive w-4 h-4 ml-1" />
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      In plain language, specify the criteria under which the tag should be applied to the
                      user query / AI response pair. Be as specific and explicit as possible. Please note that
                      the criteria can only be evaluated based on information contained within the
                      conversation history or the Agent itself. This Agent does not have access to information
                      residing within other Agents or sources to which it has no direct access.
                    </p>
                    <Input
                      id="tag-criteria"
                      className="mt-2"
                      type="text"
                      value={criteria}
                      maxLength={250}
                      onChange={(e) => {
                        setCriteria(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && validToUpdate) {
                          close({ name, criteria, color });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Label htmlFor="tag-color">Color</Label>
                    <div className="flex items-center gap-4 w-fit mt-2 pb-2">
                      <Popover>
                        <PopoverTrigger asChild>
                          <div
                            className="p-1 w-[110px] rounded-md border flex items-center gap-2 cursor-pointer hover:scale-[1.02] transition-all"
                            style={{
                              borderColor: color,
                            }}
                          >
                            <div
                              id="chatbot-message-color"
                              className="w-[25px] h-[25px] rounded-md"
                              style={{
                                backgroundColor: color,
                              }}
                            />
                            <p className="text-sm font-normal text-muted-foreground">{color}</p>
                          </div>
                        </PopoverTrigger>
                        <PopoverContent className="p-0 w-auto z-[1000]">
                          <SketchPicker
                            color={color}
                            onChange={(c: { hex: string }) => {
                              setColor(c.hex);
                            }}
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-4 mt-6 justify-end px-6">
                  <Button variant="outline" onClick={() => close()}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      close({ name, criteria, color });
                    }}
                    disabled={!validToUpdate}
                  >
                    {tagToEdit ? 'Save' : 'Create'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateEditAgentTagDialog;
