/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  ChatBotMeta,
  IChatMainColors,
  IUserMessageColors,
  WidgetSize,
  chatSizeVariants,
  defaultChatbotData,
} from 'utils/bot';
import { cn } from 'utils/cn';
import remarkGfm from 'remark-gfm';
import Strong from 'components/helpers/chat/MarkdownElements/Strong';
import Link from 'components/helpers/chat/MarkdownElements/Link';
import { addCookie } from 'utils/cookies';
import SubmitButton from './SubmitButton';

export const WidgetDisclaimer: React.FC<{
  cookieDisclaimerKey: string;
  chatbotMeta: ChatBotMeta;
  size: WidgetSize;
  chatMainColors: IChatMainColors;
  userMessageColors: IUserMessageColors;
  hide: () => void;
  // ChatAppearancePreview UI
  isPreview?: boolean;
}> = ({ isPreview, cookieDisclaimerKey, chatbotMeta, size, chatMainColors, userMessageColors, hide }) => {
  const [show, setShow] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);

  // when accept disclaimer set expiration time for 6 months
  // that's the max limit allows by gdpr
  const acceptDisclaimer = () => {
    setSubmitting(true);
    if (!isPreview) {
      addCookie(`${cookieDisclaimerKey}${new Date().toISOString()}`, 24);
    }
    setTimeout(() => {
      setShow(false);
      setTimeout(() => {
        hide();
      }, 300);
    }, 1000);
  };

  return (
    <div
      className={cn(
        'disclaimer-container rounded-b-[0.75rem] max-h-[60%] w-full absolute bottom-0 transition-all duration-300 ease-in-out z-10 border-t overflow-auto shadow-md',
        show ? 'opacity-100  translate-y-0' : 'opacity-0 translate-y-full',
        chatSizeVariants.disclaimer.container[size],
      )}
      style={{
        color: chatMainColors.text,
        borderColor: chatMainColors.border,
        backgroundColor: chatMainColors.lightBackground,
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        className={cn('prose max-w-full leading-normal text-inherit border-inherit')}
        disallowedElements={['pre', 'code', 'table']}
        components={{
          a: (e) => <Link data={e} />,
          strong: (e) => <Strong data={e} />,
        }}
      >
        {chatbotMeta?.disclaimer?.description}
      </ReactMarkdown>
      <SubmitButton
        submitting={submitting}
        size={size}
        text={chatbotMeta?.disclaimer?.button_text || defaultChatbotData.disclaimer.button_text}
        userMessageColors={userMessageColors}
        onClick={acceptDisclaimer}
      />
    </div>
  );
};

export default WidgetDisclaimer;
