import { Label } from 'components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import React from 'react';
import { SketchPicker } from 'react-color';
import { ChatBotMeta } from 'utils/bot';

export const ChatbotUserMessageColorAppearance: React.FC<{
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}> = ({ chatInterface, setChatInterface }) => {
  return (
    <div className="flex flex-col gap-2">
      <Label
        className="text-md font-medium leading-none tracking-tight flex items-center"
        htmlFor="user-message-color"
      >
        User Message Color
      </Label>
      <p className="text-sm text-muted-foreground">
        Select a unique color for the messages you and your users type.
      </p>
      <div className="flex items-center gap-4 w-fit">
        <Popover>
          <PopoverTrigger asChild>
            <div
              className="p-1 w-[110px] bg-background rounded-md border flex items-center gap-2 cursor-pointer hover:scale-[1.02] transition-all"
              style={{
                borderColor: chatInterface.user_message_color,
              }}
            >
              <div
                id="chatbot-message-color"
                className="w-[25px] h-[25px] rounded-md"
                style={{
                  backgroundColor: chatInterface.user_message_color,
                }}
              />
              <p className="text-sm font-normal text-muted-foreground">{chatInterface.user_message_color}</p>
            </div>
          </PopoverTrigger>
          <PopoverContent className="p-0 w-auto z-[1001]">
            <SketchPicker
              color={chatInterface.user_message_color}
              onChange={(color: { hex: string }) => {
                setChatInterface({
                  ...chatInterface,
                  user_message_color: color.hex,
                });
              }}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default ChatbotUserMessageColorAppearance;
