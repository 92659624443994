/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import { cn } from 'utils/cn';
import {
  BadgeAlert,
  Cctv,
  Loader2,
  LocateFixed,
  MessageCircle,
  Shield,
  Speech,
  Users,
  Image,
  X,
} from 'lucide-react';
import { Separator } from 'components/ui/separator';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { AIAgentCreateBody, AIAgentVariable, Chatbot } from 'models/api/response.types';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import agentService from 'api/agent';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import useAiAgents from 'hooks/useAiAgents';
import { predefinedAgentsData } from 'utils/agent';
import { isPrimaryProduct } from 'utils/domain';
import { Badge } from 'components/ui/badge';
import ImageGeneratorDisclaimerDialog from 'components/Dialogs/Agents/ImageGeneratorDisclaimerDialog';

interface IPredefinedTemplate {
  disabled?: boolean;
  title: string;
  description: string;
  icon: JSX.Element;
  data: any;
}

const PredefinedTemplates: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const {
    canCreateHumanEscalationAgent,
    canCreateBackgroundAgents,
    canCreateImageGeneratorAgent,
    isGPT4Enabled,
  } = useSubscriptionInfo();
  const { agents, refetchAgents } = useAiAgents();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [creating, setCreating] = useState<number>(-1);
  const [showDisclaimerDialog, setShowDisclaimerDialog] = useState<boolean>(false);

  const { spamDefenseAgentExists, humanEscalationAgentExists } = useMemo(() => {
    return {
      humanEscalationAgentExists: agents?.some((agent) => agent.type === 'human-escalation'),
      spamDefenseAgentExists: agents?.some((agent) => agent.type === 'spam-defense'),
    };
  }, [agents]);

  const existingVariableNames: string[] = useMemo(() => {
    if (agents) {
      const variables = agents.flatMap((ag) => {
        const vars = JSON.parse(ag?.variables_json || '[]') as AIAgentVariable[];
        return vars.map((param: AIAgentVariable) => param.name);
      });
      return variables;
    }
    return [];
  }, [agents]);

  const createAIAgent = (data: AIAgentCreateBody, index: number) => {
    if (data.name === 'Image Generator') {
      setShowDisclaimerDialog(true);
    }
    setCreating(index);
    agentService
      .createAIAgent({
        ...data,
        description: data.description,
        prompt: data.prompt,
        chatbot_uuid: selectedChatbot.uuid,
      })
      .then(() => {
        refetchAgents();
        hide();
        setCreating(-1);
      })
      .catch(() => {
        setCreating(-1);
      });
  };

  const predefinedTemplates: IPredefinedTemplate[] = useMemo(() => {
    const templates = [
      {
        icon: <MessageCircle strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-slate-600" />,
        title: 'General Q&A',
        description: `The General Q&A agent handles general user inquiries 
        and provides informed answers based on knowledge from the source library.`,
        data: predefinedAgentsData.general_qa(isGPT4Enabled),
      },
      {
        disabled: !canCreateBackgroundAgents,
        icon: <Cctv strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-emerald-600" />,
        title: 'Conversation Labeling',
        description: `This background agent monitors ongoing conversation and assigns tags to user queries based on predefined criteria. It does not engage with users directly in any way.`,
        data: predefinedAgentsData.conversation_monitoring,
      },
      {
        icon: <Users strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-blue-600" />,
        title: 'Lead Collection',
        description: `An agent that specializes in collecting contact information conversationally 
          from users. You can control what information is collected by modifying the base 
          prompt and creating the associated variable to store that information.`,
        data: predefinedAgentsData.lead_collection(existingVariableNames, isGPT4Enabled),
      },
      {
        disabled: !canCreateHumanEscalationAgent,
        icon: <Speech strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-indigo-600" />,
        title: 'Human Escalation',
        description: `The Human Escalation agent handles user requests for speaking with a human agent. The request for human support should be clear and unambiguous.`,
        data: predefinedAgentsData.human_escalation,
      },
      {
        disabled: !canCreateBackgroundAgents,
        icon: <BadgeAlert strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-pink-700" />,
        title: 'Frustration Detection',
        description: `A background agent designed to monitor conversations for signs of user frustration.
          It will assign pre-defined categorical tags to mark locations where suspected frustration is detected.`,
        data: predefinedAgentsData.frustration_detection,
      },
      {
        icon: <LocateFixed strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-yellow-500" />,
        title: 'Fixed Response Agent',
        description: `The Fixed Response Agent outputs a pre-canned response no matter what input is made by the user. It is particularly useful for handling queries that are out-of-scope of your chatbot and should not be routed to a LLM.`,
        data: predefinedAgentsData.fixed_response,
      },
      {
        disabled: spamDefenseAgentExists,
        icon: <Shield strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-destructive" />,
        title: 'Spam Defense Agent',
        description: `Spam Defense is designed to handle incoming queries that are spammy, malicious, or otherwise unrelated to your chatbot's designed scope of operations. It does not rely on any LLM, but rather outputs a fixed response regardless of what the user input.`,
        data: predefinedAgentsData.spam_defense,
      },
    ];

    // image generator can be used only inside gpt-trainer
    if (isPrimaryProduct) {
      templates.push({
        disabled: !canCreateImageGeneratorAgent,
        icon: <Image strokeWidth={1.75} className="mr-1 h-6 w-6 min-w-[24px] text-cyan-600" />,
        title: 'Image Generator',
        description: `The image generator produces AI-generated images based on custom instructions within a base prompt and any provided user input. It is designed for creative expressions and not for charting, plotting, or analytical visuals.`,
        data: predefinedAgentsData.image_generator,
      });
    }

    return templates;
  }, []);

  return (
    <>
      <div
        className={cn(
          'z-[3] absolute left-0 top-0 h-full transition-all duration-300 overflow-hidden ease-out w-full md:w-[500px]',
          show ? 'translate-x-[0px]' : '-translate-x-[120%]',
        )}
      >
        <div className="bg-background shadow-sm border-r h-full overflow-hidden flex flex-col pb-6">
          <div className="flex items-center text-lg font-semibold text-foreground px-6 pt-4">
            <h1>AI Agent Templates</h1>
            <button
              type="button"
              onClick={hide}
              className="rounded-sm border p-1 opacity-70 ring-offset-background transition-opacity hover:opacity-100 ml-auto"
            >
              <X strokeWidth={1.75} className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </button>
          </div>
          <Separator className="my-4" />
          <div className="flex flex-col gap-4 px-6 overflow-auto">
            {predefinedTemplates.map((template, index) => {
              const { icon, title, disabled, data } = template;
              const isHumanEscalation = data.type === 'human-escalation';
              const isImageGenerator = data.type === 'image-generator';
              const isSpamDefense = data.type === 'spam-defense';
              const canUpgradeToUse = ['human-escalation', 'background', 'image-generator'].includes(
                data.type,
              );
              const singleAgentOnly =
                (isHumanEscalation && humanEscalationAgentExists) ||
                (isSpamDefense && spamDefenseAgentExists);

              return (
                <Card key={index} className=" w-full flex flex-col relative">
                  <CardHeader className="flex flex-row items-center gap-2 space-y-0 p-4 pb-0">
                    {icon}
                    <CardTitle className="line-clamp-1">{title}</CardTitle>
                    {isImageGenerator && (
                      <Badge variant="outline" className="bg-secondary text-background rounded-md ml-auto">
                        Beta
                      </Badge>
                    )}
                  </CardHeader>
                  <Separator className="my-2" />
                  <CardContent className="p-4 pt-0 flex-1 flex flex-col">
                    <CardDescription className="mb-4 line-clamp-5">{template.description}</CardDescription>
                    <div className="flex items-center gap-2 mt-auto justify-end">
                      {singleAgentOnly ? (
                        <Button variant="outline" disabled>
                          Added (Only One)
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              createAIAgent(data, index);
                            }}
                            disabled={creating > -1 || disabled}
                            variant="outline"
                          >
                            {creating === index && (
                              <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Choose template
                          </Button>
                        </>
                      )}
                    </div>
                  </CardContent>
                  {disabled && canUpgradeToUse && (
                    <UpgradeRequired
                      className="absolute"
                      heading={
                        isImageGenerator
                          ? 'Upgrade to Unlock Image Generator Agent'
                          : isHumanEscalation
                            ? 'Upgrade to Unlock Human Escalation Agent'
                            : 'Upgrade to Unlock Background Agent'
                      }
                    >
                      {isImageGenerator
                        ? 'Please upgrade your subscription to access the image generator feature, allowing your chatbot to create stunning visuals on demand.'
                        : isHumanEscalation
                          ? 'Please upgrade your subscription to access human escalation feature to detect user requests for live support and seamlessly provide customer support via live chat.'
                          : 'Please upgrade your subscription to access background agents for performing tasks and processing necessary information'}
                    </UpgradeRequired>
                  )}
                </Card>
              );
            })}
          </div>
        </div>
      </div>
      <ImageGeneratorDisclaimerDialog
        show={showDisclaimerDialog}
        close={() => setShowDisclaimerDialog(false)}
      />
    </>
  );
};

export default PredefinedTemplates;
