/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessToken } from 'models/api/response.types';
import { IState } from '.';

export interface SessionState {
  user: AccessToken | undefined;
}

export const initialState: SessionState = {
  user: undefined,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AccessToken>) {
      state.user = action.payload;
    },
    clearSession(state) {
      state.user = undefined;
    },
  },
});

export const { reducer } = sessionSlice;

// redux actions
export const { setUser, clearSession } = sessionSlice.actions;

// redux selectors
export const getCurrentUser = (state: IState) => state.session.user;
export const selectSessionState = (state: IState) => state.session;
export const getAllState = (state: IState) => state;

export const cleanup = (
  state?: IState,
): {
  type: 'session/cleanup';
  state?: IState;
} => ({
  type: 'session/cleanup',
  state,
});

export const updateAppVerison = (
  sessionState: SessionState,
): {
  type: 'session/update_app_version';
  sessionState: SessionState;
} => ({
  type: 'session/update_app_version',
  sessionState,
});
