import AIModelsGuideDialog from 'components/Dialogs/AIModelsGuideDialog';
import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Label } from 'components/ui/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from 'components/ui/select';
import { Slider } from 'components/ui/slider';
import { Textarea } from 'components/ui/textarea';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Asterisk, Check, ChevronsUpDown } from 'lucide-react';
import { AIAgentEditData, AgentTokenDistribution } from 'models/api/response.types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AIModels,
  AgentModel,
  agentTokenDistribution,
  aiModelsGuide,
  backgroundAgentModels,
  functionCallingDisabledModels,
  openAiModelCharacterLimit,
} from 'utils/agent';
import { cn } from 'utils/cn';

const BackgroundUserFacingOptions: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ data, setData }) => {
  const { isGPT4Enabled } = useSubscriptionInfo();
  const [promptError, setPromptError] = useState<boolean>(false);
  const [showAIModelsGuide, setShowAIModelsGuide] = useState<boolean>(false);

  const promptCharacterLimit = useMemo(() => {
    return openAiModelCharacterLimit[data.meta.model];
  }, [data.meta.model]);

  useEffect(() => {
    const { prompt } = data;
    if (prompt.length === 0) {
      setPromptError(true);
    } else if (promptError) {
      setPromptError(false);
    }
  }, [data]);

  useEffect(() => {
    if (promptCharacterLimit < data.prompt.length) {
      setPromptError(true);
    } else if (promptError && data.prompt.length > 0) {
      setPromptError(false);
    }
  }, [promptCharacterLimit, data.prompt]);

  const selectedModelCredits = useMemo(() => {
    return aiModelsGuide.find((model) => model.model_name === AIModels[data.meta.model])?.credit_cost;
  }, [data]);

  const groupedAIModelsToShow: { [key: string]: AgentModel[] } = useMemo(() => {
    const group35: AgentModel[] = [];
    const group4: AgentModel[] = [];
    const group4o: AgentModel[] = [];
    const group4omini: AgentModel[] = [];
    Object.keys(AIModels).forEach((item) => {
      if (data.type === 'background' || !backgroundAgentModels.includes(item as AgentModel)) {
        if (item.startsWith('gpt-3.5')) {
          group35.push(item as AgentModel);
        } else if (item.startsWith('gpt-4o')) {
          if (item.includes('mini')) {
            group4omini.push(item as AgentModel);
          } else {
            group4o.push(item as AgentModel);
          }
        } else {
          group4.push(item as AgentModel);
        }
      }
    });
    return {
      'GPT-3.5': group35,
      'GPT-4': group4,
      'GPT-4o': group4o,
      'GPT-4o-mini': group4omini,
    };
  }, [data]);

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label className="text-md font-medium leading-none tracking-tight" htmlFor="agent-model">
          Model{' '}
        </Label>
        <p className="text-sm text-muted-foreground">
          Choose the large language model (LLM) to power your Agent. Each model carries different token limits
          and Message Credit (MC) costs. For detailed information, please review this{' '}
          <span
            className="text-secondary font-medium hover:underline cursor-pointer"
            onClick={() => setShowAIModelsGuide(true)}
          >
            table
          </span>
          .
        </p>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="max-w-[300px] hover:bg-transparent font-normal flex items-center justify-between"
              variant="outline"
            >
              <p className="text-left">{AIModels[data.meta.model]}</p>
              <ChevronsUpDown strokeWidth={1.75} className="h-4 w-4 min-w-[16px] opacity-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[300px]">
            <DropdownMenuLabel className="flex items-center gap-2">
              <img className="w-6 h-6" src="/img/open-ai-logo.svg" alt="OpenAI" />
              Open AI
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {Object.keys(groupedAIModelsToShow).map((key) => {
              const isDisabled = key === 'GPT-4' && !isGPT4Enabled;
              return (
                <DropdownMenuSub key={key}>
                  <DropdownMenuSubTrigger className={cn(isDisabled && 'opacity-30')} disabled={isDisabled}>
                    {key}
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className="w-[300px]">
                      {groupedAIModelsToShow[key].map((model) => {
                        const isSelected = model === data.meta.model;
                        return (
                          <DropdownMenuItem
                            onClick={() => {
                              setData({
                                ...data,
                                meta: {
                                  ...data.meta,
                                  model: model as AgentModel,
                                },
                              });
                            }}
                            key={model}
                            className="flex items-center gap-2 justify-between"
                          >
                            {AIModels[model]}
                            {isSelected && <Check strokeWidth={1.75} className="h-4 w-4 text-success" />}
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
        <p className="ml-1 text-xs text-warning">{selectedModelCredits} message credit(s) per message</p>
      </div>
      {data.type === 'user-facing' && (
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="token-distribution">
            Token Limit Distribution
          </Label>
          <p className="text-sm text-muted-foreground">
            Set how many tokens you wish to reserve for each part of the LLM call. The overall token limit
            depends on the LLM you picked, but you can fine tune the token distribution based on your
            particular use case.
          </p>
          <Select
            value={data?.meta?.token_distribution || 'default'}
            onValueChange={(val: AgentTokenDistribution) => {
              setData({
                ...data,
                meta: {
                  ...data.meta,
                  token_distribution: val,
                },
              });
            }}
          >
            <SelectTrigger className="max-w-[300px]">
              <p>{agentTokenDistribution[data?.meta?.token_distribution || 'default'].title}</p>
            </SelectTrigger>
            <SelectContent className="max-w-[300px]">
              <SelectGroup>
                {Object.keys(agentTokenDistribution).map((tokenDistribution) => {
                  // 2k models doesn't work with function calling
                  // need to disabled function distribution model for them
                  const isDisabled =
                    functionCallingDisabledModels.includes(data?.meta?.model) &&
                    tokenDistribution === 'max_function_calling';
                  return (
                    <SelectItem disabled={isDisabled} key={tokenDistribution} value={tokenDistribution}>
                      <p>{agentTokenDistribution[tokenDistribution as AgentTokenDistribution].title}</p>
                      <p className="text-xs text-muted-foreground">
                        {agentTokenDistribution[tokenDistribution as AgentTokenDistribution].description}
                      </p>
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
          {functionCallingDisabledModels.includes(data?.meta?.model) &&
            data?.meta?.token_distribution === 'max_function_calling' && (
              <p className="ml-1 text-xs text-warning">
                2k models don&apos;t support function calling, agent will use the default token distribution
                option instead.
              </p>
            )}
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="flex items-end">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="prompt">
            Prompt
            <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
          </Label>
        </div>
        <p className="text-sm text-muted-foreground">
          A prompt acts as the instruction that shapes the AI&apos;s responses. The prompt&apos;s
          effectiveness hinges on its clarity and specificity, so be as explicit as possible when crafting it.
        </p>
        <Textarea
          id="prompt"
          className="min-h-[230px]"
          value={data.prompt}
          rows={3}
          maxLength={promptCharacterLimit}
          placeholder="Enter the prompt"
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setPromptError(true);
            } else if (promptError && promptCharacterLimit < data.prompt.length) {
              setPromptError(false);
            }
            setData({
              ...data,
              prompt: e.target.value,
            });
          }}
        />
        <p
          className={cn(
            'text-xs text-muted-foreground ml-1',
            promptError && data.prompt.length > 0 ? 'text-destructive' : 'text-muted-foreground',
          )}
        >
          Characters: {data.prompt.length} / {promptCharacterLimit}
        </p>
        {promptError && (
          <p className="ml-1 text-xs text-destructive">
            {data.prompt.length > 0
              ? "Your prompt exceeds model limits. Shorten, split, or change the model. Extra characters won't be processed by AI."
              : "Prompt is required field and can't be empty."}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-2 mb-8">
        <Label className="text-md font-medium leading-none tracking-tight" htmlFor="temperature">
          Temperature: {data.meta.temperature}
        </Label>
        <p className="text-sm text-muted-foreground">
          Adjust this setting to influence the unpredictability of the AI&apos;s responses. A lower
          temperature results in more consistent and predictable replies, while a higher setting encourages
          creativity and variation in the agent&apos;s answers.
        </p>
        <Slider
          id="temperature"
          className="mt-2"
          value={[data.meta.temperature]}
          max={1}
          step={0.1}
          onValueChange={(value) => {
            setData({
              ...data,
              meta: {
                ...data.meta,
                temperature: value[0],
              },
            });
          }}
        />
      </div>
      <AIModelsGuideDialog show={showAIModelsGuide} close={() => setShowAIModelsGuide(false)} />
    </>
  );
};

export default BackgroundUserFacingOptions;
