import axios from 'axios';
import {
  AppProductsList,
  CheckoutSubscriptionBody,
  PromoCodeApplyBody,
  PromoCodeResponse,
  SubscriptionInfo,
  UpdateSubscriptionBody,
} from 'models/api/response.types';

const getAccountSubscriptionInfo = () =>
  axios.get<SubscriptionInfo>(`/api/subscription-info`).then((res) => res.data);

const getChatbotSubscriptionInfo = (uuid: string) =>
  axios.get<SubscriptionInfo>(`/chatbot/${uuid}/subscription-info `).then((res) => res.data);

const createCheckoutSession = (payload: CheckoutSubscriptionBody) =>
  axios.post('/api/create-checkout-session', payload).then((res) => res.data);

const createPortalSession = () => axios.post('/api/create-portal-session', {}).then((res) => res);

const cancelSubscription = (subscriptionId: string) =>
  axios.post('/api/cancel-subscription', { subscription_id: subscriptionId }).then((res) => res);

const updateSubscription = (payload: UpdateSubscriptionBody) =>
  axios.post('/api/update-subscription', payload).then((res) => res);

const applyPromoCode = (payload: PromoCodeApplyBody) =>
  axios.post<PromoCodeResponse>('/api/promote-code', payload).then((res) => res);

const getAppProducts = () => axios.get<AppProductsList>(`/api/get-products`).then((res) => res.data);

const subscriptionService = {
  getAppProducts,
  getAccountSubscriptionInfo,
  updateSubscription,
  createCheckoutSession,
  createPortalSession,
  cancelSubscription,
  getChatbotSubscriptionInfo,
  applyPromoCode,
};

export default subscriptionService;
