/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { Asterisk, PlusCircle } from 'lucide-react';
import { Button } from 'components/ui/button';
import { TabsContent } from 'components/ui/tabs';
import { AIAgentEditData, DataSource } from 'models/api/response.types';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { Badge } from 'components/ui/badge';
import useDataSources from 'hooks/useDataSources';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { isPrimaryProduct } from 'utils/domain';
import { Textarea } from 'components/ui/textarea';
import KnowledgeSources from './KnowledgeSources';
import AddSourcesCard from './AddSourcesCard';

const KnowledgeTab: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
  sourceIds: string[];
  setSourceIds: (ids: string[]) => void;
}> = ({ data, setData, sourceIds, setSourceIds }) => {
  const { canUseGoogleSearch } = useSubscriptionInfo();
  const { sources } = useDataSources();
  const [addingSources, setAddingSources] = useState<boolean>(false);

  const sourcesInUse = useMemo(() => {
    if (sources) {
      return sources.filter((source) => sourceIds.includes(source.uuid));
    }
    return [];
  }, [sources, sourceIds]);

  const sourcesAvailable = useMemo(() => {
    if (sources) {
      return sources.filter((source) => !sourceIds.includes(source.uuid) && source.status === 'success');
    }
    return [];
  }, [sources, sourceIds]);

  // this feature available only when user has sources available for knowledge
  const isAdvancedHallucinationDefenseAvailable = useMemo(() => {
    return !!data.meta.use_all_sources || sourcesInUse.length > 0;
  }, [data, sourcesInUse]);

  return (
    <TabsContent className="flex flex-col gap-6 mt-0" value="knowledge">
      {!addingSources || data.meta.use_all_sources ? (
        <>
          <p className="text-sm text-muted-foreground">
            Customize your AI agent&apos;s access to specific information sources within your chatbot
            ecosystem or enable access to a wide range of information using Google search. To maximize your
            chatbot&apos;s overall performance, each agent should focus on a specialization, so we highly
            recommend that you curate its knowledge base rather than give all agents the same access to all
            information.
          </p>
          {isPrimaryProduct && (
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight flex items-center gap-2"
                htmlFor="google-search"
              >
                <img className="w-4 h-4 min-w-[16px]" src="/img/google.svg" alt="Google" /> Google Search
                <Badge>BETA</Badge>
              </Label>
              <p className="text-muted-foreground text-sm">
                AI can extend its search capabilities beyond the chatbot&apos;s built-in sources. When a user
                asks a question or requests information that is not available within the chatbot&apos;s
                existing knowledge base, the AI will utilize Google Search to find relevant context and
                provide accurate answers from the web.{' '}
              </p>
              {canUseGoogleSearch ? (
                <>
                  <Switch
                    id="google-search"
                    checked={data?.meta?.google_search}
                    onCheckedChange={(allow) => {
                      setData({
                        ...data,
                        meta: {
                          ...data.meta,
                          google_search: allow,
                        },
                      });
                    }}
                  />
                  <p className="ml-1 text-xs text-warning">
                    This option relies on Feature Credits. If you don&apos;t have enough credits, it will not
                    operate.
                  </p>
                </>
              ) : (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Switch className="bg-input shadow-sm cursor-not-allowed" id="google-search" disabled />
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <p className="font-normal">
                        Upgrade your subscription in order to use to use Google Search.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          )}
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="all-sources">
              All Sources
            </Label>
            <p className="text-muted-foreground text-sm">
              Enable this option to grant the agent access to all trained information sources available within
              the chatbot&apos;s database.
            </p>
            <Switch
              id="all-sources"
              checked={data.meta.use_all_sources}
              onCheckedChange={(enable) => {
                setData({
                  ...data,
                  meta: {
                    ...data.meta,
                    use_all_sources: enable,
                  },
                });
              }}
            />
          </div>
          {!data.meta.use_all_sources && (
            <div className="transition-all flex-1 flex flex-col gap-4 overflow-hidden relative">
              <div className="flex items-center justify-between">
                <div>
                  <Label className="text-md font-medium leading-none tracking-tight" htmlFor="all-sources">
                    Agent Sources
                  </Label>
                  <p className="text-muted-foreground text-sm">
                    Specific sources you want the agent to utilize for completing its tasks.
                  </p>
                </div>
                <Button onClick={() => setAddingSources(true)} disabled={sourcesAvailable.length === 0}>
                  <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                  Add sources
                </Button>
              </div>
              {sourcesInUse.length > 0 && (
                <KnowledgeSources
                  sources={sourcesInUse}
                  removeSources={(sourcesToDelete: string[]) => {
                    setSourceIds(sourceIds.filter((item: string) => !sourcesToDelete.includes(item)));
                  }}
                />
              )}
            </div>
          )}
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="all-sources">
              Advanced Hallucination Defense
            </Label>
            <p className="text-muted-foreground text-sm">
              Use our unique proprietary technology that serves as an additional layer of defense against LLM
              hallucinations (made-up responses) for AI Agents using any uploaded Source as training data. It
              assigns a relevance score for all retrieved chunks from our Retrieval Augmented Generation (RAG)
              algorithm. If all chunks fall below a designated relevance threshold, the AI Agent outputs a
              fixed pre-canned response.
            </p>
            <Switch
              id="all-sources"
              disabled={!isAdvancedHallucinationDefenseAvailable}
              checked={isAdvancedHallucinationDefenseAvailable ? data.meta?.context_guard : false}
              onCheckedChange={(enable) => {
                setData({
                  ...data,
                  meta: {
                    ...data.meta,
                    context_guard: enable,
                    // prepopulate default message if doesn't exist
                    ...(!data.meta?.context_guard_message && {
                      context_guard_message:
                        'Sorry, I am not sure. Please contact our support team for more information.',
                    }),
                  },
                });
              }}
            />
            {!isAdvancedHallucinationDefenseAvailable && (
              <p className="ml-1 text-xs text-warning">
                Feature available only if there are sources used as knowledge for this agent.
              </p>
            )}
          </div>
          {data.meta.context_guard && isAdvancedHallucinationDefenseAvailable && (
            <div className="flex flex-col gap-2">
              <div className="flex items-end">
                <Label className="text-md font-medium leading-none tracking-tight" htmlFor="default-message">
                  Pre-canned Message
                  <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
                </Label>
              </div>
              <p className="text-sm text-muted-foreground">
                Message to display when no relevant context is found to support the AI Agent&apos;s response.
                This is a fixed message and does not use LLM or Message Credits when triggered.
              </p>
              <Textarea
                id="default-message"
                value={data?.meta?.context_guard_message}
                rows={2}
                maxLength={500}
                placeholder="Enter the default message"
                onChange={(e) => {
                  setData({
                    ...data,
                    meta: {
                      ...data.meta,
                      context_guard_message: e.target.value,
                    },
                  });
                }}
              />
              {!data?.meta?.context_guard_message && (
                <p className="ml-1 text-xs text-destructive">
                  Pre-canned Message is required field and can&apos;t be empty.
                </p>
              )}
            </div>
          )}
        </>
      ) : (
        <AddSourcesCard
          addNewSources={(s: string[]) => {
            setAddingSources(false);
            setSourceIds([...sourceIds, ...s]);
          }}
          sources={sourcesAvailable as DataSource[]}
          setAddingSources={setAddingSources}
        />
      )}
    </TabsContent>
  );
};

export default KnowledgeTab;
