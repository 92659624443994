import { useQuery } from '@tanstack/react-query';
import chatbotService from 'api/chatbot';
import CollaboratorPermissions from 'components/Chatbot/Collaborators/CollaboratorPermissions';
import CollaboratorSettings from 'components/Chatbot/Collaborators/CollaboratorSettings';
import CollaboratorStatus, { ICollaboratorStatus } from 'components/Chatbot/Collaborators/CollaboratorStatus';
import TransferChatbotOwnershipDialog from 'components/Dialogs/Collaborators/TransferChatbotOwnershipDialog';
import CreateEditCollaboratorDialog from 'components/Dialogs/Collaborators/CreateEditCollaboratorDialog';
import { PermissionType, permissionTypes } from 'components/helpers/CollaboratorPermissionBadge';
import FilterButton, { Filters } from 'components/helpers/FilterButton';
import LoadingOverlay from 'components/helpers/LoadingOverlay';
import DataTableColumnHeader from 'components/helpers/Table/DataTableColumnHeader';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import useUserInfo from 'hooks/useUserInfo';
import { PlusCircle, SendToBack, X } from 'lucide-react';
import { Chatbot, Invitation } from 'models/api/response.types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { cn } from 'utils/cn';
import { sortDataTableCollabs } from 'utils/dataTableSort';
import { renderAvatarStyles } from 'utils/avatar';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';

export interface Collaborator {
  id: string;
  avatar?: string;
  email: string;
  transfering_ownership?: boolean;
  transfering_ownership_expires_at?: string;
  status: ICollaboratorStatus;
  permissions?: PermissionType[];
  original_owner?: boolean;
}

export type SortCollabColumn = 'email' | 'status';

const Collaboarators: React.FC = () => {
  const { userInfo, avatarData } = useUserInfo();
  const { accountSubscription, collaborators, canTransferChatbotOwnership } = useSubscriptionInfo();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [inviteCollaborator, setInviteCollaborator] = useState<boolean>(false);
  const [transferOwnership, setTransferOwnership] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc');
  const [sortColumn, setSortColumn] = useState<SortCollabColumn | undefined>(undefined);
  const [validPermissionFilters, setValidPermissionFilters] = useState<string[]>([]);
  const [selectedPermissionFilters, setSelectedPermissionFilters] = useState<PermissionType[]>([]);
  const invitationKey = ['invitations', chatbot?.uuid];
  const transferKey = ['transfer-request', chatbot?.uuid];

  const { data: invitations } = useQuery({
    queryKey: invitationKey,
    queryFn: () => chatbotService.getChatbotInvitations(chatbot.uuid),
  });

  const { data: transferRequest } = useQuery({
    queryKey: transferKey,
    queryFn: () => chatbotService.getChatbotTransferRequest(chatbot.uuid),
  });

  const userList: Collaborator[] = useMemo(() => {
    if (userInfo && invitations && transferRequest) {
      const collabs: Collaborator[] = [
        {
          id: userInfo.uuid,
          email: userInfo.email,
          avatar: avatarData?.avatarSource,
          status: 'active' as ICollaboratorStatus,
          permissions: ['admin'],
        },
        ...invitations.map((invitation: Invitation) => {
          const { uuid, invitee_email, permissions, status, role, avatar } = invitation;
          return {
            id: uuid,
            avatar,
            email: invitee_email,
            original_owner: !!role,
            status,
            permissions: JSON.parse(permissions),
          };
        }),
      ];

      if (!transferRequest.error) {
        const existingCollabIndex = collabs.findIndex(
          (collab) => transferRequest.recipient_email === collab.email,
        );
        if (existingCollabIndex > -1) {
          collabs[existingCollabIndex] = {
            ...collabs[existingCollabIndex],
            transfering_ownership: true,
            transfering_ownership_expires_at: transferRequest.expires_at,
          };
        } else {
          const { uuid, recipient_email, expires_at, avatar } = transferRequest;
          collabs.push({
            id: uuid,
            avatar,
            email: recipient_email,
            transfering_ownership: true,
            transfering_ownership_expires_at: expires_at,
            status: 'pending',
            permissions: undefined,
          });
        }
      }

      return collabs;
    }
    return [];
  }, [userInfo, invitations, transferRequest]);

  const previousOwnerEmail = useMemo(() => {
    return userList.find((collab) => collab.original_owner)?.email;
  }, [userList]);

  const collaboratorsToUse: Collaborator[] = useMemo(() => {
    let result = userList.filter((collab) => {
      const value = filterValue.toLocaleLowerCase();
      if (filterValue) {
        if (collab.email.toLocaleLowerCase().includes(value)) {
          return true;
        }
        return false;
      }
      return true;
    });

    if (result.length > 0 && sortColumn) {
      result = sortDataTableCollabs(result, sortColumn, sortOrder);
    }

    const validTypes = new Set();
    result.forEach((collab) =>
      collab?.permissions?.forEach((permission: PermissionType) => validTypes.add(permission)),
    );
    setValidPermissionFilters(Array.from(validTypes) as PermissionType[]);

    return result.filter((collab) => {
      if (selectedPermissionFilters.length > 0) {
        return selectedPermissionFilters.some((filter) => collab?.permissions?.includes(filter));
      }
      return true;
    });
  }, [userList, filterValue, selectedPermissionFilters, sortColumn, sortOrder]);

  const canInviteCollaborators = useMemo(() => {
    if (invitations && collaborators) {
      return (
        invitations.filter((invite) => {
          return !['declined', 'left'].includes(invite.status) && !invite.role;
        }).length < collaborators
      );
    }
    return false;
  }, [invitations, collaborators]);

  const generatHeader = (title: string, key?: SortCollabColumn, canSort?: boolean) => {
    return (
      <DataTableColumnHeader
        title={title}
        sortOrder={{
          key: key || '',
          column: sortColumn,
          order: sortOrder,
        }}
        canSort={canSort}
        setColumn={(k) => setSortColumn(k as SortCollabColumn)}
        setOrder={setSortOrder}
      />
    );
  };

  if (!userInfo || !invitations || !accountSubscription) {
    return (
      <div className="h-full flex items-center justify-center">
        <LoadingOverlay />
      </div>
    );
  }

  return (
    <>
      <div
        className={cn(
          'h-full py-4 px-4 sm:px-6 flex flex-col gap-1 relative',
          collaborators ? 'overflow-auto' : 'overflow-hidden',
        )}
      >
        <div className="flex p-[1px] pb-3 gap-3 items-center flex-row overflow-x-auto">
          <Input
            placeholder="Search for ..."
            value={filterValue}
            onChange={(event) => {
              setFilterValue(event.target.value);
              setSelectedPermissionFilters([]);
            }}
            className="max-w-sm w-full shadow-sm min-w-[250px] bg-background"
          />
          {validPermissionFilters.length > 0 && (
            <FilterButton
              title="Permissions"
              options={permissionTypes}
              validFilters={validPermissionFilters}
              selectedFilters={selectedPermissionFilters}
              setSelectedFilters={(newFilters: Filters) => {
                setSelectedPermissionFilters(newFilters as PermissionType[]);
              }}
            />
          )}
          {(filterValue.length > 0 || selectedPermissionFilters.length > 0) && (
            <Button
              variant="ghost"
              onClick={() => {
                setFilterValue('');
                setSelectedPermissionFilters([]);
              }}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <X strokeWidth={1.75} className="ml-2 h-4 w-4" />
            </Button>
          )}
          <div className="flex items-center ml-auto gap-3">
            {canInviteCollaborators && (
              <Button
                className="ml-auto"
                disabled={!collaborators}
                onClick={() => {
                  setInviteCollaborator(true);
                }}
              >
                <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                Invite collaborator
              </Button>
            )}
            {canTransferChatbotOwnership && (
              <Button
                disabled={transferRequest && !transferRequest.error}
                variant="outline"
                className="bg-background"
                onClick={() => {
                  setTransferOwnership(true);
                }}
              >
                <SendToBack strokeWidth={1.75} className="w-4 h-4 mr-2" />
                Transfer ownership
              </Button>
            )}
          </div>
        </div>
        <div className="flex-1 overflow-hidden">
          <Table containerClasses="border bg-background max-h-full rounded-md" className="table-fixed">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[300px] py-2 pl-4 pr-0">
                  {generatHeader('Email', 'email', true)}
                </TableHead>
                <TableHead className="group w-[200px] px-2">
                  {generatHeader('Status', 'status', true)}
                </TableHead>
                <TableHead className="group w-[500px] px-2">
                  <div className="flex items-center">Permissions</div>
                </TableHead>
                <TableHead className="w-[110px] px-2" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {collaboratorsToUse?.length > 0 ? (
                collaboratorsToUse.map((collaborator) => {
                  const fallbackAvatarData = renderAvatarStyles(collaborator.email);
                  return (
                    <TableRow className="cursor-pointer group" key={collaborator.id}>
                      <TableCell className="p-2 pl-4">
                        <div className="overflow-hidden flex items-center gap-4">
                          <Avatar className="w-8 h-8 border">
                            <AvatarImage
                              className="w-full h-full"
                              src={collaborator?.avatar}
                              alt="Avatar Fallback"
                            />
                            <AvatarFallback
                              style={{
                                backgroundColor: fallbackAvatarData.backgroundColor,
                                color: fallbackAvatarData.color,
                              }}
                            >
                              {fallbackAvatarData.initials}
                            </AvatarFallback>
                          </Avatar>
                          <p className="overflow-ellipsis overflow-hidden whitespace-nowrap">
                            {collaborator.email}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell className="p-2">
                        <CollaboratorStatus
                          isPreviousOwner={collaborator?.original_owner}
                          isTransferingOwnership={collaborator.transfering_ownership}
                          status={collaborator.status}
                        />
                      </TableCell>
                      <TableCell className="p-2 overflow-hidden">
                        {collaborator?.permissions ? (
                          <CollaboratorPermissions permissions={collaborator.permissions} />
                        ) : (
                          <Badge
                            variant="outline"
                            className="w-fit py-1 h-fit flex gap-2 items-center whitespace-nowrap"
                          >
                            None
                          </Badge>
                        )}
                      </TableCell>
                      <TableCell className="p-2">
                        {!collaborator?.permissions?.includes('admin') && collaborators && (
                          <div className="ml-4 mr-6 flex items-center justify-end">
                            <CollaboratorSettings
                              isPreviousOwner={collaborator?.original_owner}
                              canInviteCollaborators={canInviteCollaborators}
                              collaborator={collaborator}
                            />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {!collaborators && (
          <div className="absolute w-full h-full top-0 left-0 backdrop-blur-md flex items-center justify-center">
            <div className="p-4 text-center bg-background rounded-md border shadow-md">
              <UpgradeRequired className="relative" heading="Upgrade to Invite Collaborators">
                Please upgrade your subscription to invite collaborators. Enhance your chatbot projects with
                creative synergy, taking them to new heights together.
              </UpgradeRequired>
            </div>
          </div>
        )}
      </div>
      <TransferChatbotOwnershipDialog
        previousOwnerEmail={previousOwnerEmail}
        show={transferOwnership}
        close={() => {
          setTransferOwnership(false);
        }}
      />
      <CreateEditCollaboratorDialog
        previousOwnerEmail={previousOwnerEmail}
        createNew={inviteCollaborator}
        close={() => {
          if (inviteCollaborator) {
            setInviteCollaborator(false);
          }
        }}
      />
    </>
  );
};

export default Collaboarators;
