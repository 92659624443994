import { useQuery, useQueryClient } from '@tanstack/react-query';
import sessionService from 'api/session';
import { ChatMessage, ChatMessages, RealtimeChatMessage } from 'models/api/response.types';
import { useEffect, useState } from 'react';
import { isChatMessage } from 'utils/chat';

const useSessionMessages = (sessionId?: string) => {
  const queryClient = useQueryClient();
  const [messagesToUse, setMessagesToUse] = useState<ChatMessages | undefined>(undefined);
  const queryKey = ['sessionMessages', sessionId];

  const { data: sessionMessages } = useQuery({
    queryKey: ['sessionMessages', sessionId],
    queryFn: () => sessionService.getSessionMessages(sessionId || ''),
    enabled: !!sessionId,
    refetchInterval: (query) => {
      if (query?.state?.data) {
        if (
          (query?.state?.data as ChatMessages).some(
            (message) =>
              isChatMessage(message) &&
              message?.background_pending_tasks !== undefined &&
              message.background_pending_tasks !== 0,
          )
        ) {
          return 5_000;
        }
        return false;
      }
      return false;
    },
  });

  useEffect(() => {
    setMessagesToUse(sessionMessages);
  }, [sessionMessages]);

  const updateMessages = (message: ChatMessage | RealtimeChatMessage) => {
    setMessagesToUse((prev) => {
      const newMessages = prev ? [...prev, message] : [message];
      return newMessages;
    });
  };

  const removeMessageQuery = (sessId: string) => {
    queryClient.removeQueries({ queryKey: ['sessionMessages', sessId] });
  };

  const clearAllMessageQueries = () => {
    queryClient.removeQueries({
      predicate: (query) => query.queryKey[0] === 'sessionMessages',
    });
  };

  return {
    sessionMessages: messagesToUse,
    updateMessages,
    queryKey,
    removeMessageQuery,
    clearAllMessageQueries,
  };
};

export default useSessionMessages;
