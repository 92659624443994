import React from 'react';
import { MessagesSquare, File, AppWindow, TableProperties } from 'lucide-react';
import { DataSource } from 'models/api/response.types';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { DataRow, sourceTypes } from 'utils/sources';

const SourceType: React.FC<{
  source: DataRow | DataSource;
}> = ({ source }) => {
  const { type } = source;

  const icon =
    type === 'url' ? (
      <AppWindow strokeWidth={1.75} className="ml-2 h-4 w-4 text-muted-foreground" />
    ) : type === 'qa' ? (
      <MessagesSquare strokeWidth={1.75} className="ml-2 h-4 w-4 text-muted-foreground" />
    ) : type === 'google-drive' ? (
      <img src="/img/google_drive_logo.png" alt="Google" className="ml-2 h-4 w-4" />
    ) : type === 'video' ? (
      <img src="/img/youtube_logo.webp" alt="YouTube" className="ml-2 h-4 w-4 object-contain" />
    ) : type === 'table' ? (
      <TableProperties strokeWidth={1.75} className="ml-2 h-4 w-4 text-muted-foreground" />
    ) : (
      <File strokeWidth={1.75} className="ml-2 h-4 w-4 text-muted-foreground" />
    );

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{icon}</TooltipTrigger>
        <TooltipContent>
          <p className="font-normal">{sourceTypes[type].title}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default SourceType;
