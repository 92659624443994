import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { FileCode2 } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import ChatBubbleIntegration from './ChatBubbleIntegration';
import IframeIntegaration from './IframeIntegration';

const WebflowIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img
            className="w-[40px] h-[40px] rounded-md"
            src="/img/integrations/webflow/webflow.svg"
            alt="Webflow"
          />
          <h1 className="text-xl font-semibold">Add to Webflow</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6" defaultValue="iframe">
        <TabsList>
          <TabsTrigger value="iframe">Embed to page</TabsTrigger>
          <TabsTrigger value="bubble">Chat bubble</TabsTrigger>
        </TabsList>
        <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
          In order to experience the fully functional capabilities of the chatbot within your development
          environment, we recommend enabling the <strong>&quot;Allow all domains&quot;</strong> option within
          the Chatbot settings. This will allow you to explore and test the chatbot&apos;s functionalities
          seamlessly. Feel free to disable this feature once your website is ready for deployment.
        </div>
        <TabsContent value="iframe">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. In your <strong>Dashboard</strong>, select the project where you want to add the chatbot and
            click <strong>&quot;Open Designer&quot;</strong>.
          </p>
          <img src="/img/integrations/webflow/iframe-1.png" alt="Open Editor" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. Choose the <strong>Page</strong> tab and select the page where you want the iframe to appear.
          </p>
          <img src="/img/integrations/webflow/iframe-2.png" alt="Select Page" />
          <p className="font-normal text-muted-foreground text-base my-4">
            3. Select <strong>Add new</strong> tab and choose the <strong>&quot;Code Embed&quot;</strong>{' '}
            block and drag it to the desired location within your page content.
          </p>
          <img src="/img/integrations/webflow/iframe-3.png" alt="Select Block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            4.The <strong>Code Embed Editor</strong> will open. Take the code provided below, paste it into
            the editor, and click <strong>&quot;Save&quot;</strong>.
          </p>
          <IframeIntegaration isFullWidth />
          <img src="/img/integrations/webflow/iframe-4.png" alt="Save Code" />
        </TabsContent>
        <TabsContent value="bubble">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. In your <strong>Dashboard</strong>, select the project where you want to add the chatbot, click
            on the <strong>&quot;More&quot;</strong> button, and select <strong>&quot;Settings&quot;</strong>.
          </p>
          <img src="/img/integrations/webflow/widget-1.png" alt="Open Settings" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. In the Settings page, select <strong>Custom code</strong>, scroll down to the{' '}
            <strong>Footer code</strong> section, and copy and paste the code provided below. Then click{' '}
            <strong>&quot;Save&quot;</strong> to save changes.
          </p>
          <ChatBubbleIntegration />
          <img src="/img/integrations/webflow/widget-2.png" alt="Paste widget script" />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default WebflowIntegration;
