/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import { TabsContent } from 'components/ui/tabs';
import { Label } from 'components/ui/label';
import { PlusCircle } from 'lucide-react';
import { Button } from 'components/ui/button';
import { AIAgentFunction, AIAgentFunctionList } from 'models/api/response.types';
import SubscriptionLimitsExceededDialog from 'components/Dialogs/Subscription/SubscriptionLimitsExceededDialog';
import DeleteAIAgentFunctionDialog from 'components/Dialogs/Agents/DeleteAIAgentFunctionDialog';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import FunctionCard from './FunctionCard';
import CreateEditFunctionCard from './CreateEditFunctionCard';

const FunctionsTab: React.FC<{
  modelDisabled: boolean;
  agentId: string;
  agentFunctions: AIAgentFunctionList;
}> = ({ modelDisabled, agentId, agentFunctions }) => {
  const { agentFunctionsMaxCount, canUpgrade } = useSubscriptionInfo();
  const [triggerActionCard, setTriggerActionCard] = useState<boolean>(false);
  const [showUpgradeSubscriptionDialog, setShowUpgradeSubscriptionDialog] = useState<boolean>(false);
  const [functionToEdit, setFunctionToEdit] = useState<AIAgentFunction | undefined>(undefined);
  const [functionToDelete, setFunctionToDelete] = useState<AIAgentFunction | undefined>(undefined);

  const canCreateAgentFunctions = useMemo(() => {
    return agentFunctions.length < agentFunctionsMaxCount;
  }, [agentFunctionsMaxCount]);

  return (
    <TabsContent className="flex flex-col gap-6 mt-0" value="functions">
      {!triggerActionCard ? (
        <>
          <p className="text-sm text-muted-foreground">
            In the Functions tab, you have the ability to integrate external functions into your Agent,
            empowering it to handle complex tasks with precision. Based on your defined instructions, the
            agent will intelligently determine the appropriate functions to call in order to complete the
            tasks at hand.
          </p>
          {modelDisabled && (
            <p className="text-sm text-warning">
              With the currently selected AI model, functions are disabled. If you wish to use them, please
              select a compatible model with a bigger token limit.
            </p>
          )}
          <div className="transition-all flex-1 flex flex-col gap-4 overflow-hidden relative">
            <div className="flex items-center justify-between">
              <div>
                <Label className="text-md font-medium leading-none tracking-tight" htmlFor="all-sources">
                  Agent Functions
                </Label>
                <p className="text-muted-foreground text-sm">
                  Functions your AI agent connects to and may utilize while it is running.
                </p>
              </div>
              {(canCreateAgentFunctions || canUpgrade) && (
                <Button
                  onClick={() => {
                    if (canCreateAgentFunctions) {
                      setTriggerActionCard(true);
                    } else {
                      setShowUpgradeSubscriptionDialog(true);
                    }
                  }}
                >
                  <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                  Add new
                </Button>
              )}
            </div>
            {agentFunctions.length > 0 && (
              <>
                {agentFunctions.map((func, index) => {
                  return (
                    <FunctionCard
                      key={index}
                      functionData={func}
                      editFunction={() => {
                        setFunctionToEdit(func);
                        setTriggerActionCard(true);
                      }}
                      deleteFunction={() => setFunctionToDelete(func)}
                    />
                  );
                })}
              </>
            )}
          </div>
        </>
      ) : (
        <CreateEditFunctionCard
          agentId={agentId}
          close={() => {
            setTriggerActionCard(false);
            setFunctionToEdit(undefined);
          }}
          functionCall={functionToEdit}
        />
      )}
      <DeleteAIAgentFunctionDialog
        agentId={agentId}
        functionCall={functionToDelete}
        close={() => {
          setFunctionToDelete(undefined);
        }}
      />
      <SubscriptionLimitsExceededDialog
        type="agent-functions"
        show={showUpgradeSubscriptionDialog}
        close={() => setShowUpgradeSubscriptionDialog(false)}
      />
    </TabsContent>
  );
};

export default FunctionsTab;
