/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { cn } from 'utils/cn';

const progressColors: { [key: string]: string } = {
  default: 'bg-main/90',
  warning: 'bg-warning',
  error: 'bg-destructive',
  primary: 'bg-primary',
};

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, color, value, ...props }, ref) => {
  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn('relative h-4 w-full overflow-hidden rounded-full bg-secondary-background', className)}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn(`h-full w-full flex-1 transition-all`, color ? progressColors[color] : 'bg-main/90')}
        style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  );
});
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
