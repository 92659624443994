import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { Textarea } from 'components/ui/textarea';
import { Asterisk } from 'lucide-react';
import { AIAgentEditData, HumanEscalationSettings } from 'models/api/response.types';
import React, { useEffect, useState } from 'react';
import { isPrimaryProduct } from 'utils/domain';

export const HumanEscalationOptions: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ data, setData }) => {
  const [humanRequestedMessageError, setHumanRequestedMessageError] = useState<boolean>(false);

  useEffect(() => {
    const { human_escalation_settings } = data;

    if (!!human_escalation_settings && human_escalation_settings.human_requested_message.length === 0) {
      setHumanRequestedMessageError(true);
    } else if (humanRequestedMessageError) {
      setHumanRequestedMessageError(false);
    }
  }, [data]);

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="human-requested-message"
        >
          Human Agent Requested Message
          <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
        </Label>
        <p className="text-sm text-muted-foreground">
          When the AI Supervisor detects that a human agent is needed, this message will be sent to the user.
        </p>
        <Textarea
          className="min-h-[100px]"
          id="human-requested-message"
          rows={3}
          value={data?.human_escalation_settings?.human_requested_message}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setHumanRequestedMessageError(true);
            } else if (humanRequestedMessageError) {
              setHumanRequestedMessageError(false);
            }
            setData({
              ...data,
              human_escalation_settings: {
                ...(data.human_escalation_settings as HumanEscalationSettings),
                human_requested_message: e.target.value,
              },
            });
          }}
        />
        {humanRequestedMessageError && (
          <p className="ml-1 text-xs text-destructive">
            Human Agent Requested Message is a required field and can&apos;t be empty.
          </p>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="support_requested"
        >
          Human Escalation Notifications
        </Label>
        <p className="text-sm text-muted-foreground">
          Enable to receive notifications when users request to chat with a human.{' '}
          {isPrimaryProduct && (
            <>
              To receive notifications properly from our app, follow our guide that will help you to enable
              all necessary settings.{' '}
              <a
                href="https://guide.gpt-trainer.com/human-support-escalation#enabled-human-support-notifications"
                target="_blank"
                rel="noreferrer"
                className="text-secondary font-medium hover:underline"
              >
                Learn More
              </a>
            </>
          )}
        </p>
        <Switch
          id="support_requested"
          checked={data.human_escalation_settings?.notifications_enabled}
          onCheckedChange={(allow) => {
            if (allow && Notification.permission !== 'granted') {
              Notification.requestPermission();
            }

            setData({
              ...data,
              human_escalation_settings: {
                ...(data.human_escalation_settings as HumanEscalationSettings),
                notifications_enabled: allow,
              },
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="notification-sound"
        >
          Notification Sound
        </Label>
        <p className="text-sm text-muted-foreground">
          Enable to hear a notification sound whenever a human support chat request is received.
        </p>
        <Switch
          id="notification-sound"
          checked={data.human_escalation_settings?.sound_enabled}
          onCheckedChange={(allow) => {
            if (allow) {
              new Audio('/audio/chat-notification.mp3').play().catch((error) => {
                console.error('Playback failed:', error);
              });
            }
            setData({
              ...data,
              human_escalation_settings: {
                ...(data.human_escalation_settings as HumanEscalationSettings),
                sound_enabled: allow,
              },
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="live-chat-starts-message"
        >
          Live Chat Starts Announcement
        </Label>
        <p className="text-sm text-muted-foreground">
          When the live chat feature is activated, you can choose to display a custom announcement to your
          users.
        </p>
        <Input
          id="live-chat-starts-message"
          value={data.human_escalation_settings?.live_chat_start_announcement || ''}
          onChange={(e) => {
            setData({
              ...data,
              human_escalation_settings: {
                ...(data.human_escalation_settings as HumanEscalationSettings),
                live_chat_start_announcement: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className="flex flex-col gap-2 mb-8">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="live-chat-ends-message"
        >
          Live Chat Ends Announcement
        </Label>
        <p className="text-sm text-muted-foreground">
          After a live chat session is concluded, you can choose to display a custom message to your users.
        </p>
        <Input
          id="live-chat-ends-message"
          value={data.human_escalation_settings?.live_chat_end_announcement || ''}
          onChange={(e) => {
            setData({
              ...data,
              human_escalation_settings: {
                ...(data.human_escalation_settings as HumanEscalationSettings),
                live_chat_end_announcement: e.target.value,
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default HumanEscalationOptions;
