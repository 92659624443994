import { useQuery, useQueryClient } from '@tanstack/react-query';
import chatbotService from 'api/chatbot';
import { Chatbot, Chatbots } from 'models/api/response.types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';
import { getCurrentUser } from 'store/reducers/session';
import { getSelectedChatbot } from 'store/reducers/ui';
import useUserInfo from './useUserInfo';

const useChatbots = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const user = useSelector(getCurrentUser);
  const selectedChatbot = useSelector(getSelectedChatbot);
  const queryKey = ['chatbots', user?.access_token];

  const {
    data: chatbots,
    error,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () => chatbotService.getUserChatbots(),
    enabled: !!userInfo,
  });

  // if user is unauthorized navigate to login page
  useEffect(() => {
    if (isError && (error as unknown as any)?.response?.status === 401) {
      navigate(routePaths.logout);
    }
  }, [isError]);

  const {
    userActiveChatbots,
    invitesToChatbotsExist,
    chatbotOwnershipTransferExist,
    hasCollaborationChatbots,
    sharedChatbotsExists,
  } = useMemo(() => {
    if (chatbots) {
      const currentUserActiveChatbots = chatbots.filter(
        (chatbot) =>
          chatbot.active &&
          ((!chatbot.invitation_uuid && !chatbot.pending_transfer_uuid) || !!chatbot.permissions),
      );
      const chatbotInvites = chatbots.some((chatbot) => chatbot.invitation_uuid);
      const sharedChatbots =
        chatbots.filter((chatbot) => !!chatbot.permissions && !chatbot.invitation_uuid).length > 0;
      return {
        userActiveChatbots: currentUserActiveChatbots,
        invitesToChatbotsExist: chatbotInvites,
        chatbotOwnershipTransferExist: chatbots.some((chatbot) => chatbot.pending_transfer_uuid),
        hasCollaborationChatbots: chatbotInvites || sharedChatbots,
        sharedChatbotsExists: sharedChatbots,
      };
    }

    return {
      userActiveChatbots: undefined,
      invitesToChatbotsExist: false,
      chatbotOwnershipTransferExist: false,
      sharedChatbotsExists: false,
    };
  }, [chatbots]);

  const isCurrentUserChatbot = useMemo(() => {
    return selectedChatbot && !selectedChatbot.permissions;
  }, [selectedChatbot]);

  const refetchChatbots = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const setChatbotData = (props: Chatbots) => {
    queryClient.setQueryData(queryKey, props);
  };

  const updateChatbotByUUID = (uuid: string, newChatbot: Chatbot) => {
    const chatbotList: Chatbots | undefined = queryClient.getQueryData(queryKey);
    if (chatbotList) {
      const newList = chatbotList.map((chatbot) => (chatbot.uuid === uuid ? newChatbot : chatbot));
      queryClient.setQueryData(queryKey, newList);
    } else {
      queryClient.invalidateQueries({ queryKey });
    }
  };

  return {
    chatbots,
    userActiveChatbots,
    invitesToChatbotsExist,
    chatbotOwnershipTransferExist,
    refetchChatbots,
    queryKey,
    setChatbotData,
    isCurrentUserChatbot,
    hasCollaborationChatbots,
    sharedChatbotsExists,
    updateChatbotByUUID,
  };
};

export default useChatbots;
