/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, Suspense, lazy } from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';
import MainLoader from 'components/helpers/MainLoader';
import Widget from 'pages/Widget';
import Sources from 'pages/Chatbot/Sources';
import Preview from 'pages/Chatbot/Preview';
import Collaborators from 'pages/Chatbot/Collaborators';
import Integrations from 'pages/Chatbot/Integrations';
import Dashboard from 'pages/Chatbot/Dashboard';
import Agents from 'pages/Chatbot/Agents';
import { isPrimaryProduct } from 'utils/domain';
import Inbox from 'pages/Chatbot/Inbox';
import AppearanceSettings from 'pages/Chatbot/AppearanceSettings';

// lazy rendering components
const AuthRoute = lazy(() => import('./AuthRoute'));
const LogInAsAdmin = lazy(() => import('pages/Auth/LogInAsAdmin'));
const ApproveVerification = lazy(() => import('pages/Auth/ApproveVerification'));
const Site = lazy(() => import('./Site'));
const Chatbot = lazy(() => import('pages/Chatbot/Chatbot'));
const Login = lazy(() => import('pages/Auth/Login'));
const SignUp = lazy(() => import('pages/Auth/SignUp'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const Logout = lazy(() => import('pages/Auth/Logout'));
const Redirect = lazy(() => import('pages/Auth/Redirect'));
const AppSumoValidation = lazy(() => import('pages/Auth/AppSumoValidation'));
const SubscriptionUpgrade = lazy(() => import('pages/Subscription/SubscriptionUpgrade'));
const SuccessPurchase = lazy(() => import('pages/Subscription/SuccessPurchase'));

const createDuplicateComponentRoutes = (paths: string[], component: ReactNode) => {
  return paths.map((path: string) => <Route key={path} path={path} element={component} />);
};

const Router: React.FC = () => {
  const signupRoutes = createDuplicateComponentRoutes([routePaths.signup, routePaths.referral], <SignUp />);

  return (
    <Suspense fallback={<MainLoader />}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to={routePaths.dashboard} replace />} />
          <Route path={routePaths.site} element={<Site />}>
            <Route path={`${routePaths.login}`} element={<Login />} />
            {signupRoutes}
            <Route path={`${routePaths.forgotPassword}`} element={<ForgotPassword />} />
            <Route path={`${routePaths.approveVerification}`} element={<ApproveVerification />} />
            <Route path={`${routePaths.logout}`} element={<Logout />} />
            <Route path={`${routePaths.logInAsAdmin}`} element={<LogInAsAdmin />} />
            <Route
              path={routePaths.upgrade}
              element={
                <AuthRoute>
                  <SubscriptionUpgrade />
                </AuthRoute>
              }
            />
            <Route
              path={routePaths.purchase_succeeded}
              element={
                <AuthRoute>
                  <SuccessPurchase />
                </AuthRoute>
              }
            />
            <Route
              path={`${routePaths.chatbot}`}
              element={
                <AuthRoute>
                  <Chatbot />
                </AuthRoute>
              }
            >
              <Route path="" element={<Navigate to="dashboard" replace />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="sources" element={<Sources />} />
              <Route path="inbox" element={<Inbox />} />
              <Route path="preview" element={<Preview />} />
              <Route path="appearance-settings" element={<AppearanceSettings />} />
              <Route path="ai-agents" element={<Agents />} />
              <Route path="collaborators" element={<Collaborators />} />
              <Route path="integrations" element={<Integrations />} />
            </Route>
            {isPrimaryProduct && (
              <>
                <Route path={routePaths.redirect} element={<Redirect />} />
                <Route path={`${routePaths.app_sumo}`} element={<AppSumoValidation />} />
              </>
            )}
          </Route>
          <Route path={routePaths.widget} element={<Widget />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
