import React from 'react';
import { Badge } from 'components/ui/badge';
import StatusDot from 'components/helpers/StatusDot';

export type ICollaboratorStatus = 'active' | 'pending' | 'declined' | 'left';

const CollaboratorStatus: React.FC<{
  isPreviousOwner?: boolean;
  isTransferingOwnership?: boolean;
  status: ICollaboratorStatus;
}> = ({ isPreviousOwner, isTransferingOwnership, status }) => {
  return (
    <Badge
      variant="outline"
      className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
    >
      <StatusDot
        className="h-4 w-4 p-1"
        status={
          isTransferingOwnership || isPreviousOwner
            ? 'warning'
            : status === 'pending'
              ? 'loading'
              : status === 'left' || status === 'declined'
                ? 'error'
                : 'success'
        }
      />
      {isPreviousOwner
        ? 'Previous Owner'
        : isTransferingOwnership
          ? 'Transfering Ownership'
          : status === 'pending'
            ? 'Pending'
            : status === 'left'
              ? 'Left'
              : status === 'declined'
                ? 'Declined'
                : 'Active'}
    </Badge>
  );
};

export default CollaboratorStatus;
