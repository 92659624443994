import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { AIAgent } from 'models/api/response.types';
import agentService from 'api/agent';
import { useAlerts } from 'providers/AlertProvider';
import useAiAgents from 'hooks/useAiAgents';
import { alerts } from 'utils/alert';

const DeleteAgentDialog: React.FC<{ agent?: AIAgent; close: () => void }> = ({ agent, close }) => {
  const { removeAgent } = useAiAgents();
  const { addAlert } = useAlerts();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (agent && !show) {
      setShow(true);
    }
  }, [agent]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  const deleteAgent = () => {
    if (agent) {
      agentService
        .deleteAIAgent(agent.uuid)
        .then(() => {
          hide();
          removeAgent(agent.uuid);
        })
        .catch(() => {
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
          });
        });
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete {agent?.name} Agent
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you want to delete this agent? By removing it, the chatbot will lose the
                    capabilities associated with this agent and will no longer be able to perform the tasks it
                    was responsible for. This action is irreversible and could impact the chatbot&apos;s
                    functionality. Please confirm if you wish to proceed with the deletion.
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button variant="destructive" onClick={deleteAgent}>
                    Delete
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteAgentDialog;
