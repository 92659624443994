/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { TabsContent } from 'components/ui/tabs';
import { Label } from 'components/ui/label';
import { AIAgentEditData, AIAgentVariable, AIAgentTag } from 'models/api/response.types';
import { Button } from 'components/ui/button';
import { Badge } from 'components/ui/badge';
import { Pencil, PlusCircle, X } from 'lucide-react';
import CreateEditAgentParameterDialog from 'components/Dialogs/Agents/CreateEditAgentParameterDialog';
import CreateEditAgentTagDialog from 'components/Dialogs/Agents/CreateEditAgentTagDialog';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import SubscriptionLimitsExceededDialog from 'components/Dialogs/Subscription/SubscriptionLimitsExceededDialog';

const VariablesTagsTab: React.FC<{
  agentId: string;
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ agentId, data, setData }) => {
  const { agentTagLimit, agentVariableLimit } = useSubscriptionInfo();
  const [openParameterDialog, setOpenParameterDialog] = useState<boolean>(false);
  const [openTagDialog, setOpenTagDialog] = useState<boolean>(false);
  const [variableToEdit, setVariableToEdit] = useState<undefined | AIAgentVariable>(undefined);
  const [tagToEdit, setTagToEdit] = useState<undefined | AIAgentTag>(undefined);
  const [showUpgradeSubscriptionDialog, setShowUpgradeSubscriptionDialog] = useState<
    'agent-tags' | 'agent-variables' | undefined
  >(undefined);

  const updateVariable = (variable: AIAgentVariable) => {
    if (variableToEdit) {
      const index = data.variables.findIndex((v) => v.name === variableToEdit.name);
      const newVariables = [...data.variables];
      newVariables[index] = variable;
      setData({ ...data, variables: newVariables });
      setVariableToEdit(undefined);
    } else {
      setData({ ...data, variables: [...data.variables, variable] });
    }
  };

  const updateTag = (tag: AIAgentTag) => {
    if (tagToEdit) {
      const index = data.meta?.tags.findIndex((t) => t.name === tagToEdit.name);
      const newTags = data.meta?.tags ? [...data.meta.tags] : [];
      newTags[index] = tag;
      setData({ ...data, meta: { ...data.meta, tags: newTags } });
      setTagToEdit(undefined);
    } else {
      setData({ ...data, meta: { ...data.meta, tags: data.meta?.tags ? [...data.meta.tags, tag] : [tag] } });
    }
  };

  return (
    <TabsContent className="flex flex-col gap-6 mt-0" value="variables-tags">
      {data.type === 'user-facing' && (
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center"
            htmlFor="agent-variables"
          >
            Session State Storage
            {(data.variables.length < agentVariableLimit || !agentVariableLimit) && (
              <Button
                className="ml-auto"
                onClick={() => {
                  if (agentVariableLimit) {
                    setOpenParameterDialog(true);
                  } else {
                    setShowUpgradeSubscriptionDialog('agent-variables');
                  }
                }}
              >
                <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                Add variable
              </Button>
            )}
          </Label>
          <p className="text-sm text-muted-foreground">
            Define the information you would like the Agent to store in the session cache. The session cache
            persists for as long as the chatbot is active. Variables within the session cache are shared
            across all agents and can be used as parameters or criteria for function calling, user data
            collection (e.g. name, email, phone, etc.), or session metadata storage.
          </p>
          {data.variables.length > 0 ? (
            <div className="flex flex-col gap-2">
              {data.variables.map((variable, index) => {
                return (
                  <div
                    key={variable.name}
                    className="rounded-md border p-2 px-4 flex items-start space-x-4 transition-all overflow-hidden"
                  >
                    <div className="space-y-1 flex-1 overflow-hidden">
                      <p className="text-sm font-medium leading-none text-left">{variable.name}</p>
                      <p className="text-sm text-muted-foreground overflow-hidden text-ellipsis text-left">
                        {variable.description}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setVariableToEdit(variable);
                        setOpenParameterDialog(true);
                      }}
                      className="ml-4 mt-3"
                    >
                      <Pencil strokeWidth={1.75} className="w-4 min-w-[16px] h-4 cursor-pointer" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setData({
                          ...data,
                          variables: data.variables.filter((_item, currentIndex) => currentIndex !== index),
                        });
                      }}
                      className="ml-1 mt-3"
                    >
                      <X
                        strokeWidth={1.75}
                        className="w-4 min-w-[16px] transition-all h-4 hover:text-destructive cursor-pointer"
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
              No existing variables
            </Badge>
          )}
        </div>
      )}
      {data.type === 'background' && (
        <div className="flex flex-col gap-2 mb-8">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center"
            htmlFor="agent-tags"
          >
            Conversation Tags
            {(!data.meta?.tags || !agentTagLimit || data.meta.tags.length < agentTagLimit) && (
              <Button
                className="ml-auto"
                onClick={() => {
                  if (agentTagLimit) {
                    setOpenTagDialog(true);
                  } else {
                    setShowUpgradeSubscriptionDialog('agent-tags');
                  }
                }}
              >
                <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                Add tag
              </Button>
            )}
          </Label>
          <p className="text-sm text-muted-foreground">
            Tagging is designed to label conversation snippets with tags based on certain user-defined
            criteria. This allows you to capture interesting highlights of a conversation based on whatever
            criteria you define.
          </p>
          {data.meta?.tags && data.meta.tags.length > 0 ? (
            <div className="flex flex-col gap-2">
              {data.meta.tags.map((tag, index) => {
                return (
                  <div
                    key={tag.name}
                    className="rounded-md border p-2 px-4 flex items-start space-x-4 transition-all overflow-hidden"
                  >
                    <div
                      style={{ backgroundColor: tag.color }}
                      className="w-3 h-3 min-w-[12px] rounded-full"
                    />
                    <div className="space-y-1 flex-1 overflow-hidden">
                      <p className="text-sm font-medium leading-none text-left">{tag.name}</p>
                      <p className="text-sm text-muted-foreground overflow-hidden text-ellipsis text-left">
                        {tag.criteria}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setTagToEdit(tag);
                        setOpenTagDialog(true);
                      }}
                      className="ml-4 mt-3"
                    >
                      <Pencil strokeWidth={1.75} className="w-4 min-w-[16px] h-4 cursor-pointer" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newTags = [...data.meta.tags];
                        newTags.splice(index, 1);
                        setData({
                          ...data,
                          meta: {
                            ...data.meta,
                            tags: newTags,
                          },
                        });
                      }}
                      className="ml-1 mt-3"
                    >
                      <X
                        strokeWidth={1.75}
                        className="w-4 min-w-[16px] transition-all h-4 hover:text-destructive cursor-pointer"
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
              No existing tags
            </Badge>
          )}
        </div>
      )}
      {openParameterDialog && (
        <CreateEditAgentParameterDialog
          variableToEdit={variableToEdit}
          agentId={agentId}
          currentVariables={data.variables}
          close={(variable?: AIAgentVariable) => {
            if (variable) {
              updateVariable(variable);
            }
            setOpenParameterDialog(false);
          }}
        />
      )}
      {openTagDialog && (
        <CreateEditAgentTagDialog
          tagToEdit={tagToEdit}
          currentTags={data?.meta?.tags || []}
          close={(tag?: AIAgentTag) => {
            if (tag) {
              updateTag(tag);
            }
            setOpenTagDialog(false);
          }}
        />
      )}
      <SubscriptionLimitsExceededDialog
        type={showUpgradeSubscriptionDialog || 'agent-variables'}
        show={!!showUpgradeSubscriptionDialog}
        close={() => setShowUpgradeSubscriptionDialog(undefined)}
      />
    </TabsContent>
  );
};

export default VariablesTagsTab;
