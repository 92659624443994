/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { Globe, Loader2 } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import domainService from 'api/domain';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot, ChatbotDomain, ChatbotDomainList } from 'models/api/response.types';
import DeleteDomainDialog from 'components/Dialogs/Chatbot/DeleteDomainDialog';
import { cn } from 'utils/cn';
import { useAlerts } from 'providers/AlertProvider';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import { alerts } from 'utils/alert';
import Domain from './Domain';

interface CustomDomainsProps extends React.HTMLProps<HTMLDivElement> {
  id: string;
}

const CustomDomains = React.forwardRef<HTMLDivElement, CustomDomainsProps>(({ id }, ref) => {
  const queryClient = useQueryClient();
  const { canUseCustomDomains } = useSubscriptionInfo();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const [newCustomDomain, setNewCustomDomain] = useState<string>('');
  const [domainToDelete, setDomainToDelete] = useState<ChatbotDomain | undefined>(undefined);
  const [creating, setCreating] = useState<boolean>(false);
  const domainsQueryKey = ['chatbot-domains', selectedChatbot?.uuid];

  const { data: customDomains } = useQuery({
    queryKey: ['chatbot-domains', selectedChatbot?.uuid],
    queryFn: () => domainService.getChatbotDomains(selectedChatbot.uuid),
  });

  const { isNewDomainValid, isNewDomainExist } = useMemo(() => {
    return {
      isNewDomainValid: /^[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newCustomDomain),
      isNewDomainExist: customDomains?.some((domain) => domain.domain === newCustomDomain),
    };
  }, [newCustomDomain]);

  const createCustomDomain = () => {
    if (customDomains) {
      setCreating(true);
      const domainToCreate = newCustomDomain.toLocaleLowerCase();
      domainService
        .createChatbotDomain(selectedChatbot?.uuid, domainToCreate)
        .then((data) => {
          const domains: ChatbotDomainList | undefined = queryClient.getQueryData(domainsQueryKey);
          if (domains) {
            queryClient.setQueryData(domainsQueryKey, [...domains, data]);
          } else {
            queryClient.invalidateQueries({ queryKey: domainsQueryKey });
          }
          setCreating(false);
          setNewCustomDomain('');
        })
        .catch((err) => {
          setCreating(false);
          if (err?.response?.data?.error.includes('exists')) {
            addAlert({
              severity: 'error',
              message: alerts.DOMAIN_EXISTS,
            });
          } else {
            addAlert({
              severity: 'error',
              message: alerts.SOMETHING_WRONG,
            });
          }
        });
    }
  };

  return (
    <>
      <Card id={id} ref={ref} className="relative">
        <CardHeader className="pb-6">
          <CardTitle className="flex items-center">
            <Globe strokeWidth={1.75} className="w-6 h-6 mr-2 inline" />
            Custom domains
          </CardTitle>
          <CardDescription>Integrate and host chatbot on your own domain.</CardDescription>
        </CardHeader>
        <CardContent className="grid gap-2 max-w-[1000px] overflow-hidden">
          <div className="flex flex-col items-center gap-4 overflow-hidden">
            {customDomains?.map((domain) => {
              return (
                <Domain
                  key={domain.uuid}
                  domain={domain}
                  deleteDomain={() => {
                    setDomainToDelete(domain);
                  }}
                />
              );
            })}
          </div>
          <h3
            className={cn(
              'text-md font-semibold leading-none tracking-tight flex items-center',
              customDomains && customDomains?.length > 0 ? 'mt-4' : '',
            )}
          >
            Domain
          </h3>
          <p className="text-sm text-muted-foreground">
            To get started, please add the desired domain for your chatbot. If your primary domain is, for
            example, <strong className="text-secondary">my-custom-site.com</strong>, we recommend using a
            format like <strong className="text-secondary">chat.my-custom-site.com</strong> for your custom
            subdomain. Feel free to replace <strong className="text-secondary">&quot;chat&quot;</strong> with
            any valid subdomain of your choice, ensuring it aligns with standard subdomain naming conventions.
          </p>
          <div className="flex items-start gap-4">
            <div className="flex-1">
              <Input
                type="text"
                autoComplete="off"
                disabled={!canUseCustomDomains}
                value={newCustomDomain}
                placeholder="chat.my-custom-site.com"
                onChange={(e) => {
                  setNewCustomDomain(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isNewDomainValid && !isNewDomainExist) {
                    createCustomDomain();
                  }
                }}
              />
              {!isNewDomainValid && newCustomDomain.length > 0 && (
                <p className="text-xs text-destructive ml-1 mt-1">Invalid subdomain.</p>
              )}
              {isNewDomainExist && (
                <p className="text-xs text-destructive ml-1 mt-1">Subdomain already exists.</p>
              )}
            </div>
            <Button
              disabled={!isNewDomainValid || isNewDomainExist || !canUseCustomDomains || creating}
              className="w-[68px] ml-auto"
              size="default"
              onClick={createCustomDomain}
            >
              {creating && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
              Add
            </Button>
          </div>
        </CardContent>
        {!canUseCustomDomains && (
          <UpgradeRequired className="absolute" heading="Upgrade to Unlock Custom Domains Feature">
            Please upgrade your plan to get an exclusive access to personalized domain options.
          </UpgradeRequired>
        )}
      </Card>
      <DeleteDomainDialog
        domain={domainToDelete}
        close={() => {
          setDomainToDelete(undefined);
        }}
      />
    </>
  );
});

export default CustomDomains;
