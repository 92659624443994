import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/reducers/session';
import { useAlerts } from 'providers/AlertProvider';

const SlackIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const user = useSelector(getCurrentUser);
  const { alerts } = useAlerts();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  const isInstalled = useMemo(() => {
    return alerts.some((alert) => alert?.message?.includes('Slack integration done'));
  }, [alerts]);

  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img className="w-[40px] h-[40px] rounded-md" src="/img/integrations/slack/slack.png" alt="Slack" />
          <h1 className="text-xl font-semibold">Add to Slack</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <div className="overflow-auto mt-6 px-4 pb-4 pt-0 lg:px-6 lg:pb-6">
        <p className="font-normal text-muted-foreground text-base mb-4">
          Click the button below to connect GPT-trainer to Slack.
        </p>
        <Button
          size="lg"
          disabled={isInstalled}
          className="hover:opacity-90 transition-opacity"
          style={{
            backgroundColor: '#441949',
          }}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BASE_URL}/api/slack/install?access_token=${user?.access_token}`,
              '_self',
            );
          }}
        >
          <img
            src="/img/integrations/slack/slack.png"
            className="w-6 h-6 min-w-[24px] mr-2"
            alt="Slack Logo"
          />
          {isInstalled ? 'Connected to Slack Workspace' : 'Connect to Slack Workspace'}
        </Button>
        <p className="font-normal text-muted-foreground text-base mb-4 mt-6">
          After success installation follow these steps:
        </p>
        <p className="font-normal text-muted-foreground text-base my-4">
          1. Navigate to your Slack workspace and in <strong>&quot;Apps&quot;</strong> section select{' '}
          <strong>&quot;Add apps&quot;</strong>.
        </p>
        <img src="/img/integrations/slack/integration-1.png" alt="Browse apps" />
        <p className="font-normal text-muted-foreground text-base my-4">
          2. Search for <strong>&quot;Gpt-trainer&quot;</strong> app and select it.
        </p>
        <img src="/img/integrations/slack/integration-2.png" alt="Search for GPT-trainer" />
        <p className="font-normal text-muted-foreground text-base my-4">
          3. In a <strong>&quot;Home&quot;</strong> section select the bot you want to chat with.
        </p>
        <img src="/img/integrations/slack/integration-3.png" alt="Select chatbot" />
        <p className="font-normal text-muted-foreground text-base my-4">
          4. Use <strong>&quot;Messages&quot;</strong> section to chat with your bot.
        </p>
        <img src="/img/integrations/slack/integration-4.png" alt="Chat with the chatbot" />
      </div>
    </div>
  );
};

export default SlackIntegration;
