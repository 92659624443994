import React, { Fragment, useMemo, useState } from 'react';
import { AIAgent, AIAgentUpdateBody } from 'models/api/response.types';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Label } from 'components/ui/label';
import { Asterisk, Loader2 } from 'lucide-react';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Intent } from 'components/Chatbot/Agents/Supervisor/IntentsTab/AgentIntent';
import { Textarea } from 'components/ui/textarea';
import agentService from 'api/agent';
import useAiAgents from 'hooks/useAiAgents';

export interface IntentToUpdate {
  intent: Intent;
  is_locked: boolean;
  index: number;
}

export interface IntentToDelete {
  is_locked: boolean;
  index: number;
}

const CreateEditAgentIntentDialog: React.FC<{
  agent: AIAgent;
  intent?: IntentToUpdate;
  close: () => void;
}> = ({ agent, intent, close }) => {
  const { updateAgent } = useAiAgents();
  const [intentName, setIntentName] = useState<string>(intent?.intent?.intent || '');
  const [intentExamples, setIntentExamples] = useState<string>(
    intent ? intent.intent.examples.join('\n') : '',
  );
  const [loading, setLoading] = useState<boolean>(false);

  const isValidIntent = useMemo(() => {
    return intentExamples.split('\n').length > 0 && intentName;
  }, [intentExamples, intentName]);

  const agentUpdateCall = (body: AIAgentUpdateBody) => {
    agentService
      .updateAIAgent(agent.uuid, body)
      .then((res) => {
        updateAgent(res);
        setLoading(false);
        const container = document.getElementById('intents-tab');
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        }
        close();
      })
      .catch(() => setLoading(false));
  };

  const editIntent = () => {
    if (intent) {
      setLoading(true);
      const newAgentIntents = JSON.parse(agent?.intents_json || '{}');
      if (intent.is_locked) {
        newAgentIntents.locked_intents[intent.index] = {
          intent: intentName,
          examples: intentExamples.split('\n'),
        };
      } else {
        newAgentIntents.intents.splice(intent.index, 1);
        newAgentIntents.locked_intents = [
          ...(newAgentIntents.locked_intents || []),
          { intent: intentName, examples: intentExamples.split('\n') },
        ];
      }
      agentUpdateCall({ intents_json: JSON.stringify(newAgentIntents) });
    }
  };

  const createIntent = () => {
    setLoading(true);
    const newAgentIntents = JSON.parse(agent?.intents_json || '{}');
    newAgentIntents.locked_intents = [
      ...(newAgentIntents.locked_intents || []),
      { intent: intentName, examples: intentExamples.split('\n') },
    ];
    agentUpdateCall({ intents_json: JSON.stringify(newAgentIntents) });
  };

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  {intent ? 'Edit Intent' : 'Create Intent'}
                </DialogTitle>
                {!intent?.is_locked && (
                  <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
                    By updating an autogenerated intent, you will convert it into a fixed intent. This means
                    that our AI system will no longer update it automatically in the future. Once an intent is
                    fixed, any further modifications will need to be made manually.
                  </div>
                )}
                <div className="mt-4 flex flex-col gap-4">
                  <div>
                    <Label className="flex items-center" htmlFor="intent-name">
                      Intent
                      <Asterisk className="text-destructive w-4 h-4 ml-1" />
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      A brief description of what the user is trying to do. Be as concise and explicit as
                      possible.
                    </p>
                    <Input
                      id="intent-name"
                      className="mt-2"
                      type="text"
                      autoFocus
                      value={intentName}
                      maxLength={250}
                      onChange={(e) => {
                        setIntentName(e.target.value);
                      }}
                      placeholder="Requesting company contact information"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && intentName.length > 0 && isValidIntent && !loading) {
                          if (intent) {
                            editIntent();
                          } else {
                            createIntent();
                          }
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Label className="flex items-center" htmlFor="intent-examples">
                      Intent Examples
                      <Asterisk className="text-destructive w-4 h-4 ml-1" />
                    </Label>
                    <p className="text-sm text-muted-foreground mt-1">
                      Example queries that demonstrate the above intent.
                    </p>
                    <Textarea
                      id="intent-examples"
                      rows={4}
                      className="mt-2"
                      value={intentExamples}
                      placeholder={`How can I contact your customer support?
Where can I find more information about [company department or service]?
What is the best way to reach out for assistance with [product or service]?
                      `}
                      onChange={(e) => {
                        const { value } = e.target;
                        setIntentExamples(value);
                      }}
                    />
                    <p className="text-xs font-normal text-muted-foreground ml-1">
                      Enter each example on a new line
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-4 mt-6 justify-end">
                  <Button variant="outline" onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (intent) {
                        editIntent();
                      } else {
                        createIntent();
                      }
                    }}
                    disabled={intentName.length === 0 || loading || !isValidIntent}
                  >
                    {loading && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
                    {intent ? 'Edit' : 'Create'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateEditAgentIntentDialog;
