/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from 'react';
import { AIAgentList } from 'models/api/response.types';
import { TabsContent } from 'components/ui/tabs';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { Button } from 'components/ui/button';
import { PlusCircle } from 'lucide-react';
import CreateEditAgentIntentDialog, {
  IntentToDelete,
  IntentToUpdate,
} from 'components/Dialogs/Agents/CreateEditAgentIntentDialog';
import ConfirmIntentDeletionDialog from 'components/Dialogs/Agents/ConfirmIntentDeletionDialog';
import AgentIntent from './AgentIntent';

const IntentsTab: React.FC<{ agents: AIAgentList }> = ({ agents }) => {
  const [selectedAgent, setSelectedAgent] = useState<number>(0);
  const [triggerIntentDialog, setTriggerIntentDialog] = useState<boolean>(false);
  const [intentToUpdate, setIntentToUpdate] = useState<IntentToUpdate | undefined>(undefined);
  const [intentToDelete, setIntentToDelete] = useState<IntentToDelete | undefined>(undefined);

  const canCreateIntents = useMemo(() => {
    const agent = agents[selectedAgent];
    const { intents, locked_intents } = JSON.parse(agent.intents_json || '{}');
    return (intents?.length || 0) + (locked_intents?.length || 0) < 100;
  }, [agents]);

  return (
    <>
      <TabsContent id="intents-tab" className="flex flex-col gap-6 mt-0" value="intents">
        <p className="text-sm text-muted-foreground">
          Intents are directly used by the AI Supervisor when calculating relevance of a user-facing Agent
          based on the chatbot&apos;s most recent input query. Intents are generated automatically based on
          the Agent Description and Base Prompt, but you can edit the list of intents to fine tune the
          AI-Supervisor&apos;s task delegation behavior. For best performance, adopt a &quot;MECE&quot;
          approach when assigning intents across multiple Agents - mutually exclusive, collectively
          exhaustive; or no gaps, no overlaps. Having overlaps in intents across multiple user-facing Agents
          will result in AI Supervisor becoming inconsistent in Agent assignment
        </p>
        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-4">
            <Select
              value={selectedAgent.toString()}
              onValueChange={(idx: string) => setSelectedAgent(parseInt(idx, 10))}
            >
              <SelectTrigger className="max-w-[350px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {agents.map((agent, idx) => {
                  return (
                    <SelectItem key={idx} value={idx.toString()}>
                      {agent.name}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            {canCreateIntents && (
              <Button onClick={() => setTriggerIntentDialog(true)} className="ml-auto">
                <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                Add intent
              </Button>
            )}
          </div>
          <AgentIntent
            isAlwaysActive={agents.length === 1}
            agent={agents[selectedAgent]}
            updateIntent={(editIntent: IntentToUpdate) => {
              setIntentToUpdate(editIntent);
              setTriggerIntentDialog(true);
            }}
            deleteIntent={setIntentToDelete}
          />
        </div>
      </TabsContent>
      <ConfirmIntentDeletionDialog
        agent={agents[selectedAgent]}
        intent={intentToDelete}
        close={() => setIntentToDelete(undefined)}
      />
      {triggerIntentDialog && (
        <CreateEditAgentIntentDialog
          agent={agents[selectedAgent]}
          intent={intentToUpdate}
          close={() => {
            setTriggerIntentDialog(false);
            if (intentToUpdate) {
              setIntentToUpdate(undefined);
            }
          }}
        />
      )}
    </>
  );
};

export default IntentsTab;
