import React from 'react';
import { RealtimeChatMessage as RealtimeChatMessageType } from 'models/api/response.types';
import { Clock, Hash, Speech } from 'lucide-react';
import { format } from 'date-fns';
import { ChatBotMeta } from 'utils/bot';
import RealtimeChatMessage from './RealtimeChatMessage';

const RealtimeDebugMessage: React.FC<{
  message: RealtimeChatMessageType;
  chatbotMeta: ChatBotMeta;
  reversed?: boolean;
  isPreviousSameType?: boolean;
  isNextSameType?: boolean;
}> = ({ message, chatbotMeta, reversed, isPreviousSameType, isNextSameType }) => {
  return (
    <div className="border rounded-md p-4">
      <RealtimeChatMessage
        chatbotMeta={chatbotMeta}
        isNextSameType={isNextSameType}
        isPreviousSameType={isPreviousSameType}
        reversed={reversed}
        size="sm"
        key={message.uuid}
        message={message}
      />
      <div className="flex flex-col gap-4 w-full px-2 mt-2">
        <div className="flex flex-col gap-2">
          <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
            <Speech className="w-4 h-4" strokeWidth={1.75} />
            Live Chat
          </p>
          <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
            <Hash className="w-4 h-4" strokeWidth={1.75} />
            UUID
            <span className="text-foreground ml-auto font-medium">{message.uuid}</span>
          </p>
          <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
            <Clock className="w-4 h-4" strokeWidth={1.75} />
            Created At
            <span className="text-foreground ml-auto font-medium">
              {format(new Date(message.created_at), 'dd/MM/yyyy HH:mm')}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RealtimeDebugMessage;
