import React from 'react';
import { cn } from 'utils/cn';

type Status = 'success' | 'info' | 'error' | 'warning' | 'offline' | 'loading' | 'chatbot-action';

const statuses: { [key: string]: string } = {
  success: 'text-green-500 bg-green-400/20',
  error: 'text-destructive bg-destructive/10',
  loading: 'text-blue-500 bg-blue-400/20',
  info: 'text-blue-600 bg-blue-900/20',
  offline: 'text-muted-foreground bg-muted-foreground/10',
  warning: 'text-warning bg-warning/10',
  'chatbot-action': 'text-destructive bg-destructive/10',
};

const StatusDot: React.FC<{ animate?: boolean; status: Status; className?: string }> = ({
  animate,
  status,
  className,
}) => {
  return (
    <div className={cn(statuses[status], 'h-4 w-4 flex rounded-full p-1', className)}>
      <div
        className={cn(
          (['loading', 'chatbot-action'].includes(status) || animate) && 'animate-pulse',
          'h-full w-full rounded-full bg-current transition-all',
        )}
      />
    </div>
  );
};

export default StatusDot;
