import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import Cropper, { Area } from 'react-easy-crop';
import { generateCroppedImage } from 'utils/avatar';

const ImageCropperDialog: React.FC<{
  title: string;
  img?: File;
  cropperCallback: (result?: File) => void;
}> = ({ title, img, cropperCallback }) => {
  const [show, setShow] = useState<boolean>(false);
  const [imgToCrop, setImgToCrop] = useState<string>('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  };

  useEffect(() => {
    if (img && !show) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgToCrop(reader.result as string);
        setShow(true);
      });
      reader.readAsDataURL(img);
    }
  }, [img]);

  const close = (file?: File) => {
    setShow(false);
    setTimeout(() => {
      setImgToCrop('');
      cropperCallback(file);
    }, 300);
  };

  const upload = async () => {
    if (imgToCrop && croppedArea) {
      const canvas = await generateCroppedImage(imgToCrop, croppedArea);
      canvas.toBlob(
        (blob: Blob | null) => {
          if (!blob) return;

          const file = new File([blob], 'image.png', { type: 'image/png' });
          close(file);
        },
        'image/png',
        // this parameter is ignored because PNG is a lossless format
        0.66,
      );
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => close()}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full overflow-hidden h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full flex flex-col max-w-2xl max-h-[900px] transform rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all overflow-hidden">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Add {title}
                </DialogTitle>
                <div className="my-6 flex flex-col overflow-hidden relative h-[400px] rounded-md">
                  {imgToCrop && (
                    <Cropper
                      image={imgToCrop}
                      crop={crop}
                      zoom={zoom}
                      zoomWithScroll
                      aspect={1}
                      cropShape="round"
                      objectFit="contain"
                      showGrid
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  )}
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={() => close()} variant="outline">
                    Cancel
                  </Button>
                  <Button onClick={upload}>Upload Image</Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImageCropperDialog;
