import React, { Fragment, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Loader2 } from 'lucide-react';
import { AIAgent } from 'models/api/response.types';
import agentService from 'api/agent';
import useAiAgents from 'hooks/useAiAgents';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';

const ConfirmSessionDeleteionDialog: React.FC<{
  show: boolean;
  agentId: string;
  close: () => void;
}> = ({ show, agentId, close }) => {
  const { addAlert } = useAlerts();
  const { updateAgent } = useAiAgents();
  const [updating, setUpdating] = useState<boolean>(false);

  const hide = () => {
    addAlert({
      severity: 'success',
      message: alerts.AGENT_UPDATE,
      timeout: 5000,
    });
    close();
  };

  const retrainAgent = () => {
    setUpdating(true);
    agentService
      .updateAIAgent(agentId, { retrain: true })
      .then((res: AIAgent) => {
        updateAgent(res);
        addAlert({
          severity: 'success',
          message: alerts.AGENT_INTENTS_UPDATED,
        });
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      })
      .finally(() => {
        setUpdating(false);
        close();
      });
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => {}}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Configuration Change Detected
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    One of the agent&apos;s properties, such as its name, description, or prompt, has been
                    modified. Would you like to automatically update the intent classification rules for this
                    agent as well? <strong>(Recommended)</strong>
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button disabled={updating} onClick={retrainAgent}>
                    {updating && <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />}
                    Retrain
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmSessionDeleteionDialog;
