import userService from 'api/user';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import useUserInfo from 'hooks/useUserInfo';
import { isPrimaryProduct } from 'utils/domain';
import useAiAgents from 'hooks/useAiAgents';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import OnboardingTooltip from './Tooltip';

const DashboardOnboarding: React.FC = () => {
  const { appTitle } = useWhitelabelData();
  const { userMetaData, refetchUser } = useUserInfo();
  const { agents } = useAiAgents();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [runDashboardOnboarding, setRunDashboardOnboarding] = useState<boolean>(false);

  const completeCurrentOnboarding = (action: string) => {
    if (userMetaData) {
      const newMeta = {
        ...userMetaData,
        onboarding: {
          ...userMetaData.onboarding,
          [action]: new Date().toISOString(),
        },
      };
      userService
        .updateUser({
          meta_json: JSON.stringify(newMeta),
        })
        .then(() => refetchUser())
        .catch((err) => err);
    }
  };

  useEffect(() => {
    if (userMetaData && !userMetaData?.onboarding?.browseOnboarding && agents) {
      setRunDashboardOnboarding(true);
    }
  }, [userMetaData, agents]);

  const steps = useMemo(() => {
    const qaAgent = agents?.find((agent) => agent.type === 'user-facing');
    const stepBuilder: Step[] = [
      {
        target: '.main-container',
        placement: 'center',
        disableBeacon: true,
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        content: (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-base">AI Agents</p>
            <p className="text-muted-foreground text-sm">
              {appTitle} adopts an Agents approach to chatbot building. Think of Agents as &quot;custom
              GPTs&quot; designed to perform specific tasks. Each Agent has its own unique capabilities and
              tasks to perform. {appTitle} has an AI Supervisor that sits on top of these Agents and decides
              which one should step up to respond to the user&apos;s query based on each Agent&apos;s
              properties and the ongoing conversation context.
            </p>
          </div>
        ),
      },
      {
        target: `#agent-${qaAgent?.uuid}`,
        placement: 'auto',
        disableBeacon: true,
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        content: (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-base">General Q&A Agent</p>
            <p className="text-muted-foreground text-sm">
              The General Q&A Agent is the main user-facing agent that interacts with your users or customers.
              Each chatbot must have at least one user-facing agent for effective communication.
            </p>
            <p className="text-muted-foreground text-sm">
              If you&apos;re not satisfied with your bot&apos;s performance, you can easily update the
              agent&apos;s logic by clicking on it. This allows you to refine and improve your chatbot&apos;s
              responses for a better user experience.
            </p>
          </div>
        ),
      },
      {
        target: `.agent-form`,
        placement: 'auto',
        disableBeacon: true,
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        content: (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-base">Agent Configuration</p>
            <p className="text-muted-foreground text-sm">
              Here, you can find all the necessary information for customizing your AI agent, including
              options for setting up source knowledge, defining chatbot interaction types with users, and much
              more. This form allows you to tailor your chatbot&apos;s capabilities to suit your specific
              needs and preferences.
            </p>
          </div>
        ),
      },
      {
        target: '.chatbot-selector',
        placement: 'auto',
        disableBeacon: true,
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        content: (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-lg">Manage Your Chatbots</p>
            <p className="text-muted-foreground text-sm">
              Here you can see the currently selected chatbot. By clicking this button, you will move to your
              chatbots menu where you can share, edit, or delete them. You can also accept invites and
              transfer ownership requests.
            </p>
          </div>
        ),
      },
      {
        target: '.chatbot-menu',
        placement: 'auto',
        disableBeacon: true,
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        content: (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-lg">Navigation bar</p>
            <p className="text-muted-foreground text-sm">
              This sidebar include customization options for your chatbots and offers a variety of other tools
              that can help you build and manage a powerful chatbot for different scenarios:
            </p>
            <ol className="list-decimal ml-5 my-2 text-sm text-foreground font-semibold">
              <li>Usage tracking and analytics</li>
              <li>Messages inbox</li>
              <li>Manage sources</li>
              <li>Configure the inner workings of your AI Agents and workflow</li>
              <li>Customize chat portal settings and appearance</li>
              <li>Invite and manage teammates</li>
              <li>Test and optimize AI performance</li>
              <li>Deploy the chat portal on your website or integrate with other platforms</li>
            </ol>
          </div>
        ),
      },
      {
        target: '.account-button',
        placement: 'auto',
        disableBeacon: true,
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        content: (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-lg">User Profile Settings</p>
            <p className="text-muted-foreground text-sm">
              Access your account information. Manage subscriptions, API keys, or request help from our team
              directly {isPrimaryProduct ? ', and find the latest updates or access our documentation.' : '.'}
            </p>
          </div>
        ),
      },
    ];

    // push this step only for gpt-trainer as it talks about documentation
    if (isPrimaryProduct) {
      stepBuilder.push({
        target: '.main-container',
        placement: 'center',
        disableBeacon: true,
        disableOverlayClose: true,
        disableCloseOnEsc: true,
        content: (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-lg">Documentation</p>
            <p className="text-muted-foreground text-sm">
              We have a lot of easy-to-understand guides on how to make the most of your customer engagement
              platform. To learn more, head to{' '}
              <a
                href="https://guide.gpt-trainer.com"
                target="_blank"
                rel="noreferrer"
                className="text-sm hover:underline text-secondary font-semibold"
                onClick={(e) => e.stopPropagation()}
              >
                GPT-trainer documentation
              </a>
              .
            </p>
            <img
              className="shadow-sm border rounded-md"
              src="/img/gpt-trainer-documentation.png"
              alt="GPT-trainer Documentation"
            />
          </div>
        ),
      });
    }

    return stepBuilder;
  }, [isPrimaryProduct, agents]);

  const openGeneralQAForm = () => {
    const qaAgent = agents?.find((agent) => agent.type === 'user-facing');
    const containerId = `agent-${qaAgent?.uuid}`;
    const container = document.getElementById(containerId);
    container?.click();
  };

  const closeGeneralQAForm = () => {
    const container = document.getElementById('agent-form-close-button');
    container?.click();
  };

  const stepCallback = useCallback(
    (data: CallBackProps) => {
      const { action, lifecycle, index } = data;
      if (action === 'prev') {
        if (index === 2 || index === 3) {
          setRunDashboardOnboarding(false);
          if (index === 2) {
            closeGeneralQAForm();
          } else {
            openGeneralQAForm();
          }
          setStepIndex(stepIndex - 1);
          setTimeout(() => {
            setRunDashboardOnboarding(true);
          }, 400);
        } else {
          setStepIndex(stepIndex - 1);
        }
      }
      if (action === 'close' && lifecycle === 'complete') {
        // after introducing feneral qa agent click and open editor
        if (index === 1) {
          setRunDashboardOnboarding(false);
          openGeneralQAForm();
          setStepIndex(stepIndex + 1);
          setTimeout(() => {
            setRunDashboardOnboarding(true);
          }, 400);
        } else if (index === 2) {
          closeGeneralQAForm();
          setStepIndex(stepIndex + 1);
        } else if (index === steps.length - 1) {
          completeCurrentOnboarding('browseOnboarding');
          setRunDashboardOnboarding(false);
        } else {
          setStepIndex(stepIndex + 1);
        }
      }
    },
    [steps, stepIndex],
  );

  return (
    <>
      {runDashboardOnboarding && (
        <Joyride
          run
          stepIndex={stepIndex}
          steps={steps}
          disableScrolling
          tooltipComponent={OnboardingTooltip}
          callback={stepCallback}
          floaterProps={{
            styles: {
              wrapper: {
                zIndex: 1501,
              },
            },
          }}
          styles={{
            options: {
              zIndex: 1500,
            },
          }}
        />
      )}
    </>
  );
};

export default DashboardOnboarding;
