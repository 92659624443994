const widgetImagePaths = [
  '/img/widget-images/widget-button-open-state/default-chat.svg',
  '/img/widget-images/widget-button-open-state/logo-1.svg',
  '/img/widget-images/widget-button-open-state/logo-2.svg',
  '/img/widget-images/widget-button-open-state/logo-3.svg',
  '/img/widget-images/widget-button-open-state/logo-4.svg',
  '/img/widget-images/widget-button-open-state/logo-5.svg',
  '/img/widget-images/widget-button-open-state/logo-6.svg',
  '/img/widget-images/widget-button-open-state/logo-7.svg',
  '/img/widget-images/widget-button-open-state/logo-8.svg',
  '/img/widget-images/widget-button-open-state/logo-9.svg',
  '/img/widget-images/widget-button-open-state/logo-10.svg',
  '/img/widget-images/widget-button-open-state/logo-11.svg',
  '/img/widget-images/widget-button-open-state/logo-12.svg',
  '/img/widget-images/widget-button-open-state/logo-13.svg',
  '/img/widget-images/widget-button-open-state/logo-14.svg',
  '/img/widget-images/widget-button-open-state/logo-15.svg',
  '/img/widget-images/widget-button-open-state/logo-16.svg',
  '/img/widget-images/widget-button-open-state/logo-17.svg',
  '/img/widget-images/widget-button-open-state/logo-18.svg',
  '/img/widget-images/widget-button-open-state/logo-19.svg',
  '/img/widget-images/widget-button-open-state/logo-20.svg',
];

export default widgetImagePaths;
