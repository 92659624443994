import axios from 'axios';
import {
  DataSource,
  IPopularSource,
  DataSourceChunkList,
  BatchUpdateSources,
} from 'models/api/response.types';

const getDataSources = (uuid: string) =>
  axios.get<DataSource[]>(`/api/chatbot/${uuid}/data-sources`).then((res) => res.data);

const deleteDataSources = (uuids: string[]) =>
  axios.post<DataSource[]>('/api/data-sources/delete', {
    uuids,
  });

const fetchSourceTableContent = (uuid: string) =>
  axios.get(`/api/data-source/${uuid}/table`).then((res) => res.data);

const fetchSourceChunks = (uuid: string) =>
  axios.get(`/api/data-source/${uuid}/chunks`).then((res) => res.data);

const updateDataSource = (
  uuid: string,
  body: {
    title?: string;
    meta_json?: string;
    table_json?: string;
  },
) => axios.post(`/api/data-source/${uuid}/update`, body);

const updateBatchDataSources = (uuid: string, data: BatchUpdateSources) =>
  axios.post(`/api/data-sources/${uuid}/update`, data);

const updateDataSourceChunks = (uuid: string, chunk_array: DataSourceChunkList) =>
  axios.put(`/api/data-source/${uuid}/chunks`, { chunks: chunk_array });

const cleanupDataSourceChunks = (uuid: string, chunk_array: DataSourceChunkList) =>
  axios.post(`/api/data-source/${uuid}/chunk-cleanup`, { chunks: chunk_array });

const createWebsiteUrlDataSource = (uuid: string, url: string) =>
  axios.post<DataSource>(`/api/create-data-source/url`, {
    uuid,
    url,
  });

const websiteScraping = (url: string, chatbot_uuid: string) =>
  axios
    .post(`/api/url/proxy`, {
      url,
      uuid: chatbot_uuid,
    })
    .then((res) => res.data);

const urlsReScraping = (uuids: string[]) =>
  axios.post(`/api/data-sources/url/re-scrape`, {
    uuids,
  });

const createBatchUrlsDataSources = (uuid: string, urls: string[]) =>
  axios.post<DataSource[]>(`/api/create-data-source/url/batch`, {
    uuid,
    urls,
  });

const getSiteMapSources = (url: string, chatbot_uuid: string) =>
  axios.post<string>(`/api/sitemap/proxy`, {
    url,
    uuid: chatbot_uuid,
  });

const createQADataSource = (uuid: string, question: string, answer: string) =>
  axios.post<DataSource>(`/api/create-data-source/qa`, {
    uuid,
    question,
    answer,
  });

const updateQADataSource = (uuid: string, question: string, answer: string) =>
  axios.post<DataSource>(`/api/data-source/${uuid}/update-qa`, {
    question,
    answer,
  });

const createGoogleDataSource = (
  uuid: string,
  files: { [key: string]: any }[],
  drive_auth_session_uuid: string,
) =>
  axios.post<DataSource>(`/api/create-data-source/google`, {
    uuid,
    files,
    drive_auth_session_uuid,
  });

const retryDataSources = (uuids: string[]) => axios.post<DataSource>('/api/data-sources/restart', { uuids });

const chatbotPopularSources = (uuid: string) =>
  axios.get<IPopularSource[]>(`/api/chatbot/${uuid}/data-sources/popular`).then((res) => res.data);

const sourceService = {
  getDataSources,
  deleteDataSources,
  createWebsiteUrlDataSource,
  createBatchUrlsDataSources,
  getSiteMapSources,
  createQADataSource,
  urlsReScraping,
  retryDataSources,
  websiteScraping,
  createGoogleDataSource,
  updateDataSource,
  chatbotPopularSources,
  updateQADataSource,
  fetchSourceTableContent,
  fetchSourceChunks,
  updateDataSourceChunks,
  cleanupDataSourceChunks,
  updateBatchDataSources,
};

export default sourceService;
