/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import integrationService from 'api/integrations';
import { ChevronRight, Code, Info, Loader2, Trash, X } from 'lucide-react';
import {
  ChatMessage as ChatMessageType,
  ChatMessages,
  ChatSession,
  Chatbot,
  RealtimeChatMessage as RealtimeChatMessageType,
} from 'models/api/response.types';
import {
  getSelectedChatbot,
  getShowSessionDetailsDrawer,
  setShowSessionDetailsDrawer,
} from 'store/reducers/ui';
import sessionService from 'api/session';
import ReviseMessageDialog from 'components/Dialogs/Sources/ReviseMessageDialog';
import { Separator } from 'components/ui/separator';
import { Button } from 'components/ui/button';
import SessionDetailsDrawer from 'components/helpers/SessionDetailsDrawer';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import ChatMessage from 'components/helpers/chat/ChatMessage/ChatMessage';
import { cn } from 'utils/cn';
import RealtimeChatMessage from 'components/helpers/chat/ChatMessage/RealtimeChatMessage';
import RealtimeDebugMessage from 'components/helpers/chat/ChatMessage/RealtimeDebugMessage';
import ChatInput from 'components/helpers/chat/ChatInput';
import { Badge } from 'components/ui/badge';
import { createRealTimeMessage, isChatMessage, scrollIfAtBottom } from 'utils/chat';
import StatusDot from 'components/helpers/StatusDot';
import useAiAgents from 'hooks/useAiAgents';
import { QueryToStream } from 'models/api/chat.types';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import useUserInfo from 'hooks/useUserInfo';
import DebugMessage from '../../helpers/chat/ChatMessage/DebugMessage';

const SessionOverview: React.FC<{
  currentSession: ChatSession;
  deleteSession: (sessionId: string) => void;
  setCurrentSession: (session?: ChatSession) => void;
  currentQueryKey: string[];
  sessionMessages: ChatMessages;
  emitSocketEvent: (type: string, data: any) => void;
}> = ({
  sessionMessages,
  currentSession,
  deleteSession,
  setCurrentSession,
  currentQueryKey,
  emitSocketEvent,
}) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const queryClient = useQueryClient();
  const { avatarData } = useUserInfo();
  const { canAccessLiveChats } = useAiAgents();
  const { canUseFacebookApps } = useSubscriptionInfo();
  const dispatch = useDispatch();
  const showSessionInfo = useSelector(getShowSessionDetailsDrawer);
  const [answerToRevise, setAnswerToRevise] = useState<ChatMessageType | undefined>(undefined);
  const [debugMode, setDebugMode] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const mesengerDataQueryKey = ['messenger-integration', chatbot.uuid];
  const instagramDataQueryKey = ['instagram-integration', chatbot.uuid];
  const whatsAppDataQueryKey = ['whatsapp-integration-phone', chatbot.uuid];
  const zendeskDataQueryKey = ['zendesk-integration', chatbot.uuid];

  const meta = useMemo(() => {
    return JSON.parse(currentSession?.meta_json);
  }, [currentSession]);

  // MUST check integration connections in order to allow chat through APPs
  const { data: instagramIntegration } = useQuery({
    queryKey: instagramDataQueryKey,
    queryFn: () => integrationService.getInstagramIntegration(chatbot.uuid),
    enabled: meta?.origin === 'instagram',
  });

  const { data: messengerIntegration } = useQuery({
    queryKey: mesengerDataQueryKey,
    queryFn: () => integrationService.getMessengerIntegration(chatbot.uuid),
    enabled: meta?.origin === 'messenger',
  });

  const { data: whatsAppIntegration } = useQuery({
    queryKey: whatsAppDataQueryKey,
    queryFn: () => integrationService.getWhatsAppIntegrationRegistration(chatbot.uuid),
    enabled: meta?.origin === 'whatsapp',
  });

  const { data: zendeskIntegration } = useQuery({
    queryKey: zendeskDataQueryKey,
    queryFn: () => integrationService.getZendeskIntegration(chatbot.uuid),
    enabled: meta?.origin === 'zendesk',
  });

  // check if user is able to send live messages
  const humanEscalationEnabledByOrigin = useMemo(() => {
    // origins that always available
    const isDefaultOrigin = ['widget-iframe', 'link'].includes(meta?.origin);
    // origins that available throw apps
    const isAppOrigin = ['whatsapp', 'instagram', 'messenger', 'zendesk'].includes(meta?.origin);

    if (isDefaultOrigin) return true;
    if ((!isDefaultOrigin && !isAppOrigin) || (isAppOrigin && !canUseFacebookApps)) return false;

    // for facebook apps check if integration is valid
    // we can't allow to live chat with invalid integrations (disconnected/changed)
    if (meta?.origin === 'whatsapp') {
      const whatsAppSessionData = meta?.whatsapp_details;
      return (
        whatsAppSessionData?.chatbot?.phone_number ===
        whatsAppIntegration?.display_phone_number?.replace(/[-\s]/g, '')
      );
    }
    if (meta?.origin === 'instagram') {
      const instagramSessionData = meta?.instagram_details;
      return instagramSessionData?.chatbot?.instagram_id === instagramIntegration?.instagram_id;
    }
    if (meta?.orgin === 'zendesk') {
      const zendeskSessionData = meta?.zendesk_details;
      return zendeskSessionData?.chatbot?.zendesk_app_id === zendeskIntegration?.zendesk_app_id;
    }

    const messengerSessionData = meta?.messenger_details;

    return messengerSessionData?.chatbot?.page_id === messengerIntegration?.page_id;
  }, [meta, instagramIntegration, messengerIntegration, whatsAppIntegration, zendeskIntegration]);

  const isDebugMode = useMemo(() => {
    return sessionMessages.some(
      (message) =>
        isChatMessage(message) &&
        message?.background_pending_tasks !== undefined &&
        message.background_pending_tasks !== 0,
    );
  }, [sessionMessages]);

  // only if uuid changes, meaning switching between chats
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [currentSession.uuid, chatContainerRef.current]);

  // new messages from user or announcement
  useEffect(() => {
    scrollIfAtBottom(0, 200);
  }, [sessionMessages]);

  const toggleSessionHumanEscalationStatus = (new_status: 'active' | 'disabled', msg: string) => {
    sessionService
      .updateChatbotSession(currentSession.uuid, {
        human_escalation_status: new_status,
      })
      .then(() => {
        if (new_status === 'active') {
          const message = createRealTimeMessage('admin', msg || '', {
            admin_image_src: avatarData?.avatarSource,
          });
          setTimeout(() => {
            emitSocketEvent('message', message);
          }, 50);
        }
      })
      .catch(() => {
        queryClient.invalidateQueries({ queryKey: currentQueryKey });
      });
  };

  const submitMessage = (msg: QueryToStream) => {
    if (currentSession?.human_escalation_status !== 'active') {
      toggleSessionHumanEscalationStatus('active', msg.value);
    } else {
      const message = createRealTimeMessage('admin', msg.value, {
        admin_image_src: avatarData?.avatarSource,
      });
      emitSocketEvent('message', message);
    }
  };

  if (!sessionMessages) {
    return null;
  }

  return (
    <>
      <div className="flex-1 flex overflow-hidden w-full border md:border-l-0 rounded-br-md rounded-tr-md relative">
        <div className="flex flex-col flex-1 w-full">
          <div className="border-b  overflow-x-auto overflow-y-hidden h-[48px] px-4 py-2 flex items-center justify-between gap-4">
            {currentSession?.human_escalation_status === 'requested' && (
              <Badge className="rounded-md py-2 fot-medium" variant="outline">
                <StatusDot className="mr-2 w-4 h-4" status="warning" />
                Support Requested
              </Badge>
            )}
            {currentSession?.human_escalation_status === 'active' && (
              <div className="flex items-center gap-2">
                <Badge
                  className="rounded-md py-2 flex font-medium items-center gap-2 whitespace-nowrap"
                  variant="outline"
                >
                  <StatusDot className="mr-2 w-4 h-4" status="success" /> Live Chat
                </Badge>
                <Button
                  className="hover:text-destructive hover:bg-destructive/10 hover:border-destructive whitespace-nowrap"
                  variant="outline"
                  size="sm"
                  onClick={() => toggleSessionHumanEscalationStatus('disabled', '')}
                >
                  <X strokeWidth={1.75} className="w-4 h-4 mr-2" />
                  End Live Chat
                </Button>
              </div>
            )}
            <div className="ml-auto flex items-center gap-2">
              {sessionMessages?.length > meta?.chatbot_meta?.initial_messages.length && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <button
                        disabled={isDebugMode}
                        type="button"
                        className={cn(
                          'preview-mode-switcher w-[44px] h-[24px] rounded-full relative flex items-center',
                          !debugMode ? 'bg-input' : 'bg-primary',
                        )}
                        onClick={() => setDebugMode(!debugMode)}
                      >
                        <div
                          className={cn(
                            'w-[20px] h-[20px] bg-background rounded-full shadow-lg ml-[2px] transition-all border flex items-center justify-center',
                            debugMode ? 'translate-x-[20px]' : '',
                          )}
                        >
                          {isDebugMode ? (
                            <Loader2 className="w-3 h-3 animate-spin transition-all" />
                          ) : (
                            <Code className="w-3 h-3" />
                          )}
                        </div>
                      </button>
                    </TooltipTrigger>
                    <TooltipContent side="left">
                      <p className="font-normal">{debugMode ? 'Exit debug mode' : 'Enter debug mode'}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => {
                        dispatch(setShowSessionDetailsDrawer(true));
                      }}
                    >
                      <Info strokeWidth={1.75} className="h-5 w-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <p className="font-normal">Show conversation details</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className="hover:text-destructive hover:bg-destructive/10"
                      size="icon"
                      variant="ghost"
                      onClick={() => deleteSession(currentSession.uuid)}
                    >
                      <Trash strokeWidth={1.75} className="h-5 w-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <p className="font-normal">Delete conversation</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <Separator className="h-[36px] md:hidden" orientation="vertical" />
              <Button
                size="icon"
                variant="ghost"
                className="md:hidden"
                onClick={() => {
                  setCurrentSession(undefined);
                }}
              >
                <ChevronRight strokeWidth={1.75} className="w-5 h-5" />
              </Button>
            </div>
          </div>
          <div
            id="chat-container"
            ref={chatContainerRef}
            className="flex-1 h-full gap-4 overflow-auto flex flex-col p-4 "
          >
            {sessionMessages.map((message, idx) => {
              const isAIChatMessage = isChatMessage(message);
              const isInitialMessage = meta?.chatbot_meta?.initial_messages.length > idx;
              const isFirstInitialMessage = isInitialMessage && idx === 0;

              // handle style for real time messages if next message is from the same person
              const isPreviousSameType =
                !isAIChatMessage &&
                message?.type === (sessionMessages[idx - 1] as RealtimeChatMessageType)?.type;
              const isNextSameType =
                !isAIChatMessage &&
                message?.type === (sessionMessages[idx + 1] as RealtimeChatMessageType)?.type;

              if (debugMode) {
                return isChatMessage(message) ? (
                  <>
                    {!isInitialMessage && message.response && (
                      <DebugMessage
                        reversed
                        chatbotMeta={meta.chatbot_meta}
                        key={`${message.uuid}+${idx}`}
                        message={message}
                        setAnswerToRevise={setAnswerToRevise}
                      />
                    )}
                  </>
                ) : (
                  <RealtimeDebugMessage
                    chatbotMeta={meta.chatbot_meta}
                    isNextSameType={isNextSameType}
                    isPreviousSameType={isPreviousSameType}
                    reversed
                    key={`${message.uuid}+${idx}`}
                    message={message}
                  />
                );
              }
              return isAIChatMessage ? (
                <ChatMessage
                  isInbox
                  isInitialMessage={isInitialMessage}
                  isFirstInitialMessage={isFirstInitialMessage}
                  reversed
                  size="sm"
                  chatbotMeta={meta.chatbot_meta}
                  key={`${message.uuid}+${idx}`}
                  message={message}
                />
              ) : (
                <RealtimeChatMessage
                  isPreviousSameType={isPreviousSameType}
                  isNextSameType={isNextSameType}
                  reversed
                  size="md"
                  chatbotMeta={meta.chatbot_meta}
                  key={message.uuid}
                  message={message}
                />
              );
            })}
          </div>
          {canAccessLiveChats && humanEscalationEnabledByOrigin && !debugMode && (
            <ChatInput
              size="sm"
              disabled={false}
              placeholder="Reply as a human"
              generateMessage={submitMessage}
              showWatermark={false}
            />
          )}
        </div>
        <SessionDetailsDrawer
          session={currentSession}
          messages={sessionMessages}
          hide={() => dispatch(setShowSessionDetailsDrawer(false))}
          show={showSessionInfo}
        />
      </div>
      <ReviseMessageDialog
        sessionId={currentSession.uuid}
        message={answerToRevise}
        close={() => {
          setAnswerToRevise(undefined);
        }}
      />
    </>
  );
};

export default SessionOverview;
