/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-cond-assign */
import React, { useEffect, useMemo, useState } from 'react';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
// import rehypeKatex from 'rehype-katex';
// import 'katex/dist/katex.min.css'
// We are currently using MathJax, but we could switch easily to Katex which is newer, and a faster than MathJax, but currently has more limitations than MathJax.
import rehypeMathjax from 'rehype-mathjax/svg';

import { cn } from 'utils/cn';
import {
  removeInvalidCitationTypes,
  preproccessLatex,
  renumberCitations,
  remarkMathOptions,
} from 'utils/chatMessage';
import {
  ChatBotMeta,
  IChatMainColors,
  IChatbotMessageColors,
  IUserMessageColors,
  WidgetSize,
  chatSizeVariants,
  defaultChatDarkTextColor,
  secondaryActionTextLightColor,
} from 'utils/bot';
import { ChatMessage as ChatMessageType } from 'models/api/response.types';
import { Check, CheckCircle2, ChevronRight, Copy, ThumbsDown, ThumbsUp } from 'lucide-react';
import sessionService from 'api/session';
import Strong from '../MarkdownElements/Strong';
import Link from '../MarkdownElements/Link';
import Pre from '../MarkdownElements/Pre';
import Code from '../MarkdownElements/Code';
import Image from '../MarkdownElements/Image';
import MessageCitation, { ICitationToRead } from './MessageCitation';
import MessageSources from './MessageSources';
import Paragraph from '../MarkdownElements/Paragraph';
import ChatActionButton from './ChatActionButton';
import ChatGeneratedImage from './ChatGeneratedImage';
import ChatAvatar from './ChatAvatar';

const ChatMessage: React.FC<{
  message: ChatMessageType;
  size: WidgetSize;
  chatbotMeta: ChatBotMeta;
  isInitialMessage?: boolean;
  isFirstInitialMessage?: boolean;
  userMessageColors?: IUserMessageColors;
  chatBotMessageColors?: IChatbotMessageColors;
  chatMainColors?: IChatMainColors;
  updateMessage?: (message: ChatMessageType) => void;
  // props Admin
  isPreview?: boolean; // chat appearance preview
  isInbox?: boolean;
  // props for debugger
  isMonitoredMessage?: boolean;
  reversed?: boolean;
  setAnswerToRevise?: (message: ChatMessageType) => void;
}> = ({
  message,
  size,
  isInitialMessage,
  isFirstInitialMessage,
  chatbotMeta,
  userMessageColors,
  chatBotMessageColors,
  chatMainColors,
  isMonitoredMessage,
  reversed,
  isPreview,
  isInbox,
  setAnswerToRevise,
  updateMessage,
}) => {
  const [showSources, setShowSources] = useState<boolean>(false);
  const [copy, setCopy] = useState<boolean>(false);
  const [citationToRead, setCitationToRead] = useState<ICitationToRead | undefined>(undefined);
  const [backTo, setBackTo] = useState<string | undefined>(undefined);
  const citationContainerId = `citation-container-${message.uuid}`;
  const { revisedAnswer, userVote, messageMeta } = useMemo(() => {
    const meta = JSON.parse(message.meta_json || '{}');
    return {
      revisedAnswer: meta?.revised_answer,
      userVote: meta?.user_vote,
      messageMeta: meta,
    };
  }, [message]);

  const { avatarEnabled, showAvatar } = useMemo(() => {
    const isAvatarEnabled = chatbotMeta?.message_avatar?.enabled;
    return {
      avatarEnabled: isAvatarEnabled,
      showAvatar: isAvatarEnabled && (!isInitialMessage || isFirstInitialMessage),
    };
  }, [chatbotMeta, isInitialMessage]);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 3000);
    }
  }, [copy]);

  useEffect(() => {
    // scroll to citation view
    const targetContainer = document.getElementById(citationContainerId);
    if (targetContainer) {
      targetContainer.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [citationToRead]);

  const goBack = (hide: boolean) => {
    if (hide) {
      setCitationToRead(undefined);
    }
    // scroll back to last clicked citation
    if (backTo) {
      const targetContainer = document.getElementById(backTo);
      if (targetContainer) {
        targetContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  };

  const { messageResponse, messageSources, generatedImageURL } = useMemo(() => {
    if (message?.response && message?.finish_reason !== 'null') {
      const citationRegex = /\[(\d+\.[0-9]+)\]/g;
      // regex for image generator
      const civitAIImageGeneratorRegex = /!\[image-civit-ai-image-generator\]\(([^)]+)\)/;

      let finalResponse = message.response;

      let citeData = message?.cite_data_json ? JSON.parse(message.cite_data_json) : undefined;
      let imageGenerationURL = '';

      // Search for generated image
      const imageURLMatch = message.response.match(civitAIImageGeneratorRegex);

      if (imageURLMatch) {
        imageGenerationURL = imageURLMatch[1];
        finalResponse = finalResponse.replace(imageURLMatch[0], '').trim();
      }

      citeData = Object.keys(citeData).length === 0 ? undefined : citeData;

      if (chatbotMeta?.show_citations) {
        // eslint-disable-next-line prefer-const
        let { updatedResponse, newCiteData } = renumberCitations(finalResponse, citeData);
        finalResponse = updatedResponse.replace(citationRegex, '[$1](/chat_message/citation-data/response)');
        newCiteData = removeInvalidCitationTypes(newCiteData); // gave to remove image files from sources as user can see them in chat
        citeData = newCiteData;
      } else {
        finalResponse = finalResponse.replace(citationRegex, '').replace(/\s+\./g, '.'); // remove extra space between dot and text,
      }
      // convert all math regex patterns
      finalResponse = preproccessLatex(finalResponse);
      return {
        messageResponse: finalResponse,
        messageSources: citeData,
        generatedImageURL: imageGenerationURL,
      };
    }
    return {
      messageResponse: '',
      messageSources: undefined,
      generatedImageURL: '',
    };
  }, [message]);

  const accessSourceDataEnabled =
    !!chatbotMeta?.access_source_data && messageSources && Object.values(messageSources)?.length > 0;

  const sendMessageVote = (vote: 'positive' | 'negative') => {
    if (updateMessage) {
      const newMeta = JSON.stringify({ ...messageMeta, user_vote: vote });
      sessionService.updateSessionMessage(message.uuid, {
        meta_json: newMeta,
      });
      updateMessage({ ...message, meta_json: newMeta });
    }
  };

  return (
    <div>
      {message.query && (
        <div
          className={cn(
            'w-fit max-w-[95%] rounded-lg transition-colors break-words mb-4',
            chatSizeVariants.chat.message[size],
            reversed ? 'mr-auto rounded-bl-none' : 'ml-auto rounded-br-none',
          )}
          style={
            userMessageColors
              ? {
                  background: userMessageColors.backgroundColor,
                  color: userMessageColors.textColor,
                }
              : {
                  background: isMonitoredMessage ? '#fde047' : '#367DF1',
                  color: isMonitoredMessage ? defaultChatDarkTextColor : '#fff',
                }
          }
        >
          <ReactMarkdown
            className={cn('prose leading-normal text-inherit', size)}
            allowedElements={['p', 'span', 'li', 'ul', 'ol']}
          >
            {message.query}
          </ReactMarkdown>
        </div>
      )}
      <div className={cn('flex items-start gap-1', reversed ? 'flex-row-reverse' : 'flex-row')}>
        {showAvatar && (
          <ChatAvatar
            imgUrlPath={chatbotMeta?.message_avatar?.logo}
            size={size}
            userMessageColors={userMessageColors}
          />
        )}
        {/* // in case where we don't need to show avatar, but need to make chat style consistent */}
        {!showAvatar && avatarEnabled && <ChatAvatar size={size} hidden />}
        <div className={cn('flex-1 flex max-w-[90%] flex-col gap-1', reversed ? 'items-end' : 'items-start')}>
          {generatedImageURL && (
            <ChatGeneratedImage chatMainColors={chatMainColors} size={size} url={generatedImageURL} />
          )}
          <div
            className={cn(
              'w-fit max-w-full rounded-lg relative group transition-all break-words',
              chatSizeVariants.chat.message[size],
              !isInitialMessage || isFirstInitialMessage
                ? reversed
                  ? 'rounded-tr-none'
                  : 'rounded-tl-none'
                : '',
            )}
            style={{
              ...(chatBotMessageColors
                ? {
                    background: chatBotMessageColors.backgroundColor,
                    color: chatBotMessageColors.textColor,
                  }
                : {
                    background: '#F2F5F9',
                    color: defaultChatDarkTextColor,
                  }),
            }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm, [remarkMath, remarkMathOptions]]}
              rehypePlugins={[rehypeMathjax]}
              className={cn('prose leading-normal text-inherit border-inherit', size)}
              components={{
                p: (e) => <Paragraph data={e} />,
                a: (e) => (
                  <Link
                    size={size}
                    messageId={message.uuid}
                    data={e}
                    messageSources={messageSources}
                    chatBotMessageColors={chatBotMessageColors}
                    setBackTo={setBackTo}
                    setCitationToRead={setCitationToRead}
                  />
                ),
                pre: (e) => <Pre data={e} chatBotMessageColors={chatBotMessageColors} />,
                strong: (e) => <Strong data={e} />,
                code: (e) => <Code data={e} />,
                img: (e) => <Image size={size} data={e} />,
              }}
            >
              {messageResponse}
            </ReactMarkdown>
            {citationToRead && (
              <MessageCitation
                size={size}
                id={citationContainerId}
                chatMainColors={chatMainColors}
                chatBotMessageColors={chatBotMessageColors}
                citationToRead={citationToRead}
                allowToAccess={!!chatbotMeta?.access_source_data}
                goBack={goBack}
              />
            )}
            {setAnswerToRevise && message?.response && message?.finish_reason !== 'pre-canned' && (
              <div className="flex items-center">
                {revisedAnswer ? (
                  <div
                    className={cn('flex items-center gap-2 transition-all', reversed && 'flex-row-reverse')}
                  >
                    <p className="text-muted-foreground outline-none text-xs font-medium">Revised answer</p>
                    <CheckCircle2 strokeWidth={1.75} className="w-4 h-4 text-green-500" />
                  </div>
                ) : (
                  <button
                    className="transition-all outline-none text-xs font-medium text-warning hover:text-primary hover:underline"
                    type="button"
                    onClick={() => {
                      if (setAnswerToRevise) {
                        setAnswerToRevise(message);
                      }
                    }}
                  >
                    Incorrect? Revise answer
                  </button>
                )}
              </div>
            )}
          </div>
          {/* DIFFERENT MESSAGE OPTIONS */}
          {(accessSourceDataEnabled || chatbotMeta?.response_actions_enabled) && !isInitialMessage && (
            <div className="flex items-center mt-1">
              {accessSourceDataEnabled && (
                <ChatActionButton
                  onClick={() => {
                    setShowSources(!showSources);
                  }}
                  size={size}
                  chatMainColors={chatMainColors}
                  className={reversed ? 'flex-row-reverse' : ''}
                >
                  {chatbotMeta?.source_label || 'Sources'}
                  <ChevronRight
                    strokeWidth={1.75}
                    className={cn(
                      'transition-all',
                      showSources ? 'rotate-[90deg]' : reversed ? 'rotate-[180deg]' : '',
                      chatSizeVariants.secondaryActionIcons[size],
                    )}
                  />
                </ChatActionButton>
              )}
              {chatbotMeta?.response_actions_enabled && (
                <>
                  {!userVote && !isInitialMessage && !isInbox && (
                    <>
                      <ChatActionButton
                        onClick={() => {
                          if (!isPreview) {
                            sendMessageVote('positive');
                          }
                        }}
                        size={size}
                        chatMainColors={chatMainColors}
                      >
                        <ThumbsUp className={chatSizeVariants.secondaryActionIcons[size]} strokeWidth={1.5} />
                      </ChatActionButton>
                      <ChatActionButton
                        onClick={() => {
                          if (!isPreview) {
                            sendMessageVote('negative');
                          }
                        }}
                        size={size}
                        chatMainColors={chatMainColors}
                      >
                        <ThumbsDown
                          className={chatSizeVariants.secondaryActionIcons[size]}
                          strokeWidth={1.5}
                        />
                      </ChatActionButton>
                    </>
                  )}
                  {userVote && (
                    <ChatActionButton disabled size={size} chatMainColors={chatMainColors}>
                      {userVote === 'positive' ? (
                        <ThumbsUp
                          fill={chatMainColors?.actionsText || secondaryActionTextLightColor}
                          className={chatSizeVariants.secondaryActionIcons[size]}
                          strokeWidth={1.5}
                        />
                      ) : (
                        <ThumbsDown
                          fill={chatMainColors?.actionsText || secondaryActionTextLightColor}
                          className={chatSizeVariants.secondaryActionIcons[size]}
                          strokeWidth={1.5}
                        />
                      )}
                    </ChatActionButton>
                  )}
                  {!isInitialMessage && (
                    <ChatActionButton
                      onClick={() => {
                        if ('clipboard' in navigator && !isPreview) {
                          navigator.clipboard.writeText(message?.response);
                          setCopy(true);
                        }
                      }}
                      size={size}
                      chatMainColors={chatMainColors}
                    >
                      {copy ? (
                        <Check className={chatSizeVariants.secondaryActionIcons[size]} strokeWidth={1.5} />
                      ) : (
                        <Copy className={chatSizeVariants.secondaryActionIcons[size]} strokeWidth={1.5} />
                      )}
                    </ChatActionButton>
                  )}
                </>
              )}
            </div>
          )}
          {accessSourceDataEnabled && (
            <MessageSources
              reversed={reversed}
              size={size}
              showSources={showSources}
              isInTextCitationsEnabled={chatbotMeta?.show_citations}
              chatBotMessageColors={chatBotMessageColors}
              sources={messageSources}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
