import React from 'react';
import { Button } from 'components/ui/button';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/reducers/session';

const GoogleDriveConsent: React.FC = () => {
  const user = useSelector(getCurrentUser);
  return (
    <div className="w-full max-h-full flex flex-col transform overflow-hidden rounded-md bg-background p-6 text-left align-middle shadow-sm border">
      <h2 className="text-lg font-medium leading-6 text-gray-900">Google Drive Consent</h2>
      <div className="mt-2">
        <p className="font-normal text-muted-foreground text-sm">
          To provide you with AI-powered capabilities, GPT-trainer relies on large language model (LLM)
          partners. By clicking confirm, you agree for us to send unmarked chunks of your documents from your
          Google Drive to our LLM partners, including but not limited to OpenAI, Anthropic. Our partners have
          explicitly written that this data will not be used for training or optimization of their LLMs.
        </p>
        <p className="font-normal text-warning text-sm mt-2">
          Documents imported from Google Drive are <strong>NOT</strong> automatically synchronized. Any edits
          you make to the document on Google Drive will not be reflected in GPT-trainer unless you re-import
          it.
        </p>
      </div>
      <div className="flex justify-end mt-6 gap-4">
        <Button
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BASE_URL}/api/oauth/google-drive/redirect?access_token=${user?.access_token}`,
              '_self',
            );
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default GoogleDriveConsent;
