import React, { useMemo } from 'react';
import { DataSource, ReTrainFrequency } from 'models/api/response.types';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';

const getFrequencyValue = (frequency?: ReTrainFrequency) => {
  if (frequency) {
    return frequency === 7 ? 'Weekly' : frequency === 14 ? 'Biweekly' : 'Monthly';
  }
  return 'No';
};

const SourceRetrainStatus: React.FC<{ source: DataSource }> = ({ source }) => {
  const { canUseAutoRetrain } = useSubscriptionInfo();

  const meta = useMemo(() => {
    return JSON.parse(source.meta_json);
  }, [source]);

  return (
    <p className="text-sm whitespace-nowrap">
      {canUseAutoRetrain ? getFrequencyValue(meta?.retrain_frequency) : 'No'}
    </p>
  );
};

export default SourceRetrainStatus;
