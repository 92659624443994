import React from 'react';
import CollaboratorPermissionBadge, {
  CollaboratorPermission,
  PermissionType,
  adminPermissions,
  collaboratorsPermissions,
} from 'components/helpers/CollaboratorPermissionBadge';

const CollaboratorPermissions: React.FC<{
  permissions: string[];
}> = ({ permissions }) => {
  const allPermissions = [...adminPermissions, ...collaboratorsPermissions];

  return (
    <div className="flex items-center gap-2">
      {permissions.map((permission) => {
        const currentPermission = allPermissions.find(
          (perm: CollaboratorPermission) => perm.type === (permission as PermissionType),
        );
        if (currentPermission) {
          return <CollaboratorPermissionBadge disabled key={permission} permission={currentPermission} />;
        }
        return null;
      })}
    </div>
  );
};

export default CollaboratorPermissions;
