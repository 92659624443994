/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-new */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  BellRing,
  ChevronRight,
  Contact,
  Globe,
  KeyRound,
  MessageSquare,
  MessagesSquare,
  PanelBottom,
  Power,
  ShieldCheck,
  ShieldEllipsis,
  Sliders,
  Subtitles,
  Webhook,
} from 'lucide-react';
import { Chatbot, Chatbots } from 'models/api/response.types';
import { useDispatch, useSelector } from 'react-redux';
import chatbotService from 'api/chatbot';
import {
  getHasUnsavedChanges,
  getSelectedChatbot,
  getShowSettingsSidebar,
  setHasUnsavedChanges,
  setSelectedChatbot,
  setShowSettingsSidebar,
} from 'store/reducers/ui';
import SaveChangesHeader from 'components/Chatbot/SettingsAppearance.tsx/SaveChangesHeader';
import { useLocation } from 'react-router-dom';
import { ChatBotMeta } from 'utils/bot';
import { cn } from 'utils/cn';
import Security from 'components/Chatbot/SettingsAppearance.tsx/Security';
import SettingsConversations from 'components/Chatbot/SettingsAppearance.tsx/SettingsConversations';
import CustomDomains from 'components/Chatbot/SettingsAppearance.tsx/CustomDomains/CustomDomains';
import Authentication from 'components/Chatbot/SettingsAppearance.tsx/Authentication';
import GeneralAppearance from 'components/Chatbot/SettingsAppearance.tsx/GeneralAppearance';
import MessagesAppearance from 'components/Chatbot/SettingsAppearance.tsx/MessagesAppearance';
import WidgetButtonAppearance from 'components/Chatbot/SettingsAppearance.tsx/WidgetButtonAppearance';
import SuggestedPromptsAppearance from 'components/Chatbot/SettingsAppearance.tsx/SuggestedPromptsAppearance';
import WatermarkAppearance from 'components/Chatbot/SettingsAppearance.tsx/WatermarkAppearance';
import Webhooks from 'components/Chatbot/SettingsAppearance.tsx/Webhooks';
import { useAlerts } from 'providers/AlertProvider';
import useChatbots from 'hooks/useChatbots';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { alerts } from 'utils/alert';
import EmailNotifications from 'components/Chatbot/SettingsAppearance.tsx/EmailNotifications';
import { isPrimaryProduct } from 'utils/domain';
import ChatAppearancePreview from 'components/helpers/ChatAppearancePreview';
import Disclaimer from 'components/Chatbot/SettingsAppearance.tsx/Disclaimer';
import FormSettings from 'components/Chatbot/SettingsAppearance.tsx/FormSettings';

type Section =
  | 'security'
  | 'conversations'
  | 'custom-domains'
  | 'authentication'
  | 'general'
  | 'messages'
  | 'button'
  | 'suggested-prompts'
  | 'watermark'
  | 'webhooks'
  | 'email-notifications'
  | 'disclaimer'
  | 'form';

const AppearanceSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { canUseWebhooks, canUseCustomWatermark, canUseUserIdentity, canUseCustomDomains } =
    useSubscriptionInfo();
  const { addAlert } = useAlerts();
  const { chatbots, setChatbotData } = useChatbots();
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
  const sideBarOpen = useSelector(getShowSettingsSidebar);
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const urlSearchParams = new URLSearchParams(search);
  const providedChatbot = urlSearchParams.get('bot');
  const metadata = JSON.parse(chatbot.meta_json) as ChatBotMeta;
  const [domainsTextValue, setDomainsTextValue] = useState<string>(metadata.domains.join('\n'));
  const [emailsTextValue, setEmailsTextValue] = useState<string>(
    (metadata?.email_notifications?.emails || []).join('\n'),
  );
  const [validDomains, setValidDomains] = useState<string[]>(metadata.domains);
  const [validNotificationEmails, setValidNotificationEmails] = useState<string[]>(
    metadata?.email_notifications?.emails || [],
  );
  const [closeSettignsButtonHovered, setCloseSettingsButtonHovered] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [autoScrolling, setAutoScrolling] = useState<boolean>(false);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [chatInterface, setChatInterface] = useState<ChatBotMeta>(metadata);
  const [selectedSection, setSelectedSection] = useState<Section>('security');
  const [initialMessages, setInitialMessages] = useState<string>(chatInterface.initial_messages.join('\n\n'));
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const securityRef = useRef<HTMLDivElement | null>(null);
  const lastScrollTopRef = useRef(0);
  const conversationsRef = useRef<HTMLDivElement | null>(null);
  const customDomainsRef = useRef<HTMLDivElement | null>(null);
  const authenticationRef = useRef<HTMLDivElement | null>(null);
  const generalRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const messagesRef = useRef<HTMLDivElement | null>(null);
  const suggestedMessagesRef = useRef<HTMLDivElement | null>(null);
  const watermarkRef = useRef<HTMLDivElement | null>(null);
  const webhooksRef = useRef<HTMLDivElement | null>(null);
  const emailNotificationsRef = useRef<HTMLDivElement | null>(null);
  const disclaimerRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (providedChatbot && chatbots) {
      const selectedChatbot = chatbots.find((c: Chatbot) => c.uuid === providedChatbot);
      if (selectedChatbot) {
        dispatch(setSelectedChatbot(selectedChatbot));
      }
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [chatbots]);

  useEffect(() => {
    const handleScroll = () => {
      const inViewSections: Section[] = [];
      const containerElement = containerRef.current;
      const currentScrollTop = containerElement?.scrollTop ?? 0;
      const isScrollDown = currentScrollTop > lastScrollTopRef.current;

      const checkInView = (sectionRef: React.RefObject<HTMLDivElement>, sectionName: Section) => {
        if (!containerElement || !sectionRef.current) return;
        const containerTop = containerElement.getBoundingClientRect().top;
        const containerBottom = containerElement.getBoundingClientRect().bottom;
        const sectionTop = sectionRef.current.getBoundingClientRect().top;
        const sectionBottom = sectionRef.current.getBoundingClientRect().bottom;
        // Check if section is within the container boundaries
        if (sectionTop < containerBottom && sectionBottom > containerTop) {
          inViewSections.push(sectionName as Section);
        }
      };

      checkInView(securityRef, 'security');
      checkInView(conversationsRef, 'conversations');
      checkInView(customDomainsRef, 'custom-domains');
      checkInView(authenticationRef, 'authentication');
      checkInView(webhooksRef, 'webhooks');
      checkInView(messagesRef, 'messages');
      checkInView(buttonRef, 'button');
      checkInView(suggestedMessagesRef, 'suggested-prompts');
      checkInView(watermarkRef, 'watermark');
      checkInView(generalRef, 'general');
      checkInView(emailNotificationsRef, 'email-notifications');
      checkInView(disclaimerRef, 'disclaimer');
      checkInView(formRef, 'form');

      // Depending on your requirement, you can set the first or the last section as the active section
      setSelectedSection(inViewSections[isScrollDown ? inViewSections.length - 1 : 0]);
      lastScrollTopRef.current = currentScrollTop;
    };

    const containerElement = containerRef.current;
    if (containerElement && !autoScrolling) {
      containerElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Remove the scroll event listener when the component unmounts
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [autoScrolling]);

  const scrollToSection = (sectionName: Section) => {
    setAutoScrolling(true);
    switch (sectionName) {
      case 'authentication':
        authenticationRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('authentication');
        break;
      case 'webhooks':
        webhooksRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('webhooks');
        break;
      case 'conversations':
        conversationsRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('conversations');
        break;
      case 'custom-domains':
        customDomainsRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('custom-domains');
        break;
      case 'security':
        setSelectedSection('security');
        securityRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'suggested-prompts':
        suggestedMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('suggested-prompts');
        break;
      case 'button':
        buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('button');
        break;
      case 'messages':
        messagesRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('messages');
        break;
      case 'watermark':
        watermarkRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('watermark');
        break;
      case 'email-notifications':
        emailNotificationsRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('email-notifications');
        break;
      case 'disclaimer':
        disclaimerRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('disclaimer');
        break;
      case 'form':
        formRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedSection('form');
        break;
      default:
        setSelectedSection('general');
        generalRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => {
      setAutoScrolling(false);
    }, 500);
  };

  const setInitialData = () => {
    if (chatbot) {
      const data = JSON.parse(chatbot.meta_json) as ChatBotMeta;
      setValidDomains(data.domains);
      setDomainsTextValue(data.domains.join('\n'));
      setEmailsTextValue((data?.email_notifications?.emails || []).join('\n'));
      setInitialMessages(data.initial_messages.join('\n\n'));
      setValidNotificationEmails(metadata?.email_notifications?.emails || []);
      setChatInterface(data);
    }
  };

  // set data on initial render
  // meta of chatbot can be changed, so avoid of losing "Edited data"
  // make sure this one called only when you are swithcing chatbots
  // or first entering this route
  useEffect(() => {
    if (!initialRender) {
      setInitialData();
    } else {
      setInitialRender(false);
    }
  }, [chatbot.uuid]);

  // track unsaved changes
  useEffect(() => {
    if (
      JSON.stringify(metadata) ===
      JSON.stringify({
        ...chatInterface,
        domains: validDomains,
        email_notifications: {
          ...chatInterface.email_notifications,
          emails: validNotificationEmails,
        },
      })
    ) {
      if (hasUnsavedChanges) {
        dispatch(setHasUnsavedChanges(false));
      }
    } else if (!hasUnsavedChanges) {
      dispatch(setHasUnsavedChanges(true));
    }
  }, [chatInterface, validDomains, validNotificationEmails, metadata]);

  const isValidWebsiteUrl = (websiteUrl: string) => {
    try {
      new URL(websiteUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const extractDomain = (url: string) => {
    const parsedUrl = new URL(url);
    const domain = parsedUrl.hostname.replace('www.', '');
    return domain;
  };

  // logic to check if all urls are valid
  // and extract all lines to valid hostnames
  const isDomainsError = useMemo(() => {
    let allValidDomains = true;
    const domainLines = domainsTextValue.split('\n'); // Split the text into lines
    // Remove any empty lines
    const domains = domainLines.filter((line) => line.trim() !== '');
    const domainPattern = /^([A-Za-z0-9](?:(?:[A-Za-z0-9-]){0,61}[A-Za-z0-9])?\.)+[A-Za-z]{2,}$/i;
    const valDom: string[] = [];
    domains.forEach((dom) => {
      if (isValidWebsiteUrl(dom)) {
        valDom.push(extractDomain(dom));
      } else if (domainPattern.test(dom)) {
        valDom.push(dom.replace('www.', ''));
      } else {
        allValidDomains = false;
      }
    });

    setValidDomains(valDom);
    return !allValidDomains;
  }, [domainsTextValue]);

  // check for all valid emails in email notification list
  const isNotificationEmailsError = useMemo(() => {
    let allValidEmails = true;
    const emailLines = emailsTextValue.split('\n'); // Split the text into lines
    // Remove any empty lines
    const emails = emailLines.filter((line) => line.trim() !== '');
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valEmails: string[] = [];
    emails.forEach((em) => {
      if (emailPattern.test(em)) {
        valEmails.push(em);
      } else {
        allValidEmails = false;
      }
    });

    setValidNotificationEmails(valEmails);
    return !allValidEmails;
  }, [emailsTextValue]);

  // check if users form is valid
  const isFormError = useMemo(() => {
    if (chatInterface?.form?.fields) {
      const fieldNames = new Set();
      return chatInterface?.form?.fields.some((field) => {
        if (fieldNames.has(field.label)) {
          return true;
        }

        fieldNames.add(field.label);

        if (
          field.label.length === 0 ||
          (field.type === 'dropdown' && (!field.dropdown_options || field.dropdown_options.length === 0))
        ) {
          return true;
        }
        return false;
      });
    }
    return false;
  }, [chatInterface?.form]);

  // identity verification check
  const isAuthenticationUrlError = useMemo(() => {
    const urlRegex = /^https?:\/\/[\w.-]+(?:\:[0-9]+)?\/[^\s]+$/;
    return (
      chatInterface?.webhook_auth?.enabled &&
      ((chatInterface?.webhook_auth?.url && !urlRegex.test(chatInterface?.webhook_auth.url)) ||
        !chatInterface?.webhook_auth?.url)
    );
  }, [chatInterface?.webhook_auth]);

  const success = () => {
    addAlert({
      severity: 'success',
      message: alerts.CHANGES_SAVED,
    });
  };

  const error = (message: string) => {
    addAlert({
      severity: 'error',
      message,
    });
  };

  const saveChanges = () => {
    if (!isDomainsError && !isAuthenticationUrlError && !isNotificationEmailsError && !isFormError) {
      setSaving(true);
      const newInterface = JSON.stringify({
        ...chatInterface,
        domains: validDomains,
        email_notifications: {
          ...chatInterface.email_notifications,
          emails: validNotificationEmails,
        },
      });
      if (newInterface !== chatbot.meta_json) {
        chatbotService
          .updateChatbot(chatbot.uuid, {
            meta_json: newInterface,
          })
          .then((data: Chatbot) => {
            const newChatbots = [...(chatbots as Chatbots)].map((bot) =>
              bot.uuid === data.uuid ? data : bot,
            );
            setChatbotData(newChatbots);
            dispatch(setSelectedChatbot(data));
            success();
            setSaving(false);
          })
          .catch(() => {
            error(alerts.PAGE_REFRESH_ERROR);
            setSaving(false);
          });
      } else {
        success();
        setSaving(false);
      }
    } else {
      error(alerts.FIX_ISSUES);
      setSaving(false);
    }
  };

  const settingsMenuItems = [
    {
      title: 'Security',
      sectionId: 'security' as Section,
      disabled: false,
      icon: (className: string) => <ShieldEllipsis strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <Security
          key={index}
          id="security"
          ref={securityRef}
          chatInterface={chatInterface}
          canUseUserAuthentication={canUseUserIdentity}
          setChatInterface={setChatInterface}
          domainsTextValue={domainsTextValue}
          setDomainsTextValue={setDomainsTextValue}
          domainsError={isDomainsError}
        />
      ),
    },
    {
      title: 'Conversations',
      disabled: false,
      sectionId: 'conversations' as Section,
      icon: (className: string) => <MessagesSquare strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <SettingsConversations
          key={index}
          id="conversations"
          ref={conversationsRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      title: 'Form',
      disabled: false,
      sectionId: 'form' as Section,
      icon: (className: string) => <Contact strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <FormSettings
          key={index}
          id="form"
          ref={formRef}
          setShowForm={setShowForm}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      title: 'Webhooks',
      disabled: !canUseWebhooks,
      sectionId: 'webhooks' as Section,
      icon: (className: string) => <Webhook strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <Webhooks
          key={index}
          id="webhooks"
          ref={webhooksRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          disabled={!canUseWebhooks}
        />
      ),
    },
    {
      title: 'Email Notifications',
      disabled: false,
      sectionId: 'email-notifications' as Section,
      icon: (className: string) => <BellRing strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <EmailNotifications
          key={index}
          id="email-notifications"
          ref={emailNotificationsRef}
          emailsError={isNotificationEmailsError}
          emailsTextValue={emailsTextValue}
          setEmailsTextValue={setEmailsTextValue}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      title: 'User Identity Verification',
      disabled: !canUseUserIdentity,
      sectionId: 'authentication' as Section,
      icon: (className: string) => <KeyRound strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <Authentication
          key={index}
          id="authentication"
          ref={authenticationRef}
          isUrlError={isAuthenticationUrlError}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          disabled={!canUseUserIdentity}
        />
      ),
    },
    {
      title: 'Custom Domains',
      disabled: !canUseCustomDomains,
      sectionId: 'custom-domains' as Section,
      icon: (className: string) => <Globe strokeWidth={1.75} className={className} />,
      block: (index: number) => <CustomDomains key={index} id="custom-domains" ref={customDomainsRef} />,
    },
    {
      title: 'Disclaimer',
      sectionId: 'disclaimer' as Section,
      disabled: false,
      icon: (className: string) => <ShieldCheck strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <Disclaimer
          key={index}
          id="disclaimer"
          ref={disclaimerRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          setShowDisclaimer={setShowDisclaimer}
        />
      ),
    },
  ]
    .filter((block) => isPrimaryProduct || (!isPrimaryProduct && block.sectionId !== 'email-notifications'))
    .sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1;
      }
      if (!a.disabled && b.disabled) {
        return -1;
      }
      return 0;
    });

  const appearanceMenuItems = [
    {
      title: 'General',
      sectionId: 'general' as Section,
      disabled: false,
      icon: (className: string) => <Sliders strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <GeneralAppearance
          key={index}
          id="general"
          ref={generalRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      title: 'Messages',
      sectionId: 'messages' as Section,
      disabled: false,
      icon: (className: string) => <MessageSquare strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <MessagesAppearance
          key={index}
          id="messages"
          ref={messagesRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          initialMessages={initialMessages}
          setInitialMessages={setInitialMessages}
        />
      ),
    },
    {
      title: 'Suggested Prompts',
      sectionId: 'suggested-prompts' as Section,
      disabled: false,
      icon: (className: string) => <Subtitles strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <SuggestedPromptsAppearance
          key={index}
          id="suggested-prompts"
          ref={suggestedMessagesRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      title: 'Watermark',
      sectionId: 'watermark' as Section,
      disabled: !canUseCustomWatermark,
      icon: (className: string) => <PanelBottom strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <WatermarkAppearance
          key={index}
          id="watermark"
          ref={watermarkRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
    {
      title: 'Widget Button',
      sectionId: 'button' as Section,
      disabled: false,
      icon: (className: string) => <Power strokeWidth={1.75} className={className} />,
      block: (index: number) => (
        <WidgetButtonAppearance
          key={index}
          id="button"
          ref={buttonRef}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
        />
      ),
    },
  ].sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1;
    }
    if (!a.disabled && b.disabled) {
      return -1;
    }
    return 0;
  });

  const generateSectionItem = (section: any, isSelected: boolean) => {
    return (
      <div
        key={section.sectionId}
        className={cn(
          'w-[243px] cursor-pointer relative flex select-none items-center rounded-md py-1.5 px-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-5 transition-all',
          isSelected ? 'bg-muted hover:bg-muted' : 'hover:bg-muted',
        )}
        onClick={() => {
          scrollToSection(section.sectionId);
        }}
      >
        {section.icon('mr-2 w-4 h-4')}
        <p className={isSelected ? 'font-medium' : ''}>{section.title}</p>
      </div>
    );
  };

  return (
    <>
      <SaveChangesHeader discardChanges={setInitialData} saveChanges={saveChanges} saving={saving} />
      <div className="h-full flex w-full">
        <div className="bg-background z-10 absolute top-0 left-0 h-full flex md:relative">
          <div
            className={cn(
              'transition-all overflow-x-hidden overflow-y-auto py-4 flex flex-col gap-2',
              sideBarOpen ? 'w-[260px] ml-4 border-r pr-4 pl-0' : 'w-0',
              closeSettignsButtonHovered ? 'opacity-30' : '',
            )}
          >
            <h1 className="text-sm font-semibold my-2">Settings</h1>
            {settingsMenuItems.map((section) => {
              const isSelected = selectedSection === section.sectionId;
              return generateSectionItem(section, isSelected);
            })}
            <h1 className="text-sm font-semibold my-2">Appearance</h1>
            {appearanceMenuItems.map((section) => {
              const isSelected = selectedSection === section.sectionId;
              return generateSectionItem(section, isSelected);
            })}
          </div>
          <div
            className="flex items-center justify-center bg-slate-50 border-r hover:bg-slate-200 transition-all cursor-pointer"
            onClick={() => {
              dispatch(setShowSettingsSidebar(!sideBarOpen));
            }}
            onMouseEnter={() => {
              if (sideBarOpen) {
                setCloseSettingsButtonHovered(true);
              }
            }}
            onMouseLeave={() => {
              setCloseSettingsButtonHovered(false);
            }}
          >
            <ChevronRight
              strokeWidth={1.75}
              className={cn('w-5 h-5 text-muted-foreground transition-all', sideBarOpen ? 'rotate-180' : '')}
            />
          </div>
        </div>
        <div
          ref={containerRef}
          className="transition-all p-4 flex-1 overflow-auto ml-4 md:ml-0 flex flex-col gap-4"
        >
          {settingsMenuItems.map((item, index) => item.block(index))}
          {appearanceMenuItems.map((item, index) => item.block(index))}
        </div>
      </div>
      <ChatAppearancePreview
        chatbot={chatbot}
        isPlatformView
        showDisclaimer={showDisclaimer}
        setShowDisclaimer={setShowDisclaimer}
        showForm={showForm}
        setShowForm={setShowForm}
        chatInterface={chatInterface}
      />
    </>
  );
};
export default AppearanceSettings;
