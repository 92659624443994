import React, { useEffect, useState } from 'react';
import sourceService from 'api/source';
import userService from 'api/user';
import useDataSources from 'hooks/useDataSources';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSelectedChatbot } from 'store/reducers/ui';
import { alerts } from 'utils/alert';
import DrivePicker from '../../DrivePicker';

const GoogleDriveUploader = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const { addAlert } = useAlerts();
  const { refetchSources } = useDataSources();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const isRedirect = urlSearchParams.get('google_redirect') || '';
  const tokenUuid = urlSearchParams.get('token_uuid') || '';
  const driveAuthSessionUuid = urlSearchParams.get('drive_auth_session_uuid') || '';
  const [userOAuthParams, setUserOAuthParams] = useState<{ [key: string]: any } | undefined>(undefined);

  const hideParams = () => {
    const newUrl = `${window.location.pathname}`;
    window.history.replaceState({}, '', newUrl);
  };

  const sendAlert = (severity: 'error' | 'success' | 'info', message: string) => {
    addAlert({
      severity,
      message,
      timeout: 10000,
    });
  };

  useEffect(() => {
    if (isRedirect) {
      if (tokenUuid && driveAuthSessionUuid) {
        userService
          .validateGoogleAuth({
            token_uuid: tokenUuid,
            drive_auth_session_uuid: driveAuthSessionUuid,
          })
          .then((data) => {
            setUserOAuthParams(data);
            hideParams();
          })
          .catch(() => {
            sendAlert('error', alerts.GOOGLE_AUTH_FAILED);
            hideParams();
          });
      } else {
        sendAlert('error', alerts.GOOGLE_ISS_REDIRECT_FAILED);
        hideParams();
      }
    }
  }, []);

  const handleFileSelected = (files: any) => {
    if (userOAuthParams) {
      sourceService
        .createGoogleDataSource(chatbot.uuid, files, userOAuthParams.drive_auth_session_uuid)
        .then(() => {
          refetchSources();
        })
        .catch(() => {
          sendAlert('error', alerts.SOMETHING_WRONG);
        });
      setUserOAuthParams(undefined);
    }
  };

  if (!userOAuthParams) {
    return null;
  }

  return (
    <DrivePicker
      key={userOAuthParams.token}
      onFileSelected={handleFileSelected}
      accessToken={userOAuthParams.token}
      refresh={() => {
        setUserOAuthParams(undefined);
      }}
    />
  );
};

export default GoogleDriveUploader;
