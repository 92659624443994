import { PermissionType } from 'components/helpers/CollaboratorPermissionBadge';
import { Skeleton } from 'components/ui/skeleton';
import { Chatbot, IPopularSource } from 'models/api/response.types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { cn } from 'utils/cn';

const renderName = (src: IPopularSource, meta: any, canSeeSources: boolean) => {
  if (src.type === 'url') {
    return (
      <a
        href={meta.url}
        target="_blank"
        rel="noreferrer"
        className={cn(
          'text-sm text-secondary line-clamp-1',
          canSeeSources ? 'hover:underline' : 'cursor-default',
        )}
        onClick={(e) => {
          if (!canSeeSources) {
            e.preventDefault();
          } else {
            e.stopPropagation();
          }
        }}
      >
        {meta.url}
      </a>
    );
  }

  if (src.type === 'qa') {
    return (
      <div className="line-clamp-1">
        <p className="text-sm">{meta.question}</p>
        <p className="text-sm text-muted-foreground">{meta.answer}</p>
      </div>
    );
  }
  if (src.type === 'google-drive') {
    return (
      <>
        {meta?.webViewLink ? (
          <a
            onClick={(e) => {
              if (!canSeeSources) {
                e.preventDefault();
              } else {
                e.stopPropagation();
              }
            }}
            href={meta.webViewLink}
            target="_blank"
            rel="noreferrer"
            className={cn(
              'text-sm text-secondary line-clamp-1',
              canSeeSources ? 'hover:underline' : 'cursor-default',
            )}
          >
            {src.file_name}
          </a>
        ) : (
          <p className="text-sm text-muted-foreground line-clamp-1">{src.file_name}</p>
        )}
      </>
    );
  }
  return <p className="text-sm text-muted-foreground line-clamp-1">{src.file_name}</p>;
};

const PopularSource: React.FC<{
  src?: IPopularSource;
  enabled?: boolean;
}> = ({ src, enabled = false }) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const meta = src?.meta_json ? JSON.parse(src?.meta_json) : '';

  const canSeeSources = useMemo(() => {
    return (
      chatbot &&
      (!chatbot.permissions || JSON.parse(chatbot.permissions || '[]').includes('sources' as PermissionType))
    );
  }, [chatbot]);

  return (
    <div className="flex items-center w-full overflow-hidden">
      <div className="space-y-1 mr-4 flex-1 overflow-hidden">
        {enabled && src ? (
          <>
            <p className="text-sm font-medium leading-none line-clamp-1">{src.title}</p>
            {src && meta && renderName(src, meta, canSeeSources)}
          </>
        ) : (
          <>
            <Skeleton className="h-[20px] w-[300px]" />
            <Skeleton className="h-[20px] w-[200px]" />
          </>
        )}
      </div>
      <div className="ml-auto font-medium">
        {enabled && src ? src.count : <Skeleton className="w-[50px] h-[30px]" />}
      </div>
    </div>
  );
};

export default PopularSource;
