/* eslint-disable react/no-array-index-key */
import { useMutation } from '@tanstack/react-query';
import sourceService from 'api/source';
import LoadingSpinner from 'components/helpers/LoadingSpinner';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import useDataSources from 'hooks/useDataSources';
import { ChevronLeft, ChevronRight, CornerUpRight, Loader2, Trash } from 'lucide-react';
import { Chatbot } from 'models/api/response.types';
import React, { useEffect, useMemo, useState } from 'react';
import { cn } from 'utils/cn';

const SiteMapUrlList: React.FC<{
  chatbot: Chatbot;
  creatingSiteMap: boolean;
  siteMapResults: {
    urlSet: string[];
    siteMapSet: string[];
  };
  reRunSiteMap: (url: string) => void;
  close: (try_again?: boolean) => void;
}> = ({ chatbot, creatingSiteMap, siteMapResults, reRunSiteMap, close }) => {
  const { urlSet, siteMapSet } = siteMapResults;
  const { refetchSources } = useDataSources(chatbot.uuid);
  const [creating, setCreating] = useState<boolean>(false);
  const [selectedURLs, setSelectedURLs] = useState<string[]>([]);
  const [currentType, setCurrentType] = useState<'url' | 'sitemap'>(urlSet.length > 0 ? 'url' : 'sitemap');
  const [siteMapToExtend, setSiteMapToExtend] = useState<string>('');
  const [urlsToUse, setUrlsToUse] = useState<string[]>(urlSet.length > 0 ? urlSet : siteMapSet);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const totalPages = Math.ceil(urlsToUse.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const setPage = (page: number) => {
    setSelectedURLs([]);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (urlsToUse.length === 0) {
      close();
    }
  }, [urlsToUse]);

  useEffect(() => {
    if (siteMapToExtend) {
      reRunSiteMap(siteMapToExtend);
    }
  }, [siteMapToExtend]);

  useEffect(() => {
    if (totalPages < currentPage && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  useEffect(() => {
    if (siteMapToExtend && !creatingSiteMap) {
      if (urlSet.length > 0) {
        setCurrentType('url');
        setUrlsToUse(urlSet);
      } else {
        setUrlsToUse(siteMapSet);
      }
      setCurrentPage(1);
      setSiteMapToExtend('');
    }
  }, [urlSet, siteMapSet, creatingSiteMap]);

  const createSiteMapUrlsSourcesMutation = useMutation({
    mutationFn: (payload: { uuid: string; urls: string[] }) =>
      sourceService.createBatchUrlsDataSources(payload.uuid, payload.urls),
    onSuccess: () => {
      refetchSources();
      close();
    },
    onError: () => {
      setCreating(false);
    },
  });

  const createSiteMapSources = () => {
    setCreating(true);
    createSiteMapUrlsSourcesMutation.mutate({
      uuid: chatbot.uuid,
      urls: urlsToUse,
    });
  };

  const urlsToShow = useMemo(() => {
    return urlsToUse.slice(startIndex, endIndex);
  }, [startIndex, endIndex, urlsToUse]);

  return (
    <div className="w-full flex flex-col max-h-full overflow-auto rounded-md bg-background p-6 text-left align-middle shadow-sm border">
      <h2 className="text-lg font-medium leading-6 text-gray-900">Confirm Sitemap</h2>
      {currentType === 'url' ? (
        <p className="font-normal text-warning text-sm mt-2">
          Please review the extracted data below. You can remove any URLs that you do not wish to keep. After
          reviewing, click &quot;Add to Sources&quot;. You do not need to select all the URLs to add them.
        </p>
      ) : (
        <p className="font-normal text-warning text-sm mt-2">
          Sitemap contains multiple sub-sitemaps. Please select one to proceed.
        </p>
      )}
      <div className="rounded-md border bg-background mt-4 overflow-auto flex-1">
        <Table>
          <TableHeader>
            <TableRow>
              {currentType === 'url' && (
                <TableHead className="w-[40px]">
                  <Checkbox
                    className="mt-2"
                    checked={selectedURLs.length === urlsToShow.length && urlsToShow.length > 0}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedURLs(urlsToShow);
                      } else {
                        setSelectedURLs([]);
                      }
                    }}
                    aria-label="Select rows"
                  />
                </TableHead>
              )}
              <TableHead>
                {currentType === 'url' ? 'URL' : 'Sitemap'}
                {selectedURLs.length > 0 && !creating && (
                  <Button
                    className="ml-4"
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      setUrlsToUse(urlsToUse.filter((url) => !selectedURLs.includes(url)));
                      setSelectedURLs([]);
                    }}
                  >
                    Delete ({selectedURLs.length})
                  </Button>
                )}
              </TableHead>
              <TableHead className="text-right" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {urlsToShow.map((url, index) => {
              const isSelected = selectedURLs.includes(url);
              return (
                <TableRow
                  onClick={() => {
                    if (currentType === 'sitemap' && !siteMapToExtend) {
                      setSiteMapToExtend(url);
                    }
                  }}
                  className={currentType !== 'url' ? 'cursor-pointer group' : ''}
                  key={index}
                >
                  {currentType === 'url' && (
                    <TableCell className="py-2 pr-0">
                      <Checkbox
                        className={cn('mt-2', isSelected ? 'border-none border-0' : '')}
                        checked={isSelected}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setSelectedURLs([...selectedURLs, url]);
                          } else {
                            const newSelection = selectedURLs.filter((u: string) => u !== url);
                            setSelectedURLs(newSelection);
                          }
                        }}
                        onClick={(e) => e.stopPropagation()}
                        aria-label="Select row"
                      />
                    </TableCell>
                  )}
                  <TableCell className="max-w-[640px] py-2 overflow-hidden text-ellipsis">
                    {currentType === 'url' ? (
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm hover:underline text-secondary whitespace-nowrap"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {url}
                      </a>
                    ) : (
                      <p className="text-sm text-secondary whitespace-nowrap">{url}</p>
                    )}
                  </TableCell>
                  <TableCell className="text-right py-2">
                    {currentType === 'url' ? (
                      <Button
                        className="h-5 border-0 shadow-none bg-transparent hover:bg-transparent p-0 outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent"
                        onClick={() => {
                          setSelectedURLs([]);
                          setUrlsToUse(urlsToUse.filter((u) => u !== url));
                        }}
                      >
                        <Trash strokeWidth={1.75} className="w-4 h-4 text-destructive" />
                      </Button>
                    ) : (
                      <>
                        {siteMapToExtend === url ? (
                          <LoadingSpinner small />
                        ) : (
                          <>
                            {!siteMapToExtend && (
                              <CornerUpRight
                                strokeWidth={1.75}
                                className="w-4 h-4 text-black opacity-0 group-hover:opacity-100 ml-auto transition-opacity"
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center gap-4 mt-6 justify-end">
        {totalPages > 1 && (
          <div className="text-sm flex items-center justify-end gap-4 mr-auto">
            <p className="text-muted-foreground">
              Page {currentPage} of {totalPages}
            </p>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              disabled={currentPage === 1 || !!siteMapToExtend}
              onClick={() => setPage(currentPage - 1)}
            >
              <ChevronLeft strokeWidth={1.75} className="w-4 h-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              disabled={totalPages === currentPage || !!siteMapToExtend}
              onClick={() => setPage(currentPage + 1)}
            >
              <ChevronRight strokeWidth={1.75} className="w-4 h-4" />
            </Button>
          </div>
        )}
        {currentType === 'url' ? (
          <Button onClick={createSiteMapSources} disabled={creating}>
            {creating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {creating ? 'Adding' : 'Add'} to Sources
          </Button>
        ) : (
          <Button onClick={() => close(true)} variant="outline">
            Try again
          </Button>
        )}
      </div>
    </div>
  );
};

export default SiteMapUrlList;
