import axios from 'axios';
import { UserInfo, UserOAuthInfo } from 'models/api/response.types';

const getUserInfo = () => axios.get<UserInfo>(`/api/user`).then((res) => res.data);

const updateUser = (meta: { password?: string; meta_json?: string }) =>
  axios.post(`/api/user/update`, meta).then((res) => res.data);

const updateUserOpenAIKey = (key: string) =>
  axios.post(`/api/user/ai-key/update`, { ai_key: key }).then((res) => res.data);

const validateGoogleAuth = (meta: { token_uuid: string; drive_auth_session_uuid: string }) =>
  axios.post<UserOAuthInfo>(`/api/drive-auth-token/validate`, meta).then((res) => res.data);

const userService = {
  getUserInfo,
  updateUser,
  validateGoogleAuth,
  updateUserOpenAIKey,
};

export default userService;
