import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';
import React from 'react';
import { ChatBotMeta } from 'utils/bot';

export const ChatbotInitialMessagesAppearance: React.FC<{
  chatInterface: ChatBotMeta;
  initialMessages: string;
  setInitialMessages: (messages: string) => void;
  setChatInterface: (meta: ChatBotMeta) => void;
}> = ({ chatInterface, initialMessages, setChatInterface, setInitialMessages }) => {
  return (
    <div className="flex flex-col gap-2">
      <Label className="text-md font-medium leading-none tracking-tight flex items-center" htmlFor="prompt">
        Initial Messages
      </Label>
      <p className="text-sm text-muted-foreground">
        Customize default texts like greetings or welcome messages that will automatically appear in the chat.
        Use two or more empty lines to create multiple messages, otherwise, they will be treated as a single
        message.
      </p>
      <Textarea
        id="message"
        className="min-h-[150px] bg-background"
        value={initialMessages}
        placeholder="Type your message here."
        onChange={(e) => {
          const { value } = e.target;
          setInitialMessages(value);
          const messageLines = value.split(/\n\n/); // Split the text into lines
          setChatInterface({
            ...chatInterface,
            initial_messages: messageLines.filter((line) => line.trim() !== ''),
          });
        }}
      />
    </div>
  );
};

export default ChatbotInitialMessagesAppearance;
