import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import routePaths from 'routes/routePaths';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';

const UpgradeRequired: React.FC<{ className?: string; heading: string; children: ReactNode }> = ({
  className,
  heading,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        className,
        'w-full h-full top-0 left-0 backdrop-blur-md rounded-md flex items-center justify-center',
      )}
    >
      <div className="p-4 text-center">
        <h1 className="text-base font-semibold">{heading}</h1>
        <p className="text-sm mt-2 text-muted-foreground max-w-[500px]">{children}</p>
        <Button
          size="default"
          className="mt-4 mx-auto"
          onClick={() => {
            navigate(routePaths.upgrade);
          }}
        >
          Upgrade
        </Button>
      </div>
    </div>
  );
};

export default UpgradeRequired;
