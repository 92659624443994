import { useQueryClient } from '@tanstack/react-query';
import agentService from 'api/agent';
import { AgentRuleList, Chatbot } from 'models/api/response.types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';

const DeleteAgentRuleDialog: React.FC<{ rule?: string; close: () => void }> = ({ rule, close }) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [show, setShow] = useState<boolean>(false);
  const rulesQueryKey = ['agent-rules', selectedChatbot.uuid];

  useEffect(() => {
    if (rule && !show) {
      setShow(true);
    }
  }, [rule]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  const deleteAgent = () => {
    if (rule) {
      agentService
        .deleteAgentRule(rule)
        .then(() => {
          hide();
          const rules: AgentRuleList | undefined = queryClient.getQueryData(rulesQueryKey);
          if (rules) {
            const newRules = rules.filter((r) => r.uuid !== rule);
            queryClient.setQueryData(rulesQueryKey, newRules);
          } else {
            queryClient.invalidateQueries({ queryKey: rulesQueryKey });
          }
        })
        .catch(() => {
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
          });
        });
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete Rule
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you want to delete this rule? This action is irreversible, and once the rule
                    is deleted, there is no way to recover it. Please note that deleting this rule will alter
                    the functionality of your agents, and the supervisor will no longer rely on this rule for
                    decision-making.
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button variant="destructive" onClick={deleteAgent}>
                    Delete
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteAgentRuleDialog;
