import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card';
import { Input } from 'components/ui/input';
import FilterButton, { Filters } from 'components/helpers/FilterButton';
import { Button } from 'components/ui/button';
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon, X } from 'lucide-react';
import { DataSource } from 'models/api/response.types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { Checkbox } from 'components/ui/checkbox';
import { SourceColumn } from 'store/reducers/ui';
import DataTableColumnHeader from 'components/helpers/Table/DataTableColumnHeader';
import { cn } from 'utils/cn';
import SourceFileName from 'components/Chatbot/Sources.tsx/SourceTable/SourceFileName';
import SourceType from 'components/Chatbot/Sources.tsx/SourceTable/SourceType';
import { SortSource, sourceTypes } from 'utils/sources';
import useSortedFilteredData from './useSortedFilteredData';

const AddSourcesCard: React.FC<{
  sources: DataSource[];
  setAddingSources: (adding: boolean) => void;
  addNewSources: (uuids: string[]) => void;
}> = ({ sources, setAddingSources, addNewSources }) => {
  const [selectedSourcesToAdd, setSelectedSourcesToAdd] = useState<DataSource[]>([]);
  const {
    currentPage,
    setCurrentPage,
    filterValue,
    setFilterValue,
    sortOrder,
    setSortOrder,
    sortColumn,
    setSortColumn,
    selectedFilters,
    setSelectedFilters,
    totalPages,
    dataToUse,
    validFilters,
  } = useSortedFilteredData(sources);

  const addingSources = () => {
    addNewSources(selectedSourcesToAdd.map((source) => source.uuid));
  };

  const generatHeader = (title: SourceColumn, canHide: boolean, key?: SortSource, canSort?: boolean) => {
    return (
      <DataTableColumnHeader
        title={title}
        sortOrder={{
          key: key || '',
          column: sortColumn,
          order: sortOrder,
        }}
        canSort={canSort}
        setColumn={(k) => setSortColumn(k as SortSource)}
        setOrder={setSortOrder}
      />
    );
  };

  return (
    <Card className="flex-1 flex flex-col">
      <CardHeader>
        <CardTitle>Add sources</CardTitle>
        <CardDescription>Select the sources you wish to integrate with your AI Agent</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 flex flex-col">
        <div className="flex p-[1px] pb-3 gap-3 items-center flex-row overflow-x-auto">
          <Input
            placeholder="Search for ..."
            value={filterValue}
            onChange={(event) => {
              setCurrentPage(1);
              setSelectedSourcesToAdd([]);
              setFilterValue(event.target.value);
              setSelectedFilters({
                type: [],
              });
            }}
            className="max-w-sm w-full shadow-sm min-w-[250px] bg-background"
          />
          {validFilters.type.length > 0 && (
            <FilterButton
              title="Type"
              options={sourceTypes}
              validFilters={validFilters.type}
              selectedFilters={selectedFilters.type}
              setSelectedFilters={(newType: Filters) => {
                setCurrentPage(1);
                setSelectedFilters({
                  ...selectedFilters,
                  type: newType,
                });
              }}
            />
          )}
          {(filterValue.length > 0 || selectedFilters.type.length > 0) && (
            <Button
              variant="ghost"
              onClick={() => {
                setSelectedSourcesToAdd([]);
                setFilterValue('');
                setCurrentPage(1);
                setSelectedFilters({
                  type: [],
                });
              }}
              className="h-8 px-2 lg:px-3"
            >
              Reset
              <X strokeWidth={1.75} className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        <div className="flex-1 overflow-hidden mb-2">
          <Table containerClasses="border bg-background max-h-full rounded-md " className="table-fixed">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[40px] py-2 pl-3 pr-0">
                  <Checkbox
                    className="mt-2"
                    disabled={dataToUse.length === 0}
                    checked={selectedSourcesToAdd.length === dataToUse.length && dataToUse.length > 0}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedSourcesToAdd(dataToUse);
                      } else {
                        setSelectedSourcesToAdd([]);
                      }
                    }}
                    aria-label="Select rows"
                  />
                </TableHead>
                <TableHead className="group px-2">
                  {generatHeader('Resource', false, 'file_name', true)}
                </TableHead>
                <TableHead className="group w-[100px] px-2">
                  {generatHeader('Type', true, 'type', true)}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dataToUse?.length > 0 ? (
                dataToUse.map((source) => {
                  const isSelected = selectedSourcesToAdd.some((src) => src.uuid === source.uuid);
                  return (
                    <TableRow
                      className="cursor-pointer group"
                      data-state={isSelected && 'selected'}
                      key={source.uuid}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isSelected) {
                          setSelectedSourcesToAdd([...selectedSourcesToAdd, source]);
                        } else {
                          const newSelection = selectedSourcesToAdd.filter((src) => src.uuid !== source.uuid);
                          setSelectedSourcesToAdd(newSelection);
                        }
                      }}
                    >
                      <TableCell className="py-2 pl-3 pr-0">
                        <Checkbox
                          className={cn('mt-2', isSelected ? 'border-none border-0' : '')}
                          checked={isSelected}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              setSelectedSourcesToAdd([...selectedSourcesToAdd, source]);
                            } else {
                              const newSelection = selectedSourcesToAdd.filter(
                                (src) => src.uuid !== source.uuid,
                              );
                              setSelectedSourcesToAdd(newSelection);
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                          aria-label="Select row"
                        />
                      </TableCell>
                      <TableCell className="p-2">
                        <SourceFileName source={source} />
                      </TableCell>
                      <TableCell className="p-2">
                        <SourceType source={source} />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {totalPages > 1 && (
          <div className="flex mt-auto items-center justify-between px-2 overflow-x-auto pb-1">
            <div className="flex-1 text-sm text-muted-foreground whitespace-nowrap mr-2">
              {selectedSourcesToAdd.length} of {dataToUse.length} row(s) selected.
            </div>
            <div className="flex items-center space-x-6 lg:space-x-8">
              <div className="flex whitespace-nowrap items-center justify-center text-sm font-medium">
                Page {currentPage} of {totalPages}
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  className="hidden h-8 w-8 p-0 lg:flex bg-background"
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                >
                  <span className="sr-only">Go to first page</span>
                  <ChevronsLeftIcon strokeWidth={1.75} className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  className="h-8 w-8 p-0 bg-background"
                  onClick={() => setCurrentPage((page) => page - 1)}
                  disabled={currentPage === 1}
                >
                  <span className="sr-only">Go to previous page</span>
                  <ChevronLeftIcon strokeWidth={1.75} className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  className="h-8 w-8 p-0 bg-background"
                  onClick={() => setCurrentPage((page) => page + 1)}
                  disabled={currentPage === totalPages}
                >
                  <span className="sr-only">Go to next page</span>
                  <ChevronRightIcon className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex bg-background"
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  <span className="sr-only">Go to last page</span>
                  <ChevronsRightIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex justify-end">
        <Button onClick={() => setAddingSources(false)} variant="outline">
          Cancel
        </Button>
        <Button onClick={addingSources} className="ml-4" disabled={selectedSourcesToAdd.length === 0}>
          Add
        </Button>
      </CardFooter>
    </Card>
  );
};

export default AddSourcesCard;
