import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import chatbotService from 'api/chatbot';
import { Button } from 'components/ui/button';
import { Loader2 } from 'lucide-react';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { alerts } from 'utils/alert';

const DeleteOwnershipTransferDialog: React.FC<{ show: boolean; close: () => void }> = ({ show, close }) => {
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const transferKey = ['transfer-request', chatbot?.uuid];
  const [deleting, setDeleting] = useState<boolean>(false);

  const { data: transferRequest } = useQuery({
    queryKey: transferKey,
    queryFn: () => chatbotService.getChatbotTransferRequest(chatbot.uuid),
  });

  const deleteOwnershipTransfer = () => {
    if (transferRequest?.uuid) {
      chatbotService
        .deleteChatbotTransfer(transferRequest.uuid)
        .then(() => {
          queryClient.setQueryData(transferKey, { error: 'No transfer request was found' });
          addAlert({
            severity: 'success',
            message: alerts.BOT_TRASNFER_OWNERSHIP_REQUEST_DELETED,
            timeout: 5000,
          });
          close();
        })
        .catch(() => {
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
            timeout: 5000,
          });
        })
        .finally(() => {
          setDeleting(false);
        });
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete Transfer Ownership Request
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you want to delete the ownership transfer request? This action will
                    automatically remove the request, and the user will no longer be able to approve it. After
                    deletion, the ownership of the bot will remain with you.
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={close}>
                    Cancel
                  </Button>
                  <Button disabled={deleting} variant="destructive" onClick={deleteOwnershipTransfer}>
                    {deleting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                    {deleting ? 'Deleting' : 'Delete'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteOwnershipTransferDialog;
