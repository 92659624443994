import React from 'react';
import SourceTable from 'components/Chatbot/Sources.tsx/SourceTable/SourceTable';
import useDataSources from 'hooks/useDataSources';

const Sources: React.FC = () => {
  const { sources, usedSourceData } = useDataSources();

  return <SourceTable isLoading={!sources} data={sources || []} usedData={usedSourceData} />;
};

export default Sources;
