import { motion, useAnimation } from 'framer-motion';
import { CheckIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { chatSizeVariants, defaultChatLightTextColor, IUserMessageColors, WidgetSize } from 'utils/bot';
import { cn } from 'utils/cn';

const SubmitButton: React.FC<{
  submitting?: boolean;
  size: WidgetSize;
  text: string;
  userMessageColors: IUserMessageColors;
  onClick: () => void;
}> = ({ submitting = false, size, text, userMessageColors, onClick }) => {
  const controls = useAnimation();

  useEffect(() => {
    if (submitting) {
      const buttonSize = ['lg', 'xl', 'md'].includes(size) ? 40 : size === 'xs' ? 32 : 36;

      const runButtonSubmission = async () => {
        await controls.start({
          width: buttonSize,
          height: buttonSize,
          padding: 0,
          borderRadius: '50%',
          transition: { duration: 0.3, ease: 'easeInOut' },
        });
        await controls.start({ rotate: 360, transition: { duration: 0.5 } });
      };

      runButtonSubmission();
    }
  }, [submitting]);

  return (
    <motion.button
      type="button"
      disabled={submitting}
      className={cn(
        'font-medium flex items-center justify-center mx-auto border-none w-full shadow-none rounded-lg outline-none ring-0 focus-visible:ring-0 whitespace-nowrap overflow-hidden',
        !submitting ? 'hover:brightness-90 ' : '',
        ['lg', 'xl', 'md'].includes(size)
          ? 'h-10 rounded-md px-8'
          : size === 'xs'
            ? 'h-8 rounded-md px-3 text-xs'
            : 'h-9 px-4 py-2',
        chatSizeVariants.form.button[size],
      )}
      onClick={onClick}
      style={{
        color: submitting ? defaultChatLightTextColor : userMessageColors.textColor,
        backgroundColor: submitting ? '#22c55e' : userMessageColors.backgroundColor,
      }}
      animate={controls}
    >
      {submitting ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          <CheckIcon className={['lg', 'xl', 'md'].includes(size) ? 'w-5 h-5' : 'w-4 h-4'} strokeWidth={2} />
        </motion.div>
      ) : (
        text
      )}
    </motion.button>
  );
};

export default SubmitButton;
