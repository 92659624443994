/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import LoadingSpinner from 'components/helpers/LoadingSpinner';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { Progress } from 'components/ui/progress';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { Check, ChevronLeft, ChevronRight, Copy, Hourglass, Loader2, Trash, X } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';
import { cn } from 'utils/cn';
import { formatBytes } from 'utils/formatting';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot } from 'models/api/response.types';
import { supportedTableFormats } from 'utils/dataSources';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Badge } from 'components/ui/badge';
import { Checkbox } from 'components/ui/checkbox';

const getStatus = (status: string) => {
  switch (status) {
    case 'pending':
      return (
        <Badge
          variant="outline"
          className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
        >
          <LoadingSpinner small />
          Uploading
        </Badge>
      );
    case 'success':
      return (
        <Badge
          variant="outline"
          className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
        >
          <Check strokeWidth={1.75} className="w-4 h-4 text-green-500" />
          Success
        </Badge>
      );
    case 'fulfilled':
      return (
        <Badge
          variant="outline"
          className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
        >
          <Check strokeWidth={1.75} className="w-4 h-4 text-green-500" />
          Success
        </Badge>
      );
    case 'duplicate':
      return (
        <Badge
          variant="outline"
          className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
        >
          <Copy strokeWidth={1.75} className="w-4 h-4 text-secondary" />
          Duplicate
        </Badge>
      );
    case 'rejected':
      return (
        <Badge
          variant="outline"
          className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
        >
          <X strokeWidth={1.75} className="w-4 h-4 text-destructive" />
          Failed
        </Badge>
      );
    default:
      return (
        <Badge
          variant="outline"
          className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
        >
          <Hourglass strokeWidth={1.75} className="w-4 h-4 text-warning/50" />
          Await
        </Badge>
      );
  }
};

const FilesUploader: React.FC<{
  files: File[];
  removeFiles: (files: string[]) => void;
  uploadFiles: () => void;
  isTemplateBuilder: boolean;
  fileIsUploading: boolean;
  isPostUpload: boolean;
  getUploadProgress: (index: number) => string;
  usedData: {
    tokens: number;
    file_size: number;
  };
  close: () => void;
}> = ({
  files,
  removeFiles,
  uploadFiles,
  isTemplateBuilder,
  fileIsUploading,
  isPostUpload,
  getUploadProgress,
  close,
  usedData,
}) => {
  const navigate = useNavigate();
  const { chatbotSubscription } = useSubscriptionInfo();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [openTableGuide, setOpenTableGuide] = useState<boolean>(false);
  const itemsPerPage = 15;
  const totalPages = Math.ceil(files.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (totalPages < currentPage && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  useEffect(() => {
    if (files.length === 0) {
      close();
    }
  }, [files]);

  const showTableAlert = useMemo(() => {
    return files.some((file) => {
      return supportedTableFormats.some((ext) => file.name.endsWith(ext));
    });
  }, [files]);

  const canUpgradePlan = useMemo(() => {
    return chatbot && !chatbot.permissions;
  }, [chatbot]);

  const sizeToUpload = useMemo(() => {
    return files.reduce((count, obj) => count + obj.size, 0);
  }, [files]);

  const isFileSizeLimitExceeded = useMemo(() => {
    if (sizeToUpload && chatbotSubscription?.usage && !chatbotSubscription?.usage?.storage_unlimited) {
      return chatbotSubscription.usage.storage_max < sizeToUpload + usedData.file_size;
    }
    return false;
  }, [chatbotSubscription, usedData, files]);

  const filesToShow = useMemo(() => {
    return files.slice(startIndex, endIndex);
  }, [startIndex, endIndex, files]);

  if (!chatbotSubscription) {
    return null;
  }

  return (
    <div className="h-full items-center justify-center text-center">
      {!openTableGuide ? (
        <div className="w-full max-h-full flex flex-col transform overflow-hidden rounded-md bg-background p-6 text-left align-middle shadow-sm border">
          <h2 className="text-lg font-medium leading-6 text-gray-900">Files to upload</h2>
          {showTableAlert && (
            <div className="my-4 text-sm py-2 px-4 bg-blue-50 border border-blue-200 rounded-md">
              To ensure smooth operation and avoid issues in the future, we highly recommend reviewing our
              guide on how structured data should be formatted. This guide provides valuable insights and best
              practices for structuring your data.
              <a
                className="text-secondary ml-2 underline"
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTableGuide(true);
                }}
              >
                Learn More
              </a>
            </div>
          )}
          <div className="rounded-md border bg-background mt-4 overflow-auto flex-1">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[40px]">
                    <Checkbox
                      className="mt-2"
                      disabled={isPostUpload || fileIsUploading}
                      checked={selectedSources.length === filesToShow.length && filesToShow.length > 0}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          setSelectedSources(filesToShow.map((file) => `${file.name}+${file.size}`));
                        } else {
                          setSelectedSources([]);
                        }
                      }}
                      aria-label="Select rows"
                    />
                  </TableHead>
                  <TableHead className="w-[400px]">
                    File name
                    {selectedSources.length > 0 && !fileIsUploading && (
                      <Button
                        className="ml-4"
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          removeFiles(selectedSources);
                          setSelectedSources([]);
                        }}
                      >
                        Delete ({selectedSources.length})
                      </Button>
                    )}
                  </TableHead>
                  <TableHead className="w-[50px]">Status</TableHead>
                  <TableHead className="text-right whitespace-nowrap">File size</TableHead>
                  <TableHead className="text-right" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {filesToShow.map((file, index) => {
                  const uploadProgress = getUploadProgress(index);
                  const isSelected = selectedSources.includes(`${file.name}+${file.size}`);
                  return (
                    <TableRow key={index}>
                      <TableCell className="py-2 pr-0">
                        <Checkbox
                          disabled={isPostUpload || fileIsUploading}
                          className={cn('mt-2', isSelected ? 'border-none border-0' : '')}
                          checked={isSelected}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              setSelectedSources([...selectedSources, `${file.name}+${file.size}`]);
                            } else {
                              const newSelection = selectedSources.filter(
                                (src: string) => src !== `${file.name}+${file.size}`,
                              );
                              setSelectedSources(newSelection);
                            }
                          }}
                          onClick={(e) => e.stopPropagation()}
                          aria-label="Select row"
                        />
                      </TableCell>
                      <TableCell className="whitespace-nowrap overflow-hidden max-w-[640px] text-ellipsis py-2">
                        <span className="font-medium">{file.name}</span>
                      </TableCell>
                      <TableCell className="w-[20px] py-2">
                        {getStatus(fileIsUploading || isPostUpload ? uploadProgress : '')}
                      </TableCell>
                      <TableCell className="whitespace-nowrap overflow-hidden text-right text-ellipsis py-2">
                        {formatBytes(file.size)}
                      </TableCell>
                      <TableCell className="text-right py-2">
                        <Button
                          variant="outline"
                          className="border-0 shadow-none text-destructive h-4 py-0 hover:text-destructive hover:bg-transparent outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent"
                          onClick={() => {
                            removeFiles([`${file.name}+${file.size}`]);
                            setSelectedSources([]);
                          }}
                          disabled={fileIsUploading || isPostUpload}
                        >
                          <Trash strokeWidth={1.75} className="w-4 h-4" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          {!chatbotSubscription?.usage?.storage_unlimited && (
            <div className="mt-4">
              <div className="flex items-center justify-between mb-4 flex-wrap gap-1">
                <Label className="" htmlFor="prompt">
                  Storage
                </Label>
                <Label className="" htmlFor="prompt">
                  <span
                    className={cn('mr-2', isFileSizeLimitExceeded ? 'text-destructive' : 'text-green-500')}
                  >
                    +{formatBytes(sizeToUpload)}
                  </span>
                  {formatBytes(usedData.file_size)} / {formatBytes(chatbotSubscription?.usage?.storage_max)}
                </Label>
              </div>
              <Progress
                value={
                  ((sizeToUpload + usedData.file_size) /
                    (chatbotSubscription?.usage?.storage_max as number)) *
                  100
                }
              />
            </div>
          )}
          {isFileSizeLimitExceeded && !isPostUpload && (
            <div className="mt-4 border inline-block px-2 py-1 rounded-md bg-destructive/10 border-destructive text-center">
              <p className="text-sm">
                {isTemplateBuilder
                  ? 'Storage limit has been reached. Remove files from the list so you can add them as sources.'
                  : canUpgradePlan
                    ? 'You have reached the storage limit. To download more files, please consider upgrading your tier or purchasing an add-on.'
                    : 'Chatbot reached the storage limit. To download more files, please ask owner to increase storage limit.'}
              </p>
            </div>
          )}
          <div className="flex items-center gap-4 mt-6 justify-end">
            {totalPages > 1 && (
              <div className="text-sm flex items-center justify-end gap-4 mr-auto">
                <p className="text-muted-foreground">
                  Page {currentPage} of {totalPages}
                </p>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  disabled={currentPage === 1}
                  onClick={() => setPage(currentPage - 1)}
                >
                  <ChevronLeft className="w-4 h-4" />
                </Button>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  disabled={totalPages === currentPage}
                  onClick={() => setPage(currentPage + 1)}
                >
                  <ChevronRight className="w-4 h-4" />
                </Button>
              </div>
            )}
            {isPostUpload && (
              <Button onClick={close}>{isTemplateBuilder ? 'Continue' : 'Upload more'}</Button>
            )}
            {isFileSizeLimitExceeded && !isPostUpload && canUpgradePlan && !isTemplateBuilder && (
              <Button
                onClick={() => {
                  navigate(routePaths.upgrade);
                }}
              >
                Upgrade
              </Button>
            )}
            {!isPostUpload && (!isFileSizeLimitExceeded || isTemplateBuilder) && (
              <Button disabled={fileIsUploading || isFileSizeLimitExceeded} onClick={uploadFiles}>
                {fileIsUploading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {fileIsUploading ? 'Adding' : 'Add'} to Sources
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full max-h-full overflow-auto rounded-md bg-background p-6 text-left align-middle shadow-sm border">
          <h2 className="text-lg font-medium leading-6 text-gray-900">Guide for structured data</h2>
          <div className="my-4 text-sm text-muted-foreground">
            When integrating structured tables with AI systems, particularly for chatbot applications,
            it&apos;s essential to ensure that your data is formatted correctly. This not only enhances the
            efficiency of the AI but also ensures accurate data processing. Below are key steps to follow:
            <ol className="list-decimal ml-8 my-4">
              <li className="font-semibold text-foreground">
                Transpose Row-Header or Row-Column Header Tables
              </li>
              <ul className="marker:text-primary list-outside list-disc my-2 ml-4">
                <li>
                  <span className="text-foreground">Transpose Your Table:</span> If your table uses row
                  headers or a combination of row and column headers, you should transpose it to a column
                  header format. AI systems typically process tables with column headers by default.
                </li>
                <li>
                  <span className="text-foreground">How to Transpose:</span> You can transpose your data using
                  various tools. For instance, in Microsoft Excel, follow the guide provided by Microsoft:{' '}
                  <a
                    rel="noreferrer"
                    href="https://support.microsoft.com/en-gb/office/transpose-rotate-data-from-rows-to-columns-or-vice-versa-3419f2e3-beab-4318-aae5-d0f862209744"
                    target="_blank"
                    className="text-secondary underline"
                  >
                    Transpose (rotate) data from rows to columns or vice versa.
                  </a>
                </li>
                <li>
                  <span className="text-foreground">Post-Upload Adjustments:</span> Although the AI defaults
                  to column header tables, adjustments can be made after uploading if necessary.
                </li>
              </ul>
              <li className="font-semibold text-foreground">Ensure Clarity and Organization</li>
              <ul className="marker:text-primary list-outside list-disc my-2 ml-4">
                <li>
                  <span className="text-foreground">Clear and Precise Headers:</span> The AI relies heavily on
                  well-defined headers to interpret data correctly. Ensure that your headers are clear and
                  descriptive.
                </li>
                <li>
                  <span className="text-foreground">Organized Data:</span> The structure of your table should
                  be logical and organized. This includes consistent formatting, accurate data categorization,
                  and avoidance of unnecessary clutter.
                </li>
                <li>
                  <span className="text-foreground">Quality Over Aesthetics:</span> While the AI doesn&apos;t
                  improve the visual format of your data, the quality and structure of the data itself are
                  paramount. Adhere to the principle of &quot;garbage in, garbage out&quot; - only clean,
                  well-structured data ensures effective AI processing.
                </li>
              </ul>
              <li className="font-semibold text-foreground">Minimize File Size</li>
              <ul className="marker:text-primary list-outside list-disc my-2 ml-4">
                <li>
                  <span className="text-foreground">Compact Files:</span> To enhance performance and avoid
                  issues like UI freezing, keep your table files as small as feasible.
                </li>
                <li>
                  <span className="text-foreground">Efficient Data Representation:</span> Ensure that your
                  data is presented in the most efficient way possible. This includes removing redundant
                  information and compressing data without losing essential details.
                </li>
                <li>
                  <span className="text-foreground">Splitting Large Data:</span> If your data is extensive,
                  consider breaking it into smaller, more manageable chunks. This not only speeds up
                  processing but also makes data easier to handle and analyze.
                </li>
              </ul>
            </ol>
          </div>
          <div className="flex items-center gap-4 mt-6 justify-end">
            <Button
              onClick={() => {
                setOpenTableGuide(false);
              }}
            >
              Proceed with upload
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilesUploader;
