/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { formatDistanceToNow } from 'date-fns';
import { AIAgentTag, ChatSession, ChatSessions } from 'models/api/response.types';
import { cn } from 'utils/cn';
import sessionService from 'api/session';
import { useQueryClient } from '@tanstack/react-query';
import { Badge } from 'components/ui/badge';
import { conversationFilters } from 'utils/inbox';
import { useChat } from 'providers/ChatProvider';
import StatusDot from 'components/helpers/StatusDot';
import tinycolor from 'tinycolor2';
import { ParsedSessionMeta } from 'utils/sessions';

const Session: React.FC<{
  sessionsQueryKey: string[];
  session: ChatSession;
  currentSession?: ChatSession;
  setCurrentSession: (session: ChatSession) => void;
}> = ({ sessionsQueryKey, session, currentSession, setCurrentSession }) => {
  const { unreadSessions } = useChat();
  const queryClient = useQueryClient();
  const containerRef = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLSpanElement>(null);
  const { decrementUnreads } = useChat();

  const { meta, variables } = useMemo(() => {
    return {
      meta: JSON.parse(session.meta_json) as ParsedSessionMeta,
      variables: JSON.parse(session.agent_state_store_json || '{}'),
    };
  }, [session]);

  // rely on unreadSessions, user can mark all sessions as read
  // we don't want to make expensive computation, instead just make dummy check
  const isUnread = useMemo(() => {
    return meta?.read === false && unreadSessions > 0;
  }, [meta, unreadSessions]);

  const { formData, instagramData, messengerData, whatsAppData, zendeskData } = useMemo(() => {
    return {
      formData: meta?.form_data,
      instagramData: meta?.instagram_details,
      messengerData: meta?.messenger_details,
      whatsAppData: meta?.whatsapp_details,
      zendeskData: meta?.zendesk_details,
    };
  }, [meta]);

  const identifier = useMemo(() => {
    const stateParameters = {
      ...variables?.states,
      ...formData,
      ...instagramData?.user,
      ...messengerData?.user,
      ...whatsAppData?.user,
      ...zendeskData?.chatbot,
    };

    if (!stateParameters) return undefined;

    // those keys from integrations come by default as for other things
    // keys are dynamic and can be changed
    const nameKeys = ['name', 'username', 'phone_number'];
    let nameFound = '';

    for (const param in stateParameters) {
      if (
        nameKeys.includes(param) ||
        param.toLowerCase().includes('name') ||
        param.toLowerCase().includes('email')
      ) {
        const value = stateParameters[param];
        if (typeof value === 'object' ? value?.content : value && value !== 'NOT PROVIDED') {
          nameFound = typeof value === 'object' ? value.content : value;
          break;
        }
      }
    }

    return nameFound;
  }, [variables, formData, instagramData, messengerData, whatsAppData, zendeskData]);

  const isCurrent = useMemo(() => {
    return currentSession && currentSession.uuid === session.uuid;
  }, [currentSession]);

  useEffect(() => {
    if (isUnread && currentSession?.uuid === session.uuid) {
      sessionService
        .updateChatbotSession(session.uuid, {
          meta_json: JSON.stringify({
            read: true,
          }),
        })
        .then(() => {
          const sessions: ChatSessions | undefined = queryClient.getQueryData(sessionsQueryKey);
          if (sessions) {
            const indexToUpdate = sessions.findIndex((s) => s.uuid === session.uuid);
            if (indexToUpdate !== -1) {
              const newMeta = {
                ...meta,
                read: true,
              };
              const updatedSession = {
                ...sessions[indexToUpdate],
                meta_json: JSON.stringify(newMeta),
              };
              const updatedSessions = [
                ...sessions.slice(0, indexToUpdate),
                updatedSession,
                ...sessions.slice(indexToUpdate + 1),
              ];
              queryClient.setQueryData(sessionsQueryKey, updatedSessions);
            }
          }
          decrementUnreads();
        });
    }
  }, [isUnread, currentSession]);

  useEffect(() => {
    const updateTagsDisplay = () => {
      const container = containerRef.current;
      const indicator = indicatorRef.current;
      if (!container || !indicator) return;

      // Temporarily show all tags to calculate their widths
      const tagElements = container.querySelectorAll('.tag');
      tagElements.forEach((tagElement) => {
        (tagElement as HTMLElement).style.display = 'inline-block';
      });

      let visibleTagsCount = 0;
      const containerWidth = container.offsetWidth;
      let totalTagsWidth = 0;

      // Recalculate widths and update visibility
      tagElements.forEach((tagElement) => {
        const tag = tagElement as HTMLElement;
        const tagWidth = tag.clientWidth + parseInt(getComputedStyle(tag).marginRight, 10);
        totalTagsWidth += tagWidth;

        if (totalTagsWidth <= containerWidth - 30) {
          visibleTagsCount++;
        } else {
          tag.style.display = 'none';
        }
      });

      const hiddenTagsCount = tagElements.length - visibleTagsCount;
      if (hiddenTagsCount > 0) {
        indicator.textContent = `+${hiddenTagsCount} tags`;
        indicator.style.display = 'inline-block';
      } else {
        indicator.style.display = 'none';
      }
    };

    updateTagsDisplay();
    window.addEventListener('resize', updateTagsDisplay);

    return () => {
      window.removeEventListener('resize', updateTagsDisplay);
    };
  }, [variables?.tags]);

  return (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        'flex flex-col items-start gap-2 rounded-md border p-3 text-left text-sm transition-all hover:bg-accent relative',
        isCurrent ? 'bg-primary/5' : 'cursor-pointer hover:bg-muted/50',
      )}
      onClick={() => {
        setCurrentSession(session);
      }}
    >
      <div className="flex items-center gap-2 w-full">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>{conversationFilters[meta?.origin].icon('mr-0')}</TooltipTrigger>
            <TooltipContent>
              <p className="font-normal">{conversationFilters[meta?.origin].title}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {identifier && (
          <p className="text-sm overflow-hidden text-ellipsis font-medium whitespace-nowrap">{identifier}</p>
        )}
        {isUnread && currentSession?.uuid !== session.uuid && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <StatusDot className="w-3 h-3 p-[2px]" status="info" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="font-normal">Unread message</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {session?.human_escalation_status === 'requested' && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <StatusDot className="w-3 h-3 p-[2px]" status="warning" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="font-normal">Support Requested</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {session?.human_escalation_status === 'active' && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <StatusDot className="w-3 h-3 p-[2px]" status="success" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="font-normal">Live Chat</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        <span
          className={cn(
            'ml-auto text-xs whitespace-nowrap',
            isCurrent ? 'text-foreground' : 'text-muted-foreground',
          )}
        >
          {formatDistanceToNow(new Date(session?.latest_message_at || session.modified_at), {
            addSuffix: true,
          })}
        </span>
      </div>
      <p className="text-xs line-clamp-3 text-muted-foreground">
        {meta.latest_message ?? meta.initial_message}
      </p>
      {variables?.tags && (
        <div className="flex items-center gap-1 overflow-hidden w-full" ref={containerRef}>
          {variables.tags.map((tag: AIAgentTag, index: number) => {
            return (
              <Badge
                style={{
                  borderColor: tag.color,
                  color: tinycolor(tag.color).darken(25).toString(),
                  backgroundColor: `${tag.color}10`,
                }}
                key={index}
                variant="outline"
                className="tag text-xs whitespace-nowrap rounded-md py-[2px] px-2"
              >
                {tag.name}
              </Badge>
            );
          })}
          <span className="tags-indicator text-muted-foreground whitespace-nowrap ml-2" ref={indicatorRef} />
        </div>
      )}
    </div>
  );
};

export default Session;
