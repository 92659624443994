/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo, useMemo, useState } from 'react';
import { IChatMainColors, WidgetSize } from 'utils/bot';
import { cn } from 'utils/cn';

const ChatGeneratedImage: React.FC<{
  chatMainColors?: IChatMainColors;
  url: string;
  size: WidgetSize;
}> = memo(({ chatMainColors, url, size }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { height, width } = useMemo(() => {
    const delimeter = ['sm', 'xs'].includes(size) ? 2 : ['md', 'lg'].includes(size) ? 1.5 : 1.3;
    const params = new URLSearchParams(new URL(url).search);
    return {
      height: parseInt(params.get('height') || '250', 10) / delimeter,
      width: parseInt(params.get('width') || '250', 10) / delimeter,
    };
  }, []);

  return (
    <div
      style={
        chatMainColors
          ? {
              borderColor: chatMainColors.border,
            }
          : {}
      }
      className="w-fit group relative rounded-lg border"
    >
      <img
        className={cn(
          `duration-300 h-full object-contain rounded-lg cursor-pointer hover:opacity-90 transition-opacity`,
          isLoaded ? 'opacity-100' : 'opacity-0',
        )}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
        loading="lazy"
        decoding="async"
        src={url}
        alt="Civit AI Generated Response"
        onLoad={() => setIsLoaded(true)}
        onClick={() => {
          window.open(url, '_blank');
        }}
      />
    </div>
  );
});

export default ChatGeneratedImage;
