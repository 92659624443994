import React from 'react';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import LoadingOverlay from './LoadingOverlay';

const MainLoader: React.FC = () => {
  const { appLogo, appTitle } = useWhitelabelData();
  return (
    <div className="fixed bg-secondary-background w-full flex flex-col h-full z-50 items-center justify-center">
      {appLogo && <img className="mb-10 h-12 max-w-[500px]" src={appLogo} alt={appTitle} />}
      <LoadingOverlay size="lg" />
    </div>
  );
};

export default MainLoader;
