/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { TabsContent } from 'components/ui/tabs';
import { AIAgentList } from 'models/api/response.types';
import { Label } from 'components/ui/label';
import { Slider } from 'components/ui/slider';
import agentService from 'api/agent';
import useAiAgents from 'hooks/useAiAgents';

const BiasStickinessTab: React.FC<{ agents: AIAgentList }> = ({ agents }) => {
  const { updateAgent } = useAiAgents();
  const [selectedAgent, setSelectedAgent] = useState<number>(0);
  const [properties, setProperties] = useState<{ bias: number; stickiness: number }>({
    bias: 0,
    stickiness: 0,
  });
  const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);
  const [initialLoad, setIntiialLoad] = useState<boolean>(true);
  const [isAgentSelection, setIsAgentSelection] = useState<boolean>(true);

  useEffect(() => {
    const { bias, stickiness } = JSON.parse(agents[selectedAgent].meta_json);
    setIsAgentSelection(true);
    setProperties({
      bias: bias || 0,
      stickiness: stickiness || 0,
    });
  }, [selectedAgent]);

  const updateAgentSettings = () => {
    const agentId = agents[selectedAgent].uuid;
    const currentMeta = JSON.parse(agents[selectedAgent].meta_json);
    const newMeta = JSON.stringify({ ...currentMeta, ...properties });
    agentService.updateAIAgent(agentId, { meta_json: newMeta }).then((res) => {
      updateAgent(res);
    });
  };

  const scheduleUpdate = () => {
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    setUpdateTimeout(setTimeout(updateAgentSettings, 500));
  };

  useEffect(() => {
    if (!initialLoad && !isAgentSelection) {
      scheduleUpdate();
    } else if (initialLoad) {
      setIntiialLoad(false);
    } else if (isAgentSelection) {
      setIsAgentSelection(false);
    }

    return () => {
      if (updateTimeout) {
        clearTimeout(updateTimeout);
      }
    };
  }, [properties]);

  return (
    <TabsContent id="intents-tab" className="flex flex-col gap-6 mt-0" value="bias-stickiness">
      <p className="text-sm text-muted-foreground">
        Bias and stickiness are modifiers to the default relevance the AI Supervisor assigns the Agent under
        normal circumstances. Bias represents a general preference (positive) or aversion (negative) to a
        user-facing Agent when it is considered as candidate for handling user&apos;s query. Stickiness
        represents an added &quot;switching cost&quot; that needs to be overcome in order for the Agent to be
        switched out when it is the currently active Agent.
      </p>
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-4">
          <Select
            value={selectedAgent.toString()}
            onValueChange={(idx: string) => setSelectedAgent(parseInt(idx, 10))}
          >
            <SelectTrigger className="max-w-[350px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {agents.map((agent, idx) => {
                return (
                  <SelectItem key={idx} value={idx.toString()}>
                    {agent.name}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2 mb-8">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="agent-bias">
            Bias: {properties.bias}%
          </Label>
          <p className="text-sm text-muted-foreground">
            Allows you to specify the default preference level for this Agent compared to others when there
            are no AI Supervisor Overrides in effect. Adjusting this value determines how likely this Agent is
            to be chosen in the absence of specific rules or overrides set by the AI Supervisor.
          </p>
          <Slider
            id="agent-bias"
            className="mt-2"
            value={[properties.bias]}
            max={25}
            min={-26}
            step={1}
            onValueChange={(value) => {
              setProperties({
                ...properties,
                bias: value[0] === -26 ? -100 : value[0],
              });
            }}
          />
          {properties.bias === -100 && (
            <p className="ml-1 text-xs text-warning mt-2">
              Agent will <strong>NEVER</strong> be chosen by AI Supervisor automatically. You will need
              Overrides to direct user queries to this Agent.
            </p>
          )}
        </div>
        <div className="flex flex-col gap-2 mb-8">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="agent-stickiness">
            Stickiness: {properties.stickiness}
          </Label>
          <p className="text-sm text-muted-foreground">
            Determines the difficulty level of switching away from this Agent when it is currently active. A
            higher value indicates that it will be more challenging to switch to another Agent, ensuring that
            this Agent remains active for a longer period unless specific conditions are met.
          </p>
          <Slider
            id="agent-stickiness"
            className="mt-2"
            value={[properties.stickiness]}
            max={2}
            min={0}
            step={0.1}
            onValueChange={(value) => {
              setProperties({
                ...properties,
                stickiness: value[0],
              });
            }}
          />
        </div>
      </div>
    </TabsContent>
  );
};

export default BiasStickinessTab;
