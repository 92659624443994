/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { IChatMainColors, WidgetSize, chatSizeVariants } from 'utils/bot';
import { QueryToStream, defaultQueryToStream } from 'models/api/chat.types';

const ChatInput: React.FC<{
  size: WidgetSize;
  disabled: boolean;
  autoFocus?: boolean;
  generateMessage: (message: QueryToStream) => void;
  chatMainColors?: IChatMainColors;
  showWatermark?: boolean;
  placeholder?: string;
  inputLength?: number;
  hidden?: boolean;
}> = ({
  size,
  disabled,
  autoFocus,
  generateMessage,
  placeholder = '',
  inputLength = 2000,
  // part of Bot Widget
  chatMainColors,
  showWatermark,
  hidden,
}) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [inputSizeToUse, setInputSizeToUse] = useState<WidgetSize>(size);
  const [inputValue, setInputValue] = useState<string>('');
  const [isComposing, setIsComposing] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputValue.length === 0 && textAreaRef.current) {
      textAreaRef.current.style.height = `${chatSizeVariants.input.textAreaScrollHeight[inputSizeToUse]}px`;
    }
  }, [inputValue, inputSizeToUse, size]);

  useEffect(() => {
    // Function to update window width state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Perform initial check
    handleResize();

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth < 640 && inputSizeToUse !== 'xl') {
      setInputSizeToUse('xl');
    }
    if (windowWidth > 640 && inputSizeToUse !== size) {
      setInputSizeToUse(size);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (textAreaRef?.current && autoFocus && !disabled) {
      textAreaRef.current.focus();
    }
  }, [textAreaRef]);

  const handleTextareaInput = useCallback(
    (event: any) => {
      const { value } = event.target;
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.scrollHeight > chatSizeVariants.input.textAreaScrollHeight[inputSizeToUse]) {
        textarea.style.overflow = 'auto';
      } else {
        textarea.style.overflow = 'hidden';
      }
      if (value.length < inputLength) {
        setInputValue(value);
      } else {
        setInputValue(value.slice(0, inputLength));
      }
    },
    [inputSizeToUse, size],
  );

  const sendMessage = () => {
    if (inputValue.length > 0) {
      const trimmedMessage = inputValue.trim();
      const cleanedMessage = trimmedMessage.replace(/\n{2,}/g, '\n');
      if (cleanedMessage.length > 0) {
        generateMessage({ ...defaultQueryToStream, value: cleanedMessage });
        setInputValue('');
      }
    }
  };

  return (
    <div
      className={cn(
        'transition-opacity',
        chatSizeVariants.input.container[size],
        showWatermark ? 'pb-2' : '',
        hidden && 'opacity-0',
      )}
    >
      <div
        className={cn(
          'relative border rounded-md flex items-center',
          chatSizeVariants.input.secondaryContainer[inputSizeToUse],
        )}
        style={
          chatMainColors
            ? {
                borderColor: chatMainColors.border,
              }
            : {}
        }
      >
        <textarea
          disabled={hidden}
          rows={1}
          ref={textAreaRef}
          style={
            chatMainColors
              ? {
                  color: chatMainColors.text,
                  backgroundColor: chatMainColors.background,
                }
              : {}
          }
          placeholder={placeholder || ''}
          className={cn(
            'border-none w-full p-0 bg-transparent outline-none ring-0 ring-offset-0 focus-visible:ring-offset-0 focus-visible:ring-0 focus-visible:ring-transparent transition-all resize-none overflow-hidden',
            chatSizeVariants.input.input[inputSizeToUse],
          )}
          value={inputValue}
          onChange={handleTextareaInput}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
          onKeyDown={(event: React.KeyboardEvent<any>) => {
            if (event.key === 'Enter' && !event.shiftKey && !disabled && !isComposing) {
              event.preventDefault();
              sendMessage();
            }
          }}
        />
        <Button
          variant="outline"
          disabled={disabled}
          className={cn(
            'absolute shadow-none border-0 hover:bg-transparent p-0 outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent transition-all',
            chatSizeVariants.input.button[size],
          )}
          style={
            chatMainColors
              ? {
                  color: chatMainColors.text,
                }
              : {}
          }
          onClick={sendMessage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.75}
            stroke="currentColor"
            className="w-full h-full"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default ChatInput;
