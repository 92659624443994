/* eslint-disable react/no-array-index-key */
import React, { Fragment, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Chatbot, Chatbots } from 'models/api/response.types';
import { Button } from 'components/ui/button';
import chatbotService from 'api/chatbot';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, ChevronRight, ClipboardCopy } from 'lucide-react';
import { getSelectedChatbot, setSelectedChatbot } from 'store/reducers/ui';
import domainService from 'api/domain';
import { ChatBotMeta } from 'utils/bot';
import { useAlerts } from 'providers/AlertProvider';
import { PermissionType } from 'components/helpers/CollaboratorPermissionBadge';
import useChatbots from 'hooks/useChatbots';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { defaultIntegrationDomain } from 'utils/domain';
import { alerts } from 'utils/alert';

const ShareChatbotDialog: React.FC<{
  chatbotToShare?: Chatbot;
  close: () => void;
}> = ({ chatbotToShare, close }) => {
  const { canUseUserIdentity } = useSubscriptionInfo();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const chatbot = chatbotToShare || selectedChatbot;
  const dispatch = useDispatch();
  const { addAlert } = useAlerts();
  const { chatbots, setChatbotData } = useChatbots();
  const chatbotMetadata = JSON.parse(chatbot.meta_json) as ChatBotMeta;
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: customDomains } = useQuery({
    queryKey: ['chatbot-domains', chatbot?.uuid],
    queryFn: () => domainService.getChatbotDomains(chatbot.uuid),
  });

  const { isAuthenticationEnabled, canUpdateMeta } = useMemo(() => {
    return {
      isAuthenticationEnabled: canUseUserIdentity && chatbotMetadata?.webhook_auth?.enabled,
      canUpdateMeta:
        !chatbot.permissions || JSON.parse(chatbot.permissions || '[]').includes('meta' as PermissionType),
    };
  }, [chatbot, canUseUserIdentity]);

  const domainsToUse: { domain: string; link: string }[] = useMemo(() => {
    if (customDomains) {
      return [
        ...customDomains
          .filter((domain) => domain.configuration_status === 'configured')
          .map((domain) => {
            return {
              domain: domain.domain,
              link: `https://${domain.domain}/widget/${chatbot.uuid}`,
            };
          }),
        {
          domain: defaultIntegrationDomain,
          link: `https://${defaultIntegrationDomain}/widget/${chatbot.uuid}`,
        },
      ];
    }
    return [
      {
        domain: defaultIntegrationDomain,
        link: `https://${defaultIntegrationDomain}/widget/${chatbot.uuid}`,
      },
    ];
  }, [customDomains]);

  const disableAuthentication = () => {
    const newMeta = {
      ...chatbotMetadata,
      webhook_auth: {
        ...chatbotMetadata?.webhook_auth,
        enabled: false,
      },
    };
    chatbotService
      .updateChatbot(chatbot.uuid, {
        name: chatbot.name,
        meta_json: JSON.stringify(newMeta),
      })
      .then((data: Chatbot) => {
        if (!chatbotToShare || chatbotToShare.uuid === selectedChatbot.uuid) {
          dispatch(setSelectedChatbot(data));
        }
        const newChatbots = (chatbots as Chatbots).map((bot) => (bot.uuid === data.uuid ? data : bot));
        setChatbotData(newChatbots);
      });
  };

  const updateChatbotVisibility = () => {
    const newMeta = {
      ...chatbotMetadata,
      visibility: 'public',
    };
    chatbotService
      .updateChatbot(chatbot.uuid, {
        name: chatbot.name,
        meta_json: JSON.stringify(newMeta),
      })
      .then((data) => {
        if (!chatbotToShare || chatbotToShare.uuid === selectedChatbot.uuid) {
          dispatch(setSelectedChatbot(data));
        }
        const newChatbots = (chatbots as Chatbots).map((bot) => (bot.uuid === data.uuid ? data : bot));
        setChatbotData(newChatbots);
      });
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : domainsToUse.length - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex < domainsToUse.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => close()}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                {chatbotMetadata.visibility !== 'public' ? (
                  <>
                    <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                      Share chatbot
                    </DialogTitle>
                    {canUpdateMeta ? (
                      <>
                        <div className="mt-2">
                          {isAuthenticationEnabled ? (
                            <p className="font-normal text-muted-foreground text-sm">
                              If you intend to share your chatbot using a link, it is necessary to disable the
                              authentication feature to ensure unrestricted access for all users.
                            </p>
                          ) : (
                            <p className="font-normal text-muted-foreground text-sm">
                              If you make the chatbot <strong>Public</strong>, please note that any credits
                              spent by users while interacting with the chatbot will be counted towards your
                              own account.
                            </p>
                          )}
                        </div>
                        {isAuthenticationEnabled ? (
                          <div className="flex justify-end mt-6">
                            <Button variant="destructive" onClick={disableAuthentication}>
                              Disable Authentication
                            </Button>
                          </div>
                        ) : (
                          <div className="flex justify-end mt-6">
                            <Button onClick={updateChatbotVisibility}>Set to Public</Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="mt-2">
                        <p className="font-normal text-muted-foreground text-sm">
                          Sorry, this chatbot is private and cannot be shared. Please ask the owner to change
                          the settings if you wish to share it.
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                      Share chatbot
                    </DialogTitle>
                    <p className="font-normal text-muted-foreground text-sm mt-2">
                      Here is the link you can share with anyone to grant them access to the chatbot:
                    </p>
                    {domainsToUse.length > 1 && (
                      <div className="flex items-center gap-4 mt-4">
                        <Button
                          className="h-8 w-8 p-0"
                          onClick={handlePrevClick}
                          variant="outline"
                          size="icon"
                        >
                          <ChevronLeft strokeWidth={1.75} className="h-4 w-4" />
                        </Button>
                        <div className="flex-1 overflow-hidden">
                          <div
                            className="flex text-center flex-1 transition-all duration-300"
                            style={{
                              transform: `translateX(-${currentIndex * 100}%)`,
                            }}
                          >
                            {domainsToUse.map((item) => (
                              <div key={item.domain} className="flex-shrink-0 w-full">
                                <h3 className="font-semibold text-muted-foreground">{item.domain}</h3>
                              </div>
                            ))}
                          </div>
                        </div>
                        <Button
                          className="h-8 w-8 p-0"
                          onClick={handleNextClick}
                          variant="outline"
                          size="icon"
                        >
                          <ChevronRight strokeWidth={1.75} className="h-4 w-4" />
                        </Button>
                      </div>
                    )}
                    <div className="w-full overflow-hidden mt-2">
                      <div
                        className="flex transition-all duration-300"
                        style={{
                          transform: `translateX(-${currentIndex * 100}%)`,
                        }}
                      >
                        {domainsToUse.map((item) => (
                          <div key={item.domain} className="flex-shrink-0 flex w-full items-center gap-4">
                            <pre className="w-full overflow-auto text-xs bg-secondary-background rounded p-3">
                              <code>{item.link}</code>
                            </pre>
                            <Button
                              variant="outline"
                              className="h-8 w-8 p-0"
                              onClick={() => {
                                if ('clipboard' in navigator) {
                                  navigator.clipboard.writeText(item.link);
                                  addAlert({
                                    severity: 'success',
                                    message: alerts.LINK_COPIED,
                                  });
                                  close();
                                }
                              }}
                            >
                              <ClipboardCopy strokeWidth={1.75} className="w-4 h-4" />
                            </Button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareChatbotDialog;
