/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { FormEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'components/ui/input';
import { cn } from 'utils/cn';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import {
  ChatBotMeta,
  ChatbotFormField,
  IChatMainColors,
  IUserMessageColors,
  WidgetSize,
  chatSizeVariants,
  defaultChatbotData,
} from 'utils/bot';
import gfm from 'remark-gfm';
import { Checkbox } from 'components/ui/checkbox';
import ReactMarkdown from 'react-markdown';
import { Label } from 'components/ui/label';
import { Asterisk, X } from 'lucide-react';
import Link from './MarkdownElements/Link';
import Strong from './MarkdownElements/Strong';
import SubmitButton from './SubmitButton';

export interface FormData {
  [key: string]: any;
}

const WidgetForm: React.FC<{
  chatMessagesExists: boolean;
  chatbotMeta: ChatBotMeta;
  size: WidgetSize;
  chatMainColors: IChatMainColors;
  userMessageColors: IUserMessageColors;
  hide: (data?: FormData) => void;
}> = ({ chatMessagesExists, chatbotMeta, size, chatMainColors, userMessageColors, hide }) => {
  const { form } = chatbotMeta;
  const [show, setShow] = useState<boolean>(!chatMessagesExists);
  const [user, setUser] = useState<FormData | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    const container = document.getElementById('chat-container');
    if (container) {
      container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
    }
  }, []);

  const addNewUser: FormEventHandler<HTMLFormElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSubmitting(true);
    setTimeout(() => {
      setShow(false);
      setTimeout(() => {
        hide(user);
      }, 300);
    }, 1000);
  };

  const isFormMandatory = useMemo(() => {
    return form?.fields?.some((field) => field?.required) && form?.is_mandatory;
  }, [form]);

  const handleInputChange = (field: any, value: any) => {
    setUser((prevUser) => ({
      ...prevUser,
      [field.label]: value,
    }));
  };

  const renderField = (field: ChatbotFormField) => {
    const commonProps = {
      style: { color: chatMainColors.text },
      className: 'grid gap-1 w-full',
    };

    if (field.type === 'dropdown') {
      return (
        <div key={field.id} {...commonProps}>
          <Label className={cn('font-normal', chatSizeVariants.form.label[size])} htmlFor={field.label}>
            {field.label}
          </Label>
          <Select required={field?.required} onValueChange={(value) => handleInputChange(field, value)}>
            <SelectTrigger
              id={field.label}
              className={cn(
                'form-selector opacity-80 shadow-sm overflow-hidden whitespace-nowrap flex w-full rounded-md outline-none ring-0',
                chatSizeVariants.form.input[size],
              )}
              onMouseEnter={(e: any) => {
                e.target.style.boxShadow = `0 0 0 0.5px ${userMessageColors.backgroundColor}`;
              }}
              onMouseLeave={(e: any) => {
                e.target.style.boxShadow = 'none';
              }}
              style={{
                borderColor: chatMainColors.border,
                backgroundColor: chatMainColors.background,
              }}
            >
              <SelectValue />
            </SelectTrigger>
            <SelectContent
              style={{
                borderColor: chatMainColors.border,
                backgroundColor: chatMainColors.background,
                color: chatMainColors.text,
              }}
              className="max-h-[200px] z-[999999999] overflow-y-auto"
            >
              {field?.dropdown_options?.map((option: string, idx: number) => (
                <SelectItem
                  key={idx}
                  value={option}
                  className={cn(
                    'cursor-pointer',
                    chatbotMeta?.theme === 'light'
                      ? ''
                      : ' focus:bg-transparent focus:text-inherit cursor-pointer focus:brightness-110',
                    chatSizeVariants.form.labelSize[size],
                  )}
                >
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      );
    }

    if (['email', 'text', 'tel'].includes(field.type)) {
      return (
        <div key={field.id} {...commonProps}>
          <Label
            className={cn('font-normal flex items-center gap-1', chatSizeVariants.form.label[size])}
            htmlFor={field.label}
          >
            {field.label}
            {field.required && (
              <Asterisk
                className={cn('w-3 h-3 text-destructive', chatSizeVariants.form.asterisk[size])}
                strokeWidth={2}
              />
            )}
          </Label>
          <Input
            id={field.label}
            className={cn('outline-none shadow-sm opacity-80 ring-0', chatSizeVariants.form.input[size])}
            required={field?.required}
            type={field.type}
            value={(user?.[field.label] as string) || ''}
            autoComplete="off"
            style={{
              borderColor: chatMainColors.border,
              backgroundColor: chatMainColors.background,
            }}
            onMouseEnter={(e: any) => {
              e.target.style.boxShadow = `0 0 0 0.5px ${userMessageColors.backgroundColor}`;
            }}
            onMouseLeave={(e: any) => {
              e.target.style.boxShadow = 'none';
            }}
            onChange={(e) => handleInputChange(field, e.target.value)}
          />
        </div>
      );
    }

    if (field.type === 'checkbox') {
      return (
        <div key={field.id} className="w-full flex items-center gap-2">
          <Checkbox
            checked={user?.[field.label]}
            id={field.id}
            className={cn('border-border shadow-sm  ring-0', chatSizeVariants.form.checkbox[size])}
            required={field.required}
            style={{
              borderColor: chatMainColors.border,
              backgroundColor: user?.[field.label] ? userMessageColors.backgroundColor : 'transparent',
              color: userMessageColors.textColor,
            }}
            onMouseEnter={(e: any) => {
              e.target.style.boxShadow = `0 0 0 0.5px ${userMessageColors.backgroundColor}`;
            }}
            onMouseLeave={(e: any) => {
              e.target.style.boxShadow = 'none';
            }}
            onCheckedChange={(checked) => handleInputChange(field, checked)}
          />
          <label
            htmlFor={field.id}
            className={cn(
              'leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
              chatSizeVariants.form.label[size],
            )}
            style={{ color: chatMainColors.text }}
          >
            <ReactMarkdown
              remarkPlugins={[gfm]}
              allowedElements={['p', 'span', 'a']}
              components={{
                a: (e) => <Link className="font-normal" data={e} />,
              }}
            >
              {field.label}
            </ReactMarkdown>
          </label>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={cn(
        'rounded-b-[0.75rem] max-h-[100%] w-full absolute bottom-0 transition-all duration-300 ease-in-out z-10 border-t overflow-auto shadow-md',
        show ? 'opacity-100  translate-y-0' : 'opacity-0 translate-y-full',
        chatSizeVariants.form.container[size],
      )}
      style={{
        color: chatMainColors.text,
        borderColor: chatMainColors.border,
        backgroundColor: chatMainColors.lightBackground,
      }}
    >
      <div
        className={cn(
          isFormMandatory ? 'text-center' : 'flex items-start justify-between',
          chatSizeVariants.form.headerContainer[size],
        )}
        style={{ color: chatMainColors.text }}
      >
        {form.title && <p className={chatSizeVariants.form.title[size]}>{form.title}</p>}
        {!isFormMandatory && (
          <button
            className="outline-none"
            type="button"
            onClick={() => hide()}
            style={form.title ? {} : { marginLeft: 'auto' }}
          >
            <X strokeWidth={1.75} className={chatSizeVariants.form.closeIcon[size]} />
          </button>
        )}
      </div>
      {form?.description && (
        <ReactMarkdown
          remarkPlugins={[gfm]}
          className={cn(
            'prose max-w-full leading-normal border-inherit text-muted-foreground',
            chatMainColors.theme === 'dark' ? 'brightness-125' : '',
            chatSizeVariants.form.description[size],
          )}
          disallowedElements={['pre', 'code', 'table']}
          components={{
            a: (e) => <Link data={e} />,
            strong: (e) => <Strong data={e} />,
          }}
        >
          {form?.description}
        </ReactMarkdown>
      )}
      <form
        ref={formRef}
        className={cn('flex flex-col', chatSizeVariants.form.inputGap[size])}
        onSubmit={addNewUser}
      >
        {form.fields.filter((field) => field.enabled).map((field) => renderField(field))}
        <SubmitButton
          submitting={submitting}
          size={size}
          text={chatbotMeta?.form?.button_text || defaultChatbotData.form.button_text}
          userMessageColors={userMessageColors}
          onClick={() => {
            if (formRef.current) {
              formRef.current.requestSubmit();
            }
          }}
        />
      </form>
    </div>
  );
};

export default WidgetForm;
