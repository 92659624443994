import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { useNavigate } from 'react-router-dom';
import routePaths from 'routes/routePaths';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import { Integration, IntegrationTitle } from 'utils/integrations';

const IntegrationUpgradeDialog: React.FC<{
  integration?: Integration;
  close: () => void;
}> = ({ integration, close }) => {
  const chatbot = useSelector(getSelectedChatbot);
  const navigate = useNavigate();
  const zapierInviteLink =
    'https://zapier.com/developer/public-invite/193504/f455629661455c5b35f11afcde9f66e5/';
  const [open, setOpen] = useState<boolean>(false);

  const hide = () => {
    setOpen(false);
    setTimeout(() => {
      close();
    }, 500);
  };

  useEffect(() => {
    if (integration) {
      setOpen(true);
    }
  }, [integration]);

  const canExploreIntegration = useMemo(() => {
    return integration === 'zapier';
  }, [integration]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Upgrade your plan to use {IntegrationTitle[integration as Integration]} Integration!
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    To utilize the powerful {IntegrationTitle[integration as Integration]} integration,{' '}
                    {chatbot?.permissions ? 'owner needs' : "you'll need"} to upgrade the plan.{' '}
                    {canExploreIntegration &&
                      'Feel free to explore the integration features, but keep in mind that actual usage requires an upgrade.'}
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  {canExploreIntegration && (
                    <Button
                      variant="outline"
                      onClick={() => {
                        if (integration === 'zapier') {
                          window.open(zapierInviteLink, '_blank');
                        }
                        hide();
                      }}
                    >
                      Proceed with exploring
                    </Button>
                  )}
                  {!chatbot?.permissions && (
                    <Button
                      onClick={() => {
                        navigate(routePaths.upgrade);
                      }}
                    >
                      Upgrade plan
                    </Button>
                  )}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default IntegrationUpgradeDialog;
