import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import chatbotService from 'api/chatbot';
import { useAlerts } from 'providers/AlertProvider';
import { Button } from 'components/ui/button';
import { Loader2 } from 'lucide-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Chatbot } from 'models/api/response.types';
import { alerts } from 'utils/alert';

const DeleteCollaboratorDialog: React.FC<{
  collaborator?: {
    id: string;
    is_invite: boolean;
    is_previous_owner: boolean;
  };
  close: () => void;
}> = ({ collaborator, close }) => {
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [show, setShow] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const { addAlert } = useAlerts();

  const invitationKey = ['invitations', chatbot?.uuid];

  useEffect(() => {
    if (collaborator && !show) {
      setShow(true);
    }
  }, [collaborator]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  const generateErrorAlert = () => {
    addAlert({
      severity: 'error',
      message: alerts.SOMETHING_WRONG,
      timeout: 5000,
    });
  };

  const deleteInvitedCollaborator = () => {
    chatbotService
      .deleteInvite(collaborator?.id as string)
      .then(() => {
        setDeleting(false);
        queryClient.invalidateQueries({ queryKey: invitationKey });
        addAlert({
          severity: 'success',
          message: collaborator?.is_invite ? alerts.INVITE_DELETE_SUCCESS : alerts.COLLAB_DELETE_SUCCESS,
          timeout: 5000,
        });
        hide();
      })
      .catch(() => {
        setDeleting(false);
        generateErrorAlert();
      });
  };

  const deletePreviousOwner = () => {
    chatbotService
      .deletePreviousOwner(collaborator?.id as string)
      .then(() => {
        setDeleting(false);
        queryClient.invalidateQueries({ queryKey: invitationKey });
        addAlert({
          severity: 'success',
          message: alerts.COLLAB_DELETE_SUCCESS,
          timeout: 5000,
        });
        hide();
      })
      .catch(() => {
        setDeleting(false);
        generateErrorAlert();
      });
  };

  const deleteCollaborator = () => {
    if (collaborator) {
      setDeleting(true);

      if (collaborator.is_previous_owner) {
        deletePreviousOwner();
      } else {
        deleteInvitedCollaborator();
      }
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete {collaborator?.is_invite ? 'invite' : 'collaborator'}
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    {collaborator?.is_invite
                      ? 'By deleting this invitation, the user will not be able to accept it and will consequently be unable to access the chatbot.'
                      : 'Are you sure you want to delete this collaborator? Once removed, the user will no longer have access to your chatbot and will be unable to use any of its features.'}
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button variant="destructive" disabled={deleting} onClick={deleteCollaborator}>
                    {deleting && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
                    Delete
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteCollaboratorDialog;
