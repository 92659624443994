import { DataSource } from 'models/api/response.types';
import React, { ReactNode } from 'react';
import { File, MessagesSquare, AppWindow, TableProperties } from 'lucide-react';

export interface DataRow extends DataSource {
  canRetry: boolean;
}

export type SortSource =
  | 'file_name'
  | 'title'
  | 'link'
  | 'date_added'
  | 'last_trained_on'
  | 'size'
  | 'tokens'
  | 'type'
  | 'status';

export const sourceTypes: {
  [key: string]: { title: string; icon: () => ReactNode };
} = {
  upload: {
    title: 'File',
    icon: () => {
      return <File strokeWidth={1.75} className="mr-2 w-4 h-4 text-muted-foreground" />;
    },
  },
  'google-drive': {
    title: 'Google Drive',
    icon: () => {
      return <img src="/img/google_drive_logo.png" alt="Google" className="mr-2 w-4 h-4" />;
    },
  },
  url: {
    title: 'Website URL',
    icon: () => {
      return <AppWindow strokeWidth={1.75} className="mr-2 w-4 h-4 text-muted-foreground" />;
    },
  },
  table: {
    title: 'Table',
    icon: () => {
      return <TableProperties strokeWidth={1.75} className="mr-2 w-4 h-4 text-muted-foreground" />;
    },
  },
  qa: {
    title: 'Q&A',
    icon: () => {
      return <MessagesSquare strokeWidth={1.75} className="mr-2 w-4 h-4 text-muted-foreground" />;
    },
  },
  video: {
    title: 'YouTube',
    icon: () => {
      return <img src="/img/youtube_logo.webp" alt="YouTube" className="mr-2 w-4 h-4 object-contain" />;
    },
  },
};
