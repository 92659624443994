/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const Paragraph: React.FC<{
  data: any;
}> = ({ data }) => {
  const { children } = data;

  // don't want to wrap img in p tag as it does by default through ReactMarkdown
  if (children && children?.props?.node?.tagName === 'img') {
    return children;
  }

  return <p>{children}</p>;
};

export default Paragraph;
