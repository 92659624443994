/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { Label } from 'components/ui/label';
import { ShieldCheck } from 'lucide-react';
import { ChatBotMeta } from 'utils/bot';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Switch } from 'components/ui/switch';
import { Textarea } from 'components/ui/textarea';
import { Input } from 'components/ui/input';

interface DisclaimerProps extends React.HTMLProps<HTMLDivElement> {
  id: string;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
  setShowDisclaimer: (show: boolean) => void;
}

const Disclaimer = React.forwardRef<HTMLDivElement, DisclaimerProps>(
  ({ id, chatInterface, setChatInterface, setShowDisclaimer }, ref) => {
    const handleClickOutside = (event: MouseEvent) => {
      const iframeElement = document.getElementById('iframe');
      const widgetButton = document.getElementById('widget-button');
      const saveChangesBanner = document.getElementById('save-changes-banner');
      if (
        ref &&
        'current' in ref &&
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !(iframeElement && iframeElement.contains(event.target as Node)) &&
        !(widgetButton && widgetButton.contains(event.target as Node)) &&
        !(saveChangesBanner && saveChangesBanner.contains(event.target as Node))
      ) {
        setShowDisclaimer(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);

    return (
      <Card
        onClick={() => {
          if (chatInterface?.disclaimer?.enabled) {
            setShowDisclaimer(true);
          }
        }}
        id={id}
        ref={ref}
        className="relative"
      >
        <CardHeader className="pb-6">
          <CardTitle className="flex items-center">
            <ShieldCheck strokeWidth={1.75} className="w-6 h-6 mr-2" />
            Disclaimer
          </CardTitle>
          <CardDescription>
            Custom disclaimer for users to accept your terms before chatting, enhancing compliance and trust.
            <span className="text-xs mt-1 text-warning block">
              Note: Disclaimer is non-functional in Preview Mode.
            </span>
          </CardDescription>
        </CardHeader>
        <CardContent className="max-w-[1000px] flex flex-col gap-6 overflow-hidden">
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="disclaimer">
              Enable Disclaimer
            </Label>
            <p className="text-sm text-muted-foreground">
              By enabling the disclaimer, a pop-up will appear for users to accept your terms before starting
              the chat. The chat will remain disabled until the user accepts the disclaimer.
            </p>
            <Switch
              id="disclaimer"
              checked={chatInterface?.disclaimer?.enabled || false}
              onCheckedChange={(show) => {
                setChatInterface({
                  ...chatInterface,
                  disclaimer: {
                    ...chatInterface.disclaimer,
                    enabled: show,
                  },
                });
                setShowDisclaimer(show);
              }}
            />
          </div>
          {chatInterface?.disclaimer?.enabled && (
            <>
              <div className="flex flex-col gap-2">
                <Label
                  className="text-md font-medium leading-none tracking-tight"
                  htmlFor="disclaimer-description"
                >
                  Description
                </Label>
                <p className="text-sm text-muted-foreground">
                  Please enter the text for your disclaimer below. This is the message that users will need to
                  accept before they can start chatting. You can use Markdown elements for formatting.{' '}
                  <a
                    className="text-secondary font-medium hover:underline"
                    href="https://www.markdownguide.org/cheat-sheet/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </p>
                <Textarea
                  className="min-h-[100px]"
                  id="disclaimer-description"
                  value={chatInterface?.disclaimer?.description || ''}
                  placeholder="We endeavor to deliver accurate and comprehensive information through ..."
                  onChange={(e) => {
                    setChatInterface({
                      ...chatInterface,
                      disclaimer: {
                        ...chatInterface.disclaimer,
                        description: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label
                  className="text-md font-medium leading-none tracking-tight"
                  htmlFor="disclaimer-accept-button"
                >
                  Accept Button Label
                </Label>
                <p className="text-muted-foreground text-sm">
                  The button that users has to click to accept disclaimer.
                </p>
                <Input
                  id="disclaimer-accept-button"
                  type="text"
                  placeholder="I agree"
                  value={chatInterface?.disclaimer?.button_text || ''}
                  onChange={(e) => {
                    setChatInterface({
                      ...chatInterface,
                      disclaimer: {
                        ...chatInterface.disclaimer,
                        button_text: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
    );
  },
);

export default Disclaimer;
