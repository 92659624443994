/* eslint-disable import/prefer-default-export */
import { ChatMessage, RealtimeChatMessage } from 'models/api/response.types';
import { v4 as uuidv4 } from 'uuid';

export const isChatMessage = (message: ChatMessage | RealtimeChatMessage): message is ChatMessage => {
  return (message as ChatMessage)?.query !== undefined;
};

export const createRealTimeMessage = (
  messageType: RealtimeChatMessage['type'],
  message: string,
  data?: { [key: string]: any },
): RealtimeChatMessage => ({
  uuid: uuidv4(),
  type: messageType,
  message,
  created_at: new Date().toISOString(),
  modified_at: new Date().toISOString(),
  meta_json: JSON.stringify(data || {}),
});

export const scrollToBottom = (timeout: number, behavior: ScrollBehavior = 'smooth') => {
  const container = document.getElementById('chat-container');
  if (container) {
    setTimeout(() => {
      container.scrollTo({
        top: container.scrollHeight,
        behavior,
      });
    }, timeout);
  }
};

export const scrollIfAtBottom = (timeout: number, limit = 50, behavior: ScrollBehavior = 'smooth') => {
  const chatContainer = document.getElementById('chat-container');
  if (chatContainer) {
    const { scrollTop, scrollHeight, clientHeight } = chatContainer;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - limit;
    if (isAtBottom) {
      scrollToBottom(timeout, behavior);
    }
  }
};
