import axios from 'axios';
import { ChatbotFileType, FileResponseData, UserFileType } from 'models/api/response.types';

const uploadFile = async (path: string, type: string, file: File, callback: (res?: any) => void) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('resource_type', type);

  try {
    const response = await axios.post<FileResponseData>(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    callback(response.data);
  } catch (error) {
    callback();
  }
};

const userFilePath = '/api/user/file';

const uploadUserFile = (file: File, type: UserFileType, callback: (response?: any) => void) => {
  uploadFile(userFilePath, type, file, callback);
};

const deleteUserFile = (type: UserFileType) =>
  axios.delete(`${userFilePath}?resource_type=${type}`).then((res) => res.data);

const uploadChatbotFile = (
  chatbotId: string,
  file: File,
  type: ChatbotFileType,
  callback: (response?: any) => void,
) => {
  const path = `/api/chatbot/${chatbotId}/file`;
  uploadFile(path, type, file, callback);
};

const deleteChatbotFile = (chatbotId: string, type: ChatbotFileType) => {
  const path = `/api/chatbot/${chatbotId}/file`;
  return axios.delete(`${path}?resource_type=${type}`).then((res) => res.data);
};

const contentService = {
  uploadUserFile,
  deleteUserFile,
  uploadChatbotFile,
  deleteChatbotFile,
};

export default contentService;
