import React, { useState } from 'react';
import { IChatbotMessageColors, WidgetSize, chatSizeVariants } from 'utils/bot';
import { v4 as uuidv4 } from 'uuid';
import { cn } from 'utils/cn';
import { ICitationToRead } from '../ChatMessage/MessageCitation';
import Image from './Image';

const Link: React.FC<{
  size?: WidgetSize;
  data: any;
  messageId?: string;
  messageSources?: any;
  chatBotMessageColors?: IChatbotMessageColors;
  setBackTo?: (prop: string) => void;
  setCitationToRead?: (citation: ICitationToRead | undefined) => void;
  className?: string;
}> = ({
  size = 'md',
  messageId,
  data,
  messageSources,
  chatBotMessageColors,
  setBackTo,
  setCitationToRead,
  className,
}) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const generateLink = (key: any, id: string, styles?: any, callback?: () => void) => {
    return (
      <span
        id={id}
        className="relative -top-[0.3rem] inline-flex rounded-full ml-1 my-0"
        onMouseEnter={() => {
          if (callback) setHovered(true);
        }}
        onMouseLeave={() => {
          if (callback) setHovered(false);
        }}
        onClick={() => {
          if (callback) callback();
        }}
      >
        <span
          className={cn(
            'inline-flex items-center justify-center rounded-md text-center transition-colors',
            chatSizeVariants.chat.messageCitation.link[size],
          )}
          style={styles}
        >
          {key}
        </span>
      </span>
    );
  };

  // children - image means that paths is the image from source file
  // generate image instead of link
  if (data.children === 'image') {
    return <Image size={size} data={{ alt: 'Chatbot Source', src: data.href }} />;
  }

  // children - video means that paths is the youtube video from source file
  // generate videoe instead of link
  if (data.children === 'video') {
    // need to extract only id to point to allowed url by YouTube
    // can't use public url in iframe for showing videos
    const urlPattern =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const match = data.href.match(urlPattern);
    const srcId = match[1] ?? null;

    if (srcId) {
      return (
        <div className="relative w-full pb-[56.25%] h-0 overflow-hidden my-2 rounded-lg">
          <iframe
            title="Chatbot Source"
            src={`https://www.youtube.com/embed/${srcId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full rounded-lg"
          />
        </div>
      );
    }
  }

  if (data.href === '/chat_message/citation-data/response') {
    const { children: key, node } = data;
    const position = node?.position?.start?.offset;
    if (messageSources) {
      if (key in messageSources && messageSources[key]) {
        const uniqueCitationId = `chat-message-citation-${messageId}-${position || 0}`;
        return generateLink(
          key,
          uniqueCitationId,
          {
            cursor: 'pointer',
            ...(chatBotMessageColors
              ? {
                  color: hovered
                    ? chatBotMessageColors.citationReferenceHoveredTextColor
                    : chatBotMessageColors.citationReferenceTextColor,
                  backgroundColor: hovered
                    ? chatBotMessageColors.citationReferenceHoveredColor
                    : chatBotMessageColors.citationReferenceColor,
                }
              : {
                  color: '#FFF',
                  backgroundColor: '#367DF1',
                }),
          },
          () => {
            if (setCitationToRead) {
              setCitationToRead({
                ...messageSources[key],
                key,
              });
            }

            if (setBackTo) {
              setBackTo(uniqueCitationId);
            }
          },
        );
      }
    }
    return null;
  }

  if (data.href === '/chat_message/citation-data/streaming') {
    // in case of streaming we want to show styles but disable clicking
    return generateLink(data.children, uuidv4(), {
      select: 'none',
      ...(chatBotMessageColors
        ? {
            color: chatBotMessageColors.citationReferenceColor,
            backgroundColor: chatBotMessageColors.citationReferenceColor,
          }
        : {
            color: '#FFF',
            backgroundColor: '#367DF1',
          }),
    });
  }

  return (
    <a className={cn('underline font-semibold', className)} href={data.href} rel="noreferrer" target="_blank">
      {data.children}
    </a> // All other links
  );
};

export default Link;
