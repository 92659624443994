/* eslint-disable no-new */
import React, { useMemo, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { cn } from 'utils/cn';
import { Button } from 'components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { TextareaAutoresize } from 'components/ui/textarea-autoresize';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  Loader2,
  RotateCcw,
  Save,
  Sparkles,
  X,
} from 'lucide-react';
import { Separator } from 'components/ui/separator';
import sourceService from 'api/source';
import { DataSourceChunk, DataSourceChunkList } from 'models/api/response.types';
import MetadataEditor from 'components/helpers/MetadataEditor';
import { useAlerts } from 'providers/AlertProvider';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { alerts } from 'utils/alert';
import { DataRow } from 'utils/sources';

const SourceChunksDialog: React.FC<{
  source: DataRow;
  hide: () => void;
}> = ({ source, hide }) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const { refetchChatbotSubscription } = useSubscriptionInfo();
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [newContent, setNewContent] = useState<DataSourceChunkList>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showChunkMeta, setShowChunkMeta] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeChunkIndex, setActiveChunkIndex] = useState<number | undefined>(undefined);
  const [cleanupChunkIndex, setCleanupChunkIndex] = useState<number | undefined>(undefined);
  const dataSourceChunksQueryKey = ['dataSourceChunks', source.uuid];

  const itemsPerPage = 10;
  const totalPages = newContent ? Math.ceil(newContent.length / itemsPerPage) : 1;

  const indexes = useMemo(() => {
    if (newContent) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      return {
        startIndex,
        endIndex: startIndex + itemsPerPage,
      };
    }
    return {
      startIndex: 1,
      endIndex: 1,
    };
  }, [newContent, currentPage, itemsPerPage]);

  const chunksOnThisPage = useMemo(() => {
    if (newContent && newContent.length > 0) {
      return newContent.slice(indexes.startIndex, indexes.endIndex);
    }
    return undefined;
  }, [indexes, newContent]);

  const close = () => {
    setShow(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  const {
    data: chunks,
    isLoading,
    isError,
  } = useQuery({
    queryKey: dataSourceChunksQueryKey,
    queryFn: () => sourceService.fetchSourceChunks(source.uuid),
  });

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (chunks) {
      setNewContent(chunks);
    }
  }, [chunks]);

  useEffect(() => {
    if (isError) {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG_LONG,
      });
      close();
    }
  }, [isError]);

  const chunksWereChanged = useMemo(() => {
    if (newContent) return JSON.stringify(chunks) !== JSON.stringify(newContent);
    return false;
  }, [newContent]);

  const updateChunks = () => {
    if (newContent.some((chunk) => chunk.errors?.length)) {
      addAlert({
        severity: 'error',
        message: alerts.SOURCE_CHUNKS_SAVE_ERROR,
      });
      return;
    }

    setUpdatingData(true);
    sourceService
      .updateDataSourceChunks(source.uuid, newContent)
      .then(() => {
        queryClient.setQueryData(dataSourceChunksQueryKey, newContent);
        addAlert({
          severity: 'success',
          message: alerts.SOURCE_CHUNKS_SAVE_SUCCESS,
        });
        setUpdatingData(false);
        close();
      })
      .catch((err) => {
        setUpdatingData(false);

        let messageToShow = alerts.SOMETHING_WRONG;
        if (err?.response?.data?.error_code === 'CHUNKS_TOO_LONG') {
          messageToShow = alerts.SOURCE_CHUNKS_LONG;

          const badChunkIndexes = err.response.data.chunks.map((chunk: DataSourceChunk) => chunk.chunk_index);
          const newChunks = newContent.map((chunk: DataSourceChunk) => {
            if (badChunkIndexes.includes(chunk.chunk_index)) {
              chunk.errors = [alerts.SOURCE_CHUNK_LONG];
            }
            return chunk;
          });
          setNewContent(newChunks);
        }

        addAlert({
          severity: 'error',
          message: messageToShow,
        });
      });
  };

  const validateChunkAndSetErrors = (chunk: DataSourceChunk) => {
    chunk.errors = [];
    if (!chunk.content) chunk.errors.push('Chunk cannot be empty');
    if (chunk.metaJsonHasError) chunk.errors.push('Metadata is not valid JSON');
  };

  const updateChunkContent = (newValue: string, chunk_index: number) => {
    if (newContent) {
      setNewContent(
        newContent.map((chunk) => {
          if (chunk.chunk_index === chunk_index) {
            const newChunk = { ...chunk, content: newValue };
            validateChunkAndSetErrors(newChunk);
            return newChunk;
          }
          return chunk;
        }),
      );
    }
  };

  const updateChunkMeta = (newValue: string, chunk_index: number, errors: any) => {
    if (newContent) {
      setNewContent(
        newContent.map((chunk) => {
          if (chunk.chunk_index === chunk_index) {
            const newChunk = { ...chunk, meta_json: newValue, metaJsonHasError: errors?.length };
            validateChunkAndSetErrors(newChunk);
            return newChunk;
          }
          return chunk;
        }),
      );
    }
  };

  const resetChunkData = () => {
    setCurrentPage(1);
    setNewContent(chunks);
  };

  const cleanUpChunk = () => {
    if (activeChunkIndex === undefined) return;
    setCleanupChunkIndex(activeChunkIndex);
    const chunkIndexForThisCall = activeChunkIndex;

    sourceService
      .cleanupDataSourceChunks(source.uuid, [newContent[chunkIndexForThisCall]])
      .then((response) => {
        // there should only be 1 chunk in the response, because we only sent 1 to backend
        if (response.data.length > 1) {
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
          });
          setCleanupChunkIndex(undefined);
          return;
        }

        // this call does not persist the changes to db, so that user gets a chance to review before clicking
        // "save changes". So, do not overwrite the query data, because it wasn't saved to the db yet
        const newContentWithNewChunk = newContent.map((chunk, i) =>
          i === chunkIndexForThisCall ? { ...chunk, content: response.data[0].content } : chunk,
        );
        setNewContent(newContentWithNewChunk);

        addAlert({
          severity: 'success',
          message: alerts.SOURCE_CHUNKS_CLEANUP_SUCCESS,
        });
        setCleanupChunkIndex(undefined);
        refetchChatbotSubscription();
      })
      .catch(() => {
        setCleanupChunkIndex(undefined);
        const messageToShow = alerts.SOMETHING_WRONG;

        addAlert({
          severity: 'error',
          message: messageToShow,
        });
      });
  };

  const pagination = () => {
    if (totalPages > 1) {
      return (
        <div className="mt-auto mb-2 flex items-center justify-end gap-2">
          <div className="flex-1 text-sm text-muted-foreground whitespace-nowrap mr-2">
            {indexes.startIndex + 1}-{Math.min(indexes.endIndex, newContent.length)} of {newContent.length}
          </div>
          <div className="flex whitespace-nowrap items-center justify-center text-sm font-medium">
            Page {currentPage} of {totalPages}
          </div>
          {totalPages > 1 && (
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                size="sm"
                className="hidden h-8 w-8 p-0 lg:flex bg-background"
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Go to first page</span>
                <ChevronsLeftIcon strokeWidth={1.75} className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="h-8 w-8 p-0 bg-background"
                onClick={() => setCurrentPage((page) => page - 1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronLeftIcon strokeWidth={1.75} className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="h-8 w-8 p-0 bg-background"
                onClick={() => setCurrentPage((page) => page + 1)}
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex bg-background"
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Go to last page</span>
                <ChevronsRightIcon className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const chunkElements = () => {
    if (chunksOnThisPage) {
      return chunksOnThisPage.map((chunk: DataSourceChunk, index: number) => {
        return (
          <div key={chunk.chunk_index}>
            {index === 0 || <Separator className="my-2" />}
            <div className="flex flex-col md:flex-row p-1 rounded-md">
              <div className="flex-1">
                <TextareaAutoresize
                  className={cn(
                    'm-0 border-none',
                    chunk.errors?.length ? 'ring-destructive focus-visible:ring-destructive ring-1' : '',
                  )}
                  value={chunk.content}
                  onChange={(e) => {
                    updateChunkContent(e.target.value, chunk.chunk_index);
                  }}
                  onFocus={() => setActiveChunkIndex(chunk.chunk_index)}
                  disabled={updatingData || cleanupChunkIndex === chunk.chunk_index}
                  adjustHeightSignal={showChunkMeta}
                />
              </div>
              {showChunkMeta && (
                <div className="flex-1 border-t pt-2 md:border-t-0 md:pt-0 md:border-l px-2 md:ml-2">
                  <MetadataEditor
                    metaJson={chunk.meta_json}
                    onChange={(newValue, errors) => {
                      updateChunkMeta(newValue, chunk.chunk_index, errors);
                    }}
                  />
                </div>
              )}
            </div>
            {chunk.errors &&
              chunk.errors.map((error) => (
                <p key={error} className="ml-1 text-xs text-destructive">
                  {error}
                </p>
              ))}
          </div>
        );
      });
    }
    return null;
  };

  return (
    <>
      <div
        className={cn(
          'fixed left-0 top-0  h-full w-full z-[999] transition-all',
          show ? 'bg-black bg-opacity-25 opacity-50' : '',
        )}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <div
        className={cn(
          'z-[1000] md:p-4 fixed right-0 top-0 transition-all duration-300 overflow-hidden ease-out h-[100%] w-full max-w-[1200px]',
          show ? 'translate-x-[0px]' : 'translate-x-[120%]',
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-background rounded-md shadow-sm border overflow-hidden flex flex-col px-6 pt-6 pb-2 h-full">
          <div className="flex items-center text-lg font-semibold text-foreground">
            <h1>
              View Content: <em>{source.file_name}</em>
            </h1>
            <button
              type="button"
              onClick={close}
              className="rounded-sm border p-1 opacity-70 ring-offset-background transition-opacity hover:opacity-100 ml-auto"
            >
              <X strokeWidth={1.75} className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </button>
          </div>
          {isLoading ? (
            <div className="flex items-center flex-col pb-6">
              <div className="mt-2 mb-6 w-full">
                <p className="font-normal text-muted-foreground text-sm">Preparing data source content</p>
              </div>
              <Loader2 strokeWidth={1.75} className="animate-spin h-[20rem] w-12 text-primary" />
            </div>
          ) : (
            <>
              <p className="font-normal text-muted-foreground text-sm">
                You may edit the content of this data source, which is divided into chunks, to improve
                information retrieval.
              </p>
              {chunksOnThisPage ? (
                <>
                  <div className="my-4 flex flex-col overflow-hidden rounded-md border">
                    <div className="flex items-center gap-2 px-2 border-b overflow-x-auto overflow-y-hidden min-h-[50px]">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              disabled={
                                activeChunkIndex === undefined ||
                                cleanupChunkIndex !== undefined ||
                                updatingData
                              }
                              variant="outline"
                              size="sm"
                              onClick={cleanUpChunk}
                            >
                              {cleanupChunkIndex !== undefined ? (
                                <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
                              ) : (
                                <Sparkles strokeWidth={1.75} className="h-4 w-4 mr-2" />
                              )}
                              Clean Up
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent align="start">
                            <p className="font-normal">
                              Use 1 credit to utilize a Large Language Model (LLM) for correcting typos,
                              formatting issues, and other errors in your text, ensuring a polished and
                              professional presentation.
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      {/* <Button variant="outline" size="sm" onClick={() => setShowChunkMeta((prev) => !prev)}>
                        <FileCode strokeWidth={1.75} className="h-4 w-4 mr-2" />
                        {showChunkMeta ? 'Hide' : 'Show'} Metadata
                      </Button> */}
                      <Button
                        disabled={!chunksWereChanged || cleanupChunkIndex !== undefined || updatingData}
                        variant="outline"
                        size="sm"
                        onClick={resetChunkData}
                      >
                        <RotateCcw strokeWidth={1.75} className="h-4 w-4 mr-2" />
                        Reset Changes
                      </Button>
                      {chunksWereChanged && (
                        <Button
                          disabled={updatingData || cleanupChunkIndex !== undefined}
                          className="ml-auto"
                          size="sm"
                          onClick={updateChunks}
                        >
                          {updatingData ? (
                            <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
                          ) : (
                            <Save strokeWidth={1.75} className="h-4 w-4 mr-2" />
                          )}
                          Save Changes
                        </Button>
                      )}
                    </div>
                    <div className={cn('p-2 relative overflow-auto')}>{chunkElements()}</div>
                  </div>
                  {pagination()}
                </>
              ) : (
                <div className="h-[20rem] flex flex-col justify-center items-center">
                  <h2 className="text-base font-bold text-center">Content Not Found</h2>
                  <p className="text-center text-sm text-muted-foreground max-w-[24rem]">
                    Our servers are currently unable to load the content of this data source. Please try again
                    later, or re-upload this data source.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SourceChunksDialog;
