import { Bot } from 'lucide-react';
import React from 'react';
import { ThemeType, generateChatMainColors } from 'utils/bot';

const BotInvalidSetup: React.FC<{
  theme?: ThemeType;
  error: string;
}> = ({ theme, error = '' }) => {
  const mainColor = generateChatMainColors(theme);
  return (
    <div
      className=" w-full h-full flex flex-col items-center justify-center gap-4"
      style={{
        backgroundColor: mainColor?.background,
      }}
    >
      <Bot strokeWidth={1.75} className="w-[100px] h-[100px] text-primary" />
      <p
        className="text-base text-center px-16 text-muted-foreground"
        style={{
          ...(theme === 'dark' && {
            color: mainColor.text,
          }),
        }}
      >
        {error}
      </p>
    </div>
  );
};

export default BotInvalidSetup;
