/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import sessionService from 'api/session';
import { format } from 'date-fns';
import {
  AIAgentTag,
  ChatMessage,
  ChatSession,
  ChatSessions,
  Chatbot,
  RealtimeChatMessage,
} from 'models/api/response.types';
import { DateRange } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import {
  CalendarIcon,
  Loader2,
  ChevronsLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsRightIcon,
  Variable,
  MessagesSquare,
  X,
  Search,
  MailCheck,
} from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { Calendar } from 'components/ui/calendar';
import { Skeleton } from 'components/ui/skeleton';
import { useLocation } from 'react-router-dom';
import FilterButton, { Filters } from 'components/helpers/FilterButton';
import Session from 'components/Chatbot/Inbox/Session';
import axios from 'axios';
import SessionOverview from 'components/Chatbot/Inbox/SessionOverview';
import ConfirmSessionDeletionDialog from 'components/Dialogs/Inbox/ConfirmSessionDeletionDialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import useSessionMessages from 'hooks/useSessionMessages';
import { useChat } from 'providers/ChatProvider';
import { DateSelector, conversationFilters, currentDateSelected } from 'utils/inbox';
import useAiAgents from 'hooks/useAiAgents';
import { ParsedSessionMeta } from 'utils/sessions';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';

const getDefaultDateRange = (urlSearchParams: URLSearchParams, currentDate: Date) => {
  const from = new Date(urlSearchParams.get('from') || '');
  const to = new Date(urlSearchParams.get('to') || '');
  if (!isNaN(from.getTime()) && !isNaN(to.getTime())) {
    return { from, to };
  }
  return {
    from: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7),
    to: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59),
  };
};

const Inbox: React.FC = () => {
  const queryClient = useQueryClient();
  const isInitialMount = useRef<boolean>(true);
  const { search } = useLocation();
  const { addAlert } = useAlerts();
  const { markAllAsRead: markAsRead } = useChat();
  const { canAccessLiveChats } = useAiAgents();
  const urlSearchParams = new URLSearchParams(search);
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const currentDate = new Date();
  const [defaultDate] = useState<DateRange>(getDefaultDateRange(urlSearchParams, currentDate));
  const [date, setDate] = useState<DateRange>({ from: undefined, to: undefined });
  const [dateSelector, setDateSelector] = useState<DateSelector | undefined>('week');
  const [currentSession, setCurrentSession] = useState<ChatSession | undefined>(undefined);
  const [exporting, setExporting] = useState<boolean>(false);
  const [sessionToDelete, setSessionToDelete] = useState<string | undefined>(undefined);
  const [submittedSearchValue, setSubmittedSearchValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedChatFilters, setSelectedChatFilters] = useState<Filters>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState<number>(50);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const sessionsQueryKey = [
    'sessions',
    chatbot?.uuid,
    JSON.stringify({ search_value: submittedSearchValue, ...date }),
  ];
  const { sessionMessages, updateMessages, removeMessageQuery, clearAllMessageQueries } = useSessionMessages(
    currentSession?.uuid,
  );

  const { data: sessions } = useQuery({
    queryKey: sessionsQueryKey,
    queryFn: () => sessionService.getChatbotSessions(chatbot.uuid, defaultDate, date, submittedSearchValue),
    enabled: !!chatbot?.uuid,
  });

  // remove all previous queries on query change
  useEffect(() => {
    queryClient.removeQueries({
      predicate: (query) => {
        return (
          query.queryKey[0] === 'sessions' &&
          JSON.stringify(query.queryKey) !== JSON.stringify(sessionsQueryKey)
        );
      },
    });
  }, [chatbot.uuid, date, submittedSearchValue]);

  // Remove all queries that start with 'sessions' upon unmounting
  // Remove all messages cache
  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === 'sessions',
      });
      clearAllMessageQueries();
    };
  }, []);

  // SOCKET CONNECTION
  const { subscribeToEvent, emitEvent } = useChat();

  useEffect(() => {
    if (canAccessLiveChats && emitEvent) {
      emitEvent('subscribe_chatbot', chatbot.uuid);

      return () => {
        emitEvent('unsubscribe_chatbot', chatbot.uuid);
      };
    }
  }, [chatbot.uuid, emitEvent, canAccessLiveChats]);

  const switchToSession = (session: ChatSession | undefined) => {
    if (canAccessLiveChats && emitEvent) {
      if (currentSession) {
        emitEvent('leave_chat', currentSession.uuid);
      }

      if (session) {
        emitEvent('join_chat', session.uuid);
      }
    }
    setCurrentSession(session);
  };

  const emitChildSocketEvent = (eventName: string, data: any) => {
    emitEvent?.(eventName, data);
    if (eventName === 'message' && canAccessLiveChats) {
      updateMessages(data as RealtimeChatMessage);
    }
  };

  useEffect(() => {
    if (canAccessLiveChats && subscribeToEvent) {
      const cancelSubForMessage = subscribeToEvent(
        'message',
        (message: ChatMessage | RealtimeChatMessage) => {
          updateMessages(message);
        },
      );

      const cancelSubForAnnouncement = subscribeToEvent('announcement', (message: RealtimeChatMessage) => {
        updateMessages(message);
      });

      return () => {
        cancelSubForMessage?.();
        cancelSubForAnnouncement?.();
      };
    }
  }, [subscribeToEvent, currentSession, canAccessLiveChats]);

  useEffect(() => {
    if (canAccessLiveChats) {
      const cancelSubForChatSessionUpdate = subscribeToEvent?.(
        'chat_session_updates',
        (sessionUpdates: ChatSessions) => {
          const todaySelected = currentDateSelected(date?.to || new Date());

          // receiving live session updates, for search mode
          // update only existing sessions for date can add new
          if (sessions) {
            let newSessions: ChatSessions = [];
            // Create a map for quick lookup of existing sessions
            const existingSessionsMap = new Map(sessions.map((s) => [s.uuid, s]));

            // Update existing sessions with new data
            // for todays date selection add all sessions even new
            // otherwsise only sessions that exist
            sessionUpdates.forEach((updatedSession) => {
              if (!submittedSearchValue && todaySelected) {
                existingSessionsMap.set(updatedSession.uuid, updatedSession);
              } else if (existingSessionsMap.get(updatedSession.uuid)) {
                existingSessionsMap.set(updatedSession.uuid, updatedSession);
              }
            });

            // sort result by latest_message_at
            newSessions = Array.from(existingSessionsMap.values()).sort((a, b) =>
              b.latest_message_at.localeCompare(a.latest_message_at),
            );

            queryClient.setQueryData(sessionsQueryKey, newSessions);
            sessionUpdates.forEach((s) => {
              if (s.uuid === currentSession?.uuid) {
                setCurrentSession(s);
              } else {
                removeMessageQuery(s.uuid);
              }
            });
          } else if (!submittedSearchValue && todaySelected) {
            // If no sessions and current date selected, set the updated sessions
            // we don't want to append random sessions in search result
            queryClient.setQueryData(sessionsQueryKey, sessionUpdates);
          }
        },
      );

      return () => {
        cancelSubForChatSessionUpdate?.();
      };
    }
  }, [subscribeToEvent, sessions, submittedSearchValue, currentSession, canAccessLiveChats]);

  // on chatbot change reset everything
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setSelectedChatFilters([]);
      switchToSession(undefined);
      setSubmittedSearchValue('');
      setSearchValue('');
      setCurrentPage(1);
    }
  }, [chatbot.uuid]);

  useEffect(() => {
    // Update the screenWidth state when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const validChatFilters = useMemo(() => {
    if (sessions && sessions?.length > 0) {
      const validFilters = new Set();
      const tagFilters = new Set();
      const miscellaneousFilters = new Set();
      sessions.forEach((session) => {
        const meta = JSON.parse(session.meta_json) as ParsedSessionMeta;
        const agentStore = JSON.parse(session?.agent_state_store_json || '{}');
        if (meta?.origin) {
          validFilters.add(meta?.origin);
        }
        if (agentStore?.tags) {
          agentStore.tags.forEach((tag: AIAgentTag) => {
            tagFilters.add(JSON.stringify(tag));
          });
        }

        if (
          (['active', 'requested'] as ChatSession['human_escalation_status'][]).includes(
            session?.human_escalation_status,
          )
        ) {
          miscellaneousFilters.add(`human_escalation_${session?.human_escalation_status}`);
        }
      });
      const finalTagFilters = Array.from(tagFilters).map((tag) => JSON.parse(tag as unknown as string));
      const finalMiscellaneousFilters = Array.from(miscellaneousFilters);
      return [...(Array.from(validFilters) as string[]), ...finalTagFilters, ...finalMiscellaneousFilters];
    }
    return [];
  }, [sessions]);

  const sessionsToShow = useMemo(() => {
    if (sessions && sessions?.length > 0) {
      const result = sessions.filter((session) => {
        const meta = JSON.parse(session.meta_json);
        const agentStore = JSON.parse(session?.agent_state_store_json || '{}');
        if (selectedChatFilters.length > 0) {
          if (
            selectedChatFilters.includes(meta?.origin) ||
            selectedChatFilters.some(
              (filter) =>
                typeof filter === 'string' &&
                filter.startsWith('human_escalation') &&
                filter.replace('human_escalation_', '') === session.human_escalation_status,
            )
          ) {
            return true;
          }
          if (agentStore?.tags) {
            let hasSelectedTag = false;
            agentStore.tags.forEach((tag: AIAgentTag) => {
              if (
                selectedChatFilters.some((filter) => typeof filter === 'object' && filter.name === tag.name)
              ) {
                hasSelectedTag = true;
              }
            });
            if (hasSelectedTag) {
              return true;
            }
            return false;
          }
          return false;
        }
        return true;
      });
      return result;
    }

    switchToSession(undefined);
    return [];
  }, [sessions, selectedChatFilters]);

  useEffect(() => {
    if (sessionsToShow.length > 0 && screenWidth > 768) {
      if (!currentSession || !sessionsToShow.find((session) => session.uuid === currentSession?.uuid)) {
        const selectedSessionUUID = urlSearchParams.get('session_uuid');
        if (selectedSessionUUID) {
          const sessionIndexFromLocation = sessionsToShow?.findIndex((s) => s.uuid === selectedSessionUUID);
          if (sessionIndexFromLocation !== -1) {
            setCurrentPage(Math.floor((sessionIndexFromLocation + 1) / itemsPerPage) + 1);
            switchToSession(sessionsToShow[sessionIndexFromLocation]);
          } else {
            switchToSession(sessionsToShow[0]);
          }
        } else {
          switchToSession(sessionsToShow[0]);
        }
      }
    }

    if (selectedChatFilters.length > 0 && sessionsToShow.length === 0) {
      setSelectedChatFilters([]);
    }
  }, [sessionsToShow, selectedChatFilters]);

  const setDateRange = (value: DateSelector) => {
    setDateSelector(value);
    setCurrentPage(1);
    setSelectedChatFilters([]);
    if (value === 'day') {
      const startTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
      );
      const endTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59,
      );
      setDate({
        from: startTime,
        to: endTime,
      });
    }
    if (value === 'yesterday') {
      const yesterday = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 1,
      );
      const startTime = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0);
      const endTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59,
      );
      setDate({
        from: startTime,
        to: endTime,
      });
    }
    if (value === 'week') {
      const startTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 7,
      );
      const endTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59,
      );
      setDate({
        from: startTime,
        to: endTime,
      });
    }
    if (value === 'month') {
      const startTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate(),
        0,
        0,
        0,
      );
      const endTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59,
      );
      setDate({
        from: startTime,
        to: endTime,
      });
    }
  };

  const submitNewSearchQuery = (query: string) => {
    setCurrentPage(1);
    setSubmittedSearchValue(query);
    setSelectedChatFilters([]);
  };

  const exportChatsAsExcel = (type: 'messages' | 'states') => {
    const sessionsToExport = sessionsToShow.map((session) => session.uuid);
    setExporting(true);
    axios
      .request({
        url: `/api/chatbot/${chatbot.uuid}/export-data`,
        method: 'post',
        data: {
          session_uuids: sessionsToExport,
          type,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.data);
        const fileName = `chatbot-${chatbot.uuid}.xlsx`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setExporting(false);
      })
      .catch(() => {
        setExporting(false);
      });
  };

  // mark all sessions as read
  const markAllAsRead = () => {
    sessionService
      .markAllSessionsAsRead(chatbot.uuid)
      .then(() => {
        // if not HE enabled means socket connection is disabled, we need to perfom changes manually
        if (!canAccessLiveChats) {
          markAsRead();
        }
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      });
  };

  const dataForPage = sessionsToShow.length;
  const totalPages = Math.ceil(dataForPage / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (totalPages < currentPage && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  return (
    <div className="h-full flex flex-col gap-1 overflow-hidden py-3 px-3 sm:px-5 relative">
      <div className="p-1 pb-3 flex flex-row items-center gap-3 overflow-x-auto">
        <div className="flex items-center gap-2 h-9 w-fit min-w-[300px] rounded-md border  transition-all  ease-in-out border-input bg-background px-3 pr-2 py-1 text-sm shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring">
          <Search className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
          <input
            id="search-session"
            type="text"
            maxLength={50}
            autoComplete="off"
            placeholder="Search session by UUID / meta"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !!searchValue) {
                submitNewSearchQuery(searchValue);
              }
            }}
            className="w-full h-full placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          />
          {searchValue.length > 0 && (
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                setSearchValue('');
                submitNewSearchQuery('');
              }}
              type="button"
              className="h-7 w-7"
            >
              <X className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
            </Button>
          )}
        </div>
        <Popover>
          <PopoverTrigger asChild>
            <div className="flex items-center h-9 gap-2 p-1 bg-background rounded-md border">
              <Button
                id="date"
                variant="outline"
                className={cn(
                  'w-fit justify-start h-full font-normal border-none bg-background whitespace-nowrap shadow-none',
                  !date && 'text-muted-foreground',
                )}
              >
                <CalendarIcon className="w-4 h-4 mr-2 " />
                {date?.from ? (
                  date.to ? (
                    <>
                      {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                    </>
                  ) : (
                    format(date.from, 'LLL dd, y')
                  )
                ) : (
                  <span>Date range</span>
                )}
              </Button>
              {(date?.from || date?.to) && (
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setDate({ from: undefined, to: undefined });
                    setCurrentPage(1);
                    setSelectedChatFilters([]);
                  }}
                  type="button"
                  className="h-7 w-7"
                >
                  <X className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
                </Button>
              )}
            </div>
          </PopoverTrigger>
          <PopoverContent className="flex w-auto flex-col space-y-2 p-2" align="start">
            <Select value={dateSelector} onValueChange={(value) => setDateRange(value as DateSelector)}>
              <SelectTrigger className="shadow-sm">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent position="popper">
                <SelectItem value="day">Today</SelectItem>
                <SelectItem value="yesterday">Yesterday</SelectItem>
                <SelectItem value="week">Last week</SelectItem>
                <SelectItem value="month">Last month</SelectItem>
              </SelectContent>
            </Select>
            <div className="rounded-md border shadow-sm">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={(e) => {
                  if (e) {
                    setDateSelector(undefined);
                    setDate(e);
                    setSelectedChatFilters([]);
                  }
                }}
                numberOfMonths={2}
              />
            </div>
          </PopoverContent>
        </Popover>
        {sessionsToShow.length > 0 && (
          <>
            <FilterButton
              title="Filter"
              options={conversationFilters}
              validFilters={validChatFilters}
              selectedFilters={selectedChatFilters}
              setSelectedFilters={(newFilters: Filters) => {
                setSelectedChatFilters(newFilters);
                setCurrentPage(1);
              }}
            />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size="default"
                  className="bg-background data-[state=open]:bg-muted whitespace-nowrap"
                  variant="outline"
                  disabled={exporting}
                >
                  {exporting ? (
                    <Loader2 strokeWidth={1.75} className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <ChevronRightIcon className="h-4 w-4 mr-2" />
                  )}
                  More
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="mr-10">
                <DropdownMenuItem onClick={markAllAsRead}>
                  <MailCheck strokeWidth={1.75} className="mr-2 h-4 w-4" />
                  Mark all as read
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuLabel>Export data</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => {
                    exportChatsAsExcel('states');
                  }}
                >
                  <Variable strokeWidth={1.75} className="mr-2 h-4 w-4" />
                  Collected Data
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    exportChatsAsExcel('messages');
                  }}
                >
                  <MessagesSquare strokeWidth={1.75} className="mr-2 h-4 w-4" />
                  Chat History
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <div className="flex whitespace-nowrap items-center justify-center text-sm font-medium ml-auto">
              Page {currentPage} of {totalPages}
            </div>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                size="sm"
                className="hidden h-8 w-8 p-0 lg:flex bg-background"
                onClick={() => setPage(1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Go to first page</span>
                <ChevronsLeftIcon strokeWidth={1.75} className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="h-8 w-8 p-0 bg-background"
                onClick={() => setPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronLeftIcon strokeWidth={1.75} className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="h-8 w-8 p-0 bg-background"
                onClick={() => setPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex bg-background"
                onClick={() => setPage(totalPages)}
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Go to last page</span>
                <ChevronsRightIcon className="h-4 w-4" />
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="m-0 px-1 pb-1 flex-1 flex flex-row overflow-x-hidden overflow-y-auto md:overflow-hidden relative">
        {(sessions && sessionsToShow.length > 0) || !sessions ? (
          <>
            <div className="bg-background w-full md:max-w-[400px] flex flex-col overflow-auto border rounded-l-md rounded-r-md md:rounded-r-none p-2 gap-3">
              {sessionsToShow ? (
                sessionsToShow
                  .slice(startIndex, endIndex)
                  .map((session) => (
                    <Session
                      sessionsQueryKey={sessionsQueryKey}
                      key={session.uuid}
                      session={session}
                      currentSession={currentSession}
                      setCurrentSession={switchToSession}
                    />
                  ))
              ) : (
                <>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 10].map((idx) => (
                    <div key={idx} className="p-2 transition-colors bg-background border rounded-md">
                      <div className="flex items-center justify-between flex-wrap">
                        <div className="flex items-center gap-2">
                          <Skeleton className="w-[24px] h-[24px]" />
                          <Skeleton className="w-[150px] h-[24px]" />
                        </div>
                        <Skeleton className="w-[100px] h-[24px]" />
                      </div>
                      <div className="flex flex-col gap-1 mt-2">
                        <Skeleton className="h-[24px] w-full" />
                        <Skeleton className="h-[24px] w-full" />
                      </div>
                      <div className="flex gap-2 mt-2">
                        <Skeleton className="h-[24px] w-[80px]" />
                        <Skeleton className="h-[24px] w-[120px]" />
                        <Skeleton className="h-[24px] w-[90px]" />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div
              className={cn(
                'flex md:min-w-[400px]  min-h-[500px] md:min-h-0 flex-1 h-full flex-col w-full absolute md:translate-x-0 md:relative transition-all rounded-r-md',
                currentSession ? 'translate-x-0' : 'translate-x-full',
                sessionsToShow.length > 0 ? 'bg-background' : '',
              )}
            >
              {currentSession && sessionMessages && sessionMessages.length > 0 ? (
                <SessionOverview
                  sessionMessages={sessionMessages}
                  currentSession={currentSession}
                  setCurrentSession={switchToSession}
                  deleteSession={setSessionToDelete}
                  currentQueryKey={sessionsQueryKey}
                  emitSocketEvent={emitChildSocketEvent}
                />
              ) : (
                <div className="flex-1 rounded-br-md rounded-tr-md border-b border-t border-r bg-background">
                  <div className="border-b h-[54px]" />
                  <div className="p-4 flex flex-col gap-4">
                    <Skeleton className="w-[230px] h-[48px] rounded-bl-none mb-2" />
                    <Skeleton className="w-[270px] h-[96px] rounded-br-none mt-2 mb-2 ml-auto" />
                    <Skeleton className="w-[230px] h-[48px] rounded-bl-none mb-2" />
                    <Skeleton className="w-[230px] h-[48px] rounded-br-none ml-auto mt-2 mb-2" />
                    <Skeleton className="w-[270px] h-[96px] rounded-bl-none mb-2" />
                    <Skeleton className="w-[270px] h-[96px] rounded-br-none mt-2 mb-2 ml-auto" />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <div className="w-full bg-background p-6 rounded-md shadow-sm border max-w-[470px]">
              <h1 className="text-lg font-semibold text-center">No conversations found</h1>
              <p className="text-muted-foreground text-sm mt-2 text-center">
                {submittedSearchValue
                  ? 'No sessions were found matching your input. Please verify the entered value and try again.'
                  : date.from && date.to
                    ? 'To have conversations, users or customers must interact with the chatbot. Interactions initiate sessions and enable personalized conversations.'
                    : 'No conversation matches the specified date filters.'}
              </p>
            </div>
          </div>
        )}
      </div>
      <ConfirmSessionDeletionDialog
        currentQueryKey={sessionsQueryKey}
        sessionToDelete={sessionToDelete}
        close={() => {
          setSessionToDelete(undefined);
        }}
      />
    </div>
  );
};

export default Inbox;
