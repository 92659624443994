import { useQuery, useQueryClient } from '@tanstack/react-query';
import sourceService from 'api/source';
import { loadURLStates } from 'components/Chatbot/Sources.tsx/AddNewSource/URLUploader/URLUploader';
import { sourceRetrainFinalStates } from 'components/Chatbot/Sources.tsx/SourceTable/SourceDetailsDrawer';
import { sourceFinalStates } from 'components/Chatbot/Sources.tsx/SourceTable/SourceStatus';
import { DataSource } from 'models/api/response.types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';

const useDataSources = (chatbotId?: string) => {
  const queryClient = useQueryClient();
  const selectedChatbot = useSelector(getSelectedChatbot);
  const chatbotUUIDToUse = chatbotId || selectedChatbot?.uuid;
  const queryKey = ['chatbot-data-sources', chatbotUUIDToUse];

  const { data } = useQuery({
    queryKey,
    queryFn: () => sourceService.getDataSources(chatbotUUIDToUse as string),
    enabled: !!chatbotUUIDToUse,
    refetchInterval: (query) => {
      if (query?.state?.data) {
        if (
          (query?.state?.data as DataSource[]).some(
            (source) =>
              loadURLStates.includes(source.crawl_status) ||
              !sourceFinalStates.includes(source.status) ||
              !sourceRetrainFinalStates.includes(source.retrain_status),
          )
        ) {
          return 5_000;
        }
        return false;
      }
      return 15_000;
    },
  });

  const sourcesInTrainingState = useMemo(() => {
    return data?.some(
      (source) =>
        loadURLStates.includes(source.crawl_status) ||
        !sourceFinalStates.includes(source.status) ||
        !sourceRetrainFinalStates.includes(source.retrain_status),
    );
  }, [data]);

  const { completedSources, pendingSources } = useMemo(() => {
    if (data) {
      return data.reduce<{ completedSources: DataSource[]; pendingSources: DataSource[] }>(
        (acc, item) => {
          if (item.crawl_status && item.crawl_status !== 'success') {
            acc.pendingSources.push(item);
            // duplicates should be as part of data sources
            if (item.status !== 'pending') {
              acc.completedSources.push(item);
            }
          } else {
            acc.completedSources.push(item);
          }
          return acc;
        },
        { completedSources: [], pendingSources: [] },
      );
    }
    return {
      completedSources: undefined,
      pendingSources: undefined,
    };
  }, [data]);

  const usedSourceData = useMemo(() => {
    if (completedSources) {
      const result = completedSources
        .filter((ds) => ds.status === 'success')
        .reduce(
          (acc, ds) => ({
            tokens: acc.tokens + ds.tokens,
            file_size: acc.file_size + ds.file_size,
          }),
          { tokens: 0, file_size: 0 },
        );

      return result;
    }
    return {
      tokens: 0,
      file_size: 0,
    };
  }, [completedSources]);

  const refetchSources = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const updateSource = (source: DataSource) => {
    const sourceList: DataSource[] | undefined = queryClient.getQueryData(queryKey);
    if (sourceList) {
      const newSources = sourceList.map((s) => (s.uuid === source.uuid ? source : s));
      queryClient.setQueryData(queryKey, newSources);
    } else {
      queryClient.invalidateQueries({ queryKey });
    }
  };

  const removeSourcesById = (uuids: string[]) => {
    const sourceList: DataSource[] | undefined = queryClient.getQueryData(queryKey);
    if (sourceList) {
      const newSources = sourceList.filter((s) => !uuids.includes(s.uuid));
      queryClient.setQueryData(queryKey, newSources);
    } else {
      queryClient.invalidateQueries({ queryKey });
    }
  };

  const setSourceData = (sourceList: DataSource[]) => {
    queryClient.setQueryData(queryKey, sourceList);
  };

  return {
    originalSourceData: data,
    sources: completedSources,
    sourcesInTrainingState,
    removeSourcesById,
    pendingSources,
    usedSourceData,
    refetchSources,
    setSourceData,
    queryKey,
    updateSource,
  };
};

export default useDataSources;
