import React, { useEffect, useState } from 'react';
import { AIAgentEditData } from 'models/api/response.types';
import { Label } from 'components/ui/label';
import { Asterisk } from 'lucide-react';
import { Textarea } from 'components/ui/textarea';

const PreCannedSpamDefenseOptions: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ data, setData }) => {
  const [defaultMessageError, setDefaultMessageError] = useState<boolean>(false);

  useEffect(() => {
    const { meta } = data;
    if (!meta.default_message) {
      setDefaultMessageError(true);
    } else if (defaultMessageError) {
      setDefaultMessageError(false);
    }
  }, [data]);

  return (
    <div className="flex flex-col gap-2 mb-8">
      <div className="flex items-end">
        <Label className="text-md font-medium leading-none tracking-tight" htmlFor="default-message">
          Default Message
          <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
        </Label>
      </div>
      <p className="text-sm text-muted-foreground">
        Specifies the default message that the agent will use in response to the user. This message is
        automatically sent when conditions are met.
      </p>
      <Textarea
        id="default-message"
        className="min-h-[120px]"
        value={data?.meta?.default_message}
        rows={3}
        maxLength={250}
        placeholder="Enter the default message"
        onChange={(e) => {
          setData({
            ...data,
            meta: {
              ...data.meta,
              default_message: e.target.value,
            },
          });
        }}
      />
      {defaultMessageError && (
        <p className="ml-1 text-xs text-destructive">
          Default Message is required field and can&apos;t be empty.
        </p>
      )}
    </div>
  );
};

export default PreCannedSpamDefenseOptions;
