/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { cn } from 'utils/cn';
import { Clock, Clock1, Hash, MessageSquareText, PanelTop, ShieldCheck, X } from 'lucide-react';
import { AIAgentTag, ChatMessages, ChatSession } from 'models/api/response.types';
import { format } from 'date-fns';
import { Badge } from 'components/ui/badge';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-textmate';
import { isChatMessage } from 'utils/chat';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';
import tinycolor from 'tinycolor2';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { ParsedSessionMeta } from 'utils/sessions';

const SessionDetailsDrawer: React.FC<{
  session: ChatSession;
  messages: ChatMessages;
  show: boolean;
  hide: () => void;
}> = ({ session, messages, show, hide }) => {
  const { addAlert } = useAlerts();

  const { agentStore, sessionMeta } = useMemo(() => {
    return {
      agentStore: JSON.parse(session?.agent_state_store_json || '{}'),
      sessionMeta: JSON.parse(session?.meta_json || '{}') as ParsedSessionMeta,
    };
  }, [session]);

  const { totalMessages } = useMemo(() => {
    return {
      totalMessages: messages.reduce((acc, message) => {
        if (isChatMessage(message)) return acc + (message.query ? 1 : 0) + (message.response ? 1 : 0);
        return acc + (message.type !== 'announcement' ? 1 : 0);
      }, 0),
    };
  }, [messages]);

  return (
    <div
      className={cn(
        'z-[2] absolute 2xl:relative w-full 2xl:w-auto  2xl:translate-x-[0px] right-0 top-0 h-full transition-all duration-300 overflow-hidden ease-out md:max-w-[450px] 2xl:max-w-[400px] 3xl:max-w-[500px]',
        show ? 'translate-x-[0px] opacity-100 2xl:w-full' : 'translate-x-[120%] 2xl:w-0 opacity-0',
      )}
    >
      <div className="bg-background shadow-sm border-l h-full overflow-hidden flex flex-col pb-6">
        <div className="flex items-center text-base font-semibold text-foreground px-6 h-[48px] border-b">
          <h1>Conversation Properties</h1>
          <button
            type="button"
            onClick={hide}
            className="rounded-sm border p-[2px] opacity-70 ring-offset-background transition-opacity hover:opacity-100 ml-auto"
          >
            <X strokeWidth={1.75} className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>
        </div>
        <div className="flex-1 overflow-y-auto overflow-x-hidden">
          <Accordion
            defaultValue={['details', 'form', 'variables', 'tags', 'authorization_data']}
            type="multiple"
          >
            <AccordionItem value="details">
              <AccordionTrigger className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs">
                Details
              </AccordionTrigger>
              <AccordionContent className="border-t px-6 py-4 flex flex-col gap-2">
                <p
                  onClick={() => {
                    if ('clipboard' in navigator) {
                      navigator.clipboard.writeText(session.uuid);
                      addAlert({
                        severity: 'success',
                        message: alerts.SESSION_UUID_COPIED,
                      });
                    }
                  }}
                  className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap cursor-pointer"
                >
                  <Hash className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                  UUID
                  <span className="text-foreground ml-auto font-medium">{session.uuid}</span>
                </p>
                {sessionMeta?.parent_domain && (
                  <p className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap">
                    <PanelTop className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                    Domain
                    <span className="text-foreground ml-auto font-medium">{sessionMeta.parent_domain}</span>
                  </p>
                )}
                <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
                  <MessageSquareText className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                  Total Messages
                  <span className="text-foreground ml-auto font-medium">{totalMessages}</span>
                </p>
                <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
                  <Clock className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                  Started On
                  <span className="text-foreground ml-auto font-medium">
                    {format(new Date(session.created_at), 'dd/MM/yyyy HH:mm')}
                  </span>
                </p>
                <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
                  <Clock1 className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                  Last Message At
                  <span className="text-foreground ml-auto font-medium">
                    {format(new Date(session?.latest_message_at || session.modified_at), 'dd/MM/yyyy HH:mm')}
                  </span>
                </p>
                {sessionMeta?.disclaimer_accepted_at && (
                  <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
                    <ShieldCheck className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                    Disclaimer Accepted At
                    <span className="text-foreground ml-auto font-medium">
                      {format(new Date(sessionMeta.disclaimer_accepted_at), 'dd/MM/yyyy HH:mm')}
                    </span>
                  </p>
                )}
              </AccordionContent>
            </AccordionItem>
            {sessionMeta?.form_data && (
              <AccordionItem value="form">
                <AccordionTrigger className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs">
                  Form
                </AccordionTrigger>
                <AccordionContent className="border-t p-0">
                  <AceEditor
                    mode="json"
                    theme="textmate"
                    name="jsonEditor"
                    value={JSON.stringify(sessionMeta.form_data, null, 2)}
                    fontSize={14}
                    readOnly
                    setOptions={{
                      useWorker: false,
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: false,
                      tabSize: 2,
                    }}
                    style={{ width: '100%', height: '150px' }}
                  />
                </AccordionContent>
              </AccordionItem>
            )}
            {agentStore.states && Object.keys(agentStore.states).length > 0 && (
              <AccordionItem value="variables">
                <AccordionTrigger className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs">
                  Variables
                </AccordionTrigger>
                <AccordionContent className="border-t p-0">
                  <AceEditor
                    mode="json"
                    theme="textmate"
                    name="jsonEditor"
                    value={JSON.stringify(agentStore.states, null, 2)}
                    fontSize={14}
                    readOnly
                    setOptions={{
                      useWorker: false,
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: false,
                      tabSize: 2,
                    }}
                    style={{ width: '100%', height: '150px' }}
                  />
                </AccordionContent>
              </AccordionItem>
            )}
            {agentStore?.tags && agentStore.tags.length > 0 && (
              <AccordionItem value="tags">
                <AccordionTrigger className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs">
                  Tags
                </AccordionTrigger>
                <AccordionContent className="border-t px-6 py-4 flex items-center flex-wrap gap-2">
                  {agentStore.tags.map((tag: AIAgentTag, index: number) => {
                    return (
                      <Badge
                        style={{
                          borderColor: tag.color,
                          color: tinycolor(tag.color).darken(25).toString(),
                          backgroundColor: `${tag.color}30`,
                        }}
                        key={index}
                        variant="outline"
                        className="tag text-xs whitespace-nowrap rounded-md"
                      >
                        {tag.name}
                      </Badge>
                    );
                  })}
                </AccordionContent>
              </AccordionItem>
            )}
            {sessionMeta?.auth_data && (
              <AccordionItem value="authorization_data">
                <AccordionTrigger className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs">
                  Authenticated Data
                </AccordionTrigger>
                <AccordionContent className="border-t p-0">
                  <AceEditor
                    mode="json"
                    theme="textmate"
                    name="jsonEditor"
                    value={JSON.stringify(sessionMeta?.auth_data, null, 2)}
                    fontSize={14}
                    readOnly
                    setOptions={{
                      useWorker: false,
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: false,
                      tabSize: 2,
                    }}
                    style={{ width: '100%', height: '150px' }}
                  />
                </AccordionContent>
              </AccordionItem>
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default SessionDetailsDrawer;
