import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import React, { Fragment, useEffect, useState } from 'react';
import { cn } from 'utils/cn';

const AddNotificationChatVariablesDialog: React.FC<{
  agentVariables: string[];
  variables: string[];
  show: boolean;
  close: (variables?: string[]) => void;
}> = ({ agentVariables, variables, show, close }) => {
  const [variableList, setVariableList] = useState<string[]>([]);

  useEffect(() => {
    if (variables) {
      setVariableList(variables);
    }
  }, [variables]);

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => close()}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  {variables.length === 0 ? 'Add' : 'Edit'} Chat Collected Variables
                </DialogTitle>
                <p className="text-sm text-muted-foreground mt-2">
                  {variables.length === 0
                    ? 'Select the variables you wish to be sent through email. Once AI collects them, we will promptly send you an email with the details.'
                    : 'Change your variable options by adding new ones or removing existing ones. Our system will detect these changes and automatically update to work with the new data.'}
                </p>

                <div className="mt-4">
                  <>
                    <div className="flex items-center gap-2 flex-wrap">
                      {agentVariables.map((variable) => {
                        const isSelected = variableList.includes(variable);
                        return (
                          <Badge
                            key={`${variable}-variable`}
                            variant={isSelected ? 'default' : 'outline'}
                            className={cn(
                              'text-sm px-4 w-fit rounded-md cursor-pointer transition-all whitespace-nowrap',
                              isSelected ? '' : 'hover:bg-muted',
                            )}
                            onClick={() => {
                              if (isSelected) {
                                setVariableList(variableList.filter((v) => v !== variable));
                              } else {
                                setVariableList([...variableList, variable]);
                              }
                            }}
                          >
                            {variable}
                          </Badge>
                        );
                      })}
                    </div>
                  </>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={() => close()} variant="outline">
                    Close
                  </Button>
                  <Button
                    disabled={variableList.length === 0}
                    size="default"
                    onClick={() => {
                      close(variableList);
                    }}
                  >
                    {variables.length === 0 ? 'Add' : 'Edit'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddNotificationChatVariablesDialog;
