import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Input, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { useQueryClient } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import integrationsService from 'api/integrations';
import { useAlerts } from 'providers/AlertProvider';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot } from 'models/api/response.types';
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp';

const SetWhatsAppPinDialog: React.FC<{
  open: boolean;
  close: () => void;
}> = ({ open, close }) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const [loading, setLoading] = useState<boolean>(false);
  const [pin, setPin] = useState<string>('');
  const integrationPhoneDataQueryKey = ['whatsapp-integration-phone', chatbot.uuid];

  const registerWhatsAppIntegration = () => {
    // this pin should be an option
    setLoading(true);
    integrationsService
      .registerWhatsAppIntegration(chatbot.uuid, pin)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: integrationPhoneDataQueryKey });
        close();
      })
      .catch((error) => {
        addAlert({
          severity: 'error',
          message: error.response.data.error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Add Pin Number
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    To connect and register your phone number with GPT-Trainer, please provide your 6-digit
                    two-step verification PIN.
                  </p>
                  <ul className="marker:text-primary list-outside text-sm text-muted-foreground list-disc my-2 ml-4">
                    <li>
                      If you have already set up a PIN for this number, enter it now. There is no need to
                      create a new one; you can reuse your existing PIN.
                    </li>
                    <li>If you do not have a PIN yet, this will be your new two-step verification PIN.</li>
                  </ul>
                  <p className="text-sm text-muted-foreground">
                    For instructions on how to retrieve or set up your PIN, please follow{' '}
                    <a
                      className="text-secondary font-bold hover:underline"
                      href="https://faq.whatsapp.com/2183055648554771"
                      target="_blank"
                      rel="noreferrer"
                    >
                      these instructions
                    </a>
                    .
                  </p>
                  <div className="flex flex-col items-center justify-center w-full my-8 gap-2">
                    <InputOTP maxLength={6} value={pin} onChange={setPin}>
                      <InputOTPGroup>
                        <InputOTPSlot className="h-12 w-12" index={0} />
                        <InputOTPSlot className="h-12 w-12" index={1} />
                        <InputOTPSlot className="h-12 w-12" index={2} />
                        <InputOTPSlot className="h-12 w-12" index={3} />
                        <InputOTPSlot className="h-12 w-12" index={4} />
                        <InputOTPSlot className="h-12 w-12" index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                    <div className="text-center text-sm text-muted-foreground">Enter your pin number.</div>
                  </div>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={close}>
                    Cancel
                  </Button>
                  <Button disabled={loading || pin.length !== 6} onClick={registerWhatsAppIntegration}>
                    {loading && <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />}
                    Add
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SetWhatsAppPinDialog;
