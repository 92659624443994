import axios from 'axios';
import { DataRow } from './sources';

export const downloadDataSource = (
  { file_name, uuids, canView }: { file_name: string; uuids: string[]; canView?: boolean },

  completeLoading: (success: boolean) => void,
) => {
  axios
    .post(`/api/data-sources/download`, { uuids }, { responseType: 'arraybuffer' })
    .then((response) => {
      const contentDisposition = response.headers['content-disposition'];
      let fileName = file_name; // Default filename if none is found
      let fileType = 'application/octet-stream'; // Default file type

      // Extract filename from content-disposition header if present
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          [, fileName] = fileNameMatch;
        }
      }

      if (/\.(xlsx|xls)$/.test(fileName)) {
        fileName = fileName.replace(/\.(xlsx|xls)$/, '.csv');
      }

      // Determine the file extension
      const fileExtension = fileName?.split('.')?.pop()?.toLowerCase() || '';

      // Set the appropriate fileType based on file extension
      switch (fileExtension) {
        case 'txt':
          fileType = 'text/plain';
          break;
        case 'md':
          fileType = 'text/markdown';
          break;
        case 'tex':
          fileType = 'application/x-latex';
          break;
        case 'docx':
          fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'pdf':
          fileType = 'application/pdf';
          break;
        case 'csv':
        case 'xlsx':
        case 'xls':
          fileType = 'text/csv';
          break;
        case 'zip':
        case 'newzipfile':
          fileType = 'application/zip';
          break;
        case 'png':
          fileType = 'image/png';
          break;
        case 'webp':
          fileType = 'image/webp';
          break;
        case 'jpg':
        case 'jpeg':
          fileType = 'image/jpeg';
          break;
        default:
          fileType = 'application/octet-stream';
      }
      // Create a blob from the response data and generate a URL for it
      const blob = new Blob([response.data], { type: fileType });
      const blobUrl = URL.createObjectURL(blob);

      // for cases to open file in new tab
      if (canView && ['pdf', 'jpg', 'webp', 'jpeg', 'png'].includes(fileExtension)) {
        // Create a new tab and display the PDF
        window.open(blobUrl, '_blank');
        completeLoading(true);
        return;
      }

      // Create a temporary anchor element and trigger a download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      completeLoading(true);
    })
    .catch(() => {
      completeLoading(false);
    });
};

export const downloadQASource = (source: DataRow) => {
  const { question, answer } = JSON.parse(source.meta_json);
  const jsonString = JSON.stringify({ question, answer });
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `QA.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const supportedFileFormats = ['.pdf', '.docx', '.txt', '.md', '.tex'];
export const supportedImageFormats = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
export const supportedTableFormats = ['.csv', '.xlsx', '.xls'];

export const nonValidSitemapExtensions = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.mp4',
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.zip',
  '.tar',
  '.gz',
  '.svg',
];
