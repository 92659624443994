import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import ChatBubbleIntegration from './ChatBubbleIntegration';
import IframeIntegaration from './IframeIntegration';

const DrupalIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img
            className="w-[40px] h-[40px] rounded-md"
            src="/img/integrations/drupal/drupal.jpg"
            alt="Drupal"
          />
          <h1 className="text-xl font-semibold">Add to Drupal</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6" defaultValue="iframe">
        <TabsList>
          <TabsTrigger value="iframe">Embed to page</TabsTrigger>
          <TabsTrigger value="bubble">Chat bubble</TabsTrigger>
        </TabsList>
        <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
          In order to experience the fully functional capabilities of the chatbot within your development
          environment, we recommend enabling the <strong>&quot;Allow all domains&quot;</strong> option within
          the Chatbot settings. This will allow you to explore and test the chatbot&apos;s functionalities
          seamlessly. Feel free to disable this feature once your website is ready for deployment.
        </div>
        <TabsContent value="iframe">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. Click <strong>&quot;Content&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-1.png" alt="Click Content" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. Select <strong>&quot;Blocks&quot;</strong> and click{' '}
            <strong>&quot;Add content block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-2.png" alt="Click Add content" />
          <p className="font-normal text-muted-foreground text-base my-4">
            3. Enter <strong>&quot;Block description&quot;</strong>, select text format as{' '}
            <strong>&quot;Full HTML&quot;</strong> and check <strong>&quot;Source&quot;</strong> option inside
            body form. After that paste our code to form:
          </p>
          <IframeIntegaration />
          <div className="my-4 text-sm py-2 px-4 bg-blue-50 border border-blue-200 rounded-md">
            You can change the height value (e.g., 800px for taller or 300px for shorter) to fit your design.
            Experiment until it looks perfect.
          </div>
          <img src="/img/integrations/drupal/iframe-3.png" alt="Paste code" />
          <p className="font-normal text-muted-foreground text-base my-4">
            4. Click <strong>&quot;Save&quot;</strong> and review your block.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-4.png" alt="Review block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            5. Click <strong>&quot;Structure&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-5.png" alt="Click Structure" />
          <p className="font-normal text-muted-foreground text-base my-4">
            6. Select <strong>&quot;Block layout&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-6.png" alt="Select Block layout" />
          <p className="font-normal text-muted-foreground text-base my-4">
            7. Select the block where you want to place your iframe and click{' '}
            <strong>&quot;Place block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-7.png" alt="Place block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            8. Find the block in the list and click <strong>&quot;Place block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-8.png" alt="Find and Place block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            9. Uncheck <strong>&quot;Display title&quot;</strong> and click{' '}
            <strong>&quot;Save block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-9.png" alt="Uncheck Display title" />
        </TabsContent>
        <TabsContent value="bubble">
          <p className="font-normal text-muted-foreground text-base my-4">
            1. Click <strong>&quot;Content&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-1.png" alt="Click Content" />
          <p className="font-normal text-muted-foreground text-base my-4">
            2. Select <strong>&quot;Blocks&quot;</strong> and click{' '}
            <strong>&quot;Add content block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-2.png" alt="Click Add Content" />
          <p className="font-normal text-muted-foreground text-base my-4">
            3. Enter <strong>&quot;Block description&quot;</strong>, select text format as{' '}
            <strong>&quot;Full HTML&quot;</strong> and check <strong>&quot;Source&quot;</strong> option inside
            body form. After that paste our code to form:
          </p>
          <ChatBubbleIntegration />
          <img className="mt-4" src="/img/integrations/drupal/widget-3.png" alt="Paste code" />
          <p className="font-normal text-muted-foreground text-base my-4">
            4. Click <strong>&quot;Save&quot;</strong> and review your block.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-4.png" alt="Review block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            5. Click <strong>&quot;Structure&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-5.png" alt="Click Structure" />
          <p className="font-normal text-muted-foreground text-base my-4">
            6. Select <strong>&quot;Block layout&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-6.png" alt="Select Block layout" />
          <p className="font-normal text-muted-foreground text-base my-4">
            7. Select the block where you want to place your widget and click{' '}
            <strong>&quot;Place block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-7.png" alt="Place block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            8. Find the block in the list and click <strong>&quot;Place block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-8.png" alt="Find and Place block" />
          <p className="font-normal text-muted-foreground text-base my-4">
            9. Uncheck <strong>&quot;Display title&quot;</strong> and click{' '}
            <strong>&quot;Save block&quot;</strong>.
          </p>
          <img src="/img/integrations/drupal/iframe-widget-9.png" alt="Uncheck Display title" />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default DrupalIntegration;
