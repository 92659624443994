/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
import { DataSource } from 'models/api/response.types';

// eslint-disable-next-line import/prefer-default-export
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const result = bytes / Math.pow(k, i);

  // Use toPrecision to avoid floating-point issues
  return parseFloat(result.toPrecision(dm + 1)) + ' ' + sizes[i];
}

export const formatStatus = (ds: DataSource) => {
  if (ds.status === 'queued' || ds.status === 'assigned') {
    return 'Queued';
  }
  if (ds.status === 'running') {
    return 'Running';
  }
  if (ds.status === 'error' || ds.status === 'fail') {
    return `Error: ${ds.error}`;
  }
  if (ds.status === 'success') {
    return `Ready`;
  }
  return ds.status;
};

export const numberWithDots = (number: number) => {
  return number.toLocaleString('de-DE');
};
