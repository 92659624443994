import { Filters } from 'components/helpers/FilterButton';
import { DataSource } from 'models/api/response.types';
import { useState, useMemo } from 'react';
import { sortDataTableDocuments } from 'utils/dataTableSort';
import { SortSource } from 'utils/sources';

const useSortedFilteredData = (sources: DataSource[]) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState<number>(20);
  const [filterValue, setFilterValue] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc');
  const [sortColumn, setSortColumn] = useState<SortSource>('date_added');
  const [validFilters, setValidFilters] = useState<{
    type: Filters;
  }>({
    type: [],
  });
  const [selectedFilters, setSelectedFilters] = useState<{
    type: Filters;
  }>({
    type: [],
  });

  const sortedAndSearchedData = useMemo(() => {
    let result = sources.filter((source) => {
      const { type, meta_json, file_name, title } = source;
      const meta = JSON.parse(meta_json);

      if (filterValue) {
        const value = filterValue.toLocaleLowerCase();
        if (title.toLocaleLowerCase().includes(value)) {
          return true;
        }
        if (type === 'url' && meta.url.toLocaleLowerCase().includes(value)) {
          return true;
        }
        if (
          type === 'qa' &&
          (meta.question.toLocaleLowerCase().includes(value) ||
            meta.answer.toLocaleLowerCase().includes(value))
        ) {
          return true;
        }
        if (
          (type === 'upload' || type === 'google-drive' || type === 'table') &&
          file_name.toLocaleLowerCase().includes(value)
        ) {
          return true;
        }
        return false;
      }
      return true;
    });

    if (sortColumn && result.length > 0) {
      result = sortDataTableDocuments(result, sortColumn, sortOrder);
    }
    // get valid source types;
    const validTypes = new Set();
    result.forEach((src) => validTypes.add(src.type));
    setValidFilters({
      ...validFilters,
      type: Array.from(validTypes) as string[],
    });
    return result.filter((src) => {
      if (selectedFilters.type.length > 0) {
        return selectedFilters.type.includes(src.type);
      }
      return true;
    });
  }, [sources, filterValue, sortColumn, sortOrder, selectedFilters]);

  const totalPages = useMemo(() => {
    return sortedAndSearchedData ? Math.ceil(sortedAndSearchedData.length / itemsPerPage) : 1;
  }, [sortedAndSearchedData, itemsPerPage]);

  const indexes = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return {
      startIndex,
      endIndex: startIndex + itemsPerPage,
    };
  }, [currentPage, itemsPerPage]);

  const dataToUse = useMemo(() => {
    return sortedAndSearchedData.slice(indexes.startIndex, indexes.endIndex);
  }, [indexes, sortedAndSearchedData]);

  return {
    currentPage,
    setCurrentPage,
    filterValue,
    setFilterValue,
    sortOrder,
    setSortOrder,
    sortColumn,
    setSortColumn,
    validFilters,
    setValidFilters,
    selectedFilters,
    setSelectedFilters,
    totalPages,
    dataToUse,
  };
};

export default useSortedFilteredData;
