/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import AgentData from 'components/Chatbot/Agents/AgentData/AgentData';
import AgentCard from 'components/Chatbot/Agents/AgentCard';
import { Button } from 'components/ui/button';
import { PlusCircle, Settings } from 'lucide-react';
import { cn } from 'utils/cn';
import PredefinedTemplates from 'components/Chatbot/Agents/PredefinedTemplates';
import DeleteAgentDialog from 'components/Dialogs/Agents/DeleteAgentDialog';
import { useSelector } from 'react-redux';
import { getSelectedChatbot, getShowAgentRuleInvalidState } from 'store/reducers/ui';
import { AIAgent, Chatbot } from 'models/api/response.types';
import SubscriptionLimitsExceededDialog from 'components/Dialogs/Subscription/SubscriptionLimitsExceededDialog';
import LoadingOverlay from 'components/helpers/LoadingOverlay';
import SupervisorSettings from 'components/Chatbot/Agents/Supervisor/SupervisorSettings';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import useAiAgents from 'hooks/useAiAgents';
import StatusDot from 'components/helpers/StatusDot';
import DashboardOnboarding from 'components/Onboarding/DashboardOnboarding';

const Agents: React.FC = () => {
  const { agentsMaxCount, canUseSupervisorSettings } = useSubscriptionInfo();
  const { agents } = useAiAgents();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const showAgentRuleInvalidState = useSelector(getShowAgentRuleInvalidState);
  const [showPredefinedTemplates, setShowPredefinedTemplates] = useState<boolean>(false);
  const [openSupervisorSettings, setOpenSupervisorSettings] = useState<boolean>(false);
  const [closeSettignsButtonHovered, setCloseSettingsButtonHovered] = useState<boolean>(false);
  const [showLimitExceededDialog, setShowLimitExceededDialog] = useState<boolean>(false);
  const [limitExceededDialogType, setLimitExceededDialogType] = useState<'ai-agent' | 'supervisor-settings'>(
    'ai-agent',
  );
  const [agentToDelete, setAgentToDelete] = useState<AIAgent | undefined>(undefined);
  const [selectedAgent, setSelectedAgent] = useState<AIAgent | undefined>(undefined);

  useEffect(() => {
    setSelectedAgent(undefined);
  }, [selectedChatbot]);

  useEffect(() => {
    if (agents && selectedAgent) {
      const updatedAgentIndex = agents.findIndex((ag) => ag.uuid === selectedAgent.uuid);
      if (updatedAgentIndex > -1) {
        setSelectedAgent(agents[updatedAgentIndex]);
      }
    }
  }, [agents]);

  const agentListToUse = useMemo(() => {
    return agents
      ? agents.sort((a, b) => {
          if (!a.active && b.active) return 1;
          if (a.active && !b.active) return -1;

          return 0;
        })
      : undefined;
  }, [agents]);

  const { canCreateAgents } = useMemo(() => {
    return {
      canCreateAgents: (agentListToUse?.length || 0) < agentsMaxCount,
    };
  }, [agentsMaxCount, agentListToUse]);

  useEffect(() => {
    if (selectedAgent && showPredefinedTemplates) {
      setShowPredefinedTemplates(false);
    }
  }, [selectedAgent]);

  const disableView = useMemo(() => {
    return !!selectedAgent || openSupervisorSettings || showPredefinedTemplates;
  }, [selectedAgent, openSupervisorSettings, showPredefinedTemplates]);

  if (!agentListToUse) {
    return (
      <div className="h-full flex items-center justify-center">
        <LoadingOverlay />
      </div>
    );
  }

  return (
    <div className="h-full flex w-full relative">
      {disableView && <div className="absolute h-full w-full top-0 left-0 z-[1]" />}
      <div className="bg-background z-[2] absolute top-0 left-0 h-full flex">
        <div
          className={cn(
            'hidden transition-all overflow-x-hidden overflow-y-auto sm:flex flex-col gap-2',
            openSupervisorSettings ? 'w-full xl:w-[800px] border-r' : 'w-0',
            closeSettignsButtonHovered ? 'opacity-30' : '',
          )}
        >
          <SupervisorSettings opened={openSupervisorSettings} agents={agentListToUse} />
        </div>
        <button
          disabled={showPredefinedTemplates || !!selectedAgent}
          type="button"
          className="w-[25px] disabled:pointer-events-none disabled:opacity-50 hidden sm:flex items-center justify-center bg-slate-50 border-r hover:bg-slate-200 transition-all cursor-pointer"
          onClick={() => {
            if (canUseSupervisorSettings) {
              setOpenSupervisorSettings(!openSupervisorSettings);
            } else {
              setLimitExceededDialogType('supervisor-settings');
              setShowLimitExceededDialog(true);
            }
          }}
          onMouseEnter={() => {
            if (openSupervisorSettings) {
              setCloseSettingsButtonHovered(true);
            }
          }}
          onMouseLeave={() => {
            setCloseSettingsButtonHovered(false);
          }}
        >
          <div className="flex whitespace-nowrap items-center gap-2 text-muted-foreground text-xs rotate-[270deg]">
            <Settings strokeWidth={1.75} className="h-4 w-4 min-w-[16px]" />
            <p>AI Supervisor Settings</p>
            {showAgentRuleInvalidState && !openSupervisorSettings && (
              <StatusDot animate className="h-4 w-4" status="error" />
            )}
          </div>
        </button>
      </div>
      <div className="flex-1 overflow-auto">
        <div
          className={cn(
            'transition-all p-4 sm:ml-6 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 min-[1850px]:grid-cols-4 gap-4',
            disableView && 'blur-sm',
          )}
        >
          <Card className="create-ai-agent-card flex flex-col bg-muted">
            <CardHeader className="flex flex-row items-center gap-2 space-y-0 p-4 pb-0 h-[48px]">
              <CardTitle className="line-clamp-1">Create New AI Agent</CardTitle>
            </CardHeader>
            <CardContent className="p-4 flex-1 flex flex-col">
              <CardDescription className="line-clamp-3 mb-4">
                Select and add an AI agent from our diverse range of predefined templates to quickly find the
                perfect match for your needs.
              </CardDescription>
              <Button
                onClick={() => {
                  if (canCreateAgents) {
                    setShowPredefinedTemplates(true);
                  } else {
                    setLimitExceededDialogType('ai-agent');
                    setShowLimitExceededDialog(true);
                  }
                }}
                size="sm"
                className="mt-auto"
              >
                <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
                Create new agent
              </Button>
            </CardContent>
          </Card>
          {agentListToUse.map((agent: AIAgent, index) => {
            return (
              <AgentCard
                key={index}
                agent={agent}
                deleteAgent={() => {
                  setAgentToDelete(agent);
                }}
                selectAgent={() => {
                  setSelectedAgent(agent);
                }}
              />
            );
          })}
        </div>
      </div>
      <AgentData hide={() => setSelectedAgent(undefined)} agent={selectedAgent} />
      <PredefinedTemplates hide={() => setShowPredefinedTemplates(false)} show={showPredefinedTemplates} />
      <SubscriptionLimitsExceededDialog
        type={limitExceededDialogType}
        show={showLimitExceededDialog}
        close={() => setShowLimitExceededDialog(false)}
      />
      <DeleteAgentDialog agent={agentToDelete} close={() => setAgentToDelete(undefined)} />
      {/* need to show users onboarding only in ai agent tab */}
      <DashboardOnboarding />
    </div>
  );
};

export default Agents;
