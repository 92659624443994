import React from 'react';
import { loaderSizeVariant } from 'utils/bot';
import { cn } from 'utils/cn';

const LoadingOverlay: React.FC<{ size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' }> = ({ size = 'md' }) => {
  return (
    <div className="flex flex-row justify-center items-center gap-4">
      <div className={cn('loading bg-primary loading-0 rounded-full', loaderSizeVariant[size])} />
      <div className={cn('loading bg-primary loading-1 rounded-full', loaderSizeVariant[size])} />
      <div className={cn('loading bg-primary loading-2 rounded-full', loaderSizeVariant[size])} />
    </div>
  );
};

export default LoadingOverlay;
