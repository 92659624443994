/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lone-blocks */
import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Check, ChevronsUpDown, LinkIcon, Mail, MessageSquare } from 'lucide-react';
import { ChatBotMeta } from 'utils/bot';
import { Textarea } from 'components/ui/textarea';

const promptsTypeIcons: { [key: string]: ReactNode } = {
  query: <MessageSquare strokeWidth={1.75} className="mt-px h-4 w-4 min-w-4" />,
  email: <Mail strokeWidth={1.75} className="mt-px h-4 w-4 min-w-4" />,
  link: <LinkIcon strokeWidth={1.75} className="mt-px h-4 w-4 min-w-4" />,
};

export const promptsTypeDescription: { [key: string]: string } = {
  query: 'Message is sent to the chat.',
  email: 'Email client is opened.',
  link: 'Opens specified URL.',
};

export type EventSelection = 'query' | 'email' | 'link';

const promptsTypeNames: { [key: string]: ReactNode } = {
  query: 'User Message',
  link: 'Navigate to URL',
  email: 'Send Email',
};

const UpdateSuggestedPromptDialog: React.FC<{
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
  promptToUpdate?: number;
  close: () => void;
}> = ({ chatInterface, setChatInterface, promptToUpdate, close }) => {
  const [show, setShow] = useState<boolean>(false);
  const [createPromptEventSelection, setCreatePromptEventSelection] = useState<EventSelection>('query');
  const [suggestedPromptLabel, setSuggestedPromptLabel] = useState<string>('');
  const [suggestedPromptInput, setSuggestedPromptInput] = useState<string>('');
  const [preCannedResponse, setPreCannedResponse] = useState<string>('');
  const promptToUpdateExists = promptToUpdate !== undefined;

  useEffect(() => {
    if (promptToUpdateExists) {
      const item = chatInterface.suggested_prompts[promptToUpdate];
      setCreatePromptEventSelection(item.type);
      setSuggestedPromptLabel(item.label);
      setSuggestedPromptInput(item.data);
      setPreCannedResponse(item?.pre_canned_response || '');
      setShow(true);
    }
  }, [promptToUpdate]);

  const isValidToUpdate = useMemo(() => {
    if (promptToUpdateExists) {
      const item = chatInterface.suggested_prompts[promptToUpdate as number];
      return (
        item &&
        createPromptEventSelection &&
        suggestedPromptInput &&
        suggestedPromptLabel &&
        (createPromptEventSelection !== item.type ||
          suggestedPromptInput !== item.data ||
          suggestedPromptLabel !== item.label ||
          preCannedResponse !== item.pre_canned_response)
      );
    }
    return false;
  }, [
    createPromptEventSelection,
    preCannedResponse,
    suggestedPromptLabel,
    suggestedPromptInput,
    promptToUpdateExists,
  ]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
      setCreatePromptEventSelection('query');
      setSuggestedPromptInput('');
      setSuggestedPromptLabel('');
    }, 300);
  };

  const updateSuggestedPrompt = () => {
    const newPrompts = [...chatInterface.suggested_prompts];
    newPrompts[promptToUpdate as number] = {
      type: createPromptEventSelection as EventSelection,
      label: suggestedPromptLabel,
      data: suggestedPromptInput,
      pre_canned_response: createPromptEventSelection === 'query' ? preCannedResponse : '',
    };
    setChatInterface({
      ...chatInterface,
      suggested_prompts: newPrompts,
    });
    hide();
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Edit Suggested Prompt
                </DialogTitle>
                <div className="mt-4">
                  <h3 className="text-base font-medium leading-none tracking-tight flex items-center mb-2">
                    Type
                  </h3>
                  <Listbox value={createPromptEventSelection} onChange={setCreatePromptEventSelection}>
                    <div className="relative mt-1">
                      <ListboxButton className="flex h-10 w-full items-center rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 hover:bg-accent">
                        {promptsTypeIcons[createPromptEventSelection]}
                        <span className="block truncate ml-2">
                          {promptsTypeNames[createPromptEventSelection]}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronsUpDown className="h-4 w-4 min-w-[16px] text-gray-400" aria-hidden="true" />
                        </span>
                      </ListboxButton>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <ListboxOptions className="absolute z-[5] px-1 mt-1 max-h-60 w-full overflow-auto rounded-md bg-background py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {['query', 'link', 'email'].map((event, eventIdx) => (
                            <ListboxOption
                              key={eventIdx}
                              className="relative flex hover:bg-accent cursor-pointer w-full select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-5"
                              value={event}
                            >
                              {({ selected }) => (
                                <>
                                  <div>
                                    <div className="flex items-center">
                                      {promptsTypeIcons[event]}
                                      <span className="block truncate ml-2">{promptsTypeNames[event]}</span>
                                    </div>
                                    <p className="text-xs text-muted-foreground">
                                      {promptsTypeDescription[event]}
                                    </p>
                                  </div>

                                  {selected ? (
                                    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                                      <Check
                                        strokeWidth={1.75}
                                        className="h-4 w-4 min-w-[16px] text-success"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Transition>
                    </div>
                  </Listbox>
                  <h3 className="text-base font-medium leading-none tracking-tight flex items-center mt-4 mb-2">
                    Label
                  </h3>
                  <div className="flex-1 max-w-[868px]">
                    <Input
                      type="text"
                      autoComplete="off"
                      disabled={!createPromptEventSelection}
                      value={suggestedPromptLabel}
                      placeholder={
                        createPromptEventSelection === 'link'
                          ? 'Check pricing plans'
                          : createPromptEventSelection === 'email'
                            ? 'Contact support'
                            : 'How do I get started?'
                      }
                      onChange={(e) => {
                        setSuggestedPromptLabel(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && isValidToUpdate && promptToUpdateExists) {
                          updateSuggestedPrompt();
                        }
                      }}
                    />
                  </div>
                  <h3 className="text-base font-medium leading-none tracking-tight flex items-center mt-6 mb-2">
                    {createPromptEventSelection === 'link'
                      ? 'URL'
                      : createPromptEventSelection === 'email'
                        ? 'Email'
                        : !createPromptEventSelection
                          ? 'Action'
                          : 'Prompt'}
                  </h3>
                  <div className="flex items-start gap-4">
                    <div className="flex-1">
                      <Input
                        type="text"
                        autoComplete="off"
                        disabled={!createPromptEventSelection}
                        value={suggestedPromptInput}
                        placeholder={
                          createPromptEventSelection === 'query'
                            ? 'How do I get started?'
                            : createPromptEventSelection === 'email'
                              ? 'support@example.com'
                              : 'https:my-custom-site.com'
                        }
                        onChange={(e) => {
                          setSuggestedPromptInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && isValidToUpdate && promptToUpdateExists) {
                            updateSuggestedPrompt();
                          }
                        }}
                      />
                    </div>
                  </div>
                  {createPromptEventSelection === 'query' && (
                    <>
                      <h3 className="text-base font-medium leading-none tracking-tight flex items-center mt-6 mb-2">
                        Pre Canned Response
                      </h3>
                      <div className="flex items-start gap-4">
                        <div className="flex-1">
                          <Textarea
                            autoFocus
                            className="min-h-[100px] resize-none"
                            id="expected-response"
                            value={preCannedResponse}
                            placeholder="To get started you ..."
                            onChange={(e) => {
                              setPreCannedResponse(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={hide} variant="outline">
                    Close
                  </Button>
                  <Button
                    disabled={!isValidToUpdate}
                    size="default"
                    onClick={() => {
                      updateSuggestedPrompt();
                    }}
                  >
                    {promptToUpdateExists ? 'Update' : 'Create'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateSuggestedPromptDialog;
