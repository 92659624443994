export type Integration =
  | 'link'
  | 'wix'
  | 'slack'
  | 'site'
  | 'wordpress'
  | 'shopify'
  | 'squarespace'
  | 'drupal'
  | 'zapier'
  | 'notion'
  | 'whatsapp'
  | 'bubble'
  | 'webflow'
  | 'instagram'
  | 'messenger'
  | 'make'
  | 'hubspot'
  | 'zendesk';

// export type IntegrationType = 'Web' | 'Link' | 'App' | 'Webhooks';

// export const badgeClassNames: { [key in IntegrationType]: string } = {
//   Web: 'bg-blue-600 text-background border-blue-700',
//   Webhooks: 'bg-orange-600 text-background border-orange-700',
//   Link: 'bg-teal-600 text-background border-teal-700',
//   App: 'bg-rose-600 text-background border-rose-700',
// };

export const IntegrationTitle: { [key in Integration]: string } = {
  link: 'Link',
  wix: 'Wix',
  slack: 'Slack',
  site: 'Website',
  wordpress: 'Wordpress',
  shopify: 'Shopify',
  squarespace: 'Squarespace',
  drupal: 'Drupal',
  zapier: 'Zapier',
  notion: 'Notion',
  whatsapp: 'WhatsApp',
  bubble: 'Bubble',
  webflow: 'Webflow',
  instagram: 'Instagram',
  messenger: 'Messenger',
  make: 'Make',
  hubspot: 'HubSpot',
  zendesk: 'Zendesk',
};

export const buttonVariants = {
  hidden: {
    y: 10,
    opacity: 0,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
};
