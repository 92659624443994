/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Label } from 'components/ui/label';
import { KeyRound } from 'lucide-react';
import { ChatBotMeta } from 'utils/bot';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Switch } from 'components/ui/switch';
import { Input } from 'components/ui/input';
import { useAlerts } from 'providers/AlertProvider';
import { isPrimaryProduct } from 'utils/domain';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import { alerts } from 'utils/alert';

interface AuthenticationProps extends React.HTMLProps<HTMLDivElement> {
  id: string;
  disabled: boolean;
  isUrlError: boolean;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const Authentication = React.forwardRef<HTMLDivElement, AuthenticationProps>(
  ({ id, disabled, isUrlError, chatInterface, setChatInterface }, ref) => {
    const { addAlert } = useAlerts();

    return (
      <Card id={id} ref={ref} className="relative">
        <CardHeader className="pb-6">
          <CardTitle className="flex items-center">
            <KeyRound strokeWidth={1.75} className="w-6 h-6 mr-2" />
            User Identity Verification
          </CardTitle>
          <CardDescription>
            Configure and manage the authentication process, ensuring that only authorized individuals gain
            access to your system.
          </CardDescription>
        </CardHeader>
        <CardContent className="max-w-[1000px] flex flex-col gap-6 overflow-hidden">
          <div className="flex flex-col gap-2">
            <Label className="text-md font-medium leading-none tracking-tight" htmlFor="user-authentication">
              Enable User Identity
            </Label>
            {isPrimaryProduct && (
              <p className="text-muted-foreground text-sm">
                <a
                  className="font-bold text-secondary hover:underline"
                  href="https://guide.gpt-trainer.com/user-identity"
                  target="_blank"
                  rel="noreferrer"
                >
                  Detailed Intructions
                </a>{' '}
                on how integrate authentication feature in your chatbot.
              </p>
            )}
            <Switch
              disabled={disabled}
              id="user-authentication"
              checked={chatInterface?.webhook_auth?.enabled || false}
              onCheckedChange={(show) => {
                let switchVisibility = false;
                if (show && chatInterface?.visibility !== 'hybrid') {
                  switchVisibility = true;
                  addAlert({
                    severity: 'warning',
                    message: alerts.UNAVAILABLE_MODE,
                    timeout: 10000,
                  });
                }

                setChatInterface({
                  ...chatInterface,
                  ...(switchVisibility && {
                    visibility: 'hybrid',
                  }),
                  webhook_auth: {
                    ...chatInterface?.webhook_auth,
                    enabled: show,
                  },
                });
              }}
            />
          </div>
          {chatInterface?.webhook_auth?.enabled && (
            <>
              <div className="flex flex-col gap-2">
                <Label className="text-md font-semibold leading-none tracking-tight flex items-center">
                  API Endpoint
                </Label>
                <p className="text-sm text-muted-foreground">
                  We will send tokens for validation to this URL through a{' '}
                  <strong className="text-secondary">POST</strong> request.
                </p>
                <div>
                  <Input
                    type="text"
                    autoComplete="off"
                    disabled={disabled}
                    value={chatInterface?.webhook_auth?.url || ''}
                    placeholder="https://my-custom-site.com/webhook/"
                    onChange={(e) => {
                      setChatInterface({
                        ...chatInterface,
                        webhook_auth: {
                          ...chatInterface.webhook_auth,
                          url: e.target.value,
                        },
                      });
                    }}
                  />
                  {isUrlError && chatInterface?.webhook_auth?.url && (
                    <p className="text-xs text-destructive ml-1 mt-1">Invalid API.</p>
                  )}
                  {!chatInterface?.webhook_auth?.url && (
                    <p className="text-xs text-destructive ml-1 mt-1">API is required.</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <Label className="text-md font-medium leading-none tracking-tight" htmlFor="save-data">
                  Save Data on Success
                </Label>
                <p className="text-muted-foreground text-sm">
                  Ensures that all data received during a successful authentication process is securely
                  stored. This data becomes accessible for you at any time in <strong>Chats</strong> tab.
                </p>
                <Switch
                  disabled={disabled}
                  id="save-data"
                  checked={chatInterface?.webhook_auth?.save_response || false}
                  onCheckedChange={(save) => {
                    setChatInterface({
                      ...chatInterface,
                      webhook_auth: {
                        ...chatInterface?.webhook_auth,
                        save_response: save,
                      },
                    });
                  }}
                />
              </div>
              <div className="grid w-full items-center gap-2">
                <Label
                  className="text-md font-medium leading-none tracking-tight"
                  htmlFor="authentication-error-message"
                >
                  Error Message
                </Label>
                <p className="text-muted-foreground text-sm">
                  Personalized error message here that will be displayed users in case of invalid
                  authorization.
                </p>
                <Input
                  type="text"
                  id="authentication-error-message"
                  autoComplete="off"
                  placeholder="Authentication failed. Invalid user."
                  disabled={disabled}
                  value={chatInterface?.webhook_auth?.error_message || ''}
                  onChange={(e) => {
                    setChatInterface({
                      ...chatInterface,
                      webhook_auth: {
                        ...chatInterface.webhook_auth,
                        error_message: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </>
          )}
        </CardContent>
        {disabled && (
          <UpgradeRequired
            className="absolute"
            heading=" Upgrade to Unlock User Identity Verification Feature"
          >
            Upgrade your plan to unlock the User Identity Verification Feature. This will enable secure and
            reliable verification of your users&apos; identities.
          </UpgradeRequired>
        )}
      </Card>
    );
  },
);

export default Authentication;
