import React, { Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';

const ConfirmSourceDeletion: React.FC<{
  show: boolean;
  close: () => void;
}> = ({ show, close }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full overflow-hidden h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all max-h-full overflow-auto">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  New Embedding Model Migration
                </DialogTitle>
                <div className="mt-2 flex flex-col gap-2 font-normal text-muted-foreground text-sm">
                  <p>
                    We are re-embedding all the training data for all our users right now as part of the
                    planned embedding model migration. Due to OpenAI rate limits, we can only proceed at a
                    certain pace.
                  </p>
                  <p>
                    For stability purposes, we disabled new training data from being processed and queued them
                    to the end of the migration. Training based on new data will be resumed after migration is
                    completed. We estimate it will take until 9pm-11pm EST, 3/3/2024 before everything is good
                    to go.
                  </p>
                  <p>
                    We will be using the latest and largest embedding model from OpenAI, so RAG efficiency
                    should improve after the upgrade.
                  </p>
                  <strong>Sorry for the inconvenience! </strong>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={close}>Got it</Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmSourceDeletion;
