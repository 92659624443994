import React, { Fragment, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { useQueryClient } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import integrationsService from 'api/integrations';
import { useAlerts } from 'providers/AlertProvider';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { alerts } from 'utils/alert';
import { Chatbot } from 'models/api/response.types';

const ConfirmHubspotDeleteionDialog: React.FC<{
  open: boolean;
  close: () => void;
}> = ({ open, close }) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [disconnecting, setDisconnecting] = useState<boolean>(false);
  const integrationDataQueryKey = ['hubspot-integration', chatbot.uuid];

  const disconnectIntegration = () => {
    setDisconnecting(true);
    integrationsService
      .disconnectHubspotIntegration(chatbot.uuid)
      .then(() => {
        queryClient.setQueryData(integrationDataQueryKey, null);
        close();
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      })
      .finally(() => {
        setDisconnecting(false);
      });
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Disconnect HubSpot Integration
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you want to disconnect your HubSpot integration? Please confirm your decision
                    to proceed with the disconnection.
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={close}>
                    Cancel
                  </Button>
                  <Button disabled={disconnecting} variant="destructive" onClick={disconnectIntegration}>
                    {disconnecting && <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />}
                    Disconnect
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmHubspotDeleteionDialog;
