/* eslint-disable import/prefer-default-export */
import { DataSource } from 'models/api/response.types';
import { Collaborator, SortCollabColumn } from 'pages/Chatbot/Collaborators';
import { DataRow, SortSource } from './sources';

export const sortDataTableDocuments = (
  data: DataRow[] | DataSource[],
  column: SortSource,
  order: 'asc' | 'desc',
) => {
  const sortOrder = order === 'asc' ? 1 : -1;
  if (column === 'file_name') {
    const sortedSources = [...data].sort((a, b) => {
      if (a.type !== b.type) {
        return a.type.localeCompare(b.type) * sortOrder;
      }
      if (a.type === 'url') {
        const metaA = JSON.parse(a.meta_json);
        const metaB = JSON.parse(b.meta_json);
        return metaA.url.localeCompare(metaB.url) * sortOrder;
      }
      if (a.type === 'qa') {
        const metaA = JSON.parse(a.meta_json);
        const metaB = JSON.parse(b.meta_json);
        return metaA.question.localeCompare(metaB.question) * sortOrder;
      }
      return a.file_name.localeCompare(b.file_name) * sortOrder;
    });
    return sortedSources;
  }

  if (column === 'title') {
    return data.slice().sort((a, b) => b.title.localeCompare(a.title) * sortOrder);
  }

  if (column === 'link') {
    return data.slice().sort((a, b) => {
      const aMeta = JSON.parse(a.meta_json);
      const bMeta = JSON.parse(b.meta_json);
      return (
        (bMeta?.reference_source_link || '').localeCompare(aMeta?.reference_source_link || '') * sortOrder
      );
    });
  }

  if (column === 'size') {
    return data.slice().sort((a, b) => (a.file_size - b.file_size) * sortOrder);
  }

  if (column === 'type') {
    return data.slice().sort((a, b) => b.type.localeCompare(a.type) * sortOrder);
  }

  if (column === 'tokens') {
    return data.slice().sort((a, b) => (a.tokens - b.tokens) * sortOrder);
  }

  if (column === 'status') {
    return data.slice().sort((a, b) => b.status.localeCompare(a.status) * sortOrder);
  }

  if (column === 'last_trained_on') {
    return data.slice().sort((a, b) => b.modified_at.localeCompare(a.modified_at) * sortOrder);
  }

  // default sort by date added
  return data.slice().sort((a, b) => b.created_at.localeCompare(a.created_at) * sortOrder);
};

export const sortDataTableCollabs = (
  data: Collaborator[],
  column: SortCollabColumn,
  order: 'asc' | 'desc',
) => {
  const sortOrder = order === 'asc' ? 1 : -1;

  if (column === 'email') {
    return data.slice().sort((a, b) => b.email.localeCompare(a.email) * sortOrder);
  }

  return data.slice().sort((a, b) => b.status.localeCompare(a.status) * sortOrder);
};
