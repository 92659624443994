/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { cn } from 'utils/cn';
import {
  ChatBotMeta,
  IChatbotMessageColors,
  IUserMessageColors,
  WidgetSize,
  chatSizeVariants,
  defaultChatDarkTextColor,
} from 'utils/bot';
import remarkMath from 'remark-math';
import rehypeMathJaxSvg from 'rehype-mathjax/svg';
import { messageAnimation, preproccessLatex, remarkMathOptions } from 'utils/chatMessage';
import { QueryToStream } from 'models/api/chat.types';
import WidgetLoadingOverlay from 'components/Widget/WidgetLoadingOverlay';
import Strong from '../MarkdownElements/Strong';
import Link from '../MarkdownElements/Link';
import Image from '../MarkdownElements/Image';
import Pre from '../MarkdownElements/Pre';
import Code from '../MarkdownElements/Code';
import ImageGeneratorLoader from './ImageGeneratorLoader';
import ChatAvatar from '../ChatMessage/ChatAvatar';

const ChatStreamingResponse: React.FC<{
  chatbotMeta: ChatBotMeta;
  queryToStream: QueryToStream;
  streamingResponse: string;
  size: WidgetSize;
  userMessageColors?: IUserMessageColors;
  chatBotMessageColors?: IChatbotMessageColors;
}> = ({
  queryToStream,
  streamingResponse,
  chatbotMeta,
  size,
  // part of Bot Widget
  userMessageColors,
  chatBotMessageColors,
}) => {
  const isGeneratingImage = useMemo(() => {
    return streamingResponse.includes('chatbot_generating_image');
  }, [streamingResponse]);

  const answer = useMemo(() => {
    // citations from sources [1.2]
    const citationRegex = /\[(\d+\.[0-9]+)\]/g;
    // citations from sources that disabled [N1.2]
    const invalidCitationRegex = /\[N\d+\.\d+\]/g;
    const functionCallingRegex = /gpt_trainer_ai_function_x_call\s*/g;
    let updatedMessage = streamingResponse.replace(functionCallingRegex, '');
    updatedMessage = updatedMessage.replace(invalidCitationRegex, '');
    if (!chatbotMeta.show_citations) {
      updatedMessage = updatedMessage.replace(citationRegex, '').replace(/\s+\./g, '.');
    } else {
      // Regular expression to find citations like [2.0], [2.1], etc.
      updatedMessage.replace(citationRegex, '[$1](/chat_message/citation-data/streaming)');
    }
    // convert all math regex patterns
    updatedMessage = preproccessLatex(updatedMessage);

    return updatedMessage;
  }, [streamingResponse]);

  const citationsExist = useMemo(() => {
    return streamingResponse !== answer;
  }, [streamingResponse, answer]);

  const showPlaceholder = useMemo(() => {
    return (!!chatbotMeta?.access_source_data && citationsExist) || !!chatbotMeta?.response_actions_enabled;
  }, [citationsExist]);

  const showAvatar = useMemo(() => {
    return chatbotMeta?.message_avatar?.enabled;
  }, [chatbotMeta]);

  return (
    <div id="streaming-container" className={isGeneratingImage ? 'image-generation-streaming' : ''}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={messageAnimation}
        className={cn(
          'w-fit max-w-[95%] mb-4 ml-auto rounded-lg rounded-br-none transition-colors break-words',
          chatSizeVariants.chat.message[size],
        )}
        style={
          userMessageColors
            ? {
                background: userMessageColors.backgroundColor,
                color: userMessageColors.textColor,
              }
            : {
                background: '#367DF1',
                color: '#fff',
              }
        }
      >
        <ReactMarkdown
          className={cn('prose leading-normal text-inherit', size)}
          allowedElements={['p', 'span', 'li', 'ul', 'ol']}
        >
          {queryToStream.value}
        </ReactMarkdown>
      </motion.div>
      <div className="flex items-start gap-1">
        {showAvatar && (
          <motion.div initial="hidden" animate="visible" variants={messageAnimation} custom={0.2}>
            <ChatAvatar
              imgUrlPath={chatbotMeta?.message_avatar?.logo}
              userMessageColors={userMessageColors}
              size={size}
            />
          </motion.div>
        )}
        <div className="flex-1 max-w-[90%]">
          {isGeneratingImage ? (
            <ImageGeneratorLoader chatBotMessageColors={chatBotMessageColors} size={size} />
          ) : (
            <motion.div
              custom={0.4}
              initial="hidden"
              animate="visible"
              variants={messageAnimation}
              className={cn(
                'w-fit max-w-full rounded-lg rounded-tl-none break-words',
                chatSizeVariants.chat.message[size],
              )}
              style={
                chatBotMessageColors
                  ? {
                      background: chatBotMessageColors.backgroundColor,
                      borderColor: chatBotMessageColors.textColor,
                      color: chatBotMessageColors.textColor,
                    }
                  : {
                      background: '#F2F5F9',
                      color: defaultChatDarkTextColor,
                      borderColor: defaultChatDarkTextColor,
                    }
              }
            >
              {answer.length > 0 ? (
                <ReactMarkdown
                  remarkPlugins={[remarkGfm, [remarkMath, remarkMathOptions]]}
                  rehypePlugins={[rehypeMathJaxSvg]}
                  className={cn('prose leading-normal text-inherit border-inherit', size)}
                  components={{
                    a: (e) => <Link size={size} data={e} chatBotMessageColors={chatBotMessageColors} />,
                    strong: (e) => <Strong data={e} />,
                    pre: (e) => <Pre data={e} chatBotMessageColors={chatBotMessageColors} />,
                    code: (e) => <Code data={e} />,
                    img: (e) => <Image size={size} data={e} />,
                  }}
                >
                  {answer}
                </ReactMarkdown>
              ) : (
                <WidgetLoadingOverlay
                  bubbleColor={chatBotMessageColors ? chatBotMessageColors.loadingColor : undefined}
                  size={size}
                  className={cn(
                    'w-fit max-w-[95%] mr-auto gap-2',
                    chatSizeVariants.chat.streamingResponseHeight[size],
                  )}
                />
              )}
            </motion.div>
          )}
          {showPlaceholder && (
            <div className="ml-4 mt-1 opacity-0 text-[15px] flex items-center outline-none transition-transform select-none">
              placeholder
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatStreamingResponse;
