/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Badge } from 'components/ui/badge';
import { cn } from 'utils/cn';
import { Pencil, X } from 'lucide-react';
import { Separator } from 'components/ui/separator';
import { AIAgentFunction } from 'models/api/response.types';

const FunctionCard: React.FC<{
  functionData: AIAgentFunction;
  editFunction: () => void;
  deleteFunction: () => void;
}> = ({ functionData, editFunction, deleteFunction }) => {
  return (
    <div className={cn('border rounded-md p-4', !functionData.active ? 'opacity-50' : '')}>
      <div className="flex items-center gap-4">
        <Badge
          variant="outline"
          className={cn(
            'font-semibold p-1 px-4 rounded-md w-[67px] flex justify-center',
            functionData.method === 'GET'
              ? 'bg-green-100 text-green-600 border-green-600'
              : ' bg-blue-100 text-blue-600 border-blue-500',
          )}
        >
          {functionData.method}
        </Badge>
        <Separator className="h-[16px]" orientation="vertical" />
        <p className="text-sm font-medium line-clamp-1">{functionData.name}</p>
        {functionData.active && (
          <div className="ml-auto flex items-center gap-4">
            <button type="button" onClick={editFunction}>
              <Pencil strokeWidth={1.75} className="w-4 min-w-[16px] h-4 cursor-pointer" />
            </button>
            <button type="button" onClick={deleteFunction}>
              <X
                strokeWidth={1.75}
                className="w-4 min-w-[16px] h-4 transition-all hover:text-destructive cursor-pointer"
              />
            </button>
          </div>
        )}
      </div>
      <div className="flex items-center gap-4 mt-2">
        <Badge variant="outline" className="font-semibold p-1 px-4 rounded-md w-[67px] flex justify-center">
          URL
        </Badge>
        <Separator className="h-[16px]" orientation="vertical" />
        <p className="text-sm text-muted-foreground line-clamp-1">{functionData.external_url}</p>
      </div>
      <p className="text-sm mt-2 text-muted-foreground line-clamp-3">{functionData.description}</p>
    </div>
  );
};

export default FunctionCard;
