import React, { ReactNode, useMemo } from 'react';
import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';
import { AIAgentEditData, ImageGeneratorSettings, ImageGeneratorSize } from 'models/api/response.types';
import { ImageGegeratorModel, ImageGeneratorModels, openAiModelCharacterLimit } from 'utils/agent';
import { cn } from 'utils/cn';
import { Toggle } from 'components/ui/toggle';
import { Camera, Palette, Square, RectangleHorizontal, RectangleVertical } from 'lucide-react';

export const ImageModelIcons: { [key in ImageGegeratorModel]: (className: string) => ReactNode } = {
  'urn:air:sd1:checkpoint:civitai:4384@128713': (className?: string) => (
    <Camera className={className} strokeWidth={1.75} />
  ),
  'urn:air:sd1:checkpoint:civitai:47800@267897': (className?: string) => (
    <Palette className={className} strokeWidth={1.75} />
  ),
};

export const ImageSizeIcons: { [key in ImageGeneratorSize]: (className: string) => ReactNode } = {
  '512x512': (className?: string) => <Square className={className} />,
  '512x256': (className?: string) => <RectangleHorizontal className={className} strokeWidth={1.75} />,
  '256x512': (className?: string) => <RectangleVertical className={className} strokeWidth={1.75} />,
  '704x384': (className?: string) => (
    <RectangleHorizontal className={cn(className, 'w-[20px] h-[20px] min-w-[20px]')} strokeWidth={1.75} />
  ),
  '384x704': (className?: string) => (
    <RectangleVertical className={cn(className, 'w-[20px] h-[20px] min-w-[20px]')} strokeWidth={1.75} />
  ),
  '640x448': (className?: string) => (
    <RectangleHorizontal className={cn(className, 'w-[18px] h-[18px] min-w-[18px]')} strokeWidth={1.75} />
  ),
  '448x640': (className?: string) => (
    <RectangleVertical className={cn(className, 'w-[18px] h-[18px] min-w-[18px]')} strokeWidth={1.75} />
  ),
};

const imageModels: ImageGegeratorModel[] = [
  'urn:air:sd1:checkpoint:civitai:4384@128713',
  // 'urn:air:sd1:checkpoint:civitai:47800@267897',
];

const imageSizes: ImageGeneratorSize[] = [
  '512x512',
  '512x256',
  '256x512',
  '640x448',
  '448x640',
  '704x384',
  '384x704',
];

export const ImageGeneratorOptions: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ data, setData }) => {
  // image generator prompt limit the same as default gpt-3.5-turbo model
  const promptCharacterLimit = useMemo(() => {
    return openAiModelCharacterLimit['gpt-3.5-turbo'];
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex items-end">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="prompt">
            Prompt
          </Label>
        </div>
        <p className="text-sm text-muted-foreground">
          Give detailed instructions to the AI on how the image should look. Specify the purpose and style of
          the image, whether it&apos;s a logo for a business, a video game icon, or any other type of visual.
          Your precise input will help generate an image that perfectly matches your vision.
        </p>
        <Textarea
          id="prompt"
          className="min-h-[80px]"
          value={data.prompt}
          rows={2}
          maxLength={promptCharacterLimit}
          placeholder="Enter the prompt"
          onChange={(e) => {
            setData({
              ...data,
              prompt: e.target.value,
            });
          }}
        />
        <p
          className={cn(
            'text-xs text-muted-foreground ml-1',
            data.prompt.length === promptCharacterLimit ? 'text-destructive' : 'text-muted-foreground',
          )}
        >
          Characters: {data.prompt.length} / {promptCharacterLimit}
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <Label className="text-md font-medium leading-none tracking-tight" htmlFor="image-size">
          Model
        </Label>
        <p className="text-sm text-muted-foreground">
          Choose the image model that best suits your needs. The Photorealistic model generates highly
          detailed and lifelike images, perfect for realistic renderings. The Illustration model produces
          stylized and artistic images, ideal for creative and unique artwork. Select the model to create the
          desired visual effect for your project.
        </p>
        <div id="image-generator-model" className="flex items-center gap-2 flex-wrap">
          {imageModels.map((model) => (
            <Toggle
              key={model}
              aria-label={ImageGeneratorModels[model]}
              pressed={data.image_generator_settings?.image_model === model}
              className="whitespace-nowrap"
              onPressedChange={() => {
                setData({
                  ...data,
                  image_generator_settings: {
                    ...(data?.image_generator_settings as ImageGeneratorSettings),
                    image_model: model,
                  },
                });
              }}
            >
              {ImageModelIcons[model]('w-4 h-4 min-w-[16px] mr-2')}
              {ImageGeneratorModels[model]}
            </Toggle>
          ))}
        </div>
        <p className="ml-1 text-xs text-warning">15 feature credits(s) per image</p>
      </div>
      <div className="flex flex-col gap-2 mb-8">
        <Label className="text-md font-medium leading-none tracking-tight" htmlFor="image-size">
          Image Size
        </Label>
        <p className="text-sm text-muted-foreground">
          Choose the image size to get images that best fit your requirements. Various dimensions are
          available to suit different aspect ratios and formats.
        </p>
        <div id="image-size" className="flex items-center gap-2 flex-wrap">
          {imageSizes.map((size) => (
            <Toggle
              key={size}
              aria-label={size}
              pressed={data.image_generator_settings?.image_size === size}
              className="whitespace-nowrap"
              onPressedChange={() => {
                setData({
                  ...data,
                  image_generator_settings: {
                    ...(data?.image_generator_settings as ImageGeneratorSettings),
                    image_size: size,
                  },
                });
              }}
            >
              {ImageSizeIcons[size]('w-4 h-4 min-w-[16px] mr-2')}
              {size}
            </Toggle>
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageGeneratorOptions;
