import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { useMutation } from '@tanstack/react-query';
import sourceService from 'api/source';
import { Loader2 } from 'lucide-react';
import useDataSources from 'hooks/useDataSources';

const ConfirmSourceDeletion: React.FC<{
  sourcesToDelete: string[];
  close: (success?: boolean) => void;
}> = ({ sourcesToDelete, close }) => {
  const { removeSourcesById } = useDataSources();
  const [show, setShow] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (sourcesToDelete.length > 0) {
      setShow(true);
    }
  }, [sourcesToDelete]);

  const hide = (success?: boolean) => {
    setShow(false);
    setTimeout(() => {
      close(success);
    }, 300);
  };

  const deleteDataSourceMutation = useMutation({
    mutationFn: (uuids: string[]) => sourceService.deleteDataSources(uuids),
    onSuccess: () => {
      removeSourcesById(sourcesToDelete);
      hide(true);
    },
    onSettled: () => {
      setDeleting(false);
    },
  });

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => hide()}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete {sourcesToDelete.length} {sourcesToDelete.length > 1 ? 'sources' : 'source'}
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you want to continue with the deletion? All associated training data will be
                    permanently removed from the chatbot. Once deleted, this information cannot be recovered.
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={() => hide()}>
                    Cancel
                  </Button>
                  <Button
                    disabled={deleting}
                    variant="destructive"
                    onClick={() => {
                      setDeleting(true);
                      deleteDataSourceMutation.mutate(sourcesToDelete);
                    }}
                  >
                    {deleting && <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />}
                    Delete
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmSourceDeletion;
