/* eslint-disable jsx-a11y/no-autofocus */
import { useAlerts } from 'providers/AlertProvider';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { cn } from 'utils/cn';
import { Trash } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Skeleton } from 'components/ui/skeleton';
import { alerts } from 'utils/alert';

const useOnClickAway = <T extends HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

const SourceDataCell: React.FC<{
  canDeleteRow?: boolean;
  deleteRow?: () => void;
  canDeleteColumn?: boolean;
  deleteColumn?: () => void;
  allColumnHeaders?: string[];
  allRowHeaders?: string[];
  transferingColumns?: boolean;
  isColumnHeader?: boolean;
  isColumnDescription?: boolean;
  isRowDescription?: boolean;
  isRowHeader?: boolean;
  isInactive?: boolean;
  value: string;
  setValue: (newValue: string) => void;
}> = ({
  canDeleteColumn,
  deleteColumn,
  canDeleteRow,
  deleteRow,
  allColumnHeaders,
  transferingColumns,
  allRowHeaders,
  isColumnDescription,
  isRowDescription,
  isColumnHeader,
  isRowHeader,
  isInactive,
  value,
  setValue,
}) => {
  const { addAlert } = useAlerts();
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const isHeader = isColumnHeader || isRowHeader;
  const isDescription = isColumnDescription || isRowDescription;
  const ref = useRef<HTMLDivElement>(null);

  useOnClickAway(ref, () => {
    setIsUpdateMode(false);
  });

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!isUpdateMode && value !== currentValue && typeof currentValue === 'string') {
      if (isHeader && currentValue.length === 0) {
        setCurrentValue(value);
        addAlert({
          severity: 'error',
          message: alerts.SOURCE_CELL_ERROR,
          timeout: 5000,
        });
      } else if (isColumnHeader && allColumnHeaders && allColumnHeaders.includes(currentValue)) {
        setCurrentValue(value);
        addAlert({
          severity: 'error',
          message: alerts.SOURCE_COLUMN_EXISTS,
          timeout: 5000,
        });
      } else if (isRowHeader && allRowHeaders && allRowHeaders.includes(currentValue)) {
        setCurrentValue(value);
        addAlert({
          severity: 'error',
          message: alerts.SOURCE_ROW_HEADER_EXISTS,
          timeout: 5000,
        });
      } else {
        setValue(currentValue);
      }
    }
  }, [isUpdateMode]);

  return (
    <div
      ref={ref}
      className={cn(
        'group min-w-[240px] min-h-[40px] relative w-[240px] h-[40px] border rounded-md flex items-center text-xs overflow-hidden px-2',
        (isHeader || isDescription) && 'bg-muted',
        isDescription && !currentValue && 'opacity-50',
        isUpdateMode && 'border-secondary bg-background',
      )}
      onClick={() => {
        if (!isUpdateMode && !isInactive) {
          setIsUpdateMode(true);
        }
      }}
    >
      {isUpdateMode ? (
        <input
          className="h-full w-full outline-none"
          type="text"
          value={currentValue || ''}
          autoFocus
          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
            event.target.select();
          }}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              setIsUpdateMode(false);
            }
          }}
        />
      ) : isDescription ? (
        <p className={cn('m-0 whitespace-nowrap overflow-hidden text-ellipsis', !currentValue && 'italic')}>
          {!currentValue
            ? isColumnDescription
              ? 'Add column description'
              : 'Add row description'
            : currentValue}
        </p>
      ) : (
        <p className="m-0 whitespace-nowrap overflow-hidden text-ellipsis">{currentValue}</p>
      )}
      {(canDeleteColumn || canDeleteRow) && !isUpdateMode && !isInactive && (
        <Button
          variant="outline"
          size="icon"
          className="outline-none absolute bg-background text-destructive top-2 right-1 rounded-md hidden group-hover:flex w-6 h-6 hover:bg-destructive hover:text-background"
          onClick={(e) => {
            e.stopPropagation();
            if (canDeleteColumn && deleteColumn) {
              deleteColumn();
            }
            if (canDeleteRow && deleteRow) {
              deleteRow();
            }
          }}
        >
          <Trash strokeWidth={1.75} className="w-4 h-4" />
        </Button>
      )}
      {transferingColumns && <Skeleton className="absolute w-[240px] h-[40px] rounded-md" />}
    </div>
  );
};

export default SourceDataCell;
