/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import {
  ChatBotMeta,
  IChatbotMessageColors,
  IUserMessageColors,
  WidgetSize,
  chatSizeVariants,
  defaultChatDarkTextColor,
} from 'utils/bot';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { cn } from 'utils/cn';
import Pre from './MarkdownElements/Pre';
import Strong from './MarkdownElements/Strong';
import Code from './MarkdownElements/Code';
import Link from './MarkdownElements/Link';
import ChatAvatar from './ChatMessage/ChatAvatar';

const ChatInitialMessages: React.FC<{
  chatbotMeta: ChatBotMeta;
  initialMessages: string[];
  size: WidgetSize;
  chatBotMessageColors?: IChatbotMessageColors;
  userMessageColors?: IUserMessageColors;
}> = ({
  chatbotMeta,
  initialMessages,
  size,
  // part of Bot Widget
  chatBotMessageColors,
  userMessageColors,
}) => {
  const showAvatar = useMemo(() => {
    return chatbotMeta?.message_avatar?.enabled;
  }, [chatbotMeta]);

  return (
    <div className="flex items-start gap-1">
      {showAvatar && (
        <ChatAvatar
          imgUrlPath={chatbotMeta?.message_avatar?.logo}
          size={size}
          userMessageColors={userMessageColors}
        />
      )}
      <div className={cn('flex-1  max-w-[90%] flex flex-col', chatSizeVariants.chat.initialMessageGap[size])}>
        {initialMessages.map((message, index) => (
          <div
            key={index}
            className={cn(
              'w-fit max-w-full mr-auto rounded-lg relative group transition-all break-words',
              chatSizeVariants.chat.message[size],
              index === 0 ? 'rounded-tl-none' : '',
            )}
            style={
              chatBotMessageColors
                ? {
                    background: chatBotMessageColors.backgroundColor,
                    color: chatBotMessageColors.textColor,
                    lineHeight: 1.5,
                  }
                : {
                    background: '#F2F5F9',
                    color: defaultChatDarkTextColor,
                    lineHeight: 1.5,
                  }
            }
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              className={cn('prose leading-normal text-inherit border-inherit', size)}
              disallowedElements={['table', 'img']}
              components={{
                a: (e) => <Link data={e} />,
                pre: (e) => <Pre data={e} chatBotMessageColors={chatBotMessageColors} />,
                strong: (e) => <Strong data={e} />,
                code: (e) => <Code data={e} />,
              }}
            >
              {message}
            </ReactMarkdown>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatInitialMessages;
