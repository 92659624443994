import axios from 'axios';
import {
  InstagramIntegration,
  InstagramIntegrationCreateBody,
  MessengerIntegrationCreateBody,
  WhatsAppIntegration,
  WhatsAppIntegrationCreateBody,
  MessengerIntegration,
  HubspotIntegrationCreateBody,
  HubspotIntegration,
  ZendeskIntegration,
  ZendeskIntegrationCreateBody,
} from 'models/api/response.types';

const createWhatsAppIntegration = (body: WhatsAppIntegrationCreateBody) =>
  axios.post<WhatsAppIntegration>(`/api/whatsapp/create`, body).then((res) => res.data);

const getWhatsAppIntegration = (uuid: string) =>
  axios.get<WhatsAppIntegration>(`/api/chatbot/${uuid}/whatsapp`).then((res) => res.data);

// required to connect phone to Facebook's Cloud API.
const getWhatsAppIntegrationRegistration = (uuid: string) =>
  axios.get<any>(`/api/chatbot/${uuid}/whatsapp/register`).then((res) => res.data);

const registerWhatsAppIntegration = (uuid: string, pin: string) =>
  axios.post<any>(`/api/chatbot/${uuid}/whatsapp/register`, { pin }).then((res) => res.data);

const disconnectWhatsAppIntegration = (uuid: string) =>
  axios.delete<WhatsAppIntegration>(`/api/chatbot/${uuid}/whatsapp/delete`).then((res) => res.data);

const createInstagramIntegration = (body: InstagramIntegrationCreateBody) =>
  axios.post<InstagramIntegration>(`/api/instagram/create`, body).then((res) => res.data);

const getInstagramIntegration = (uuid: string) =>
  axios.get<InstagramIntegration>(`/api/chatbot/${uuid}/instagram`).then((res) => res.data);

const disconnectInstagramIntegration = (uuid: string) =>
  axios.delete<InstagramIntegration>(`/api/chatbot/${uuid}/instagram/delete`).then((res) => res.data);

const createMessengerIntegration = (body: MessengerIntegrationCreateBody) =>
  axios.post<MessengerIntegration>(`/api/messenger/create`, body).then((res) => res.data);

const getMessengerIntegration = (uuid: string) =>
  axios.get<MessengerIntegration>(`/api/chatbot/${uuid}/messenger`).then((res) => res.data);

const deleteMessengerIntegration = (uuid: string) =>
  axios.delete<MessengerIntegration>(`/api/chatbot/${uuid}/messenger/delete`).then((res) => res.data);

const createHubspotIntegration = (body: HubspotIntegrationCreateBody) =>
  axios.post<HubspotIntegration>(`/api/hubspot/create`, body).then((res) => res.data);

const getHubspotIntegration = (uuid: string) =>
  axios.get<HubspotIntegration>(`/api/chatbot/${uuid}/hubspot`).then((res) => res.data);

const disconnectHubspotIntegration = (uuid: string) =>
  axios.delete<HubspotIntegration>(`/api/chatbot/${uuid}/hubspot/delete`).then((res) => res.data);

const createZendeskIntegration = (body: ZendeskIntegrationCreateBody) =>
  axios.post<ZendeskIntegration>(`/api/zendesk/create`, body).then((res) => res.data);

const getZendeskIntegration = (uuid: string) =>
  axios.get<ZendeskIntegration>(`/api/chatbot/${uuid}/zendesk`).then((res) => res.data);

const deleteZendeskIntegration = (uuid: string) =>
  axios.delete<ZendeskIntegration>(`/api/chatbot/${uuid}/zendesk/delete`).then((res) => res.data);

const integrationsService = {
  createWhatsAppIntegration,
  getWhatsAppIntegration,
  createInstagramIntegration,
  getInstagramIntegration,
  disconnectInstagramIntegration,
  createMessengerIntegration,
  getMessengerIntegration,
  deleteMessengerIntegration,
  getWhatsAppIntegrationRegistration,
  registerWhatsAppIntegration,
  disconnectWhatsAppIntegration,
  getHubspotIntegration,
  disconnectHubspotIntegration,
  createHubspotIntegration,
  createZendeskIntegration,
  getZendeskIntegration,
  deleteZendeskIntegration,
};

export default integrationsService;
