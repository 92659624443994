/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import {
  ChatBotMeta,
  SuggestedPromptType,
  WidgetSize,
  chatSizeVariants,
  defaultChatLightTextColor,
  defaultDarkBorderColor,
  defaultLightBorderColor,
  generateChatBotMessageColors,
  generateChatMainColors,
  generateUserMessageColors,
  poweredByClass,
} from 'utils/bot';
import { cn } from 'utils/cn';
import { Button } from 'components/ui/button';
import { LinkIcon, Mail, MessageSquare, RefreshCcw, X } from 'lucide-react';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import ReactDOM from 'react-dom';
import { Chatbot } from 'models/api/response.types';
import { isChatPreviewOpen, setToggleChatPreview } from 'store/reducers/ui';
import { useSelector, useDispatch } from 'react-redux';
import ChatMessage from './chat/ChatMessage/ChatMessage';
import WidgetForm from './chat/WidgetForm';
import ChatInitialMessages from './chat/ChatInitialMessages';
import WidgetDisclaimer from './chat/WidgetDisclaimer';

// need to pass chatbot as we are using this component
// in template form creation
const ChatAppearancePreview: React.FC<{
  chatbot: Chatbot;
  isPlatformView?: boolean;
  showDisclaimer?: boolean;
  showForm?: boolean;
  chatInterface: ChatBotMeta;
  setShowForm?: (open: boolean) => void;
  setShowDisclaimer?: (open: boolean) => void;
}> = ({
  chatbot,
  isPlatformView,
  showForm,
  setShowForm,
  setShowDisclaimer,
  showDisclaimer,
  chatInterface,
}) => {
  const dispatch = useDispatch();
  const { appTitle, mainWebsite } = useWhitelabelData();
  const { canRemoveGPTMark, canUseCustomWatermark, canSeeProfilePicture } = useSubscriptionInfo();
  const isChatOpen = useSelector(isChatPreviewOpen);
  const [buttonHoverIndex, setButtonHoverIndex] = useState<number | undefined>(undefined);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [inputSizeToUse, setInputSizeToUse] = useState<WidgetSize>(chatInterface.chat_size);

  const isDisabled = useMemo(() => {
    return showDisclaimer || showForm;
  }, [showDisclaimer, showForm]);

  const handleWindowWidthChange = (metaData: ChatBotMeta) => {
    const style =
      window.innerWidth < 640
        ? {
            height: '100%',
            width: '100%',
            zIndex: 999999999,
            bottom: 0,
            borderRadius: 0,
            border: 0,
            ...(metaData?.widget_vertical_align === 'left'
              ? {
                  left: 0,
                }
              : {
                  right: 0,
                }),
          }
        : {
            zIndex: 50,
            bottom: '80px',
            border: `1px solid ${metaData.theme === 'light' ? '#e5e7eb' : '#a3a3a3'}`,
            borderRadius: '0.75rem',
            height: `min(${metaData?.dimensions?.height ?? 800}px, 100% - 104px)`,
            width: `min(${metaData?.dimensions?.width ?? 450}px, 100% - 104px)`,
            ...(metaData?.widget_vertical_align === 'left'
              ? {
                  left: '1rem',
                }
              : {
                  right: '1rem',
                }),
          };
    const iframe = document.getElementById('iframe');
    if (iframe) {
      Object.assign(iframe.style, style);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth < 640 && inputSizeToUse !== 'xl') {
      setInputSizeToUse('xl');
    }
    if (windowWidth > 640 && inputSizeToUse !== chatInterface.chat_size) {
      setInputSizeToUse(chatInterface.chat_size);
    }
  }, [windowWidth, chatInterface.chat_size]);

  useEffect(() => {
    if (chatInterface) {
      handleWindowWidthChange(chatInterface);

      // Event listener to track window resize and apply styles accordingly
      window.addEventListener('resize', () => handleWindowWidthChange(chatInterface));
    }

    return () => {
      window.removeEventListener('resize', () => handleWindowWidthChange(chatInterface));
    };
  }, [chatInterface]);

  const handleMouseInside = (event: any) => {
    const { clientX, clientY } = event;
    const isLeft = chatInterface?.widget_vertical_align === 'left';
    setTooltipPosition({
      x: isLeft ? clientX : clientX - 300,
      y: clientY - 80,
    });
  };

  const handleMouseLeave = () => {
    setTooltipPosition({ x: 0, y: 0 });
  };

  const showWatermark = useMemo(() => {
    return !canRemoveGPTMark || (canUseCustomWatermark && chatInterface?.custom_watermark?.label);
  }, [canRemoveGPTMark, chatInterface?.custom_watermark, canUseCustomWatermark]);

  const useButtonLogo = useMemo(() => {
    const { button } = chatInterface;
    return button?.use_logo && button?.logo;
  }, [chatInterface?.button]);

  const { chatMainColors, userMessageColors, chatBotMessageColors, chatSize } = useMemo(() => {
    return {
      chatMainColors: generateChatMainColors(chatInterface?.theme || 'light'),
      userMessageColors: generateUserMessageColors(chatInterface),
      chatBotMessageColors: generateChatBotMessageColors(chatInterface),
      chatSize: chatInterface?.chat_size || 'md',
    };
  }, [chatInterface]);

  const handleTextareaInput = (event: any) => {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    if (textarea.scrollHeight > chatSizeVariants.input.textAreaScrollHeight[chatSize]) {
      textarea.style.overflow = 'auto';
    } else {
      textarea.style.overflow = 'hidden';
    }
  };

  const generateSuggestedPromptIcon = (type: SuggestedPromptType, styles: string) => {
    if (type === 'email') {
      return <Mail strokeWidth={1.75} className={cn(styles)} />;
    }
    if (type === 'link') {
      return <LinkIcon strokeWidth={1.75} className={cn(styles)} />;
    }
    return <MessageSquare strokeWidth={1.75} className={cn(styles)} />;
  };

  return (
    <>
      <div
        id="iframe"
        className={chatInterface.theme === 'dark' ? 'dark-theme' : ''}
        style={{
          fontFamily: chatInterface?.font_style,
          position: 'fixed',
          boxShadow: 'rgba(150, 150, 150, 0.2) 0px 10px 30px 0px',
          bottom: '80px',
          ...(chatInterface?.widget_vertical_align === 'left'
            ? {
                left: '1rem',
              }
            : {
                right: '1rem',
              }),
          zIndex: 50,
          borderRadius: '0.75rem',
          overflow: 'hidden',
          border: `1px solid ${chatInterface.theme === 'light' ? defaultLightBorderColor : defaultDarkBorderColor}`,
          background: 'transparent',
          display: 'flex',
          transformOrigin: chatInterface?.widget_vertical_align === 'left' ? 'bottom left' : 'bottom right',
          transform: isChatOpen ? 'scale(1)' : 'scale(0)',
          opacity: isChatOpen ? 1 : 0,
          transition:
            'width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s',
        }}
      >
        <div
          className="flex-1 flex flex-col overflow-hidden transition-all rounded-[0.75rem]"
          style={{
            background: chatMainColors.background,
          }}
        >
          <div
            className={cn(
              'border-b-[1px] flex justify-between items-center',
              chatInterface.profile_picture && canSeeProfilePicture
                ? chatSizeVariants.header.withIcon[chatSize]
                : chatSizeVariants.header.regular[chatSize],
            )}
            style={{
              borderColor: chatMainColors.border,
              color: chatMainColors.text,
            }}
          >
            <div className="flex items-center">
              {chatInterface.profile_picture && canSeeProfilePicture && (
                <img
                  className={cn('rounded-full', chatSizeVariants.header.profileIcon[chatSize])}
                  src={`${chatInterface.profile_picture}${!chatInterface.profile_picture.startsWith('data:') ? `?v=${chatbot.modified_at}` : ''}`}
                  alt="Chatbot Logo"
                />
              )}
              <p
                className={cn('font-semibold transition-all', chatSizeVariants.header.profileName[chatSize])}
              >
                {chatInterface.display_name}
              </p>
            </div>
            <Button
              className="ml-auto h-5 shadow-none border-0 hover:bg-transparent hover:text-inherit p-0 outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent transition-all"
              variant="outline"
            >
              <RefreshCcw className={chatSizeVariants.header.buttonIcons[chatSize]} />
            </Button>
            <Button
              className="h-5 shadow-none border-0 hover:bg-transparent hover:text-inherit p-0 outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent"
              variant="outline"
              onClick={() => {
                dispatch(setToggleChatPreview(!isChatOpen));
              }}
            >
              <X className={chatSizeVariants.header.buttonIcons[chatSize]} />
            </Button>
          </div>
          <div
            className={cn('flex-1 overflow-auto flex flex-col', chatSizeVariants.chat.container[chatSize])}
          >
            <ChatInitialMessages
              chatbotMeta={chatInterface}
              userMessageColors={userMessageColors}
              size={chatSize}
              initialMessages={chatInterface.initial_messages}
              chatBotMessageColors={chatBotMessageColors}
            />
            <ChatMessage
              size={chatSize}
              message={{
                uuid: '12312312313',
                response: `Sure, how can I help you ? ${chatInterface?.show_citations ? '[1.1]' : ''}`,
                query: 'Help me to resolve my issue.',
                meta_json: '{}',
                modified_at: '12313',
                created_at: '12313',
                finish_reason: 'stop',
                cite_data_json: `{"1.1": {"data_source_uuid": "8ad7ed3b2a8945a39ea654823a3d2adf-6849d52e5349eb512361c7a076e063e9ac70d166", "title": "Test Source", "text": "This sample response showcase.", "file_name": "${mainWebsite}", "type": "url"}}`,
                error_message: '',
              }}
              chatbotMeta={chatInterface}
              userMessageColors={userMessageColors}
              chatBotMessageColors={chatBotMessageColors}
              chatMainColors={chatMainColors}
            />
          </div>
          {chatInterface?.suggested_prompts?.length > 0 && (
            <div
              className={cn(
                'flex transition-opacity',
                isDisabled && 'opacity-0',
                !chatInterface?.suggested_prompts_view ? 'overflow-x-auto' : '',
                chatInterface?.suggested_prompts_view?.includes('horizontal')
                  ? chatInterface?.suggested_prompts_view === 'horizontal-single'
                    ? 'overflow-x-auto'
                    : 'flex-wrap'
                  : '',
                chatInterface?.suggested_prompts_view?.includes('vertical')
                  ? chatInterface?.suggested_prompts_view === 'vertical-start'
                    ? 'items-start flex-col'
                    : 'items-end flex-col'
                  : '',
                chatSizeVariants.suggestedPrompts.container[chatSize],
              )}
            >
              {chatInterface.suggested_prompts.map((prompt, index) => (
                <Button
                  variant="outline"
                  key={index}
                  disabled={isDisabled}
                  className={cn(
                    'font-normal shadow-none rounded-lg text-left outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent whitespace-nowrap',
                    chatSizeVariants.suggestedPrompts.prompt[chatSize],
                  )}
                  onMouseEnter={() => {
                    setButtonHoverIndex(index);
                  }}
                  onMouseLeave={() => {
                    setButtonHoverIndex(undefined);
                  }}
                  style={{
                    color: chatMainColors.text,
                    borderColor: chatMainColors.border,
                    background:
                      buttonHoverIndex === index
                        ? chatMainColors.hoverBackground
                        : chatMainColors.suggestedPrompt,
                  }}
                >
                  {chatInterface.show_suggested_prompt_icons && (
                    <>
                      {generateSuggestedPromptIcon(
                        prompt.type,
                        chatSizeVariants.suggestedPrompts.icon[chatSize],
                      )}
                    </>
                  )}
                  {prompt.label}
                </Button>
              ))}
            </div>
          )}
          <div
            className={cn(
              'transition-opacity',
              chatSizeVariants.input.container[chatSize],
              showWatermark ? 'pb-2' : '',
              isDisabled && 'opacity-0',
            )}
          >
            <div
              className={cn(
                'relative border-[1px] rounded-lg flex items-center',
                chatSizeVariants.input.secondaryContainer[inputSizeToUse],
              )}
              style={{
                borderColor: chatMainColors.border,
              }}
            >
              <textarea
                disabled={isDisabled}
                id="chat-textfield"
                rows={1}
                className={cn(
                  'border-none w-full p-0 outline-none ring-0 ring-offset-0 focus-visible:ring-offset-0 focus-visible:ring-0 focus-visible:ring-transparent transition-all resize-none overflow-hidden',
                  chatSizeVariants.input.input[inputSizeToUse],
                )}
                style={{
                  color: chatMainColors.text,
                  backgroundColor: chatMainColors.background,
                }}
                onMouseMove={(e) => isPlatformView && handleMouseInside(e)}
                onMouseLeave={() => isPlatformView && handleMouseLeave()}
                onChange={handleTextareaInput}
                placeholder={chatInterface?.textfield_placeholder || ''}
              />
              {tooltipPosition.x > 0 &&
                tooltipPosition.y > 0 &&
                ReactDOM.createPortal(
                  <div
                    className="fixed z-[99999999999] max-w-[350px] rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1"
                    style={{
                      left: tooltipPosition.x,
                      top: tooltipPosition.y,
                    }}
                  >
                    UI preview, not editable. To test the chatbot, please navigate to{' '}
                    <strong>“Preview”</strong> on the left sidebar.
                  </div>,
                  document.body,
                )}
              <Button
                variant="outline"
                disabled={isDisabled}
                className={cn(
                  'absolute shadow-none border-0 hover:bg-transparent p-0 outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent',
                  chatSizeVariants.input.button[chatSize],
                )}
                style={{
                  color: chatMainColors.text,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.75}
                  stroke="currentColor"
                  className="w-full h-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
                <span className="sr-only">Send message</span>
              </Button>
            </div>
          </div>
          {showDisclaimer && (
            <WidgetDisclaimer
              isPreview
              cookieDisclaimerKey="test-key"
              chatbotMeta={chatInterface}
              size={chatSize}
              chatMainColors={chatMainColors}
              userMessageColors={userMessageColors}
              hide={() => {
                if (setShowDisclaimer) {
                  setShowDisclaimer(false);
                }
              }}
            />
          )}
          {showForm && (
            <WidgetForm
              chatMessagesExists
              chatbotMeta={chatInterface}
              size={chatSize}
              chatMainColors={chatMainColors}
              userMessageColors={userMessageColors}
              hide={() => {
                if (setShowForm) {
                  setShowForm(false);
                }
              }}
            />
          )}
          {showWatermark && (
            <button
              type="button"
              disabled={isDisabled}
              className={cn(
                'p-2 border-t transition-all outline-none flex items-center justify-center',
                isDisabled && 'opacity-0',
                chatSizeVariants.poweredByText[chatSize],
                poweredByClass(
                  chatInterface.theme,
                  !canRemoveGPTMark || !!chatInterface?.custom_watermark?.url,
                ),
              )}
              onClick={() => {
                if (!canRemoveGPTMark) {
                  if (mainWebsite) {
                    window.open(mainWebsite, '_blank');
                  }
                } else if (chatInterface?.custom_watermark?.url) {
                  window.open(chatInterface?.custom_watermark?.url, '_blank');
                }
              }}
              style={{
                borderColor: chatMainColors.border,
              }}
            >
              {!canRemoveGPTMark ? (
                <>
                  Powered by&nbsp;<span className="font-semibold">{appTitle}</span>
                </>
              ) : (
                <>
                  {chatInterface?.custom_watermark?.logo && (
                    <img
                      className={cn('rounded-full', chatSizeVariants.poweredByLogo[chatSize])}
                      src={`${chatInterface?.custom_watermark?.logo}?v=${chatbot.modified_at}`}
                      alt="Watermark logo"
                    />
                  )}
                  {chatInterface?.custom_watermark?.label}
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <button
        type="button"
        id="widget-button"
        className="hover:scale-[1.1] transition-transform"
        onClick={() => {
          dispatch(setToggleChatPreview(!isChatOpen));
          const button = document.getElementById('widget-button') as HTMLButtonElement;
          if (button) {
            button.style.transform = 'scale(0.90)';
            setTimeout(() => {
              button.style.transform = '';
            }, 100);
          }
        }}
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '9999px',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px',
          cursor: 'pointer',
          zIndex: 50,
          border: 0,
          transition: 'all 0.2s ease-in-out 0s',
          color: 'white',
          backgroundColor: chatInterface.chat_bubble_color,
          position: 'fixed',
          bottom: '1rem',
          padding: 0,
          ...(chatInterface?.widget_vertical_align === 'left'
            ? {
                left: '1rem',
              }
            : {
                right: '1rem',
              }),
          right: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            borderRadius: '9999px',
            position: 'absolute',
            ...(useButtonLogo && !isChatOpen
              ? {
                  opacity: 1,
                }
              : { opacity: 0 }),
          }}
        >
          <img
            src={`${chatInterface?.button?.logo}?v=${chatbot.modified_at}`}
            alt="open-icon"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '9999px',
            }}
          />
        </div>
        <img
          src={!isChatOpen ? chatInterface.button.icon : '/img/widget-images/down-arrow.svg'}
          alt="close-icon"
          style={{
            width: '25px',
            height: '25px',
            filter: chatInterface.chat_bubble_text_color === defaultChatLightTextColor ? 'invert(100%)' : '',
            display: 'flex',
            opacity: isChatOpen ? 1 : useButtonLogo ? 0 : 1,
          }}
        />
      </button>
    </>
  );
};

export default ChatAppearancePreview;
