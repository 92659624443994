/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactNode, useMemo } from 'react';
import { CheckIcon, Filter } from 'lucide-react';
import { cn } from 'utils/cn';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { Badge } from 'components/ui/badge';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/ui/command';
import tinycolor from 'tinycolor2';

export interface TagFilter {
  name: string;
  color: string;
}

export type Filters = (string | TagFilter)[];

const FilterButton: React.FC<{
  title: string;
  options: { [key: string]: { title: string; icon: () => ReactNode } };
  validFilters: Filters;
  selectedFilters: Filters;
  setSelectedFilters: (filters: Filters) => void;
}> = ({ title, options, validFilters, selectedFilters, setSelectedFilters }) => {
  const { generalFilters, miscellaneousFilters, tagFilters } = useMemo(() => {
    const gFilters: string[] = [];
    const miscFilters: string[] = [];
    const tFilters: TagFilter[] = [];

    validFilters.forEach((filter) => {
      if (typeof filter === 'string') {
        if (filter.startsWith('human_escalation')) {
          miscFilters.push(filter);
        } else {
          gFilters.push(filter);
        }
      } else {
        tFilters.push(filter);
      }
    });

    return {
      miscellaneousFilters: miscFilters,
      generalFilters: gFilters,
      tagFilters: tFilters,
    };
  }, [validFilters]);

  const renderOption = (option: string) => {
    const isSelected = selectedFilters.includes(option);
    return (
      <CommandItem
        key={option}
        onSelect={() => {
          if (!isSelected) {
            setSelectedFilters([...selectedFilters, option]);
          } else {
            const newSelection = selectedFilters.filter((sType) => sType !== option);
            setSelectedFilters(newSelection);
          }
        }}
      >
        <div
          className={cn(
            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
            isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
          )}
        >
          <CheckIcon strokeWidth={1.75} className={cn('h-4 w-4')} />
        </div>
        {options[option].icon()}
        <span>{options[option].title}</span>
      </CommandItem>
    );
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="border-dashed bg-background">
          <Filter strokeWidth={1.75} className="mr-2 h-4 w-4" />
          {title}
          {selectedFilters?.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="outline" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedFilters.length}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedFilters.length > 2 ? (
                  <Badge variant="outline" className="rounded-sm px-1 font-normal">
                    {selectedFilters.length} selected
                  </Badge>
                ) : (
                  selectedFilters.map((option, index) => (
                    <Badge variant="outline" key={index} className="rounded-sm px-1 font-normal">
                      {typeof option === 'string' ? options[option].title : option.name}
                    </Badge>
                  ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {generalFilters.map((option) => renderOption(option))}
              {miscellaneousFilters.length > 0 && <CommandSeparator className="my-2" />}
              {miscellaneousFilters.map((option) => renderOption(option))}
              {tagFilters.length > 0 && <CommandSeparator className="my-2" />}
              {tagFilters.map((option) => {
                const isSelected = selectedFilters.some(
                  (selected: string | TagFilter) =>
                    typeof selected === 'object' && selected?.name === option.name,
                );
                return (
                  <CommandItem
                    key={option.name}
                    onSelect={() => {
                      if (!isSelected) {
                        setSelectedFilters([...selectedFilters, option]);
                      } else {
                        const newSelection = selectedFilters.filter(
                          (sType) =>
                            typeof sType === 'string' ||
                            (typeof sType === 'object' && sType?.name !== option.name),
                        );
                        setSelectedFilters(newSelection);
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon strokeWidth={1.75} className={cn('h-4 w-4')} />
                    </div>
                    <Badge
                      style={{
                        borderColor: option.color,
                        color: tinycolor(option.color).darken(25).toString(),
                        backgroundColor: `${option.color}30`,
                      }}
                      variant="outline"
                      className="text-xs whitespace-nowrap rounded-md"
                    >
                      {option.name}
                    </Badge>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedFilters.length > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => setSelectedFilters([])}
                    className="justify-center text-center hover:bg-accent cursor-pointer"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default FilterButton;
