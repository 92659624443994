import React, { useState } from 'react';
import { AppWindow, Files, Map, MessagesSquare, MoveLeft, TableProperties, Image } from 'lucide-react';
import { isPrimaryProduct } from 'utils/domain';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import GoogleDriveConsent from 'components/Chatbot/Sources.tsx/AddNewSource/GoogleDrive/GoogleDriveConsent';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import { Chatbot } from 'models/api/response.types';
import FileUploaderContainer from './FIlesUploader/FIleUploadContainer';
import QAUploader from './QAUploader';
import SiteMapUploader from './SIteMapUploader/SiteMapUploader';
import URLUploader from './URLUploader/URLUploader';
import YouTubeUploader from './YouTubeUploader';

type TabValue = 'file' | 'table' | 'url' | 'sitemap' | 'qa' | 'google' | 'youtube' | 'image';

const AddNewSource: React.FC<{
  usedData: {
    tokens: number;
    file_size: number;
  };
  dataExists?: boolean;
  close: () => void;
}> = ({ usedData, dataExists, close }) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [tab, setTab] = useState<TabValue>('file');

  const menuOptions = [
    {
      title: 'Files',
      tab: 'file',
      description: 'Upload PDFs, Word and txt files containing unstructured text data.',
      smallIcon: <Files strokeWidth={1.75} className="mr-2 h-4 w-4" />,
      bigIcon: <Files strokeWidth={1.75} className="mt-px h-5 w-5 min-w-[20px]" />,
      action: () => {
        setTab('file');
      },
    },
    {
      title: 'Tables',
      tab: 'table',
      description: 'Upload CSV, XLSX and xls files containing structured text data.',
      smallIcon: <TableProperties strokeWidth={1.75} className="mr-2 h-4 w-4" />,
      bigIcon: <TableProperties strokeWidth={1.75} className="mt-px h-5 w-5 min-w-[20px]" />,
      action: () => {
        setTab('table');
      },
    },
    {
      title: 'Images',
      tab: 'image',
      description: 'Train your chatbot with images to include visuals in its replies.',
      smallIcon: <Image strokeWidth={1.75} className="mr-2 h-4 w-4" />,
      bigIcon: <Image strokeWidth={1.75} className="mt-px h-5 w-5 min-w-[20px]" />,
      action: () => {
        setTab('image');
      },
    },
    {
      title: 'Website URL',
      tab: 'url',
      description: 'Add a URL (or a family of URLs) to be scraped and indexed for training.',
      smallIcon: <AppWindow strokeWidth={1.75} className="mr-2 h-4 w-4" />,
      bigIcon: <AppWindow strokeWidth={1.75} className="mt-px h-5 w-5 min-w-[20px]" />,
      action: () => {
        setTab('url');
      },
    },
    {
      title: 'Website Sitemap',
      tab: 'sitemap',
      description: 'Add a sitemap indicating web pages to be scraped and indexed for training.',
      smallIcon: <Map strokeWidth={1.75} className="mr-2 h-4 w-4" />,
      bigIcon: <Map strokeWidth={1.75} className="mt-px h-5 w-5 min-w-[20px]" />,
      action: () => {
        setTab('sitemap');
      },
    },
    {
      title: 'Q&A',
      tab: 'qa',
      description: "Enter specific question and answer pairs to directly inform the chatbot's responses.",
      smallIcon: <MessagesSquare strokeWidth={1.75} className="mr-2 h-4 w-4" />,
      bigIcon: <MessagesSquare strokeWidth={1.75} className="mt-px h-5 w-5 min-w-[20px]" />,
      action: () => {
        setTab('qa');
      },
    },
    {
      title: 'Google Drive',
      tab: 'google',
      description: "Incorporate files from Google Drive into the chatbot's learning resources.",
      smallIcon: <img src="/img/google_drive_logo.png" alt="Goolge" className="mr-2 h-4 w-4" />,
      bigIcon: <img src="/img/google_drive_logo.png" alt="Goolge" className="mt-px h-5 w-5 min-w-[20px]" />,
      action: () => {
        setTab('google');
      },
    },
    {
      title: 'YouTube',
      tab: 'youtube',
      description: 'Extract text content from YouTube videos to train the chatbot.',
      smallIcon: <img src="/img/youtube_logo.webp" alt="Youtube" className="mr-2 h-4 w-4 object-contain" />,
      bigIcon: (
        <img
          src="/img/youtube_logo.webp"
          alt="Youtube"
          className="mt-px h-5 w-5 min-w-[20px] object-contain"
        />
      ),
      action: () => {
        setTab('youtube');
      },
    },
  ].filter((type) => {
    if (!isPrimaryProduct) {
      return type.title !== 'Google Drive';
    }
    return true;
  });

  return (
    <div className="h-full flex flex-col w-full py-4 overflow-auto items-start">
      {dataExists && (
        <Button className="px-4 sm:px-6 mb-4" variant="link" onClick={close}>
          <MoveLeft strokeWidth={1.75} className="w-4 h-4 mr-2" />
          Back
        </Button>
      )}
      <h1 className="text-lg font-semibold leading-none px-4 sm:px-6">Add Sources</h1>
      <p className="mt-1 text-sm text-muted-foreground px-4 sm:px-6">
        Add new sources to use for training your chatbot
      </p>
      <div className="w-full flex-1 flex flex-col xl:flex-row items-start gap-8 mt-6 overflow-y-auto px-4 sm:px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:flex xl:flex-col gap-2 md:gap-4 w-full xl:w-[350px] 2xl:w-[450px]">
          {menuOptions.map((option) => {
            return (
              <button
                type="button"
                key={option.title}
                className={cn(
                  '-mx-2 flex items-start justify-start cursor-pointer bg-background border space-x-4 rounded-md px-6 py-3 transition-all hover:bg-accent hover:text-accent-foreground w-full',
                  tab === option.tab && 'border-primary',
                )}
                onClick={() => option.action()}
              >
                {option.bigIcon}
                <div className="space-y-1 text-left">
                  <p className="text-sm font-medium leading-none">{option.title}</p>
                  <p className="text-xs text-muted-foreground">{option.description}</p>
                </div>
              </button>
            );
          })}
        </div>
        <div className="flex-1 h-full w-full relative" style={{ position: 'sticky', top: 0 }}>
          {['file', 'table', 'image'].includes(tab) && (
            <FileUploaderContainer chatbot={chatbot} type={tab} usedData={usedData} />
          )}
          {tab === 'qa' && <QAUploader chatbot={chatbot} close={close} />}
          {tab === 'google' && <GoogleDriveConsent />}
          {tab === 'sitemap' && <SiteMapUploader chatbot={chatbot} close={close} />}
          {tab === 'url' && <URLUploader chatbot={chatbot} close={close} />}
          {tab === 'youtube' && <YouTubeUploader chatbot={chatbot} close={close} />}
        </div>
      </div>
    </div>
  );
};

export default AddNewSource;
