import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IChatbotMessageColors, WidgetSize } from 'utils/bot';
import { cn } from 'utils/cn';
import { Image } from 'lucide-react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { scrollIfAtBottom } from 'utils/chat';

const ImageGeneratorLoader: React.FC<{
  size: WidgetSize;
  chatBotMessageColors?: IChatbotMessageColors;
}> = ({ size, chatBotMessageColors }) => {
  const [percentage, setPercentage] = useState(0);
  const treshholdSize = ['sm', 'xs'].includes(size) ? 220 : ['md', 'lg'].includes(size) ? 250 : 300;

  // on appearing scroll only once
  useLayoutEffect(() => {
    scrollIfAtBottom(0, treshholdSize + 50);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev >= 95) {
          clearInterval(interval);
          return 95;
        }
        const increment = Math.random() * 10;
        return Math.min(prev + increment, 95);
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={cn('rounded-lg relative rounded-bl-none flex items-end justify-end')}
      style={{
        backgroundColor: chatBotMessageColors?.backgroundColor,
        width: treshholdSize,
        height: treshholdSize,
      }}
    >
      <Image
        className={cn(
          'transition-none absolute animate-pulse',
          ['sm', 'xs'].includes(size) ? 'h-6 w-6' : ['md', 'lg'].includes(size) ? 'h-8 w-8' : 'h-10 w-10',
        )}
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: chatBotMessageColors?.loadingColor,
        }}
        strokeWidth={1.75}
      />
      <ProgressPrimitive.Root
        className="relative h-1 w-full overflow-hidden rounded-lg rounded-tl-none rounded-tr-none"
        value={percentage}
      >
        <ProgressPrimitive.Indicator
          className="h-full w-full flex-1 transition-all animate-pulse"
          style={{
            transform: `translateX(-${100 - (percentage || 0)}%)`,
            backgroundColor: chatBotMessageColors?.loadingColor,
          }}
        />
      </ProgressPrimitive.Root>
    </div>
  );
};

export default ImageGeneratorLoader;
