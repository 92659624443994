import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Loader2, Plus } from 'lucide-react';
import CollaboratorPermissionBadge, {
  CollaboratorPermission,
  collaboratorsPermissions,
} from 'components/helpers/CollaboratorPermissionBadge';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { cn } from 'utils/cn';
import chatbotService from 'api/chatbot';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import { useQueryClient } from '@tanstack/react-query';
import { Collaborator } from 'pages/Chatbot/Collaborators';
import { alerts } from 'utils/alert';

const CreateEditCollaboratorDialog: React.FC<{
  previousOwnerEmail?: string;
  collaborator?: Collaborator;
  createNew?: boolean;
  close: () => void;
}> = ({ previousOwnerEmail, collaborator, createNew, close }) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [show, setShow] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [selectedPermissions, setSelectedPermissions] = useState<CollaboratorPermission[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const invitationKey = ['invitations', chatbot?.uuid];

  useEffect(() => {
    if (createNew) {
      setSelectedPermissions([]);
      setShow(true);
    }

    if (collaborator) {
      const permissionsToUse: Set<CollaboratorPermission> = new Set();
      collaborator?.permissions?.forEach((permission) => {
        const realPermission = collaboratorsPermissions.find((perm) => perm.type === permission);
        if (realPermission) {
          permissionsToUse.add(realPermission);
        }
      });
      setEmail(collaborator.email);
      setSelectedPermissions(Array.from(permissionsToUse));
      setShow(true);
    }
  }, [createNew, collaborator]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      setEmail('');
      setSelectedPermissions([]);
      close();
    }, 300);
  };

  const dissallowToUpdate = useMemo(() => {
    if (collaborator) {
      return (
        JSON.stringify(collaborator.permissions) ===
        JSON.stringify(selectedPermissions.map((perm) => perm.type))
      );
    }
    return false;
  }, [selectedPermissions, collaborator]);

  const permissionsToShow = useMemo(() => {
    return collaboratorsPermissions.sort((a, b) => {
      const hasSelectedPropertyA = selectedPermissions.some((perm) => perm.type === a.type);
      const hasSelectedPropertyB = selectedPermissions.some((perm) => perm.type === b.type);

      if (!hasSelectedPropertyA && !hasSelectedPropertyB) return 0;
      if (!hasSelectedPropertyA) return -1;
      if (!hasSelectedPropertyB) return 1;

      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;
      return 0;
    });
  }, [selectedPermissions, collaboratorsPermissions]);

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    if (createNew) {
      setLoading(true);
      const permissions = JSON.stringify(selectedPermissions.map((perm) => perm.type));
      chatbotService
        .inviteCollaborator(chatbot.uuid, { email, permissions })
        .then(() => {
          setLoading(false);
          addAlert({
            severity: 'success',
            message: alerts.INVITE_SEND_SUCCESS(email),
            timeout: 5000,
          });
          queryClient.invalidateQueries({ queryKey: invitationKey });
          hide();
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.data?.error?.includes('already invited')) {
            addAlert({
              severity: 'error',
              message: alerts.ALREADY_INVITED_ERROR,
              timeout: 3000,
            });
          } else {
            addAlert({
              severity: 'error',
              message: alerts.SOMETHING_WRONG,
              timeout: 5000,
            });
          }
        });
    } else if (collaborator) {
      const permissions = JSON.stringify(selectedPermissions.map((perm) => perm.type));
      chatbotService
        .updateCollaboratorPermissions(chatbot.uuid, { email, permissions })
        .then(() => {
          setLoading(false);
          addAlert({
            severity: 'success',
            message: alerts.COLLAB_PERMISSION_UPDATE_SUCCESS(collaborator.email),
            timeout: 5000,
          });
          queryClient.invalidateQueries({ queryKey: invitationKey });
          hide();
        })
        .catch(() => {
          setLoading(false);
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
            timeout: 5000,
          });
        });
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  {createNew ? 'Invite Collaborator' : 'Update Collaborator Permissions'}
                </DialogTitle>
                <form onSubmit={handleSubmitForm}>
                  <div className="mt-6 flex flex-col gap-4">
                    <div className="grid gap-2">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        disabled={!!collaborator}
                        autoComplete="email"
                        required
                        autoFocus
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {email === previousOwnerEmail && (
                        <p className="text-xs text-destructive ml-1 mt-1">
                          Cannot send an invite to the previous owner. Please remove the current owner from
                          the collaborator list before inviting.
                        </p>
                      )}
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="permissions">Permissions</Label>
                      <p className="text-muted-foreground text-sm">
                        Easily add permissions using the &quot;Add Permission&quot; button or dismiss by
                        clicking on selected permissions.
                      </p>
                      <div className="flex gap-2 flex-wrap">
                        {selectedPermissions.map((permission, idx) => (
                          <CollaboratorPermissionBadge
                            key={permission.type}
                            permission={permission}
                            showCloseIcon
                            onClick={() => {
                              const newSelectedPermissions = [...selectedPermissions];
                              newSelectedPermissions.splice(idx, 1);
                              setSelectedPermissions(newSelectedPermissions);
                            }}
                          />
                        ))}
                        {collaboratorsPermissions.length > selectedPermissions.length && (
                          <Popover>
                            <PopoverTrigger asChild>
                              <button
                                type="button"
                                className="rounded-full border px-2.5 text-xs font-normal transition-colors w-fit py-1 h-fit flex gap-2 items-center cursor-pointer hover:bg-accent"
                              >
                                <Plus className="w-3 h-3" />
                                Add Permission
                              </button>
                            </PopoverTrigger>
                            <PopoverContent className="w-80 z-[1000000] max-h-[325px] overflow-auto">
                              <div className="grid gap-2">
                                <div className="space-y-2">
                                  <h4 className="font-medium leading-none">Permissions</h4>
                                  <p className="text-sm text-muted-foreground">
                                    Select permission for collaborator
                                  </p>
                                </div>
                                <div className="grid gap-2">
                                  {permissionsToShow.map((badge) => {
                                    const isSelected = selectedPermissions.some(
                                      (perm) => perm.type === badge.type,
                                    );
                                    return (
                                      <button
                                        key={badge.type}
                                        type="button"
                                        disabled={isSelected}
                                        className={cn(
                                          'flex items-start space-x-2 rounded-md p-2 transition-all hover:bg-accent text-accent-foreground',
                                          isSelected && 'bg-accent opacity-50',
                                        )}
                                        onClick={() => {
                                          setSelectedPermissions([...selectedPermissions, badge]);
                                        }}
                                      >
                                        {badge.icon('w-3 h-3')}
                                        <div className="space-y-1">
                                          <p className="text-sm font-medium leading-none text-left">
                                            {badge.title}
                                          </p>
                                          <p className="text-xs text-muted-foreground text-left">
                                            {badge.description}
                                          </p>
                                        </div>
                                      </button>
                                    );
                                  })}
                                </div>
                              </div>
                            </PopoverContent>
                          </Popover>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 mt-6 justify-end">
                    <Button type="button" variant="outline" onClick={hide}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        email === previousOwnerEmail ||
                        selectedPermissions.length === 0 ||
                        loading ||
                        dissallowToUpdate
                      }
                    >
                      {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                      {createNew ? 'Invite user' : 'Update permissions'}
                    </Button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateEditCollaboratorDialog;
