import React, { Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';

const ImageGeneratorDisclaimerDialog: React.FC<{
  show: boolean;
  close: () => void;
}> = ({ show, close }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => {}}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Important Notice Regarding Image Generation
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm mb-2">
                    GPT-trainer is not responsible for the accuracy or appropriateness of images generated
                    through this AI Agent. While we try to implement simple filters and safeguards, we do not
                    provide ANY guarantee that generated images are compliant with your own or your
                    users&apos; Terms of Service, values, or governing laws.
                  </p>
                  <p className="text-warning text-sm font-medium">Deploy this at your own risk!</p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={close}>Got it</Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImageGeneratorDisclaimerDialog;
