/* eslint-disable react/no-array-index-key */
import React from 'react';
import { cn } from 'utils/cn';
import { ChatBotMeta, IChatbotMessageColors, IUserMessageColors } from 'utils/bot';
import { AIAgentTag, ChatMessage as ChatMessageType } from 'models/api/response.types';
import {
  BookOpenText,
  Bot,
  Clock,
  FunctionSquare,
  Hash,
  MessageCircleReply,
  Tags,
  VariableIcon,
} from 'lucide-react';
import { format } from 'date-fns';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-textmate';
import { Badge } from 'components/ui/badge';
import tinycolor from 'tinycolor2';
import ChatMessage from './ChatMessage';

const DebugMessage: React.FC<{
  message: ChatMessageType;
  chatbotMeta: ChatBotMeta;
  userMessageColors?: IUserMessageColors;
  chatBotMessageColors?: IChatbotMessageColors;
  reversed?: boolean;
  setAnswerToRevise?: (message: ChatMessageType) => void;
}> = ({ message, reversed, chatbotMeta, setAnswerToRevise, userMessageColors, chatBotMessageColors }) => {
  const { agent_state, functions_called, query_intent, tags, agent_overridden, monitored_message } =
    JSON.parse(message.meta_json || '{}');

  return (
    <div className="border rounded-md p-4">
      <ChatMessage
        isInbox
        size="sm"
        reversed={reversed}
        isMonitoredMessage={!!monitored_message}
        key={message.uuid}
        message={message}
        chatbotMeta={chatbotMeta}
        setAnswerToRevise={setAnswerToRevise}
        userMessageColors={userMessageColors}
        chatBotMessageColors={chatBotMessageColors}
      />
      <div className="flex flex-col gap-2 w-full px-2 mt-2">
        <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
          <Hash className="w-4 h-4" strokeWidth={1.75} />
          UUID
          <span className="text-foreground ml-auto font-medium">{message.uuid}</span>
        </p>
        <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
          <Clock className="w-4 h-4" strokeWidth={1.75} />
          Created At
          <span className="text-foreground ml-auto font-medium">
            {format(new Date(message.created_at), 'dd/MM/yyyy HH:mm')}
          </span>
        </p>
        {message?.finish_reason !== 'pre-canned' ? (
          <>
            <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
              <Bot className="w-4 h-4" strokeWidth={1.75} />
              Active Agent
              {agent_overridden && (
                <Badge className="text-sm ml-auto rounded-md font-medium" variant="outline">
                  Override
                </Badge>
              )}
              <span className={cn('text-foreground font-medium', !agent_overridden ? 'ml-auto' : '')}>
                {agent_state?.current_agent_name || 'NOT PROVIDED'}
              </span>
            </p>
            {query_intent && (
              <p className="text-sm flex items-start gap-4 text-muted-foreground whitespace-nowrap">
                <span className="flex items-center gap-2">
                  <BookOpenText className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                  Intent
                </span>
                <span className="text-foreground ml-auto font-medium max-w-[80%] whitespace-normal">
                  {query_intent.intent}
                </span>
              </p>
            )}
            {agent_state?.states && Object.keys(agent_state.states).length > 0 && (
              <>
                <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
                  <VariableIcon className="w-4 h-4" strokeWidth={1.75} />
                  Variables
                </p>
                <div className="flex flex-col gap-2">
                  {Object.entries(agent_state.states).map((v) => {
                    const value =
                      typeof v[1] === 'object'
                        ? (v[1] as unknown as { content: string; static: number })?.content
                        : (v[1] as unknown as string | number | boolean);
                    return (
                      <div className="text-sm flex items-start gap-4" key={v[0]}>
                        <Badge variant="outline" className="text-sm">
                          {v[0]}
                        </Badge>
                        <span className="text-foreground ml-auto font-medium">{value || 'NOT PROVIDED'}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {tags && tags.length > 0 && (
              <>
                <div className="text-sm flex items-start gap-4 text-muted-foreground whitespace-nowrap">
                  <span className="flex items-center gap-2 mt-1">
                    <Tags className="w-4 h-4" strokeWidth={1.75} />
                    Assigned Tags
                  </span>
                  <div className="flex items-center justify-end gap-2 ml-auto flex-wrap">
                    {tags.map((tag: AIAgentTag, index: number) => {
                      return (
                        <Badge
                          style={{
                            borderColor: tag.color,
                            color: tinycolor(tag.color).darken(25).toString(),
                            backgroundColor: `${tag.color}30`,
                          }}
                          key={index}
                          variant="outline"
                          className="tag text-sm whitespace-nowrap rounded-md"
                        >
                          {tag.name}
                        </Badge>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {functions_called && (
              <div>
                <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
                  <FunctionSquare className="w-4 h-4" strokeWidth={1.75} />
                  Functions Called
                </p>
                <div className="flex flex-col gap-2 my-2">
                  {functions_called.map((func: any, index: number) => {
                    const content = `
function_name: ${func.name},
content: ${JSON.stringify(func.content, null, 2)}
                                `;
                    return (
                      <div key={index}>
                        <AceEditor
                          mode="json"
                          theme="textmate"
                          name="jsonEditor"
                          value={content}
                          fontSize={14}
                          readOnly
                          setOptions={{
                            useWorker: false,
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: false,
                            tabSize: 2,
                          }}
                          style={{ width: '100%', height: '150px' }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        ) : (
          <p className="text-sm flex items-center gap-2 text-muted-foreground whitespace-nowrap">
            <MessageCircleReply className="w-4 h-4" strokeWidth={1.75} />
            Response Type
            <span className="text-foreground ml-auto font-medium">Pre Canned</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default DebugMessage;
