import { AppCustomCode } from 'models/api/response.types';
import React, { useEffect } from 'react';

const CustomScriptGenerator: React.FC<{ codes: AppCustomCode[] }> = ({ codes }) => {
  useEffect(() => {
    codes.forEach((code) => {
      if (code.enabled) {
        const { content, type } = code;
        let targetElement;

        try {
          switch (type) {
            case 'header':
              targetElement = document.head;
              break;
            case 'body-start':
              targetElement = document.body;
              if (targetElement.firstChild) {
                const div = document.createElement('div');
                div.innerHTML = content;
                while (div.firstChild) {
                  targetElement.insertBefore(div.firstChild, targetElement.firstChild);
                }
                return;
              }
              break;
            default:
              targetElement = document.body;
          }

          const div = document.createElement('div');
          div.innerHTML = content;
          while (div.firstChild) {
            targetElement.appendChild(div.firstChild);
          }
        } catch (error) {
          console.error(`Error injecting custom code: ${(error as any)?.message}`, code);
        }
      }
    });
  }, []);

  return null;
};

export default CustomScriptGenerator;
