import { AppWindow, LinkIcon, MonitorPlay, Network } from 'lucide-react';
import React, { ReactNode } from 'react';
import StatusDot from 'components/helpers/StatusDot';
import { cn } from './cn';

export type DateSelector = 'day' | 'yesterday' | 'week' | 'month';

export const conversationFilters: {
  [key: string]: { title: string; icon: (className?: string) => ReactNode };
} = {
  human_escalation_requested: {
    title: 'Support Requested',
    icon: (className?: string) => {
      return <StatusDot className={cn('mr-2', className)} status="warning" />;
    },
  },
  human_escalation_active: {
    title: 'Live Chat',
    icon: (className?: string) => {
      return <StatusDot className={cn('mr-2', className)} status="success" />;
    },
  },
  preview: {
    title: 'Preview',
    icon: (className?: string) => {
      return (
        <MonitorPlay
          strokeWidth={1.75}
          className={cn('mr-2 w-4 h-4 min-w-[16px] text-teal-500', className)}
        />
      );
    },
  },
  link: {
    title: 'Link Share',
    icon: (className?: string) => {
      return (
        <LinkIcon strokeWidth={1.75} className={cn('mr-2 w-4 h-4 min-w-[16px] text-blue-500', className)} />
      );
    },
  },
  'widget-iframe': {
    title: 'Widget / Iframe',
    icon: (className?: string) => {
      return (
        <AppWindow
          strokeWidth={1.75}
          className={cn('mr-2 w-4 h-4 min-w-[16px] text-violet-600', className)}
        />
      );
    },
  },
  api: {
    title: 'API',
    icon: (className?: string) => {
      return (
        <Network strokeWidth={1.75} className={cn('mr-2 w-4 h-4 min-w-[16px] text-amber-500', className)} />
      );
    },
  },
  slack: {
    title: 'Slack',
    icon: (className?: string) => {
      return (
        <img
          src="/img/session-types/slack-type.svg"
          alt="Slack"
          className={cn('mr-2 w-4 h-4 min-w-[16px]', className)}
        />
      );
    },
  },
  zapier: {
    title: 'Zapier',
    icon: (className?: string) => {
      return (
        <img
          src="/img/session-types/zapier-type.svg"
          alt="Zapier"
          className={cn('mr-2 w-4 h-4 min-w-[16px]', className)}
        />
      );
    },
  },
  whatsapp: {
    title: 'WhatsApp',
    icon: (className?: string) => {
      return (
        <img
          src="/img/session-types/whatsapp-type.svg"
          alt="WhatsApp"
          className={cn('mr-2 w-4 h-4 min-w-[16px]', className)}
        />
      );
    },
  },
  instagram: {
    title: 'Instagram',
    icon: (className?: string) => {
      return (
        <img
          src="/img/session-types/instagram-type.svg"
          alt="Instagram"
          className={cn('mr-2 w-4 h-4 min-w-[16px]', className)}
        />
      );
    },
  },
  messenger: {
    title: 'Messenger',
    icon: (className?: string) => {
      return (
        <img
          src="/img/session-types/messenger-type.svg"
          alt="Facebook Messenger"
          className={cn('mr-2 w-4 h-4 min-w-[16px]', className)}
        />
      );
    },
  },
  zendesk: {
    title: 'Zendesk',
    icon: (className?: string) => {
      return (
        <img
          src="/img/session-types/zendesk-type.png"
          alt="Zendesk"
          className={cn('mr-2 w-4 h-4 min-w-[16px]', className)}
        />
      );
    },
  },
  make: {
    title: 'Make.com',
    icon: (className?: string) => {
      return (
        <img
          src="/img/integrations/make.png"
          alt="Facebook Messenger"
          className={cn('mr-2 w-4 h-4 min-w-[16px]', className)}
        />
      );
    },
  },
};

export const currentDateSelected = (date: Date): boolean => {
  const today = new Date();
  return (
    today.getFullYear() <= date.getFullYear() &&
    today.getMonth() <= date.getMonth() &&
    today.getDate() <= date.getDate()
  );
};
