/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */

import { Options } from 'remark-math';

export const renumberCitations = (response: string, citeData?: { [key: string]: any }) => {
  // This regex matches the format [x.y]
  const citationPattern = /\[\d+\.\d+\]/g;

  // Find all unique citations from the text
  const citations = Array.from(new Set(response.match(citationPattern) || []));

  // Sort citations based on source number and section number
  citations.sort((a, b) => {
    const [sourceA, sectionA] = a.slice(1, -1).split('.').map(Number);
    const [sourceB, sectionB] = b.slice(1, -1).split('.').map(Number);

    return sourceA - sourceB || sectionA - sectionB;
  });

  let sourceCounter = 1;
  let sectionCounter = 1;
  let lastSource = -1;

  const citationMap: { [key: string]: string } = {};

  for (const cite of citations) {
    const [source] = cite.slice(1, -1).split('.').map(Number);

    if (source !== lastSource) {
      sectionCounter = 1;
      lastSource = source;
      sourceCounter++;
    }

    const newCite = `[${sourceCounter - 1}.${sectionCounter++}]`;
    citationMap[cite] = newCite;
  }

  let updatedResponse = response;

  // Replace old citations with new citations
  for (const oldCite of Object.keys(citationMap)) {
    const regex = new RegExp(`\\${oldCite}`, 'g'); // escape brackets for regex
    updatedResponse = updatedResponse.replace(regex, citationMap[oldCite]);
  }

  // Create a lookup table
  const newCiteData: { [key: string]: any } = {};

  if (citeData) {
    for (const [oldCite, newCite] of Object.entries(citationMap)) {
      newCiteData[newCite.slice(1, -1)] = citeData[oldCite.slice(1, -1)];
    }
  }

  return {
    updatedResponse,
    newCiteData: citeData ? (Object.keys(newCiteData).length > 0 ? newCiteData : citeData) : undefined,
  };
};

export const invalidImageTypes = ['.jpg', '.jpeg', '.png', 'webp'];

export const removeInvalidCitationTypes = (citeData?: { [key: string]: any }) => {
  // Return undefined if the input is undefined or null
  if (!citeData) {
    return citeData;
  }

  // Create a new object to store the filtered key-value pairs
  const filteredObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(citeData)) {
    if (typeof value === 'object' && value !== null && 'file_name' in value) {
      const fileName = value.file_name.toLowerCase();
      // Check if file_name ends with any of the specified image types or type of citation is video
      if (!invalidImageTypes.some((type) => fileName.endsWith(type)) && value.type !== 'video') {
        filteredObj[key] = value;
      }
    } else {
      filteredObj[key] = value;
    }
  }

  return filteredObj;
};

export const encodeText = (text: string) => {
  let cleanedText = text;

  const replacements: { [key: string]: string } = {
    '�': ' ',
    '“': '"',
    '”': '"',
    '‘': "'",
    '’': "'",
  };

  Object.keys(replacements).forEach((key) => {
    const value = replacements[key];
    cleanedText = cleanedText.replace(new RegExp(key, 'g'), value);
  });

  return cleanedText;
};

export const formAnimation = {
  hidden: {
    opacity: 0,
    scale: 0.97,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'ease-in-out',
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.97,
    transition: {
      duration: 0.2,
    },
  },
};

export const messageAnimation = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: (delay: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      duration: 0.2,
      delay: delay * 0.2,
    },
  }),
};
export const remarkMathOptions: Options = {
  singleDollarTextMath: false,
};
export const preproccessLatex = (content: string) => {
  // Replace inline LaTeX
  const blockProcessedContent = content.replace(/\\\[([\S\s]*?)\\\]/g, (_, equation) => `$$${equation}$$`);
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\(([\s\S]*?)\\\)/g,
    (_, equation) => `$$${equation}$$`,
  );
  return inlineProcessedContent;
};
