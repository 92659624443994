import React from 'react';
import { loaderSizeVariant } from 'utils/bot';
import { cn } from 'utils/cn';

const WidgetLoadingOverlay: React.FC<{
  bgColor?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  bubbleColor?: string;
  className?: string;
}> = ({ bgColor, size = 'md', bubbleColor = '#A0A3BA', className }) => {
  return (
    <div
      className={cn('flex flex-row justify-center items-center gap-4', className)}
      style={
        bgColor
          ? {
              backgroundColor: bgColor,
            }
          : {}
      }
    >
      <div
        className={cn('loading loading-0 rounded-full', loaderSizeVariant[size])}
        style={{
          backgroundColor: bubbleColor,
        }}
      />
      <div
        className={cn('loading loading-1 rounded-full', loaderSizeVariant[size])}
        style={{
          backgroundColor: bubbleColor,
        }}
      />
      <div
        className={cn('loading loading-2 rounded-full', loaderSizeVariant[size])}
        style={{
          backgroundColor: bubbleColor,
        }}
      />
    </div>
  );
};

export default WidgetLoadingOverlay;
