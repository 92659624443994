/* eslint-disable no-useless-escape */
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import chatbotService from 'api/chatbot';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import useUserInfo from 'hooks/useUserInfo';
import { Loader2 } from 'lucide-react';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { alerts } from 'utils/alert';

const TransferChatbotOwnershipDialog: React.FC<{
  previousOwnerEmail?: string;
  show: boolean;
  close: () => void;
}> = ({ previousOwnerEmail, show, close }) => {
  const queryClient = useQueryClient();
  const { userInfo } = useUserInfo();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const [creating, setCreating] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  const transferKey = ['transfer-request', chatbot?.uuid];

  const { isEmailInvalid, sameAsOwner, sameAsPreviousOwner } = useMemo(() => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const ownerEmailTyped = userInfo?.email === email;
    const previousOwnerEmailTyped = email === previousOwnerEmail;
    return {
      isEmailInvalid:
        (!emailRegex.test(email) && email.length > 0) || ownerEmailTyped || previousOwnerEmailTyped,
      sameAsOwner: ownerEmailTyped,
      sameAsPreviousOwner: previousOwnerEmailTyped,
    };
  }, [email]);

  const hide = () => {
    close();
    setTimeout(() => {
      setStep(0);
      setEmail('');
    }, 300);
  };

  const createTransferRequest = () => {
    setCreating(true);
    chatbotService
      .initiateChatbotTransfer(chatbot.uuid, email)
      .then(({ data }) => {
        queryClient.setQueryData(transferKey, data);
        addAlert({
          severity: 'success',
          message: alerts.BOT_TRASNFER_OWNERSHIP_REQUEST_CREATE(chatbot?.name as string, email),
          timeout: 5000,
        });
        hide();
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
          timeout: 5000,
        });
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Transfer Chatbot Ownership
                </DialogTitle>
                {step ? (
                  <div className="mt-2">
                    <p className="font-normal text-muted-foreground text-sm">
                      Provide the email address of the user who will receive ownership of the{' '}
                      <strong>{chatbot?.name}</strong> chatbot.
                    </p>
                    <div className="grid gap-2 mt-4">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        className="shadow-sm"
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        autoFocus
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    {isEmailInvalid && (
                      <p className="text-xs text-destructive ml-1 mt-1">
                        {sameAsOwner
                          ? 'Cannot send an ownership transfer request to yourself.'
                          : sameAsPreviousOwner
                            ? 'Cannot send an ownership transfer request to the previous owner. Please remove the current owner from the collaborator list before attempting to reassign ownership.'
                            : 'Invalid email.'}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="mt-2">
                    <p className="font-normal text-muted-foreground text-sm">
                      You are about to create a request to transfer admin ownership of{' '}
                      <strong>{chatbot?.name}</strong> chatbot to another user. By submitting this request,
                      you will become a collaborator, and the recipient will become the new owner.
                    </p>
                    <p className="font-normal text-muted-foreground text-sm mt-2">Please note following:</p>
                    <ul className="marker:text-primary list-outside text-sm text-muted-foreground list-disc my-2 ml-4">
                      <li>
                        <span className="font-medium text-foreground">Chatbot Transfer Data:</span> Along with
                        the ownership transfer, all settings, sources, appearance customizations, chat
                        history, and current collaborators associated with this chatbot will also be
                        transferred to the new owner.
                      </li>
                      <li>
                        <span className="font-medium text-foreground">Request Expiry:</span> This transfer
                        request will be valid for 7 days. If the recipient does not accept the request within
                        this period, it will expire, and you will retain ownership.
                      </li>
                      <li>
                        <span className="font-medium text-foreground">One Request Limit:</span> A chatbot can
                        only have one active ownership transfer request at a time. If there&apos;s an existing
                        pending request, it must be cancelled before creating a new one.
                      </li>
                      <li>
                        <span className="font-medium text-foreground">Cancellation:</span> You have the option
                        to cancel this request at any time before the recipient accepts it.
                      </li>
                    </ul>
                    <p className="font-normal text-muted-foreground text-sm mt-2">
                      The recipient must match the subscription criteria that align with the current chatbot
                      settings in order to accept this request.
                    </p>
                    <p className="font-normal text-warning text-sm mt-2">
                      Once the recipient accepts the request and meets the subscription criteria, you will not
                      be able to regain ownership permissions.
                    </p>
                    <p className="font-normal text-muted-foreground text-sm mt-2">
                      Do you wish to proceed with this transfer request?
                    </p>
                  </div>
                )}

                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!!step && (!email || isEmailInvalid || creating)}
                    onClick={() => {
                      if (step) {
                        createTransferRequest();
                      } else {
                        setStep(1);
                      }
                    }}
                  >
                    {creating && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
                    {step ? 'Create request' : 'Proceed with transfer'}
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TransferChatbotOwnershipDialog;
