import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Chatbot, Chatbots } from 'models/api/response.types';
import { Button } from 'components/ui/button';
import chatbotService from 'api/chatbot';
import { useDispatch } from 'react-redux';
import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';
import { setSelectedChatbot } from 'store/reducers/ui';
import { PermissionType } from 'components/helpers/CollaboratorPermissionBadge';
import useChatbots from 'hooks/useChatbots';
import { Integration } from 'utils/integrations';

const integrationsWithDomain: Integration[] = [
  'wix',
  'site',
  'wordpress',
  'squarespace',
  'shopify',
  'drupal',
];

const CheckintegrationDialog: React.FC<{
  close: (success: boolean) => void;
  chatbot: Chatbot;
  integration: Integration;
}> = ({ chatbot, close, integration }) => {
  const { chatbots, setChatbotData } = useChatbots();
  const dispatch = useDispatch();
  const chatbotMetadata = JSON.parse(chatbot.meta_json);
  const [domainsTextValue, setDomainsTextValue] = useState<string>(chatbotMetadata.domains.join('\n'));
  const [domainsError, setDomainsError] = useState<boolean>(false);
  const [showVisibility, setShowVisibility] = useState<boolean>(false);
  const [showDomain, setShowDomain] = useState<boolean>(false);

  // check if integration is good to go
  useEffect(() => {
    if (integrationsWithDomain.includes(integration)) {
      if (chatbotMetadata.visibility === 'private') {
        setShowVisibility(true);
      } else if (chatbotMetadata.domains.length === 0 && !chatbotMetadata?.all_domains_allowed) {
        setShowDomain(true);
      } else {
        close(true);
      }
    } else {
      close(true);
    }
  }, [chatbotMetadata]);

  // logic to check if all domains are valid
  useEffect(() => {
    if (domainsTextValue.length > 0) {
      const domains = domainsTextValue.split('\n');
      const domainPattern = /^([A-Za-z0-9](?:(?:[A-Za-z0-9-]){0,61}[A-Za-z0-9])?\.)+[A-Za-z]{2,}$/i;
      if (domains.some((domain) => !domainPattern.test(domain))) {
        setDomainsError(true);
      } else {
        setDomainsError(false);
      }
    }
  }, [domainsTextValue]);

  const canUpdateMeta = useMemo(() => {
    return (
      chatbot &&
      (!chatbot.permissions || JSON.parse(chatbot.permissions || '[]').includes('meta' as PermissionType))
    );
  }, [chatbot]);

  const updateChatbot = (prop: 'visibility' | 'domain' | 'all-domains', visibilityStatus?: string) => {
    const newMeta = {
      ...chatbotMetadata,
      ...(prop === 'visibility' && {
        visibility: visibilityStatus || 'public',
      }),
      ...(prop === 'domain' && {
        domains: domainsTextValue.split('\n'),
      }),
      ...(prop === 'all-domains' && {
        all_domains_allowed: true,
      }),
    };
    chatbotService
      .updateChatbot(chatbot.uuid, {
        name: chatbot.name,
        meta_json: JSON.stringify(newMeta),
      })
      .then((data) => {
        dispatch(setSelectedChatbot(data));
        const newChatbots = (chatbots as Chatbots).map((bot) => (bot.uuid === data.uuid ? data : bot));
        setChatbotData(newChatbots);
        if (prop === 'visibility') {
          setShowVisibility(false);
        }
        if (prop === 'domain' || prop === 'all-domains') {
          setShowDomain(false);
        }
      });
  };

  if (!showDomain && !showVisibility) {
    return null;
  }

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={() => close(false)}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                {showVisibility && (
                  <>
                    <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                      Deploy chatbot
                    </DialogTitle>
                    <div className="mt-2">
                      {canUpdateMeta ? (
                        <p className="font-normal text-muted-foreground text-sm">
                          If you make the chatbot <strong>Public/Embed</strong>, please note that any credits
                          spent by users while interacting with the chatbot will be counted towards your own
                          account.
                        </p>
                      ) : (
                        <p className="font-normal text-muted-foreground text-sm">
                          To deploy the chatbot, its visibility must be set to <strong>Public/Embed</strong>.
                          Please request the owner to modify this setting.
                        </p>
                      )}
                    </div>
                    {canUpdateMeta && (
                      <div className="flex justify-end mt-6 gap-4">
                        <Button
                          onClick={() => {
                            updateChatbot('visibility', 'hybrid');
                          }}
                        >
                          Set to Embed
                        </Button>
                        <Button
                          onClick={() => {
                            updateChatbot('visibility', 'public');
                          }}
                        >
                          Set to Public
                        </Button>
                      </div>
                    )}
                  </>
                )}
                {showDomain && (
                  <>
                    <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                      Specify domain
                    </DialogTitle>
                    {canUpdateMeta ? (
                      <>
                        <p className="font-normal text-muted-foreground text-sm mt-2">
                          To embed our widget on other websites, specify the domains where it will be used.
                          This ensures secure integration
                        </p>
                        <div className="flex flex-col gap-2 mt-4">
                          <Label className=" mb-2" htmlFor="domains">
                            Domains
                          </Label>
                          <Textarea
                            id="domains"
                            value={domainsTextValue}
                            placeholder={window.location.hostname}
                            onChange={(e) => {
                              const { value } = e.target;
                              setDomainsTextValue(value);
                            }}
                          />
                        </div>
                        <p className="text-xs text-muted-foreground mt-2 ml-1">
                          Enter each domain in a new line.
                        </p>
                        {domainsError && <p className="text-xs text-destructive ml-1">Invalid domain(s).</p>}
                        <div className="flex justify-end mt-6">
                          <Button
                            onClick={() => {
                              updateChatbot('all-domains');
                            }}
                          >
                            Allow all domains
                          </Button>
                          <Button
                            className="ml-2"
                            disabled={domainsError || domainsTextValue.length === 0}
                            onClick={() => {
                              updateChatbot('domain');
                            }}
                          >
                            Add domains
                          </Button>
                        </div>
                      </>
                    ) : (
                      <p className="font-normal text-muted-foreground text-sm mt-2">
                        To embed our widget on other websites, ask owner to specify the domains where it will
                        be used. This ensures secure integration
                      </p>
                    )}
                  </>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CheckintegrationDialog;
