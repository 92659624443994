import axios from 'axios';
import { ChatbotDomain, ChatbotDomainList } from 'models/api/response.types';

const getChatbotDomains = (chatbotId: string) =>
  axios.get<ChatbotDomainList>(`/api/chatbot/${chatbotId}/custom-domains`).then((res) => res.data);

const createChatbotDomain = (chatbotId: string, domain: string) =>
  axios
    .post<ChatbotDomain>(`/api/chatbot/${chatbotId}/create-custom-domain`, {
      custom_domain: domain,
    })
    .then((res) => res.data);

const refreshChatbotDomain = (domainId: string) =>
  axios.post<ChatbotDomain>(`/api/custom-domain/${domainId}/refresh`).then((res) => res.data);

const deleteChatbotDomain = (domainId: string) => axios.post(`/api/custom-domain/${domainId}/delete`);

const domainService = {
  createChatbotDomain,
  getChatbotDomains,
  deleteChatbotDomain,
  refreshChatbotDomain,
};

export default domainService;
