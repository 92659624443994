import React, { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Ban, MoreHorizontal, Pencil, Send, Trash2 } from 'lucide-react';
import { Button } from 'components/ui/button';
import { useAlerts } from 'providers/AlertProvider';
import { Collaborator } from 'pages/Chatbot/Collaborators';
import DeleteCollaboratorDialog from 'components/Dialogs/Collaborators/DeleteCollaboratorDialog';
import chatbotService from 'api/chatbot';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import { Chatbot } from 'models/api/response.types';
import { useQueryClient } from '@tanstack/react-query';
import CreateEditCollaboratorDialog from 'components/Dialogs/Collaborators/CreateEditCollaboratorDialog';
import { alerts } from 'utils/alert';
import DeleteOwnershipTransferDialog from 'components/Dialogs/Collaborators/DeleteOwnershipTransferDialog';

const CollaboratorSettings: React.FC<{
  isPreviousOwner?: boolean;
  collaborator: Collaborator;
  canInviteCollaborators: boolean;
}> = ({ isPreviousOwner, collaborator, canInviteCollaborators }) => {
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const [deleteCollaborator, setDeleteCollaborator] = useState<
    { id: string; is_invite: boolean; is_previous_owner: boolean } | undefined
  >(undefined);
  const [editCollaborator, setEditCollaborator] = useState<Collaborator | undefined>(undefined);
  const [cancelOwnershipTransfer, setCancelOwnershipTransfer] = useState<boolean>(false);
  const invitationKey = ['invitaions', chatbot?.uuid];

  const generateErrorAlert = (message: string) => {
    addAlert({
      severity: 'error',
      message,
      timeout: 5000,
    });
  };

  const resendInvitation = () => {
    chatbotService
      .deleteInvite(collaborator.id)
      .then(() => {
        chatbotService
          .inviteCollaborator(chatbot.uuid, {
            email: collaborator.email,
            permissions: JSON.stringify(collaborator.permissions),
          })
          .then(() => {
            setTimeout(() => {
              queryClient.invalidateQueries({ queryKey: invitationKey });
            }, 500);
            addAlert({
              severity: 'success',
              message: alerts.INVITE_SEND_SUCCESS(collaborator.email),
              timeout: 5000,
            });
          })
          .catch(() => {
            generateErrorAlert(alerts.INVITE_SEND_ERROR);
          });
      })
      .catch(() => {
        generateErrorAlert(alerts.SOMETHING_WRONG);
      });
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
            <MoreHorizontal strokeWidth={1.75} className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-10">
          {collaborator.status === 'active' && !isPreviousOwner && (
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                setEditCollaborator(collaborator);
              }}
            >
              <Pencil strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Edit
            </DropdownMenuItem>
          )}
          {['declined', 'left'].includes(collaborator.status) && canInviteCollaborators && (
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                resendInvitation();
              }}
            >
              <Send strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Resend Invitation
            </DropdownMenuItem>
          )}
          {collaborator.transfering_ownership && (
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                setCancelOwnershipTransfer(true);
              }}
              className="focus:text-destructive focus:bg-destructive/10"
            >
              <Ban strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Cancel ownership transfer
            </DropdownMenuItem>
          )}
          {collaborator.permissions && (
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation();
                setDeleteCollaborator({
                  id: collaborator.id,
                  is_invite: collaborator.status !== 'active',
                  is_previous_owner: !!isPreviousOwner,
                });
              }}
              className="focus:text-destructive focus:bg-destructive/10"
            >
              <Trash2 strokeWidth={1.75} className="mr-2 h-4 w-4" />
              Delete
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <DeleteOwnershipTransferDialog
        show={cancelOwnershipTransfer}
        close={() => setCancelOwnershipTransfer(false)}
      />
      <DeleteCollaboratorDialog
        collaborator={deleteCollaborator}
        close={() => {
          setDeleteCollaborator(undefined);
        }}
      />
      <CreateEditCollaboratorDialog
        collaborator={editCollaborator}
        close={() => {
          setEditCollaborator(undefined);
        }}
      />
    </>
  );
};

export default CollaboratorSettings;
