/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactNode, useMemo, useState } from 'react';
import { Contact, Database, MessagesSquare, Pencil, Send, Webhook as WebhookIcon, X } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { cn } from 'utils/cn';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { ChatBotMeta, Webhook } from 'utils/bot';
import { Label } from 'components/ui/label';
import UpdateWebhookDialog from 'components/Dialogs/Chatbot/UpdateWebhookDialog';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import useAiAgents from 'hooks/useAiAgents';
import { AIAgentVariable } from 'models/api/response.types';
import { Badge } from 'components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';

export const webhookTypeIcons: { [key: string]: ReactNode } = {
  form_submission: <Contact strokeWidth={1.75} className="mt-px h-5 w-5 min-w-5" />,
  response: <MessagesSquare strokeWidth={1.75} className="mt-px h-5 w-5 min-w-5" />,
  query: <Send strokeWidth={1.75} className="mt-px h-5 w-5 min-w-5" />,
  data_collection: <Database strokeWidth={1.75} className="mt-px h-5 w-5 min-w-5" />,
};

export const webhookTypeNames: { [key: string]: ReactNode } = {
  form_submission: 'Form Submission',
  query: 'User Message',
  response: 'AI Response',
  data_collection: 'Data Collection',
};

export const webhookTypeDescription: { [key: string]: string } = {
  form_submission: 'User submits the chat form.',
  response: 'AI sends the response to user.',
  query: 'User submits the message to chat.',
  data_collection: 'Agent collects specified data.',
};

interface Webhooks extends React.HTMLProps<HTMLDivElement> {
  id: string;
  disabled: boolean;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const Webhooks = React.forwardRef<HTMLDivElement, Webhooks>(
  ({ id, disabled, chatInterface, setChatInterface }, ref) => {
    const { agents } = useAiAgents();
    const [createHookEventSelection, setCreateHookEventSelection] = useState<Webhook['type']>('query');
    const [createHookEndpoint, setCreateHookEndpoint] = useState<string>('');
    const [hookToUpdate, setHookToUpdate] = useState<number | undefined>(undefined);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [selectedVariables, setSelectedVariables] = useState<string[]>([]);

    const validateEndpoint = (apiEndpoint: string) => {
      const urlRegex = /^https?:\/\/[\w.-]+(?:\:[0-9]+)?\/[^\s]+$/;
      setIsValid(urlRegex.test(apiEndpoint));
    };

    const myWebhooks = useMemo(() => {
      return (
        chatInterface?.webhooks?.filter((webhook) =>
          ['query', 'response', 'data_collection', 'form_submission'].includes(webhook.type),
        ) || []
      );
    }, [chatInterface]);

    const createWebhook = () => {
      const currentWebhooks = chatInterface?.webhooks || [];
      setChatInterface({
        ...chatInterface,
        webhooks: [
          ...currentWebhooks,
          {
            type: createHookEventSelection as Webhook['type'],
            url: createHookEndpoint,
            ...(createHookEventSelection === 'data_collection' && {
              variables: selectedVariables,
            }),
          },
        ],
      });
      setCreateHookEndpoint('');
      setSelectedVariables([]);
      setIsValid(false);
      setCreateHookEventSelection('query');
    };

    const agentVariables = useMemo(() => {
      if (agents) {
        let variables: string[] = [];
        agents.forEach((agent) => {
          const vars: AIAgentVariable[] = JSON.parse(agent.variables_json || '[]');
          const varNames = vars.map((v) => v.name);
          variables = [...variables, ...varNames];
        });
        return variables;
      }
      return [];
    }, [agents]);

    const isValidToCreate = useMemo(() => {
      const isWebhookVariableValid =
        createHookEventSelection === 'data_collection' && selectedVariables.length > 0;
      return (
        isValid &&
        createHookEventSelection &&
        (createHookEventSelection !== 'data_collection' || isWebhookVariableValid)
      );
    }, [isValid, createHookEventSelection, selectedVariables]);

    return (
      <>
        <Card id={id} ref={ref} className="mt-4 relative">
          <CardHeader className="pb-6">
            <CardTitle className="flex items-center">
              <WebhookIcon strokeWidth={1.75} className="w-6 h-6 mr-2 inline" />
              Webhooks
            </CardTitle>
            <CardDescription>
              Tailor webhook functionalities for a bespoke integration experience.
            </CardDescription>
          </CardHeader>
          <CardContent className="grid gap-1 max-w-[1000px] overflow-hidden">
            {myWebhooks.length > 0 &&
              !disabled &&
              myWebhooks.map((webhook, index) => {
                const webhookVariables = webhook?.variables?.filter((v) => agentVariables.includes(v));
                return (
                  <div
                    key={`${webhook.type}-${webhook.url}-${index}`}
                    className={cn(
                      'rounded-md border p-2 -mx-2 flex items-start space-x-4 transition-all overflow-hidden',
                      index === myWebhooks.length - 1 ? 'mb-6' : 'mb-2',
                    )}
                  >
                    {webhookTypeIcons[webhook.type]}
                    <div className="space-y-1 flex-1 overflow-hidden">
                      <p className="text-sm font-medium leading-none text-left">
                        {webhookTypeNames[webhook.type]}
                      </p>
                      <p className="text-sm text-muted-foreground overflow-hidden text-ellipsis text-left">
                        {webhook.url}
                      </p>
                      {webhookVariables &&
                        (webhookVariables.length > 0 ? (
                          <div className="flex items-center gap-2">
                            {webhookVariables.map((variable) => {
                              return (
                                <Badge
                                  key={`${variable}-variable`}
                                  variant="outline"
                                  className="text-xs font-medium px-2 py-1 w-fit rounded-md transition-all"
                                >
                                  {variable}
                                </Badge>
                              );
                            })}
                          </div>
                        ) : (
                          <p className="text-warning text-xs">
                            No variables have been selected for the webhook. Please edit webhook.
                          </p>
                        ))}
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setHookToUpdate(index);
                      }}
                      className="ml-4 mt-3"
                    >
                      <Pencil strokeWidth={1.75} className="w-4 min-w-[16px] h-4 cursor-pointer" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newWebhooks = [...myWebhooks];
                        newWebhooks.splice(index, 1);
                        setChatInterface({
                          ...chatInterface,
                          webhooks: newWebhooks,
                        });
                      }}
                      className="ml-1 mt-3"
                    >
                      <X
                        strokeWidth={1.75}
                        className="w-4 min-w-[16px] h-4 transition-all hover:text-destructive cursor-pointer"
                      />
                    </button>
                  </div>
                );
              })}
            {(myWebhooks.length < 6 || !disabled) && (
              <>
                <div className="flex flex-col gap-2">
                  <Label className="text-md font-semibold leading-none tracking-tight flex items-center">
                    Events
                  </Label>
                  <p className="text-sm text-muted-foreground mb-2">
                    To get started, please choose one event for the webhook to monitor.
                  </p>
                </div>
                <button
                  type="button"
                  className={cn(
                    '-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all',
                    createHookEventSelection === 'form_submission'
                      ? 'bg-accent text-accent-foreground cursor-default'
                      : 'hover:bg-accent hover:text-accent-foreground',
                  )}
                  onClick={() => {
                    setCreateHookEventSelection('form_submission');
                  }}
                >
                  {webhookTypeIcons.form_submission}
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none text-left">
                      {webhookTypeNames.form_submission}
                    </p>
                    <p className="text-sm text-muted-foreground text-left">
                      {webhookTypeDescription.form_submission}
                    </p>
                  </div>
                </button>
                <button
                  type="button"
                  className={cn(
                    '-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all',
                    createHookEventSelection === 'query'
                      ? 'bg-accent text-accent-foreground cursor-default'
                      : 'hover:bg-accent hover:text-accent-foreground',
                  )}
                  onClick={() => {
                    setCreateHookEventSelection('query');
                  }}
                >
                  {webhookTypeIcons.query}
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none text-left">{webhookTypeNames.query}</p>
                    <p className="text-sm text-muted-foreground text-left">{webhookTypeDescription.query}</p>
                  </div>
                </button>
                <button
                  type="button"
                  className={cn(
                    '-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all',
                    createHookEventSelection === 'response'
                      ? 'bg-accent text-accent-foreground cursor-default'
                      : 'hover:bg-accent hover:text-accent-foreground',
                  )}
                  onClick={() => {
                    setCreateHookEventSelection('response');
                  }}
                >
                  {webhookTypeIcons.response}
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none text-left">{webhookTypeNames.response}</p>
                    <p className="text-sm text-muted-foreground text-left">
                      {webhookTypeDescription.response}
                    </p>
                  </div>
                </button>
                <button
                  type="button"
                  className={cn(
                    '-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all',
                    createHookEventSelection === 'data_collection'
                      ? 'bg-accent text-accent-foreground cursor-default'
                      : 'hover:bg-accent hover:text-accent-foreground',
                  )}
                  onClick={() => {
                    setCreateHookEventSelection('data_collection');
                  }}
                >
                  {webhookTypeIcons.data_collection}
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none text-left">
                      {webhookTypeNames.data_collection}
                    </p>
                    <p className="text-sm text-muted-foreground text-left">
                      {webhookTypeDescription.data_collection}
                    </p>
                  </div>
                </button>
                {createHookEventSelection === 'data_collection' && (
                  <>
                    <div className="flex flex-col gap-2 mt-4">
                      <Label className="text-md font-semibold leading-none tracking-tight flex items-center">
                        Variables
                      </Label>
                      <p className="text-sm text-muted-foreground mb-1">
                        Select the specific variables you want to send. Once collected, only the chosen
                        variables will be transmitted through the webhook.
                      </p>
                    </div>
                    <div className="flex items-center gap-2 flex-wrap">
                      {agentVariables.length > 0 ? (
                        <>
                          {agentVariables.map((variable) => {
                            const isSelected = selectedVariables.includes(variable);
                            return (
                              <Badge
                                key={`${variable}-variable`}
                                variant={isSelected ? 'default' : 'outline'}
                                className={cn(
                                  'text-sm px-4 w-fit rounded-md cursor-pointer transition-all whitespace-nowrap',
                                  isSelected ? '' : 'hover:bg-muted',
                                )}
                                onClick={() => {
                                  if (isSelected) {
                                    setSelectedVariables(selectedVariables.filter((v) => v !== variable));
                                  } else {
                                    setSelectedVariables([...selectedVariables, variable]);
                                  }
                                }}
                              >
                                {variable}
                              </Badge>
                            );
                          })}
                        </>
                      ) : (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Badge variant="outline" className="text-sm px-4 w-fit rounded-md">
                                No existing variables
                              </Badge>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="font-normal">
                                We couldn&apos;t find any existing variables in your agents. Please create one
                                to proceed forward.
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </div>
                  </>
                )}
                <div className="flex flex-col gap-2 mt-4">
                  <Label className="text-md font-semibold leading-none tracking-tight flex items-center">
                    API Endpoint
                  </Label>
                  <p className="text-sm text-muted-foreground mb-1">
                    Webhook events will be delivered to this URL through a{' '}
                    <strong className="text-secondary">POST</strong> request.
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <Input
                      type="text"
                      autoComplete="off"
                      disabled={disabled}
                      value={createHookEndpoint}
                      placeholder="https://my-custom-site.com/webhook/"
                      onChange={(e) => {
                        setCreateHookEndpoint(e.target.value);
                        validateEndpoint(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && isValidToCreate) {
                          createWebhook();
                        }
                      }}
                    />
                    {!isValid && createHookEndpoint.length > 0 && (
                      <p className="text-xs text-destructive ml-1 mt-1">Invalid API.</p>
                    )}
                  </div>
                  <Button
                    disabled={!isValidToCreate || disabled}
                    className="w-fit ml-auto"
                    size="default"
                    onClick={createWebhook}
                  >
                    Create
                  </Button>
                </div>
              </>
            )}
          </CardContent>
          {disabled && (
            <UpgradeRequired className="absolute" heading="Upgrade to Unlock Webhooks Feature">
              Webhooks are not available with your current subscription. Enhance your experience by upgrading
              to gain exclusive access to custom Webhooks.
            </UpgradeRequired>
          )}
        </Card>
        <UpdateWebhookDialog
          hookToUpdate={hookToUpdate}
          chatInterface={chatInterface}
          setChatInterface={setChatInterface}
          agentVariables={agentVariables}
          close={() => {
            setHookToUpdate(undefined);
          }}
        />
      </>
    );
  },
);

export default Webhooks;
