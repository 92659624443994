/* eslint-disable consistent-return */
// useSocket.ts
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { useEffect, useState, useCallback } from 'react';
import { Socket, io } from 'socket.io-client';

const useSocket = (isSubscribed: boolean, authToken?: string) => {
  const { appDomain } = useWhitelabelData();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [subscriptions, setSubscriptions] = useState<(() => void)[]>([]);

  useEffect(() => {
    if (isSubscribed) {
      const newSocket = io(`https://${appDomain}`, {
        transports: ['websocket', 'polling'],
        ...(authToken && {
          auth: {
            token: authToken,
          },
        }),
      });
      setSocket(newSocket);

      return () => {
        subscriptions.forEach((unsubscribe) => unsubscribe());
        newSocket.close();
      };
    }
  }, [isSubscribed]);

  const subscribeToEvent = useCallback(
    (eventName: string, callback: (...args: any[]) => void) => {
      if (socket) {
        socket.on(eventName, callback);

        // Create a function to unsubscribe from this event
        const unsubscribe = () => {
          socket.off(eventName, callback);
        };

        // Add the unsubscribe function to the list of subscriptions
        setSubscriptions((prevSubscriptions) => [...prevSubscriptions, unsubscribe]);

        // Return the unsubscribe function for manual cleanup if needed
        return unsubscribe;
      }
    },
    [socket],
  );

  const emitEvent = useCallback(
    (eventName: string, data: any) => {
      if (socket) {
        socket.emit(eventName, data);
      }
    },
    [socket],
  );

  return { subscribeToEvent, emitEvent };
};

export default useSocket;
