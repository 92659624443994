/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes, ReactNode, useState } from 'react';
import {
  IChatMainColors,
  WidgetSize,
  chatSizeVariants,
  secondaryActionLightBackgroundColor,
  secondaryActionTextLightColor,
} from 'utils/bot';
import { cn } from 'utils/cn';

interface ChatActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  size: WidgetSize;
  children: ReactNode;
  chatMainColors?: IChatMainColors;
}

const ChatActionButton: React.FC<ChatActionButtonProps> = ({
  disabled,
  size,
  className,
  chatMainColors,
  children,
  ...props
}) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <button
      type="button"
      onMouseEnter={() => {
        if (!disabled) {
          setHover(true);
        }
      }}
      disabled={disabled}
      onMouseLeave={() => setHover(false)}
      {...props}
      className={cn(
        'flex py-1 px-2 rounded-lg items-center outline-none transition-all',
        className,
        chatSizeVariants.secondaryActionsText[size],
        disabled && 'opacity-30 cursor-default',
      )}
      style={
        chatMainColors
          ? {
              color: chatMainColors.actionsText,
              background: hover ? chatMainColors.actionsHoverBackground : 'transparent',
            }
          : {
              color: secondaryActionTextLightColor,
              background: hover ? secondaryActionLightBackgroundColor : 'transparent',
            }
      }
    >
      {children}
    </button>
  );
};

export default ChatActionButton;
