/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import { File, Link, Loader2, MessagesSquare } from 'lucide-react';
import { cn } from 'utils/cn';
import { Transition } from '@headlessui/react';
import { DataSource, MessageSource } from 'models/api/response.types';
import { IChatbotMessageColors, WidgetSize, chatSizeVariants } from 'utils/bot';
import { downloadDataSource } from 'utils/dataSources';

interface SourceItem {
  id: string;
  type: DataSource['type'];
  title: string;
  file_name: string;
  key_number: string;
  reference_link?: string;
  qa_text?: string;
}

const MessageSources: React.FC<{
  size?: WidgetSize;
  isInTextCitationsEnabled: boolean;
  reversed?: boolean;
  showSources: boolean;
  sources: { [key: string]: MessageSource };
  chatBotMessageColors?: IChatbotMessageColors;
}> = ({
  size = 'md',
  reversed = false,
  showSources,
  isInTextCitationsEnabled,
  sources,
  chatBotMessageColors,
}) => {
  const [sourceHovered, setSourceHovered] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<string | undefined>(undefined);
  const textColor = chatBotMessageColors?.theme === 'dark' ? '#FFFF' : '#303030';

  const sourcesToShow: SourceItem[] = useMemo(() => {
    const sourceSet = new Set<SourceItem>();
    Object.entries(sources)
      .filter((src) => {
        return src[1]?.data_source_uuid;
      })
      .forEach((src) => {
        const srcData = src[1];
        const srcKey = src[0].split('.')[0];
        const sourceToAdd = {
          id: srcData.data_source_uuid,
          type: srcData.type,
          title: srcData.title,
          file_name: srcData.file_name,
          key_number: srcKey,
          ...(srcData?.reference_source_link && {
            reference_link: srcData?.reference_source_link,
          }),
          ...(srcData.type === 'qa' && {
            qa_text: srcData?.text,
          }),
        };

        // Check if a source with the same ID already exists in the set
        let sourceExists = false;
        for (const existingSource of sourceSet) {
          if (existingSource.id === sourceToAdd.id) {
            sourceExists = true;
            break;
          }
        }

        // If the source doesn't exist in the set, add it
        if (!sourceExists) {
          sourceSet.add(sourceToAdd);
        }
      });
    return Array.from(sourceSet);
  }, [sources]);

  const completeLoading = () => {
    setLoading(undefined);
  };

  return (
    <Transition
      show={showSources}
      enter="transition ease-out duration-100"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      {() => (
        <div
          className={cn(
            'p-1 flex items-center gap-1 max-w-[500px] md:max-w-[60%] flex-wrap transition-transform',
            reversed ? 'ml-auto justify-end' : '',
          )}
        >
          {/* Your collapsible content goes here */}
          {sourcesToShow.map((src) => {
            const { type, title, file_name, id, key_number, reference_link } = src;
            const isFile = type === 'upload' || type === 'google-drive';
            const isUrl = type === 'url';
            const isQA = type === 'qa';
            const isVideo = type === 'video';
            const isTable = type === 'table';
            const isLoading = loading === id;
            return (
              <button
                key={id}
                className={cn(
                  'w-fit flex items-center border rounded-md px-2 py-1 transition-all overflow-hidden',
                  chatSizeVariants.secondaryActionsText[size],
                )}
                type="button"
                onMouseEnter={() => {
                  setSourceHovered(id);
                }}
                onMouseLeave={() => {
                  setSourceHovered(undefined);
                }}
                onClick={() => {
                  if (reference_link) {
                    window.open(reference_link, '_blank');
                  } else if (isUrl || isVideo) {
                    const url = file_name.replace('.html', '');
                    window.open(url, '_blank');
                  } else if (isFile || isTable) {
                    setLoading(id);
                    downloadDataSource({ file_name, uuids: [id], canView: !!isFile }, () =>
                      setLoading(undefined),
                    );
                  } else if (isQA) {
                    const jsonString = JSON.stringify(src?.qa_text || '', null, 2);
                    const blob = new Blob([jsonString], {
                      type: 'application/json',
                    });
                    const dataUrl = URL.createObjectURL(blob);

                    // Open the data URL in a new tab
                    window.open(dataUrl);

                    // Clean up the data URL after opening the tab
                    URL.revokeObjectURL(dataUrl);
                  }
                }}
                style={
                  chatBotMessageColors
                    ? {
                        color: textColor,
                        borderColor: chatBotMessageColors.backgroundColor,
                        ...(sourceHovered === id && {
                          backgroundColor: chatBotMessageColors.backgroundColor,
                          color: chatBotMessageColors.textColor,
                        }),
                      }
                    : {
                        color: textColor,
                        borderColor: '#F2F5F9',
                        ...(sourceHovered === id && {
                          backgroundColor: '#F2F5F9',
                        }),
                      }
                }
              >
                {isLoading ? (
                  <Loader2
                    strokeWidth={1.75}
                    className={cn('mr-2 animate-spin', chatSizeVariants.secondaryActionIcons[size])}
                  />
                ) : (
                  <>
                    {isFile ? (
                      <File
                        strokeWidth={1.75}
                        className={cn('mr-2', chatSizeVariants.secondaryActionIcons[size])}
                      />
                    ) : isUrl ? (
                      <Link
                        strokeWidth={1.75}
                        className={cn('mr-2', chatSizeVariants.secondaryActionIcons[size])}
                      />
                    ) : isVideo ? (
                      <img
                        src="/img/youtube_logo.webp"
                        alt="YouTube"
                        className={cn('mr-2 object-contain', chatSizeVariants.secondaryActionIcons[size])}
                      />
                    ) : (
                      <MessagesSquare
                        strokeWidth={1.75}
                        className={cn('mr-2', chatSizeVariants.secondaryActionIcons[size])}
                      />
                    )}
                  </>
                )}
                {isInTextCitationsEnabled && <p className="mr-2">{key_number}.</p>}
                <p className="whitespace-nowrap overflow-ellipsis overflow-hidden">{title}</p>
              </button>
            );
          })}
        </div>
      )}
    </Transition>
  );
};

export default MessageSources;
