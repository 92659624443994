/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-new */
import React, { useEffect, useMemo, useState } from 'react';
import { Input } from 'components/ui/input';
import { Checkbox } from 'components/ui/checkbox';
import { Button } from 'components/ui/button';
import { Check, ChevronLeft, ChevronRight, Copy, Loader2, Trash } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import sourceService from 'api/source';
import { useAlerts } from 'providers/AlertProvider';
import useDataSources from 'hooks/useDataSources';
import { alerts } from 'utils/alert';
import { Chatbot, DataSource } from 'models/api/response.types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { Badge } from 'components/ui/badge';
import LoadingSpinner from 'components/helpers/LoadingSpinner';
import { cn } from 'utils/cn';

export const loadURLStates = ['pending_extraction', 'extracting'];

// can't use redux selected chatbot as this uses also in chatbot template creation flow
interface IURLUploader {
  chatbot: Chatbot;
  close: () => void;
}

const URLUploader: React.FC<IURLUploader> = ({ chatbot, close }) => {
  const { addAlert } = useAlerts();
  const { originalSourceData, pendingSources, refetchSources, removeSourcesById, setSourceData } =
    useDataSources(chatbot.uuid);
  const [webSiteUrl, setWebSiteUrl] = useState('');
  const [webSiteUrlError, setWebSiteUrlError] = useState(false);
  const [isSingleUrlOnly, setIsSingleUrlOnly] = useState(false);
  const [creating, setCreating] = useState<boolean>(false);
  const [selectedURLs, setSelectedURLs] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 15;
  const totalPages = Math.ceil((pendingSources?.length || 0) / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const setPage = (page: number) => {
    setSelectedURLs([]);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (totalPages < currentPage && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  const isCrawling = useMemo(() => {
    return pendingSources && pendingSources.some((src) => loadURLStates.includes(src?.crawl_status));
  }, [pendingSources]);

  const dataToUse = useMemo(() => {
    if (pendingSources) {
      return pendingSources
        .sort((a, b) => {
          const aIsPending = a.status === 'pending';
          const bIsPending = b.status === 'pending';

          if (aIsPending && !bIsPending) {
            return -1;
          }
          if (!aIsPending && bIsPending) {
            return 1;
          }
          return (
            (loadURLStates.includes(a.crawl_status) ? 0 : 1) -
            (loadURLStates.includes(b.crawl_status) ? 0 : 1)
          );
        })
        .slice(startIndex, endIndex);
    }
    return undefined;
  }, [pendingSources, startIndex, endIndex]);

  // show domain that user is tryong toc rawl
  const domainExtraction = useMemo(() => {
    if (dataToUse && dataToUse?.length > 0) {
      const source = dataToUse[0];
      const meta = JSON.parse(source.meta_json);
      try {
        const parsedUrl = new URL(meta.url);
        return parsedUrl.hostname;
      } catch (error) {
        console.error('Invalid URL:', error);
        return null;
      }
    }
    return undefined;
  }, [dataToUse]);

  const isValidUrl = (websiteUrl: string) => {
    try {
      new URL(websiteUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const createWebsiteDataSourceMutation = useMutation({
    mutationFn: (payload: { uuid: string; url: string }) =>
      sourceService.createWebsiteUrlDataSource(payload.uuid, payload.url),
    onSuccess: ({ data }) => {
      setSourceData([...(originalSourceData as DataSource[]), data]);
      setCreating(false);
      close();
    },
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        addAlert({
          severity: 'error',
          message: alerts.URL_EXISTS_ERROR,
        });
      } else {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      }
      setCreating(false);
    },
  });

  const addURLsToSourcesMutation = useMutation({
    mutationFn: (variables: string[]) =>
      sourceService.updateBatchDataSources(chatbot.uuid, {
        uuids: variables,
        convert_pending: true,
      }),
    onSuccess: (_, context) => {
      const newSources = (originalSourceData as DataSource[]).map((src) => {
        const isDuplicated = src.status !== 'pending';
        return {
          ...src,
          crawl_status: 'success' as DataSource['crawl_status'],
          ...(!isDuplicated && {
            status: context.includes(src.uuid) ? 'await' : src.status,
          }),
        };
      });
      setSourceData(newSources);
      setCreating(false);
      close();
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
      setCreating(false);
    },
  });

  const handleWebsiteSourcesCreate = () => {
    setCreating(true);
    if (isSingleUrlOnly) {
      createWebsiteDataSourceMutation.mutate({
        uuid: chatbot.uuid,
        url: webSiteUrl,
      });
    } else {
      sourceService
        .websiteScraping(webSiteUrl, chatbot.uuid)
        .then(() => {
          refetchSources();
          setTimeout(() => {
            setCreating(false);
          }, 1000);
        })
        .catch((err) => {
          setCreating(false);
          addAlert({
            severity: 'error',
            message:
              err?.response?.status === 403
                ? err?.response?.data?.error || alerts.LOAD_URL_ERROR
                : err?.response?.status === 402
                  ? alerts.FEATURE_CREDITS_ERROR
                  : alerts.SOMETHING_WRONG,
            timeout: 10000,
          });
        });
    }
  };

  const deleteSources = (uuids: string[]) => {
    removeSourcesById(uuids);
    setSelectedURLs([]);
    sourceService.deleteDataSources(uuids).catch(() => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
      refetchSources();
    });
  };

  return (
    <div className="w-full max-h-full overflow-hidden flex flex-col rounded-md bg-background p-6 text-left align-middle shadow-sm border">
      {!dataToUse || dataToUse.length === 0 ? (
        <>
          <h2 className="text-lg font-medium leading-6 text-gray-900">Add Website URL(s)</h2>
          <div className="flex flex-col gap-2 mt-6">
            <Input
              id="url"
              value={webSiteUrl}
              placeholder="https://www.example.com"
              onChange={(e) => {
                const { value } = e.target;
                setWebSiteUrl(value);

                if (!isValidUrl(value) && value.length > 0) {
                  setWebSiteUrlError(true);
                } else if (webSiteUrlError) {
                  setWebSiteUrlError(false);
                }
              }}
            />
            {webSiteUrl.length > 0 && !webSiteUrlError && !isSingleUrlOnly && (
              <p className="text-xs text-muted-foreground ml-1">
                This process will crawl all URLs starting with &quot;{webSiteUrl}&quot;, excluding any file
                resources on the website. Additionally, URLs already present in your data sources, having been
                previously crawled and trained, will not be included in the resulting list to prevent
                redundancy and maintain the integrity of the data set.
              </p>
            )}
            {webSiteUrlError && <p className="text-xs text-destructive ml-1">Invalid URL.</p>}
            <div className="flex items-center space-x-2 ml-1 mt-4">
              <Checkbox
                id="single-url"
                checked={isSingleUrlOnly}
                onCheckedChange={(checked) => {
                  setIsSingleUrlOnly(checked as boolean);
                }}
              />
              <label
                htmlFor="single-url"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Add single URL only
              </label>
            </div>
          </div>
          <div className="flex justify-end mt-6 gap-4">
            <Button
              onClick={() => {
                handleWebsiteSourcesCreate();
              }}
              className="self-start"
              disabled={webSiteUrlError || webSiteUrl.length === 0 || creating}
            >
              {creating && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
              {!isSingleUrlOnly ? (
                <>{creating ? 'Crawling' : 'Crawl'} Links</>
              ) : (
                <>{creating ? 'Adding' : 'Add'}</>
              )}
            </Button>
          </div>
        </>
      ) : (
        <>
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            {isCrawling ? `Crawling ${domainExtraction}` : 'Review Extracted URLs'}
          </h2>
          <p className="text-sm text-warning mt-2">
            {isCrawling
              ? `We are currently extracting URLs from the specified site. This process may take some time
            depending on the size and complexity of the site. Please be patient while we gather all sub-URLs.`
              : `Please review the extracted data below. You can remove any URLs that you do not wish to keep. After
              reviewing, click "Add to Sources". You do not need to select all the URLs to add them.`}
          </p>
          <div className="rounded-md border bg-background mt-4 overflow-auto flex-1">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[40px]">
                    <Checkbox
                      className="mt-2"
                      disabled={isCrawling}
                      checked={selectedURLs.length === dataToUse.length && dataToUse.length > 0}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          // allow to select only pending sources
                          setSelectedURLs(
                            dataToUse.filter((src) => src.status === 'pending').map((source) => source.uuid),
                          );
                        } else {
                          setSelectedURLs([]);
                        }
                      }}
                      aria-label="Select rows"
                    />
                  </TableHead>
                  <TableHead>
                    URL
                    {selectedURLs.length > 0 && !creating && !isCrawling && (
                      <Button
                        className="ml-4"
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          deleteSources(selectedURLs);
                        }}
                      >
                        Delete ({selectedURLs.length})
                      </Button>
                    )}
                  </TableHead>
                  <TableHead className="w-[230px]">Status</TableHead>
                  <TableHead className="w-[50px] text-right" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {dataToUse.map((url) => {
                  const meta = JSON.parse(url.meta_json);
                  const extracting = loadURLStates.includes(url.crawl_status);
                  const isDuplicated = url.status !== 'pending';
                  const isSelected = selectedURLs.includes(url.uuid);
                  return (
                    <TableRow key={url.uuid}>
                      <TableCell className="py-2 pr-0">
                        <Checkbox
                          className={cn('mt-2', isSelected ? 'border-none border-0' : '')}
                          checked={isSelected}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              setSelectedURLs([...selectedURLs, url.uuid]);
                            } else {
                              const newSelection = selectedURLs.filter((id: string) => id !== url.uuid);
                              setSelectedURLs(newSelection);
                            }
                          }}
                          disabled={isDuplicated}
                          onClick={(e) => e.stopPropagation()}
                          aria-label="Select row"
                        />
                      </TableCell>
                      <TableCell className="whitespace-nowrap overflow-hidden max-w-[640px] text-ellipsis py-2">
                        <a
                          href={meta.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-sm line-clamp-1 hover:underline text-secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {meta.url}
                        </a>
                      </TableCell>
                      <TableCell className="w-[20px] py-2">
                        <Badge
                          variant="outline"
                          className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap"
                        >
                          {extracting ? (
                            <>
                              <LoadingSpinner small />
                              Extracting
                            </>
                          ) : isDuplicated ? (
                            <>
                              <Copy strokeWidth={1.75} className="w-4 h-4 text-secondary" />
                              Duplicate
                            </>
                          ) : (
                            <>
                              <Check strokeWidth={1.75} className="w-4 h-4 text-green-500" />
                              Ready
                            </>
                          )}
                        </Badge>
                      </TableCell>
                      <TableCell className="text-right py-2">
                        <Button
                          variant="outline"
                          className="border-0 shadow-none text-destructive h-4 py-0 hover:text-destructive hover:bg-transparent outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent"
                          onClick={() => {
                            deleteSources([url.uuid]);
                          }}
                          disabled={isCrawling || isDuplicated}
                        >
                          <Trash strokeWidth={1.75} className="w-4 h-4" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className="flex justify-end mt-6 gap-4">
            {totalPages > 1 && (
              <div className="text-sm flex items-center justify-end gap-4 mr-auto">
                <p className="text-muted-foreground">
                  Page {currentPage} of {totalPages}
                </p>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  disabled={currentPage === 1}
                  onClick={() => setPage(currentPage - 1)}
                >
                  <ChevronLeft className="w-4 h-4" />
                </Button>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  disabled={totalPages === currentPage}
                  onClick={() => setPage(currentPage + 1)}
                >
                  <ChevronRight className="w-4 h-4" />
                </Button>
              </div>
            )}
            <Button
              onClick={() => {
                if (pendingSources) {
                  addURLsToSourcesMutation.mutate(pendingSources.map((src) => src.uuid));
                }
              }}
              disabled={isCrawling}
              className="self-start"
            >
              {(creating || isCrawling) && (
                <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
              )}
              {isCrawling ? 'Crawling' : <>{creating ? 'Adding' : 'Add'} to Sources</>}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default URLUploader;
