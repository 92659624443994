/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot } from 'models/api/response.types';
import { cn } from 'utils/cn';
import { Button } from 'components/ui/button';
import integrationService from 'api/integrations';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { Layers, Loader2 } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import ConfirmHubspotDeleteionDialog from 'components/Dialogs/Integrations/ConfirmHubspotDeletionDialog';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';

const HubspotIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const { appTitle } = useWhitelabelData();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [open, setOpen] = useState<boolean>(false);
  const [disconnectIntegration, setDisconnectIntegration] = useState<boolean>(false);
  const integrationDataQueryKey = ['hubspot-integration', chatbot.uuid];

  const { data: integrationData, isLoading: isIntegrationLoading } = useQuery({
    queryKey: integrationDataQueryKey,
    queryFn: () => integrationService.getHubspotIntegration(chatbot.uuid),
    enabled: open,
  });

  // when showing integration append script that connects hubspot to our app
  useEffect(() => {
    if (show) {
      return () => {};
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  const launchHubspotSignin = () => {
    window.open(
      `https://app.hubspot.com/oauth/authorize?client_id=b1430694-0e0f-47d1-9b53-0102e9f16bdc&redirect_uri=${window.location.protocol}//${window.location.host}/site/chatbot/integrations&scope=tickets%20crm.objects.contacts.write%20oauth%20conversations.read%20conversations.write%20crm.objects.contacts.read&state=hubspot_create`,
      '_self',
      // `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`,
    );
  };

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img
            className="w-[40px] h-[40px] rounded-md"
            src="/img/integrations/hubspot/hubspot.webp"
            alt="Hubspot"
          />
          <h1 className="text-xl font-semibold">Add to HubSpot</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs
        className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6 flex-1 flex flex-col"
        defaultValue="account"
      >
        <TabsList className="w-fit">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="guide">Guide</TabsTrigger>
        </TabsList>
        <TabsContent value="account" className="flex-1">
          {!isIntegrationLoading ? (
            <>
              {integrationData ? (
                <>
                  <Card className="mt-4">
                    <CardHeader>
                      <CardTitle>Connected HubSpot Account</CardTitle>
                      <CardDescription>
                        This HubSpot account is currently selected as the active account for your chatbot. A
                        ticket will be created after a conversation takes place. If this is not the correct
                        account, you may disconnect it and select another HubSpot account from the integration
                        settings.
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-6">
                      <div className="flex items-center justify-between space-x-4">
                        <div className="flex items-center space-x-4">
                          <Layers className="w-8 h-8" strokeWidth={1.2} />
                          <div>
                            <p className="text-sm font-medium leading-none">{integrationData.name}</p>
                          </div>
                        </div>
                        <Button
                          variant="outline"
                          onClick={() => setDisconnectIntegration(true)}
                          className="ml-auto hover:bg-destructive/5 hover:border-destructive hover:text-destructive"
                        >
                          Disconnect
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                  <ConfirmHubspotDeleteionDialog
                    open={disconnectIntegration}
                    close={() => setDisconnectIntegration(false)}
                  />
                </>
              ) : (
                <>
                  <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
                    To connect your HubSpot Domain to {appTitle}, you need a HubSpot Account that has valid
                    permissions within your domain. <p className="my-4" /> This integration allows you to
                    create tickets whenever a user engages with your chatbot, and create contacts based on
                    variable/form collection We <strong>strongly</strong> recommend that you follow our guide
                    on how to set up everything.
                  </div>
                  <Button
                    onClick={launchHubspotSignin}
                    className="hover:opacity-90 transition-all bg-[#EC6744] hover:bg-[#EC6744]"
                  >
                    Login with Hubspot
                  </Button>
                </>
              )}
            </>
          ) : (
            <p className="mt-4 text-sm text-muted-foreground flex items-center">
              <Loader2
                className="w-4 h-4 min-w-[16px] text-secondary mr-2 animate-spin transition-all"
                strokeWidth={1.75}
              />
              Please wait while we retrieve the Hubspot integration data for this chatbot.
            </p>
          )}
        </TabsContent>
        <TabsContent value="guide">
          <h2 className="text-2xl font-bold my-4">Guide to Native Integration with HubSpot</h2>
          <p className="font-normal text-muted-foreground text-base my-4">
            This native integration allows for auto-ticket creation and auto-contact management in HubSpot.
            When a chat session is started, it will automatically create a ticket in HubSpot and include the
            conversation details. Additionally, it will create a contact in HubSpot with user information such
            as email, name, address, and phone number. Follow the steps below to enable and configure this
            integration.
          </p>
          <h3 className="text-xl font-semibold mt-6 mb-2">1. Setting Up the Integration</h3>
          <p className="font-normal text-muted-foreground text-base my-4">
            To start using the integration, you&apos;ll need to login with your HubSpot account. Ensure you
            have the necessary permissions in HubSpot to install Integrations.
          </p>
          <ol className="list-decimal ml-6">
            <li>
              <strong>Go to the &quot;Account&quot; tab in the HubSpot Integration</strong>
              <p className="font-normal text-muted-foreground text-base">Log in with your HubSpot account.</p>
            </li>
            <li>
              <strong>Follow Instructions in HubSpot Connection Page until you return to {appTitle}</strong>
            </li>
            <li>
              <strong>Enable Ticket and Contact Creation</strong>
              <p className="font-normal text-muted-foreground text-base">
                Check that the connection was successful in the &quot;Account&quot; section above. You should
                see &quot;Connected HubSpot Account&quot;
              </p>
            </li>
          </ol>
          <h3 className="text-xl font-semibold mt-6 mb-2">2. Configuring Auto-Contact Creation</h3>
          <p className="font-normal text-muted-foreground text-base my-4">
            Auto-contact creation ensures that each new user interacting with your chatbot is added to your
            HubSpot contacts. (if information is collected)
          </p>
          <strong>Map User Information using Form Submission</strong>
          <p className="font-normal text-muted-foreground text-base my-4">
            Contacts can be created on form submission using the default variables (Email, Name, Phone). If
            you want further customization, you should utilize the webhook functionality.
          </p>

          <strong>Map User Information using Agent Variables</strong>
          <p className="font-normal text-muted-foreground text-base my-4">
            Specify which user details (e.g., email, phone number) should be sent to HubSpot. This is done by
            setting up any front-facing agent to collect the following variables. You can disable Auto-Contact
            by not collecting a <code>email</code> variable. See the table below for options
          </p>
          <hr />
          <Table className="table-fixed">
            <TableHeader>
              <TableRow>
                <TableHead>Variable Name</TableHead>

                <TableHead>Variable Description</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <code className="bg-black/10 p-1">email</code>
                </TableCell>
                <TableCell>
                  <p className="font-normal text-muted-foreground">
                    User&apos;s Email Address such as &quot;john_doe@example.com&quot;, this is{' '}
                    <strong>required</strong> for any of the other variables to be collected.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <code className="bg-black/10 p-1">first_name</code>
                </TableCell>
                <TableCell>
                  <p className="font-normal text-muted-foreground">
                    User&apos;s First Name such as &quot;John&quot;
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <code className="bg-black/10 p-1">last_name</code>
                </TableCell>
                <TableCell>
                  <p className="font-normal text-muted-foreground">
                    User&apos;s Last Name such as &quot;Doe&quot;
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <code className="bg-black/10 p-1">phone</code>
                </TableCell>
                <TableCell>
                  <p className="font-normal text-muted-foreground">
                    User&apos;s Phone Number such as &quot;15555555555&quot;
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <code className="bg-black/10 p-1">address</code>
                </TableCell>
                <TableCell>
                  <p className="font-normal text-muted-foreground">
                    User&apos;s Physical Address such as &quot;123 Main Street, New York, USA 12345&quot;
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <p className="mb-2 text-foreground">
            That&apos;s it! You have now connected your HubSpot account with {appTitle}.
          </p>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default HubspotIntegration;
