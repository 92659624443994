/* eslint-disable react/jsx-props-no-spreading */
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { supportedFileFormats, supportedImageFormats, supportedTableFormats } from 'utils/dataSources';
import useDataSources from 'hooks/useDataSources';
import axios from 'axios';
import { Chatbot } from 'models/api/response.types';
import { cn } from 'utils/cn';
import { Input } from 'components/ui/input';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import FilesUploader from './FilesUploader';

const maxConcurrentUploads = 1;
const progressBarDebounceInterval = 100;

export enum UploadTaskType {
  create = 'create',
  update = 'update',
}

interface Datasource {
  uuid: string;
}

const supportedDataFormarts: { [key: string]: string[] } = {
  file: supportedFileFormats,
  table: supportedTableFormats,
  image: supportedImageFormats,
};

export class UploadTask {
  file: File;
  formData?: FormData;
  xhr?: XMLHttpRequest;
  errorMessage: string;
  sent: boolean;
  state: string;
  loaded: number;
  total: number;
  response: any;
  datasource?: Datasource;
  timestamp: number;
  constructor(file: File) {
    this.file = file;
    this.sent = false;
    this.state = 'queued';
    this.errorMessage = '';
    this.loaded = 0;
    this.total = 0;
    this.timestamp = performance.now();
  }
}

// can't use redux selected chatbot as this uses also in chatbot template creation flow
interface IFileUloader {
  chatbot: Chatbot;
  type: string;
  usedData: {
    tokens: number;
    file_size: number;
  };
  // only for template steps to proceed forward
  close?: () => void;
}

// can't use redux selected chatbot as this uses also in chatbot template creation flow
const FileUploaderContainer: React.FC<IFileUloader> = ({ chatbot, close, type, usedData }) => {
  const { refetchSources } = useDataSources(chatbot.uuid);
  const { canUploadImageSources } = useSubscriptionInfo(chatbot);
  const [fileSources, setFileSources] = useState<File[]>([]);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [dragDropFiles, setDragDropFiles] = useState<boolean>(false);
  const [isPostUpload, setIsPostUpload] = useState(false);
  const [uploadRowData, setUploadRowData] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploads = useRef<UploadTask[]>([]) as MutableRefObject<UploadTask[]>;
  const lastRender = useRef<number>(performance.now());

  // as close function exists only in template builder
  const isTemplateBuilder = useMemo(() => {
    if (close) {
      return true;
    }
    return false;
  }, []);

  // cover iteration where no files exist/ prevent of showing dialog twice
  useEffect(() => {
    return () => {
      setFileSources([]);
    };
  }, [type]);

  const upgradeSubscriptionRequired = useMemo(() => {
    return type === 'image' && !canUploadImageSources;
  }, [type, canUploadImageSources]);

  const handleDragLeave = useCallback((e: any) => {
    // Check if the leave event is related to the main container
    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
      setDragDropFiles(false);
    }
  }, []);

  const handleDragEnter = useCallback((e: any) => {
    e.preventDefault();
    setDragDropFiles(true);
  }, []);

  const { getRootProps } = useDropzone({
    noClick: true,
    onDrop: (files) => {
      const formats = supportedDataFormarts[type];
      const filesToUpload = files.filter((file) => {
        for (const ext of formats) {
          if (file.name.toLowerCase().endsWith(ext)) {
            return true;
          }
        }
        return false;
      });
      if (filesToUpload.length > 0) {
        setFileSources(filesToUpload);
      }
      setDragDropFiles(false);
    },
  });

  const fileInputOnChange = () => {
    setFileSources([]);
    if (fileInputRef?.current?.files?.length) {
      const { files } = fileInputRef.current;
      const fileArray = Array.from(files);
      const supportedFormats = supportedDataFormarts[type].join(',');
      setFileSources(
        fileArray.filter((file: File) => {
          const fileName = file.name;
          if (!fileName) {
            return false;
          }
          const fileType = fileName.split('.').pop()?.toLowerCase();
          return supportedFormats.includes(fileType || '');
        }),
      );
      fileInputRef.current.value = '';
    }
  };

  const removeFiles = (files: string[]): void => {
    const newFiles = fileSources.filter((item) => !files.includes(`${item.name}+${item.size}`));
    setFileSources(newFiles);
  };

  // monitor uploads to only send new requests when below maxConcurrentUploads
  const updateRequests = (forceUpdate = false) => {
    let queued = 0;
    let pending = 0;
    uploads.current.forEach((upload) => {
      queued += upload.state === 'queued' ? 1 : 0;
      pending += upload.state === 'pending' || (upload.xhr?.readyState === 1 && upload.sent) ? 1 : 0;
    });
    // send pending requests if below maxConcurrentUploads
    if (pending < maxConcurrentUploads) {
      for (let i = 0; i < uploads.current.length; i += 1) {
        const upload = uploads.current[i];
        if (!upload.sent && upload.xhr?.readyState === 1) {
          upload.xhr.send(upload.formData);
          upload.sent = true;
          break;
        }
      }
    }

    // keep updating requests until all requests are resolved
    if (queued + pending > 0) {
      window.setTimeout(() => {
        setFileIsUploading(true);
        updateRequests();
      }, progressBarDebounceInterval);
    } else {
      setFileIsUploading(false);
      setIsPostUpload(true);
      refetchSources();
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRowData(forceUpdate);
  };

  // Update row data from uploads ref
  const updateRowData = (force = false) => {
    if (performance.now() - lastRender.current > progressBarDebounceInterval || force) {
      setUploadRowData(
        uploads.current.map((upload) => ({
          ...upload,
          filename: upload.file.name,
        })),
      );
      lastRender.current = performance.now();
    }
  };

  const uploadFile = (index: number) => {
    const upload = uploads.current[index];
    const { file } = upload;
    // construct multipart/form-data
    const formData = new FormData();
    formData.append('chatbot_uuid', chatbot?.uuid);
    formData.append('file', file);
    formData.append('meta_json', '{}');
    formData.append('content_type', file.type);
    upload.formData = formData;
    // use xhr instead of fetch for onprogress event handler
    upload.xhr = new XMLHttpRequest();
    const url = `${axios.defaults.baseURL}/api/create-data-source/upload`;
    upload.xhr.open('POST', url);
    upload.xhr.setRequestHeader('Authorization', axios.defaults.headers.common.Authorization as string);
    // update upload onprogress event
    upload.xhr.upload.addEventListener(
      'progress',
      (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
        upload.loaded = e.loaded;
        upload.total = e.total;
        upload.state = 'pending';
        upload.timestamp = performance.now();
        updateRowData();
      },
      false,
    );
    // update upload loadend event
    upload.xhr.addEventListener('loadend', () => {
      if (upload.xhr) {
        upload.loaded = upload.total; // ensure progress bars reach 100%
        try {
          upload.response = JSON.parse(upload.xhr.responseText);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err, upload.xhr.responseText);
        }
        if (upload.xhr.status === 200 && upload.response) {
          upload.datasource = upload.response;
          upload.state = 'fulfilled';
          upload.timestamp = performance.now();
        } else if (upload.xhr.status === 409 && upload.response) {
          upload.state = 'duplicate';
          upload.timestamp = performance.now();
        } else {
          upload.state = 'rejected';
          upload.errorMessage = upload.xhr.responseText;
          upload.timestamp = performance.now();
        }
        updateRequests(true);
        updateRowData(true);
      }
    });
    upload.state = 'queued';
    updateRequests(true);
  };

  const uploadFiles = () => {
    setFileIsUploading(true);
    uploads.current = [];
    for (let i = 0; i < fileSources.length; i += 1) {
      const upload = new UploadTask(fileSources[i]);
      uploads.current.push(upload);
      uploadFile(i);
    }
  };

  const getUploadProgress = (index: number) => {
    const upload = uploads.current[index];
    return upload?.state || 'error';
  };

  return (
    <>
      {fileSources.length > 0 ? (
        <FilesUploader
          files={fileSources}
          removeFiles={removeFiles}
          uploadFiles={uploadFiles}
          fileIsUploading={fileIsUploading}
          isPostUpload={isPostUpload}
          isTemplateBuilder={isTemplateBuilder}
          getUploadProgress={getUploadProgress}
          close={() => {
            if (close) {
              close();
            } else {
              setFileSources([]);
              setFileIsUploading(false);
              setIsPostUpload(false);
            }
          }}
          usedData={usedData}
        />
      ) : (
        <>
          {!upgradeSubscriptionRequired ? (
            <div
              {...getRootProps()}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onMouseDown={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
              className={cn(
                'cursor-pointer xl:absolute top-0 left-0 w-full h-full border-[1px] border-dashed rounded-md border-primary flex items-center justify-center transition-colors',
                dragDropFiles ? 'bg-accent/50' : 'bg-background',
              )}
            >
              <div
                className={cn(
                  'w-full  p-6 rounded-md flex flex-col items-center max-w-[470px] transition-all',
                  dragDropFiles && 'bg-background  border shadow-sm',
                )}
              >
                <img className="h-14" alt="Upload file icon" src="/img/upload-file.gif" />
                <h1 className="text-center text-sm text-muted-foreground">
                  Drag & drop files here, or click to select files
                </h1>
                <p className="text-center text-sm mt-2">
                  We currently support{' '}
                  {type === 'file' ? (
                    <>
                      <strong>.pdf</strong>, <strong>.docx</strong>, <strong>.txt</strong>,{' '}
                      <strong>.md</strong>, and <strong>.tex</strong>
                    </>
                  ) : type === 'table' ? (
                    <>
                      <strong>.xlsx</strong>, <strong>.xls</strong>, and <strong>.csv</strong>
                    </>
                  ) : (
                    <>
                      <strong>.png</strong>, <strong>.jpg</strong>, <strong>.jpeg</strong>, and{' '}
                      <strong>.webp</strong>
                    </>
                  )}{' '}
                  file types. Our system automatically filters out any invalid file types, ensuring a smooth
                  and efficient process.
                </p>
              </div>
            </div>
          ) : (
            <UpgradeRequired
              className="absolute bg-background rounded-md border min-h-[300px]"
              heading="Upgrade to Unlock Image Upload Feature"
            >
              Please upgrade your plan to enable image uploads. Train your chatbot with images to enhance its
              responses and include visuals in its replies.
            </UpgradeRequired>
          )}
        </>
      )}
      <Input
        className="hidden"
        accept={supportedDataFormarts[type].join(',')}
        ref={fileInputRef}
        id="file"
        type="file"
        multiple
        onChange={fileInputOnChange}
      />
    </>
  );
};

export default FileUploaderContainer;
