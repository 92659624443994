/* eslint-disable no-empty */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import { cn } from 'utils/cn';
import { alerts } from 'utils/alert';
import { Button } from 'components/ui/button';
import integrationService from 'api/integrations';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import StatusDot from 'components/helpers/StatusDot';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Loader2, Phone } from 'lucide-react';
import ConfirmWhatsAppDeletionDialog from 'components/Dialogs/Integrations/ConfirmWhatsAppDeletionDialog';
import { Badge } from 'components/ui/badge';
import SetWhatsAppPinDialog from 'components/Dialogs/Integrations/SetWhatsAppPinDialog';

const WhatsAppIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const queryClient = useQueryClient();
  const { appTitle } = useWhitelabelData();
  const { addAlert } = useAlerts();
  const [loginData, setLoginData] = useState<undefined | any>(undefined);
  const [loginCode, setLoginCode] = useState<string>('');
  const [connecting, setConnecting] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [disconnectIntegration, setDisconnectIntegration] = useState<boolean>(false);
  const [registeringIntegration, setRegisteringIntegration] = useState<boolean>(false);
  const [setUpPin, setSetUpPin] = useState<boolean>(false);
  const integrationDataQueryKey = ['whatsapp-integration', chatbot.uuid];
  const integrationPhoneDataQueryKey = ['whatsapp-integration-phone', chatbot.uuid];
  const { data: integrationData, isLoading: isIntegrationLoading } = useQuery({
    queryKey: integrationDataQueryKey,
    queryFn: () => integrationService.getWhatsAppIntegration(chatbot.uuid),
    enabled: show,
  });

  const { data: whatsAppPhone, isLoading: isPhoneInfoLoading } = useQuery({
    queryKey: integrationPhoneDataQueryKey,
    queryFn: () => integrationService.getWhatsAppIntegrationRegistration(chatbot.uuid),
    enabled: show,
  });

  useEffect(() => {
    if (!loginData || !loginCode) return;
    if (loginData.type === 'WA_EMBEDDED_SIGNUP') {
      // if user finishes the Embedded Signup flow and the popup window closes
      if (loginData.event === 'FINISH' || loginData.event === 'FINISH_ONLY_WABA') {
        setRegisteringIntegration(true);
        const { phone_number_id, waba_id } = loginData.data;
        // For now, you do not need to do anything here. Once App Review has been completed, then you
        // will pass this data to your backend service and use Twilio's Senders API to register the number.
        integrationService
          .createWhatsAppIntegration({
            chatbot_uuid: chatbot.uuid,
            waba_id,
            phone_number_id,
            code: loginCode,
          })
          .then((newIntegrationData) => {
            queryClient.setQueryData(integrationDataQueryKey, newIntegrationData);
            queryClient.invalidateQueries({ queryKey: integrationPhoneDataQueryKey });
            setLoginCode('');
            setLoginData(undefined);
            setTimeout(() => {
              addAlert({
                severity: 'success',
                message: alerts.WHATSAPP_SETUP_SUCCESS,
              });
              setRegisteringIntegration(false);
            }, 1000);
          })
          .catch((err) => {
            if (err?.response?.status === 409) {
              // duplication, already exists in another chatbot
              addAlert({
                severity: 'error',
                message: alerts.WHATSAPP_DUPLICATE_ERROR,
                timeout: 10000,
              });
            } else {
              addAlert({
                severity: 'error',
                message: alerts.WHATSAPP_SETUP_ERROR,
              });
            }
            setRegisteringIntegration(false);
          });
      } else {
        // user canceled the Embedded Signup flow by closing the popup window before the last step
        // const { current_step } = data.data;
        addAlert({
          severity: 'error',
          message: alerts.WHATSAPP_SETUP_ERROR,
        });
      }
    }
  }, [loginCode, loginData]);

  useEffect(() => {
    if (show) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);

      window.fbAsyncInit = () => {
        FB.init({
          appId: '430325189614145', // Your Meta App ID
          version: 'v20.0', // Meta Graph API version
        });
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [show]);

  const authChangeEvent = (response: any) => {
    const token = response?.authResponse?.code;
    if (response?.status === 'connected' && token) {
      setLoginCode(token);
    }
    setConnecting(false);
  };

  // render embedded facebook auth flow
  const launchEmbeddedSignup = () => {
    setConnecting(true);
    // Launch Facebook login
    FB.Event.subscribe('auth.authResponseChange', authChangeEvent);
    FB.login(authChangeEvent, {
      config_id: '370384279395132',
      auth_type: 'rerequest', // avoids "user is already logged" in errors if users click the button again before refreshing the page
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        sessionInfoVersion: 2, // required to get WABA ID
        // set this to 'only_waba_sharing' if using a Twilio SMS capable number
        // featureType: 'only_waba_sharing',
        // setup: {
        //   solutionID: '756908723306066',
        // },
      },
    });
  };

  const embeddedSignupInfoListener = (event: any) => {
    if (event.origin !== 'https://www.facebook.com') return;
    // need try/catch block because facebook returns different responses
    // and one of them can't be parsed
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow and the popup window closes
        if (data.event === 'FINISH' || data.event === 'FINISH_ONLY_WABA') {
          setLoginData(data);
        }
      }
    } catch {} // integration setup was canceled
  };

  useEffect(() => {
    if (show) {
      // listen for embedded signup events
      window.addEventListener('message', embeddedSignupInfoListener);

      return () => {
        window.removeEventListener('message', embeddedSignupInfoListener);
      };
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  const loginWithFacebookButton = (text: string) => {
    return (
      <Button
        disabled={connecting}
        onClick={launchEmbeddedSignup}
        className="hover:opacity-90 transition-all"
        style={{
          backgroundColor: '#2C64F6',
        }}
      >
        {connecting ? (
          <Loader2 className="transition-all animate-spin w-6 h-6 min-w-[24px] mr-2" strokeWidth={1.75} />
        ) : (
          <img
            src="/img/integrations/facebook.svg"
            className="w-6 h-6 min-w-[24px] mr-2"
            alt="Facebook Logo"
          />
        )}
        {text}
      </Button>
    );
  };

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img
            className="w-[40px] h-[40px] rounded-md"
            src="/img/integrations/whatsapp/whatsapp.png"
            alt="WhatsApp"
          />
          <h1 className="text-xl font-semibold">Add to WhatsApp</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs
        className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6 flex-1 flex flex-col"
        defaultValue="account"
      >
        <TabsList className="w-fit">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="guide">Guide</TabsTrigger>
        </TabsList>
        <TabsContent value="account" className="flex-1">
          {!registeringIntegration ? (
            <>
              {!isIntegrationLoading && !isPhoneInfoLoading ? (
                <>
                  {whatsAppPhone ? (
                    <>
                      <Card className="mt-4">
                        <CardHeader>
                          <CardTitle className="flex items-center justify-between gap-2">
                            {whatsAppPhone.status === 'CONNECTED'
                              ? 'Connected Phone Number'
                              : 'Add a Pin Number'}
                            {whatsAppPhone.status !== 'CONNECTED' && (
                              <Badge
                                variant="outline"
                                className="w-fit rounded-md py-1 h-fit flex gap-2 items-center font-medium whitespace-nowrap capitalize"
                              >
                                <StatusDot className="h-4 w-4 p-1" status="loading" />
                                {String(whatsAppPhone.status.toLowerCase())}
                              </Badge>
                            )}
                          </CardTitle>
                          <CardDescription>
                            {whatsAppPhone.status === 'CONNECTED' &&
                            whatsAppPhone.platform_type === 'CLOUD_API'
                              ? `This phone number is connected to your chatbot.
                        All automated messages and interactions will be handled through it. If this
                        is not the correct number, you may disconnect it and reconnect your account.`
                              : `The last step is to connect your phone number to ${appTitle} using your two-step verification PIN. 
                              Please click on "Connect." After entering the PIN, we will connect your phone number to the chatbot.`}
                            {whatsAppPhone.status === 'CONNECTED' &&
                            whatsAppPhone.platform_type === 'CLOUD_API' ? (
                              ''
                            ) : (
                              <p className="font-medium py-2">
                                Important: You cannot use the WhatsApp or WhatsApp Buisness Apps along with{' '}
                                {appTitle}. To see how to disconnect them, view the guide.
                              </p>
                            )}
                          </CardDescription>
                        </CardHeader>
                        <CardContent className="grid gap-6">
                          <div className="flex items-center justify-between space-x-4">
                            <div className="flex items-center space-x-4">
                              <Phone className="w-8 h-8" strokeWidth={1.2} />
                              <div>
                                <p className="text-sm font-medium leading-none">
                                  {whatsAppPhone.verified_name}
                                </p>
                                <p className="text-sm text-muted-foreground">
                                  {whatsAppPhone.display_phone_number}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-center gap-4 ml-auto">
                              {whatsAppPhone.status !== 'CONNECTED' && (
                                <Button
                                  className="ml-auto"
                                  disabled={whatsAppPhone.status === 'CONNECTED'}
                                  onClick={() => setSetUpPin(true)}
                                >
                                  Connect
                                </Button>
                              )}
                              <Button
                                variant="outline"
                                onClick={() => setDisconnectIntegration(true)}
                                className="hover:bg-destructive/5 hover:border-destructive hover:text-destructive"
                              >
                                Remove Integration
                              </Button>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                      <ConfirmWhatsAppDeletionDialog
                        open={disconnectIntegration}
                        close={() => setDisconnectIntegration(false)}
                      />
                      <SetWhatsAppPinDialog open={setUpPin} close={() => setSetUpPin(false)} />
                    </>
                  ) : (
                    <>
                      {integrationData ? (
                        <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
                          We were unable to locate a phone number that matches this account. This may be due
                          to a disconnection or an incorrect number on file. Please reconnect the correct
                          phone number associated with your Facebook Business Account. If you continue to
                          experience issues, please reach out to our support team for further assistance.
                        </div>
                      ) : (
                        <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
                          To connect your Phone Number through WhatsApp to {appTitle}, you need an available
                          phone number. If you have one ready, you can set up your business portfolio and
                          profile when you select, <strong>&quot;Login with Facebook&quot;</strong> button
                          below. We <strong>highly</strong> recommend that you follow our guide on how to set
                          up everything.
                        </div>
                      )}
                      {loginWithFacebookButton(
                        integrationData ? 'Reconnect with Facebook' : 'Login with Facebook',
                      )}
                    </>
                  )}
                </>
              ) : (
                <p className="mt-4 text-sm text-muted-foreground flex items-center">
                  <Loader2
                    className="w-4 h-4 min-w-[16px] text-secondary mr-2 animate-spin transition-all"
                    strokeWidth={1.75}
                  />
                  Please wait while we retrieve the WhatsApp integration data for this chatbot.
                </p>
              )}
            </>
          ) : (
            <p className="mt-4 text-sm text-muted-foreground flex items-start">
              <Loader2
                className="w-4 h-4 min-w-[16px] text-secondary mr-2 animate-spin transition-all mt-1"
                strokeWidth={1.75}
              />
              We are in the process of registering your Facebook Business Profile. This may take a few
              moments. Please wait patiently while we complete the registration.
            </p>
          )}
        </TabsContent>
        <TabsContent value="guide">
          <p className="font-normal text-muted-foreground text-base my-4">
            In this guide, you&apos;ll discover how to automate your WhatsApp Business conversations
            effectively. Follow these step-by-step instructions to set up your WhatsApp Business with{' '}
            {appTitle}.
          </p>
          <div className="mb-6">
            <h2 className="text-base font-semibold mb-2">What You&apos;ll Need:</h2>
            <ul className="list-disc list-inside text-muted-foreground">
              <li>A Facebook Account</li>
              <li>A Valid Phone Number</li>
            </ul>
          </div>
          <div className="mb-6">
            <h2 className="text-base font-semibold mb-2">
              To use WhatsApp with your chatbot, you cannot use the WhatsApp or WhatsApp Business App
              simultaneously while using this Integration.
            </h2>
            <p className="mb-2 text-muted-foreground">
              To remove your phone number from the WhatsApp/WhatsApp Business App:
            </p>
            <ul className="list-disc list-inside text-muted-foreground">
              <li>Navigate to WhatsApp or WhatsApp Business app.</li>
              <li>Go to Settings &gt; Account.</li>
              <li>
                Select Delete my account. This may take a few minutes, but after that, the number will be
                available to use.
              </li>
            </ul>
          </div>
          <div className="mb-6">
            <h2 className="text-base font-semibold mb-2">
              If you have ever set up two-step verification and you cannot remember your PIN, you must disable
              two-step verification:
            </h2>
            <ul className="list-disc list-inside text-muted-foreground">
              <li>Navigate to your WhatsApp Business Account and log in.</li>
              <li>Choose the phone number you would like to integrate.</li>
              <li>Go to Settings &gt; Two-step verification and choose to turn off two-step verification.</li>
            </ul>
          </div>
          <div className="mb-6">
            <h2 className="text-base font-semibold mb-2">Integrating {appTitle} with WhatsApp Business</h2>
            <ol className="list-decimal list-inside text-muted-foreground">
              <li>
                Navigate back to the <strong>Account tab</strong> to start.
              </li>
              <li>Click the &quot;Login with Facebook&quot; button.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step1WhatsApp.png"
                alt="Login with Facebook"
                loading="lazy"
                decoding="async"
              />
              <li>Log in with your personal Facebook Account.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step2WhatsApp.png"
                alt="Personal Account"
                loading="lazy"
                decoding="async"
              />
              <li>Click &quot;Get started&quot;.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step3WhatsApp.png"
                alt="Get Started"
                loading="lazy"
                decoding="async"
              />
              <li>Choose or create a business portfolio.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step4WhatsApp.png"
                alt="Business Portfolio"
                loading="lazy"
                decoding="async"
              />
              <li>Create a WhatsApp business profile or select an existing one.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step5WhatsApp.png"
                alt="Buisness Profile"
                loading="lazy"
                decoding="async"
              />
              <li>Fill in the information for the Business profile.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step6WhatsApp.png"
                alt="Business Profile Setup"
                loading="lazy"
                decoding="async"
              />
              <li>Add and verify a phone number. Try to keep only one phone number on this profile.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step7WhatsApp.png"
                alt="Verify Phone Number"
                loading="lazy"
                decoding="async"
              />
              <li>Click &quot;Continue&quot;.</li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step8WhatsApp.png"
                alt="Approve Required Permissions"
                loading="lazy"
                decoding="async"
              />
              <li>Wait a few seconds for information verification.</li>
              <li>
                Click on &quot;Finish&quot;. If nothing happens, please try again by selecting &quot;Login in
                with Facebook&quot; again.
              </li>
              <img
                className="rounded-md border my-4 mx-auto"
                src="https://app.gpt-trainer.com/blog-assets/whatsapp_integration/Step9WhatsApp.png"
                alt="Finish"
                loading="lazy"
                decoding="async"
              />
              <li>
                When it is done loading, you will need to click &quot;Connect&quot; and set up a two-step
                verifcation pin
              </li>
              <p className="my-2 text-warning">
                <strong>Note:</strong> For most, this will simply be setting up a new pin, however if you have
                set one up before you must enter it. Additionally, make sure you have removed your phone
                number from the WhatsApp app before doing the above step
              </p>
            </ol>
          </div>
          <div className="mb-6">
            <h2 className="font-semibold mb-2">You&apos;re Ready!</h2>
            <p className="mb-2 text-muted-foreground">
              Success! Your integration is now live, allowing you to send 1000 free messages every month. To
              increase this limit, add a payment method on your{' '}
              <a
                className="text-secondary font-bold hover:underline"
                href="https://business.facebook.com/billing_hub/accounts?account_type=whatsapp-business-account"
                target="_blank"
                rel="noreferrer"
              >
                Meta billing settings
              </a>
              .
            </p>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default WhatsAppIntegration;
