import contentService from 'api/content';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import useChatbots from 'hooks/useChatbots';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Image as IconImage, Loader2, UploadIcon } from 'lucide-react';
import { Chatbot, FileResponseData } from 'models/api/response.types';
import React, { useRef, useState } from 'react';
import { ChatBotMeta } from 'utils/bot';
import { alerts } from 'utils/alert';
import { useAlerts } from 'providers/AlertProvider';
import ImageCropperDialog from 'components/Dialogs/ImageCropperDialog';

// need to pass chatbot as we are using this component
// in template form creation
const ChatbotLogoAppearance: React.FC<{
  chatbot: Chatbot;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}> = ({ chatbot, chatInterface, setChatInterface }) => {
  const { addAlert } = useAlerts();
  const { updateChatbotByUUID } = useChatbots();
  const { canSeeProfilePicture } = useSubscriptionInfo();
  const profilePictureRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageToUpload, setImageToUpload] = useState<File | undefined>(undefined);

  const updateChatbotLogo = (file: File) => {
    setLoading(true);
    contentService.uploadChatbotFile(chatbot.uuid, file, 'chatbot_logo', (response?: FileResponseData) => {
      if (response) {
        updateChatbotByUUID(chatbot.uuid, {
          ...chatbot,
          meta_json: JSON.stringify({ ...JSON.parse(chatbot.meta_json), profile_picture: response.url }),
          // need for img rerendering
          modified_at: new Date().toISOString(),
        });
        setChatInterface({
          ...chatInterface,
          profile_picture: response.url,
        });
      }
      setLoading(false);
    });
  };

  const deleteChatbotLogo = () => {
    contentService.deleteChatbotFile(chatbot.uuid, 'chatbot_logo').then(() => {
      updateChatbotByUUID(chatbot.uuid, {
        ...chatbot,
        meta_json: JSON.stringify({ ...JSON.parse(chatbot.meta_json), profile_picture: '' }),
      });
      setChatInterface({
        ...chatInterface,
        profile_picture: '',
      });
    });
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label
          className="text-md font-medium leading-none tracking-tight flex items-center"
          htmlFor="chatbot-message-color"
        >
          Chatbot Logo
        </Label>
        <p className="text-sm text-muted-foreground">
          Upload your company logo or preferred image, branding the chat interface.
        </p>
        <div className="flex items-center gap-4">
          {canSeeProfilePicture ? (
            <div
              id="profile-picture-container"
              className="w-[50px] bg-background h-[50px] relative rounded-full border-[1px] flex items-center justify-center"
            >
              {!chatInterface?.profile_picture ? (
                <IconImage strokeWidth={1.75} className="w-7 h-7" />
              ) : (
                <img
                  className="w-[50px] h-[50px] rounded-full"
                  src={`${chatInterface.profile_picture}${!chatInterface.profile_picture.startsWith('data:') ? `?v=${chatbot.modified_at}` : ''}`}
                  alt="Chatbot Logo"
                />
              )}
            </div>
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="w-[50px] h-[50px] relative rounded-full border-[1px] opacity-50 transition-all flex items-center justify-center">
                    <IconImage strokeWidth={1.75} className="w-6 h-6" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    This feature is not available with your current plan. Upgrade your plan to access this
                    customization option.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          <div>
            <div className="flex items-center gap-2">
              <Button
                disabled={!canSeeProfilePicture || loading}
                size="sm"
                variant="outline"
                className="bg-background"
                onClick={() => {
                  if (!loading) {
                    profilePictureRef?.current?.click();
                  }
                }}
              >
                {loading ? (
                  <Loader2 className="mr-2 w-4 h-4 animate-spin transition-all" />
                ) : (
                  <UploadIcon strokeWidth={1.75} className="mr-2 h-4 w-4" />
                )}
                Upload
              </Button>
              <Button
                disabled={!chatInterface.profile_picture}
                size="sm"
                variant="ghost"
                onClick={deleteChatbotLogo}
              >
                Remove
              </Button>
            </div>
            <p className="text-muted-foreground text-xs mt-1">Supports JPG, PNG, and SVG files up to 1MB</p>
          </div>
        </div>
        <Input
          className="hidden"
          accept="image/png, image/jpg, image/svg+xml"
          ref={profilePictureRef}
          id="profile-picture"
          type="file"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              if (file.size <= 1000000) {
                setImageToUpload(file);
                e.target.value = '';
              } else {
                addAlert({
                  severity: 'error',
                  message: alerts.IMAGE_SIZE_EXCEEDED,
                });
              }
            }
          }}
        />
      </div>
      <ImageCropperDialog
        title="Chatbot Logo"
        img={imageToUpload}
        cropperCallback={(croppedImage?: File) => {
          if (croppedImage) {
            updateChatbotLogo(croppedImage);
          }
          setImageToUpload(undefined);
        }}
      />
    </>
  );
};

export default ChatbotLogoAppearance;
