/* eslint-disable consistent-return */
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

const ChatScrollContainer: React.FC<{
  children: ReactNode;
  className: string;
  style?: { [key: string]: string };
  streaming: boolean;
  streamingResponse: string;
}> = ({ children, className, style, streaming, streamingResponse }) => {
  const [isAutoScrollActive, setIsAutoScrollActive] = useState<boolean>(true);
  const clickedOnScrollRef = useRef(false);
  const touchStartedRef = useRef(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const streamingResponseExists = useMemo(() => {
    return streamingResponse.length > 0;
  }, [streamingResponse]);

  const scrollToBottom = () => {
    const container = chatContainerRef.current;
    const shouldKeepScrolling = { current: true }; // Use ref to persist the flag
    let interval: any;
    const performScroll = () => {
      if (container && shouldKeepScrolling.current) {
        interval = setInterval(() => {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        }, 300);
      }
    };

    performScroll(); // Initiate scrolling

    // Cleanup function to stop scrolling
    return () => {
      clearInterval(interval);
      shouldKeepScrolling.current = false;
      if (container) {
        // Interrupt the smooth scroll by scrolling to the current position
        container.scrollTo({
          top: container.scrollTop,
          behavior: 'auto',
        });
      }
    };
  };

  const checkIfCanSetToAutoScroll = (canSetFalse?: boolean) => {
    if (
      chatContainerRef.current &&
      streaming &&
      (canSetFalse || (!isAutoScrollActive && !touchStartedRef.current && !clickedOnScrollRef.current))
    ) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 24;
      if (isAtBottom) {
        setIsAutoScrollActive(true);
      } else if (canSetFalse) {
        setIsAutoScrollActive(false);
      }
    }
  };

  // perform autoscroll when user starts getting streaming response
  // no need autoscroll on image generation streaming
  useEffect(() => {
    if (isAutoScrollActive && streaming && streamingResponseExists) {
      const streamingContainer = document.getElementById('streaming-container');
      if (streamingContainer) {
        const classes = Array.from(streamingContainer.classList);
        if (!classes.includes('image-generation-streaming')) {
          const cancelScroll = scrollToBottom();
          return cancelScroll;
        }
      }
    }
  }, [streaming, isAutoScrollActive, streamingResponseExists]);

  const handleWheel = () => {
    checkIfCanSetToAutoScroll(true);
  };

  const handleScroll = () => {
    checkIfCanSetToAutoScroll();
  };

  const onTouchEnd = () => {
    touchStartedRef.current = false;
    checkIfCanSetToAutoScroll();
  };

  const onTouchStart = () => {
    touchStartedRef.current = true;
    setIsAutoScrollActive(false);
  };

  const onMouseUp = () => {
    clickedOnScrollRef.current = false;
    checkIfCanSetToAutoScroll();
  };

  const onMouseDown = (event: any) => {
    const container = chatContainerRef.current;
    if (container && streaming) {
      const clickX = event.clientX - container.getBoundingClientRect().left;
      const clickY = event.clientY - container.getBoundingClientRect().top;
      if (clickX > container.clientWidth || clickY > container.clientHeight) {
        setIsAutoScrollActive(false);
        clickedOnScrollRef.current = true;
      }
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer && streaming) {
      chatContainer.addEventListener('wheel', handleWheel);
      chatContainer.addEventListener('scroll', handleScroll);
      chatContainer.addEventListener('touchend', onTouchEnd);
      chatContainer.addEventListener('touchstart', onTouchStart);
      chatContainer.addEventListener('mouseup', onMouseUp);
      chatContainer.addEventListener('mousedown', onMouseDown);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('wheel', handleWheel);
        chatContainer.removeEventListener('scroll', handleScroll);
        chatContainer.removeEventListener('touchend', onTouchEnd);
        chatContainer.removeEventListener('touchstart', onTouchStart);
        chatContainer.removeEventListener('mouseup', onMouseUp);
        chatContainer.removeEventListener('mousedown', onMouseDown);
      }
    };
  }, [streaming, clickedOnScrollRef.current, touchStartedRef.current]);

  return (
    <div id="chat-container" className={className} style={style} ref={chatContainerRef}>
      {children}
    </div>
  );
};

export default ChatScrollContainer;
