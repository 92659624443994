/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { cn } from 'utils/cn';
import { DataSource } from 'models/api/response.types';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  Columns3,
  Loader2,
  PanelBottomClose,
  PanelRightClose,
  RotateCcw,
  Save,
  Table2,
} from 'lucide-react';
import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import sourceService from 'api/source';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlerts } from 'providers/AlertProvider';
import useDataSources from 'hooks/useDataSources';
import { alerts } from 'utils/alert';
import SourceDataCell from './SourceDataCell';

type IRowColumnRow = {
  [key: string]: {
    [nestedKey: string]: any;
  };
};

const generateUniqueKey = (baseName: string, existingKeys: string[]) => {
  let counter = 1;
  let uniqueKey = `${baseName}-${counter}`;
  while (existingKeys.includes(uniqueKey)) {
    counter++;
    uniqueKey = `${baseName}-${counter}`;
  }
  return uniqueKey;
};

const decrementDescriptionKeys = (description_keys: { [key: string]: string }, breakpoint?: number) => {
  const updatedDescriptions: { [key: string]: string } = {};
  for (const key in description_keys) {
    if (description_keys[key]) {
      const keyNumber = Number(key);
      if (key === '0' && !breakpoint) continue;
      if ((breakpoint !== undefined && keyNumber > breakpoint) || !breakpoint) {
        const decrementedKey = String(keyNumber - 1);
        updatedDescriptions[decrementedKey] = description_keys[key];
      } else {
        updatedDescriptions[key] = description_keys[key];
      }
    }
  }
  return updatedDescriptions;
};

const incrementColumnDescriptionKeys = (column_description: { [key: string]: string }) => {
  const updatedDescriptions: { [key: string]: string } = {};
  for (const key in column_description) {
    if (column_description[key]) {
      const decrementedKey = String(Number(key) + 1);
      updatedDescriptions[decrementedKey] = column_description[key];
    }
  }
  return updatedDescriptions;
};

const deepCopyNestedObject = (obj: IRowColumnRow): IRowColumnRow => {
  const key = Object.keys(obj)[0];
  return { [key]: { ...obj[key] } };
};

const SourceTableDataDialog: React.FC<{
  source?: DataSource;
  close: () => void;
}> = ({ source, close }) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const { refetchSources } = useDataSources();
  const originalMeta = JSON.parse(source?.meta_json || '{}');
  const [newDocumentMeta, setNewDocumentMeta] = useState<{
    [key: string]: any;
  }>(JSON.parse(source?.meta_json || '{}'));
  const [newContent, setNewContent] = useState<any>(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [transferingColumns, setTransferingColumns] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 400;
  const totalPages = newContent ? Math.ceil(newContent.length / itemsPerPage) : 1;

  const indexes = useMemo(() => {
    if (newContent) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      return {
        startIndex,
        endIndex: startIndex + itemsPerPage,
      };
    }
    return {
      startIndex: 1,
      endIndex: 1,
    };
  }, [newContent, currentPage, itemsPerPage]);
  const tableContentQueryKey = ['table-source', source?.uuid];

  const { data: originalContent, isError } = useQuery({
    queryKey: tableContentQueryKey,
    queryFn: () => sourceService.fetchSourceTableContent(source?.uuid as string),
    enabled: !!source,
  });

  useEffect(() => {
    if (originalContent) {
      setNewContent(JSON.parse(originalContent.str));
      setTimeout(() => {
        setInitialLoad(false);
      }, 300);
    }
  }, [originalContent, initialLoad]);

  useEffect(() => {
    if (isError) {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG_LONG,
      });
      close();
    }
  }, [isError]);

  const canUpdateMeta = useMemo(() => {
    return JSON.stringify(newDocumentMeta) !== JSON.stringify(originalMeta);
  }, [newDocumentMeta, originalMeta]);

  const isRowColumnHeader = useMemo(() => {
    if (newContent?.length > 0) {
      const firstDataContent = newContent[0];
      const firstContentDataKeys = Object.keys(firstDataContent);
      // null reads by JS as object
      return (
        firstDataContent[firstContentDataKeys[0]] !== null &&
        typeof firstDataContent[firstContentDataKeys[0]] === 'object'
      );
    }
    return false;
  }, [newContent]);

  const columnHeaders = useMemo(() => {
    if (newContent && newContent.length > 0) {
      if (isRowColumnHeader) {
        const mainKey = Object.keys(newContent[0])[0];
        return Object.keys(newContent[0][mainKey]);
      }
      return Object.keys(newContent[0]);
    }
    return undefined;
  }, [newContent, isRowColumnHeader]);

  const canDeleteColumns = useMemo(() => {
    return columnHeaders && columnHeaders.length > 1;
  }, [columnHeaders]);

  const rowHeaders = useMemo(() => {
    if (newContent && newContent.length > 0 && isRowColumnHeader) {
      return newContent.map((data: { [key: string]: any }) => Object.keys(data)[0]);
    }
    return undefined;
  }, [newContent, isRowColumnHeader]);

  const canDeleteRows = useMemo(() => {
    return rowHeaders && rowHeaders.length > 1;
  }, [rowHeaders]);

  const canUpdateTableContent = useMemo(() => {
    if (newContent) {
      return JSON.stringify(JSON.parse(originalContent.str)) !== JSON.stringify(newContent);
    }
    return false;
  }, [newContent]);

  // COLUMN ACTIONS
  // const updateColumnHeaderDescription = (value: string, index: number) => {
  //   setNewDocumentMeta({
  //     ...newDocumentMeta,
  //     column_description: {
  //       ...newDocumentMeta?.column_description,
  //       [index]: value,
  //     },
  //   });
  // };

  const updateColumnViewColumnHeaderCellValue = (value: string, rowId: number, keyId: number) => {
    const contentToUpdate = newContent.map((row: any) => ({ ...row }));
    const row = contentToUpdate[rowId];
    const keyValues = Object.keys(row);
    const keyToUpdate = keyValues[keyId];
    contentToUpdate[rowId][keyToUpdate] = value;
    setNewContent(contentToUpdate);
  };

  const updateColumnViewColumnHeader = (newValue: string, index: number) => {
    const updatedData = newContent
      .map((row: any) => ({ ...row }))
      .map((obj: { [key: string]: string }) => {
        const keys = Object.keys(obj);
        if (index < keys.length) {
          const oldKey = keys[index];
          const value = obj[oldKey];

          delete obj[oldKey];

          const updatedObj: { [key: string]: string } = {};
          keys.forEach((key, idx) => {
            if (idx === index) {
              updatedObj[newValue] = value;
            }
            if (key !== oldKey) {
              updatedObj[key] = obj[key];
            }
          });

          return updatedObj;
        }
        return obj;
      });
    setNewContent(updatedData);
  };

  const deleteColumnHeaderTypeColumn = (col: string, index: number) => {
    // shallow copy preventing from overriding original data
    const newData = newContent.map((row: { [key: string]: string }) => {
      return { ...row };
    });

    // Now we modify the copied data
    newData.forEach((row: { [key: string]: string }) => {
      delete row[col];
    });
    // let newColumnDescriptions = { ...newDocumentMeta?.column_description };
    // delete newColumnDescriptions[index.toString()];
    // newColumnDescriptions = decrementDescriptionKeys(newColumnDescriptions, index);
    // setNewDocumentMeta({
    //   ...newDocumentMeta,
    //   column_description: newColumnDescriptions,
    // });
    setNewContent(newData);
  };

  // ROW-COLUMN ACTIONS
  // const updateRowHeaderDescription = (value: string, index: number) => {
  //   setNewDocumentMeta({
  //     ...newDocumentMeta,
  //     row_description: {
  //       ...newDocumentMeta?.row_description,
  //       [index]: value,
  //     },
  //   });
  // };

  const updateRowColumnViewColumnHeader = (newValue: string, index: number) => {
    const contentToUpdate = newContent.map((row: IRowColumnRow) => deepCopyNestedObject(row));
    const newData = contentToUpdate.map((parentObject: IRowColumnRow) => {
      const parentKey = Object.keys(parentObject)[0];
      const childObject = parentObject[parentKey];
      const childObjectKeys = Object.keys(childObject);
      if (index < childObjectKeys.length) {
        const oldKey = childObjectKeys[index];
        const value = childObject[oldKey];

        delete childObject[oldKey];

        const updatedObj: { [key: string]: string } = {};
        childObjectKeys.forEach((key, idx) => {
          if (idx === index) {
            updatedObj[newValue] = value;
          }
          if (key !== oldKey) {
            updatedObj[key] = childObject[key];
          }
        });

        return {
          [parentKey]: updatedObj,
        };
      }
      return {
        [parentKey]: childObject,
      };
    });
    setNewContent(newData);
  };

  const deleteRowColumnHeaderTypeColumn = (col: string, index: number) => {
    // Deep copy each row to prevent modifying original data
    const newData = newContent.map((row: { [key: string]: { [key: string]: string } }) => {
      const rowKey = Object.keys(row)[0];
      // Deeply copy the nested object
      const newRowData = { ...row[rowKey] };
      return { [rowKey]: newRowData };
    });

    newData.forEach((row: { [key: string]: { [key: string]: string } }) => {
      const rowKey = Object.keys(row)[0];
      const rowData = row[rowKey];
      delete rowData[col];
    });

    // let newColumnDescriptions = { ...newDocumentMeta?.column_description };
    // delete newColumnDescriptions[index.toString()];
    // newColumnDescriptions = decrementDescriptionKeys(newColumnDescriptions, index);

    setNewContent(newData);
    // setNewDocumentMeta({
    //   ...newDocumentMeta,
    //   column_description: newColumnDescriptions,
    // });
  };

  const updateRowColumnViewRowHeader = (oldValue: string, newValue: string, index: number) => {
    const contentToUpdate = newContent.map((row: any) => ({ ...row }));
    const row = contentToUpdate[index];
    const keyValue = row[oldValue];

    delete row[oldValue];
    row[newValue] = keyValue;
    contentToUpdate[index] = row;
    setNewContent(contentToUpdate);
  };

  const updateRowColumnViewCellValue = (value: string, rowIndex: number, keyValue: string) => {
    const contentToUpdate = newContent.map((row: IRowColumnRow) => deepCopyNestedObject(row));
    const row = contentToUpdate[rowIndex];
    const key = Object.keys(row)[0];
    contentToUpdate[rowIndex][key][keyValue] = value;
    setNewContent(contentToUpdate);
  };

  const deleteRowColumnHeaderTypeRow = (colId: number) => {
    const newData = [...newContent];
    newData.splice(colId, 1);

    // let newRowDescriptions = { ...newDocumentMeta?.row_description };
    // delete newRowDescriptions[colId.toString()];
    // newRowDescriptions = decrementDescriptionKeys(newRowDescriptions, colId);
    setNewContent(newData);
    // setNewDocumentMeta({
    //   ...newDocumentMeta,
    //   row_description: newRowDescriptions,
    // });
  };

  const switchToColumnHeader = () => {
    setTransferingColumns(true);
    setTimeout(() => {
      setCurrentPage(1);
      const uniqueKey = generateUniqueKey('Column', columnHeaders || []);
      const newData = newContent.map((row: any) => {
        const value = Object.entries(row)[0];
        return {
          [uniqueKey]: value[0],
          ...(value[1] as { [key: string]: string }),
        };
      });
      setNewContent(newData);
      const newMeta = { ...newDocumentMeta };
      delete newMeta.row_description;
      setNewDocumentMeta({
        ...newMeta,
        column_description: newMeta?.column_description
          ? incrementColumnDescriptionKeys(newMeta?.column_description)
          : {},
      });

      setTransferingColumns(false);
      const element = document.getElementById('source-table');
      if (element) {
        setTimeout(() => {
          element.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }, 100);
      }
    }, 500);
  };

  const switchToRowColumnHeader = () => {
    setTransferingColumns(true);
    setTimeout(() => {
      setCurrentPage(1);
      const uniqueIdentifier: Set<string> = new Set();
      const updatedContent: { [key: string]: { [key: string]: string } }[] = [];
      newContent.forEach((obj: { [key: string]: string }) => {
        let keyValue = Object.values(obj)[0];
        const objectKeys = Object.keys(obj);
        if (!keyValue) {
          keyValue = generateUniqueKey('Row', Array.from(uniqueIdentifier));
        } else if (uniqueIdentifier.has(keyValue)) {
          keyValue = generateUniqueKey(keyValue, Array.from(uniqueIdentifier));
        }
        uniqueIdentifier.add(keyValue);
        updatedContent.push({
          [keyValue]: {
            ...Object.fromEntries(Object.entries(obj).slice(objectKeys.length > 1 ? 1 : 0)),
          },
        });
      });
      setNewContent(updatedContent);
      setNewDocumentMeta({
        ...newDocumentMeta,
        column_description: newDocumentMeta?.column_description
          ? decrementDescriptionKeys(newDocumentMeta?.column_description)
          : {},
        row_description: {
          0: '',
        },
      });
      setTransferingColumns(false);
      const element = document.getElementById('source-table');
      if (element) {
        setTimeout(() => {
          element.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }, 100);
      }
    }, 500);
  };

  const addRowColumnHeaderTypeColumn = () => {
    if (columnHeaders) {
      const uniqueKey = generateUniqueKey('Column', columnHeaders || []);
      setNewContent(
        newContent.map((rowValue: { [key: string]: { [key: string]: string } }) => {
          const value = Object.entries(rowValue);
          return {
            [value[0][0]]: {
              ...value[0][1],
              [uniqueKey]: '',
            },
          };
        }),
      );
      const element = document.getElementById('source-table');
      if (element) {
        setTimeout(() => {
          element.scrollTo({
            top: 0,
            left: element.scrollWidth,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  };

  const addColumnHeaderTypeColumn = () => {
    if (columnHeaders) {
      const uniqueKey = generateUniqueKey('Column', columnHeaders || []);
      setNewContent(
        newContent.map((rowValue: { [key: string]: string }) => {
          return { ...rowValue, [uniqueKey]: '' };
        }),
      );
      const element = document.getElementById('source-table');
      if (element) {
        setTimeout(() => {
          element.scrollTo({
            top: 0,
            left: element.scrollWidth,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  };

  const addRowColumnHeaderTypeRow = () => {
    if (columnHeaders && rowHeaders) {
      const uniqueKey = generateUniqueKey('Row', rowHeaders || []);
      const newRowValue: { [key: string]: string } = {};
      columnHeaders.forEach((header) => {
        newRowValue[header] = '';
      });
      setNewContent([
        ...newContent,
        {
          [uniqueKey]: newRowValue,
        },
      ]);
      setCurrentPage(totalPages);
      const element = document.getElementById('source-table');
      if (element) {
        setTimeout(() => {
          element.scrollTo({
            left: 0,
            top: element.scrollHeight,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  };

  const addColumnHeaderTypeRow = () => {
    if (columnHeaders) {
      const newRow: { [key: string]: string } = {};
      columnHeaders.forEach((header) => {
        newRow[header] = '';
      });
      setNewContent([...newContent, newRow]);
      setCurrentPage(totalPages);
      const element = document.getElementById('source-table');
      if (element) {
        setTimeout(() => {
          element.scrollTo({
            left: 0,
            top: element.scrollHeight,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  };

  const resetTableData = () => {
    setNewDocumentMeta(JSON.parse(source?.meta_json || '{}'));
    setCurrentPage(1);
    setNewContent(JSON.parse(originalContent.str));
  };

  const dataToUse = useMemo(() => {
    if (newContent && newContent.length > 0) {
      return newContent.slice(indexes.startIndex, indexes.endIndex);
    }
    return undefined;
  }, [indexes, newContent]);

  const updateTable = () => {
    if (source) {
      setUpdatingData(true);
      const contentToUpdate = JSON.stringify(newContent);
      sourceService
        .updateDataSource(source.uuid, {
          ...(canUpdateMeta && {
            meta_json: JSON.stringify(newDocumentMeta),
          }),
          ...(canUpdateTableContent && {
            table_json: JSON.stringify(newContent),
          }),
          table_json: contentToUpdate,
        })
        .then(() => {
          queryClient.setQueryData(tableContentQueryKey, { str: contentToUpdate });
          refetchSources();
          addAlert({
            severity: 'success',
            message: alerts.SOURCE_TABLE_UPDATE_SUCCESS,
          });
          setUpdatingData(false);
          close();
        })
        .catch(() => {
          setUpdatingData(false);
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
          });
        });
    }
  };

  const generateInactiveHeader = () => {
    return (
      <SourceDataCell
        value=""
        isColumnHeader
        transferingColumns={transferingColumns}
        isInactive
        setValue={() => {}}
      />
    );
  };

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={cn(
                  'w-full max-h-full transform overflow-hidden rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all flex flex-col',
                  initialLoad ? 'max-w-2xl' : 'max-w-5xl',
                )}
              >
                <DialogTitle
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-ellipsis whitespace-nowrap overflow-hidden"
                >
                  {source?.file_name}
                </DialogTitle>
                {initialLoad ? (
                  <div className="flex items-center flex-col pb-6">
                    <div className="mt-2 mb-6 w-full">
                      <p className="font-normal text-muted-foreground text-sm">Preparing table content</p>
                    </div>
                    <Loader2 strokeWidth={1.75} className="animate-spin h-12 w-12 text-primary" />
                  </div>
                ) : (
                  <>
                    <div className="mt-2">
                      <p className="font-normal text-muted-foreground text-sm">
                        This is the data file used for generating your AI response. You have the option to
                        modify this data to ensure accuracy and relevance in the AI&apos;s output. Feel free
                        to correct or update the information as needed.
                      </p>
                    </div>
                    <div className="mt-4 flex flex-col overflow-hidden rounded-md border">
                      <div className="flex items-center gap-2 px-2 border-b overflow-x-auto overflow-y-hidden min-h-[50px]">
                        <Button
                          disabled={updatingData || transferingColumns}
                          variant={!isRowColumnHeader ? 'default' : 'outline'}
                          size="sm"
                          onClick={() => {
                            if (isRowColumnHeader) {
                              switchToColumnHeader();
                            }
                          }}
                        >
                          <Columns3 strokeWidth={1.75} className="h-4 w-4 mr-2" />
                          Column Header
                        </Button>
                        <Button
                          disabled={updatingData || transferingColumns}
                          variant={isRowColumnHeader ? 'default' : 'outline'}
                          size="sm"
                          onClick={() => {
                            if (!isRowColumnHeader) {
                              switchToRowColumnHeader();
                            }
                          }}
                        >
                          <Table2 strokeWidth={1.75} className="h-4 w-4 mr-2" />
                          Row-Column Header
                        </Button>
                        <Separator orientation="vertical" className="h-[49px]" />
                        <Button
                          disabled={updatingData || transferingColumns}
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            if (isRowColumnHeader) {
                              addRowColumnHeaderTypeColumn();
                            } else {
                              addColumnHeaderTypeColumn();
                            }
                          }}
                        >
                          <PanelBottomClose strokeWidth={1.75} className="h-4 w-4 mr-2" />
                          Add Column
                        </Button>
                        <Button
                          disabled={updatingData || transferingColumns}
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            if (isRowColumnHeader) {
                              addRowColumnHeaderTypeRow();
                            } else {
                              addColumnHeaderTypeRow();
                            }
                          }}
                        >
                          <PanelRightClose strokeWidth={1.75} className="h-4 w-4 mr-2" />
                          Add Row
                        </Button>
                        <Button
                          disabled={!canUpdateTableContent || updatingData || transferingColumns}
                          variant="outline"
                          size="sm"
                          onClick={resetTableData}
                        >
                          <RotateCcw strokeWidth={1.75} className="h-4 w-4 mr-2" />
                          Reset Changes
                        </Button>
                        {(canUpdateTableContent || canUpdateMeta) && (
                          <Button
                            disabled={updatingData || transferingColumns}
                            className="ml-auto"
                            size="sm"
                            onClick={updateTable}
                          >
                            {updatingData ? (
                              <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
                            ) : (
                              <Save strokeWidth={1.75} className="h-4 w-4 mr-2" />
                            )}
                            Save Changes
                          </Button>
                        )}
                      </div>
                      {newContent?.length > 0 ? (
                        <div
                          id="source-table"
                          className={cn(
                            'p-2 max-h-[500px] relative flex flex-col gap-1 overflow-auto',
                            transferingColumns && 'blur-sm',
                          )}
                        >
                          {/* // column headers */}
                          <div className="flex items-center gap-1 w-fit">
                            {isRowColumnHeader && (
                              <>
                                {generateInactiveHeader()}
                                {/* {generateInactiveHeader()} */}
                              </>
                            )}
                            {columnHeaders?.map((header, index) => {
                              return (
                                <SourceDataCell
                                  transferingColumns={transferingColumns}
                                  canDeleteColumn={canDeleteColumns}
                                  allColumnHeaders={columnHeaders}
                                  key={`${header}-${index}`}
                                  isColumnHeader
                                  isInactive={updatingData}
                                  value={header}
                                  setValue={(value: string) => {
                                    if (isRowColumnHeader) {
                                      updateRowColumnViewColumnHeader(value, index);
                                    } else {
                                      updateColumnViewColumnHeader(value, index);
                                    }
                                  }}
                                  deleteColumn={() => {
                                    if (isRowColumnHeader) {
                                      deleteRowColumnHeaderTypeColumn(header, index);
                                    } else {
                                      deleteColumnHeaderTypeColumn(header, index);
                                    }
                                  }}
                                />
                              );
                            })}
                          </div>
                          {/* // column header descriptions */}
                          {/* <div className="flex items-center gap-1 w-fit">
                            {isRowColumnHeader && (
                              <>
                                {generateInactiveHeader()}
                                {generateInactiveHeader()}
                              </>
                            )}
                            {columnHeaders?.map((header, index) => {
                              const value = newDocumentMeta?.column_description
                                ? newDocumentMeta?.column_description[index] || ''
                                : '';
                              return (
                                <SourceDataCell
                                  key={`${header}-${index}`}
                                  isColumnDescription
                                  value={value}
                                  setValue={(newValue: string) => {
                                    updateColumnHeaderDescription(newValue, index);
                                  }}
                                />
                              );
                            })}
                          </div> */}
                          {/* // table rows */}
                          {dataToUse.map((rowData: { [key: string]: any }, idx: number) => {
                            if (isRowColumnHeader) {
                              const rowHeader = Object.keys(rowData)[0];
                              const cellValues: string[] = Object.values(rowData[rowHeader]);
                              const rowDescription = '';
                              // newDocumentMeta?.row_description[idx] || "";
                              return (
                                <div key={idx} className="flex items-center gap-1 w-fit">
                                  {/* // row header */}
                                  <SourceDataCell
                                    transferingColumns={transferingColumns}
                                    isInactive={updatingData}
                                    canDeleteRow={canDeleteRows}
                                    allRowHeaders={rowHeaders}
                                    value={rowHeader}
                                    isRowHeader
                                    setValue={(value: string) => {
                                      updateRowColumnViewRowHeader(rowHeader, value, idx);
                                    }}
                                    deleteRow={() => {
                                      deleteRowColumnHeaderTypeRow(idx);
                                    }}
                                  />
                                  {/* // row description */}
                                  {/* <SourceDataCell
          isRowDescription
          value={rowDescription}
          setValue={(newValue: string) => {
            updateRowHeaderDescription(newValue, idx);
          }}
        /> */}
                                  {/* // row values */}
                                  {cellValues.map((cell: string, id: number) => (
                                    <SourceDataCell
                                      transferingColumns={transferingColumns}
                                      isInactive={updatingData}
                                      key={`${cell}-${idx}-${id}`}
                                      value={cell}
                                      setValue={(value: string) => {
                                        updateRowColumnViewCellValue(
                                          value,
                                          idx,
                                          (columnHeaders as string[])[id],
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                              );
                            }
                            return (
                              <div key={idx} className="flex items-center gap-1 w-fit">
                                {Object.values(rowData).map((cell: string, id: number) => {
                                  return (
                                    <SourceDataCell
                                      transferingColumns={transferingColumns}
                                      isInactive={updatingData}
                                      key={`${cell}-${idx}-${id}`}
                                      value={cell}
                                      setValue={(value: string) => {
                                        updateColumnViewColumnHeaderCellValue(value, idx, id);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            );
                          })}
                          {transferingColumns && <div className="w-full h-full absolute top-0 left-0" />}
                        </div>
                      ) : (
                        <p className="p-4 text-sm text-center text-muted-foreground">
                          There is no data. You can start by adding columns/rows.
                        </p>
                      )}
                    </div>
                    {totalPages > 0 && (
                      <div className="mt-4 flex items-center justify-end gap-2">
                        <div className="flex whitespace-nowrap items-center justify-center text-sm font-medium">
                          Page {currentPage} of {totalPages}
                        </div>
                        <div className="flex items-center space-x-2">
                          <Button
                            variant="outline"
                            size="sm"
                            className="hidden h-8 w-8 p-0 lg:flex bg-background"
                            onClick={() => setCurrentPage(1)}
                            disabled={currentPage === 1}
                          >
                            <span className="sr-only">Go to first page</span>
                            <ChevronsLeftIcon strokeWidth={1.75} className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="outline"
                            size="sm"
                            className="h-8 w-8 p-0 bg-background"
                            onClick={() => setCurrentPage((page) => page - 1)}
                            disabled={currentPage === 1}
                          >
                            <span className="sr-only">Go to previous page</span>
                            <ChevronLeftIcon strokeWidth={1.75} className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="outline"
                            size="sm"
                            className="h-8 w-8 p-0 bg-background"
                            onClick={() => setCurrentPage((page) => page + 1)}
                            disabled={currentPage === totalPages}
                          >
                            <span className="sr-only">Go to next page</span>
                            <ChevronRightIcon className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="outline"
                            className="hidden h-8 w-8 p-0 lg:flex bg-background"
                            onClick={() => setCurrentPage(totalPages)}
                            disabled={currentPage === totalPages}
                          >
                            <span className="sr-only">Go to last page</span>
                            <ChevronsRightIcon className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SourceTableDataDialog;
