import React from 'react';
import {
  IChatbotMessageColors,
  defaultChatDarkTextColor,
  defaultChatLightTextColor,
  defaultDarkBackgroundColor,
  defaultLightBackgroundColor,
} from 'utils/bot';

const Pre: React.FC<{
  data: any;
  chatBotMessageColors?: IChatbotMessageColors;
}> = ({ data, chatBotMessageColors }) => {
  return (
    <pre
      className="shadow-sm rounded-md"
      style={
        chatBotMessageColors?.theme === 'dark'
          ? {
              color: defaultChatDarkTextColor,
              backgroundColor: defaultLightBackgroundColor,
            }
          : {
              color: defaultChatLightTextColor,
              backgroundColor: defaultDarkBackgroundColor,
            }
      }
    >
      {data.children}
    </pre>
  );
};

export default Pre;
