/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import { cn } from 'utils/cn';
import { alerts } from 'utils/alert';
import { Button } from 'components/ui/button';
import integrationService from 'api/integrations';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { Layers, Loader2 } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Input } from 'components/ui/input';
import ConfirmZendeskDeleteionDialog from 'components/Dialogs/Integrations/ConfirmZendeskDeletionDialog';

const ZendeskIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const { appTitle } = useWhitelabelData();
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [open, setOpen] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [disconnectIntegration, setDisconnectIntegration] = useState<boolean>(false);
  const integrationDataQueryKey = ['zendesk-integration', chatbot.uuid];
  const [createForm, setCreateForm] = useState({
    zendesk_app_id: '',
    zendesk_key_id: '',
    zendesk_secret: '',
    zendesk_domain: '',
  });

  const handleChange = (event: any) => {
    setCreateForm((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const handleSubmit = () => {
    setConnecting(true);
    integrationService
      .createZendeskIntegration({
        chatbot_uuid: chatbot.uuid,
        zendesk_app_id: createForm.zendesk_app_id,
        zendesk_key_id: createForm.zendesk_key_id,
        zendesk_secret: createForm.zendesk_secret,
        zendesk_domain:
          createForm.zendesk_domain === '' ? createForm.zendesk_app_id : createForm.zendesk_domain,
      })
      .then((integration) => {
        addAlert({
          severity: 'success',
          message: alerts.ZENDESK_SETUP_SUCCESS,
        });
        queryClient.setQueryData(integrationDataQueryKey, integration);
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          // duplication, already exists in another chatbot
          addAlert({
            severity: 'error',
            message: alerts.ZENDESK_DUPLICATE_ERROR,
            timeout: 10000,
          });
        } else {
          addAlert({
            severity: 'error',
            message: alerts.ZENDESK_SETUP_ERROR,
          });
        }
      })
      .finally(() => setConnecting(false));
  };

  const { data: integrationData, isLoading: isIntegrationLoading } = useQuery({
    queryKey: integrationDataQueryKey,
    queryFn: () => integrationService.getZendeskIntegration(chatbot.uuid),
    enabled: open,
  });

  // when showing integration append script that connects zendesk to our app
  useEffect(() => {
    if (show) {
      return () => {};
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img
            className="w-[40px] h-[40px] rounded-md"
            src="/img/integrations/zendesk/zendesk.png"
            alt="Zendesk"
          />
          <h1 className="text-xl font-semibold">Add to Zendesk</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs
        className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6 flex-1 flex flex-col"
        defaultValue="account"
      >
        <TabsList className="w-fit">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="guide">Guide</TabsTrigger>
        </TabsList>
        <TabsContent value="account" className="flex-1">
          {!isIntegrationLoading ? (
            <>
              {integrationData ? (
                <>
                  <Card className="mt-4">
                    <CardHeader>
                      <CardTitle>Connected Zendesk Account</CardTitle>
                      <CardDescription>
                        This Zendesk account is currently selected as the active account for your chatbot.
                        Your chatbot will respond to all messages sent to your Zendesk account. If this is the
                        wrong account, you may disconnect it and select another Zendesk account from the
                        integration settings.
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-6">
                      <div className="flex items-center justify-between space-x-4">
                        <div className="flex items-center space-x-4">
                          <Layers className="w-8 h-8" strokeWidth={1.2} />
                          <div>
                            <p className="text-sm font-medium leading-none">{integrationData.domain}</p>
                          </div>
                        </div>
                        <Button
                          variant="outline"
                          onClick={() => setDisconnectIntegration(true)}
                          className="ml-auto hover:bg-destructive/5 hover:border-destructive hover:text-destructive"
                        >
                          Disconnect
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                  <ConfirmZendeskDeleteionDialog
                    open={disconnectIntegration}
                    close={() => setDisconnectIntegration(false)}
                  />
                </>
              ) : (
                <>
                  <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
                    To connect your Zendesk Domain to {appTitle}, you need a Zendesk Account that has valid
                    permissions within your domain. <p className="my-4" /> This integration allows you to
                    respond to messages with your chatbot. To fill in the details here, We{' '}
                    <strong>strongly</strong> recommend that you follow our guide on how to set up everything.
                  </div>
                  <p className="my-4 font-normal text-foreground text-base">
                    After creating your &apos;Conversation API Key&apos; you must fill out these details (In
                    your Zendesk Admin Center)
                  </p>
                  <form>
                    <div className="my-4 font-normal text-foreground text-base  gap-3 flex-col flex">
                      <p>Zendesk Details</p>
                      <Input
                        name="zendesk_domain"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="Zendesk Subdomain ([subdomain].zendesk.com)"
                      />
                    </div>

                    <div className="my-4 font-normal text-foreground text-base gap-3 flex-col flex">
                      <p>API Key Details</p>
                      <Input
                        name="zendesk_app_id"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="Zendesk App ID"
                      />
                      <Input
                        name="zendesk_key_id"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="API Key ID"
                      />
                      <Input
                        name="zendesk_secret"
                        onChange={(e) => handleChange(e)}
                        type="password"
                        placeholder="API Key Secret"
                      />
                    </div>
                    <div className="my-4 font-normal text-foreground text-base gap-3 flex-col flex">
                      <Button
                        disabled={
                          connecting ||
                          [
                            createForm.zendesk_secret,
                            createForm.zendesk_app_id,
                            createForm.zendesk_key_id,
                          ].includes('')
                        }
                        type="button"
                        onClick={() => handleSubmit()}
                      >
                        {connecting ? (
                          <>
                            <Loader2
                              className="transition-all animate-spin w-6 h-6 min-w-[24px] mr-2"
                              strokeWidth={1.75}
                            />
                            Connecting
                          </>
                        ) : (
                          'Integrate'
                        )}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </>
          ) : (
            <p className="mt-4 text-sm text-muted-foreground flex items-center">
              <Loader2
                className="w-4 h-4 min-w-[16px] text-secondary mr-2 animate-spin transition-all"
                strokeWidth={1.75}
              />
              Please wait while we retrieve the Zendesk integration data for this chatbot.
            </p>
          )}
        </TabsContent>
        <TabsContent value="guide">
          <h2 className="text-2xl font-bold my-4">Guide to Native Integration with Zendesk</h2>
          <p className="font-normal text-muted-foreground text-base my-4">
            This native integration allows for your {appTitle} bot to be used for conversations within Zendesk
            initatlly. When a zendesk chat session is started, your bot will automatically respond, and if the
            name of the user (variable, <code>user_name</code> or <code>name</code>) is collected, will be
            sent to zendesk. Additionally, when Human Escalation is activated, it will alert active Zendesk
            Agents and allow you to respond with your Zendesk account. This integration is specialized, so
            please feel free to reach out to the team if you&apos;re having trouble with the setup process.
            integration.
          </p>
          <h3 className="text-xl font-semibold mt-6 mb-2">1. Setting Up the Integration</h3>
          <p className="font-normal text-muted-foreground text-base my-4">
            To start using the integration, you&apos;ll need to login with your Zendesk Admin account. Ensure
            you have the necessary permissions in Zendesk to install Integrations within your domain.
          </p>
          <ol className="list-decimal ml-6">
            <li>
              <a href="https://support.zendesk.com/hc/en-us/articles/4581766374554-Using-Zendesk-Admin-Center#topic_hfg_dyz_1hb">
                <strong>Go to &quot;Admin Center&quot;</strong>
              </a>
              <p className="font-normal text-muted-foreground text-base">Go to any Zendesk Product</p>
              <p className="font-normal text-muted-foreground text-base flex items-center">
                Click the Zendesk Products icon (
                <img
                  src="https://zen-marketing-documentation.s3.amazonaws.com/docs/en/admin_center_product_icon.png"
                  alt=""
                  loading="lazy"
                  decoding="async"
                  className="inline h-fit w-fit p-1"
                />
                ) in the top bar, then select Admin Center.
              </p>
            </li>
            <img
              src="https://zen-marketing-documentation.s3.amazonaws.com/docs/en/admin_center_product_tray.png"
              alt=""
              className="rounded-md border my-4 mx-auto h-[600px]"
              loading="lazy"
              decoding="async"
            />
            <li>
              <strong>Create a new Conversations API Key.</strong>
              <p className="font-normal text-muted-foreground text-base">
                Apps and integrations {`>`} APIs {`>`} Conversations API {`>`} Create API key
              </p>
            </li>
            <img
              className="rounded-md border my-4 mx-auto"
              src="https://app.gpt-trainer.com/blog-assets/zendesk_integration/Step3Zendesk.png"
              alt="Zendesk Create API Key"
              loading="lazy"
              decoding="async"
            />
            <li>
              <strong>Name your API Key, (up to you!) and then hit next. </strong>
              <p className="font-normal text-muted-foreground text-base">
                The three IDs are required to set up the integration. Please copy all three and store them,
                then hit next.
              </p>
            </li>
            <img
              className="rounded-md border my-4 mx-auto"
              src="https://app.gpt-trainer.com/blog-assets/zendesk_integration/Step4Zendesk.PNG"
              alt="Zendesk Setup API Key 1"
              loading="lazy"
              decoding="async"
            />
            <img
              className="rounded-md border my-4 mx-auto"
              src="https://app.gpt-trainer.com/blog-assets/zendesk_integration/Step5Zendesk.PNG"
              alt="Zendesk Setup API Key 2"
              loading="lazy"
              decoding="async"
            />
            <li>
              <strong>Go back to the Account tab and enter in the information gathered</strong>
              <p className="font-normal text-muted-foreground text-base">
                Your subdomain will be based on your Zendesk URL. <br />
                For example, if I access Zendesk through {appTitle}.zendesk.com, then my subdomain is &quot;
                {appTitle}&quot;
              </p>
            </li>
            <li>
              <strong>Select &quot;Integrate&quot;</strong>
            </li>
          </ol>
          <p className="my-2 text-foreground">
            That&apos;s it! You have now connected your Zendesk account with {appTitle}.
          </p>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ZendeskIntegration;
