/* eslint-disable import/prefer-default-export */
import tinycolor from 'tinycolor2';
import { v4 as uuidv4 } from 'uuid';
import { hexToHSL } from './colors';

export interface AuthenticationType {
  enabled: boolean;
  url: string;
  error_message: string;
  save_response: boolean;
}

export type SuggestedPromptType = 'query' | 'email' | 'link';

export interface SuggestedPrompt {
  type: SuggestedPromptType;
  label: string;
  data: string;
  pre_canned_response?: string;
}

export interface ChatHistory {
  enabled: boolean;
  time: number;
}

export interface WidgetFormType {
  title: string;
  description: string;
  fields: ChatbotFormField[];
  show_after_qa: number;
  button_text: string;
  is_mandatory: boolean;
}

export type ChatbotFormType = 'email' | 'text' | 'tel' | 'dropdown' | 'checkbox';

export interface ChatbotFormField {
  id: string;
  label: string;
  type: ChatbotFormType;
  enabled: boolean;
  default: boolean;
  dropdown_options?: string[];
  required: boolean;
}

export type ThemeType = 'light' | 'dark';

export type WidgetSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface ChatBotMeta {
  access_source_data: boolean;
  all_domains_allowed: boolean;
  button: {
    icon: string;
    logo: string;
    use_logo: boolean;
  };
  chatbot_message_color: string;
  chat_bubble_color: string;
  chat_history: ChatHistory;
  chat_size: WidgetSize;
  chat_bubble_text_color: string;
  credit_limit_message: string;
  custom_watermark: {
    label: string;
    url: string;
    logo: string;
  };
  default_ai_error_message: string;
  dimensions: {
    width: number;
    height: number;
  };
  display_name: string;
  default_agent_uuid: string;
  disclaimer: {
    enabled: boolean;
    description: string;
    button_text: string;
  };
  domains: string[];
  font_style: string;
  form: WidgetFormType;
  initial_messages: string[];
  email_notifications: {
    emails: string[];
    live_variables_enabled: boolean;
    daily_variables_enabled: boolean;
    daily_history_enabled: boolean;
    form_submission_enabled: boolean;
    variables: string[];
  };
  message_avatar: {
    enabled: boolean;
    logo: string;
  };
  message_credit_limit: number;
  response_actions_enabled: boolean;
  open_by_default: 'on' | 'off' | 'computer' | 'mobile';
  profile_picture: string;
  rate_limit: number[];
  rate_limit_message: string;
  source_label: string;
  suggested_prompts: SuggestedPrompt[];
  show_suggested_prompt_icons: boolean;
  suggested_prompts_view: 'vertical-start' | 'vertical-end' | 'horizontal-single' | 'horizontal-multi';
  show_initial_messages_time: number;
  show_citations: boolean;
  theme: ThemeType;
  time_zone: string;
  template_progress?: 'created' | 'agents-created' | 'personalized' | 'finished';
  textfield_placeholder: string;
  user_message_color: string;
  visibility: 'public' | 'private' | 'hybrid';
  widget_vertical_align: 'right' | 'left';
  webhook_auth: AuthenticationType;
  webhooks: Webhook[];
  // properties that are available only in static mode
  // and only changable by BE
  input_length?: number;
}

export interface IChatbotMessageColors {
  textColor: string;
  backgroundColor: string;
  loadingColor: string;
  citationReferenceColor: string;
  citationReferenceTextColor: string;
  citationReferenceHoveredColor: string;
  citationReferenceHoveredTextColor: string;
  theme: string;
  // disclaimerButtonColor: string;
  // disclaimerButtonTextColor: string;
}

export interface IUserMessageColors {
  textColor: string;
  backgroundColor: string;
}

export interface Webhook {
  type: 'form_submission' | 'response' | 'query' | 'data_collection';
  url: string;
  variables?: string[];
}

export interface IChatMainColors {
  theme: 'light' | 'dark';
  background: string;
  lightBackground: string;
  text: string;
  border: string;
  suggestedPrompt: string;
  hoverBackground: string;
  actionsText: string;
  actionsHoverBackground: string;
}

export const defaultBotInitialMessage = '👋 Hi! What can I help you with?';
export const defaultAIErrorMessage =
  'Our servers are currently overloaded; please try again later. Thank you for your patience as we continue to scale our services.';
export const defaultCreditLimitMessage =
  'Chatbot is currently disabled as your message credit limit has been reached.';
export const defaultDisclaimerDescription =
  'We endeavor to deliver accurate and comprehensive information through this chat portal. However, we cannot guarantee 100% accuracy or completeness of the provided information. The AI may occasionally offer information that is incorrect or outdated due to limitations in its training data. By using this chat portal, you acknowledge and accept our Privacy Policy and Terms of Service.';

export const defaultChatDarkTextColor = '#303030';
export const defaultChatLightTextColor = '#f8fafc';
export const defaultDarkBackgroundColor = '#111827';
export const defaultLightBackgroundColor = '#ffffff';
// for forms and things that appearars not in the chat itself
export const defaultBrigtherLightBackgroundColor = '#FCFCFC';
export const defaultBrigtherDarkBackgroundColor = '#101725';
// for secondary actions like source button, copy, scoring
export const secondaryActionTextLightColor = '#64748B';
export const secondaryActionTextDarkColor = '#B8C0CC';
export const secondaryActionLightBackgroundColor = '#f8fafc';
export const secondaryActiondDarkBackgroundColor = '#1F2C47';
// other
export const defaultDarkBorderColor = '#343f50';
export const defaultLightBorderColor = '#e5e7eb';
export const defaultDarkPromptColor = '#1f2937';
export const defaultLightPromptColor = '#f8fafc';
export const defaultLightHoverColor = '#f1f5f9';
export const defaultDarkHoverColor = '#2C3A4E';

export const defaultChatbotData: ChatBotMeta = {
  profile_picture: '',
  custom_watermark: {
    label: '',
    url: '',
    logo: '',
  },
  button: {
    icon: '/img/widget-images/widget-button-open-state/default-chat.svg',
    logo: '',
    use_logo: false,
  },
  message_avatar: {
    enabled: false,
    logo: '',
  },
  default_ai_error_message: defaultAIErrorMessage,
  default_agent_uuid: '',
  dimensions: {
    height: 800,
    width: 450,
  },
  visibility: 'public',
  domains: [],
  all_domains_allowed: false,
  initial_messages: [defaultBotInitialMessage],
  rate_limit_message: 'Too many messages in a row.',
  rate_limit: [20, 240],
  suggested_prompts: [],
  show_suggested_prompt_icons: false,
  suggested_prompts_view: 'horizontal-single',
  display_name: '',
  theme: 'light',
  chatbot_message_color: '#F2F5F9',
  user_message_color: '#0071F9',
  chat_bubble_color: JSON.parse(
    sessionStorage.getItem('platform_whitelabel_data') || '{"app": {"primary_color": "#404258"}}',
  )?.app.primary_color,
  chat_bubble_text_color: defaultChatLightTextColor,
  show_initial_messages_time: 3,
  show_citations: false,
  access_source_data: false,
  widget_vertical_align: 'right',
  textfield_placeholder: '',
  source_label: '',
  chat_size: 'md',
  time_zone: 'UTC',
  open_by_default: 'off',
  webhooks: [],
  message_credit_limit: -1,
  response_actions_enabled: false,
  credit_limit_message: defaultCreditLimitMessage,
  form: {
    title: 'Let us know how to contact you',
    description: '',
    fields: [
      {
        id: uuidv4(),
        label: 'Email',
        type: 'email',
        enabled: false,
        default: true,
        required: false,
      },
      {
        id: uuidv4(),
        label: 'Name',
        type: 'text',
        enabled: false,
        default: true,
        required: false,
      },
      {
        id: uuidv4(),
        label: 'Phone',
        type: 'tel',
        enabled: false,
        default: true,
        required: false,
      },
    ],
    show_after_qa: 1,
    is_mandatory: false,
    button_text: 'Submit',
  },
  chat_history: {
    enabled: true,
    time: 4,
  },
  webhook_auth: {
    enabled: false,
    url: '',
    error_message: '',
    save_response: false,
  },
  font_style: 'Inter',
  email_notifications: {
    emails: [],
    live_variables_enabled: false,
    daily_variables_enabled: false,
    daily_history_enabled: false,
    form_submission_enabled: false,
    variables: [],
  },
  disclaimer: {
    enabled: false,
    description: defaultDisclaimerDescription,
    button_text: 'I agree',
  },
};

export const poweredByClass = (theme?: ThemeType, urlExist?: boolean) => {
  const colors = theme === 'light' ? 'bg-slate-50 text-slate-500' : 'bg-[#192339] text-[#f8fafc] ';
  const hoverColors = theme === 'light' ? 'hover:bg-slate-100' : 'hover:bg-[#1f2937]';
  return `${colors} ${urlExist ? `${hoverColors} cursor-pointer` : 'cursor-default'}`;
};

export const generateChatMainColors = (theme?: ThemeType): IChatMainColors => {
  if (theme === 'dark') {
    return {
      theme,
      background: defaultDarkBackgroundColor,
      lightBackground: defaultBrigtherDarkBackgroundColor,
      text: defaultChatLightTextColor,
      border: defaultDarkBorderColor,
      suggestedPrompt: defaultDarkPromptColor,
      hoverBackground: defaultDarkHoverColor,
      actionsText: secondaryActionTextDarkColor,
      actionsHoverBackground: secondaryActiondDarkBackgroundColor,
    };
  }
  return {
    theme: 'light',
    background: defaultLightBackgroundColor,
    lightBackground: defaultBrigtherLightBackgroundColor,
    text: defaultChatDarkTextColor,
    border: defaultLightBorderColor,
    suggestedPrompt: defaultLightPromptColor,
    hoverBackground: defaultLightHoverColor,
    actionsText: secondaryActionTextLightColor,
    actionsHoverBackground: secondaryActionLightBackgroundColor,
  };
};

export const generateUserMessageColors = (metadata?: ChatBotMeta): IUserMessageColors => {
  const color = metadata?.user_message_color || defaultChatbotData.user_message_color;
  const { l: luminosity } = hexToHSL(color);
  const textColor = luminosity < 60 ? defaultChatLightTextColor : defaultChatDarkTextColor;
  return {
    textColor,
    backgroundColor: color,
  };
};

export const generateChatBotMessageColors = (metadata?: ChatBotMeta): IChatbotMessageColors => {
  const color = metadata?.chatbot_message_color || defaultChatbotData.chatbot_message_color;
  const { l: luminosity } = hexToHSL(color);
  const textColor = luminosity < 60 ? defaultChatLightTextColor : defaultChatDarkTextColor;
  const loadingColor =
    luminosity < 60 ? tinycolor(color).brighten(60).toString() : tinycolor(color).darken(50).toString();
  const citationReferenceColor =
    luminosity < 60 ? tinycolor(color).brighten(40).toString() : tinycolor(color).darken(40).toString();
  const { l: citationLuminocity } = hexToHSL(citationReferenceColor);
  const citationReferenceTextColor =
    citationLuminocity < 60 ? defaultChatLightTextColor : defaultChatDarkTextColor;
  const citationReferenceHoveredColor = loadingColor;
  const { l: citationHoveredLuminocity } = hexToHSL(citationReferenceHoveredColor);
  const citationReferenceHoveredTextColor =
    citationHoveredLuminocity < 60 ? defaultChatLightTextColor : defaultChatDarkTextColor;

  return {
    theme: metadata?.theme || 'light',
    textColor,
    backgroundColor: color,
    loadingColor,
    citationReferenceColor,
    citationReferenceTextColor,
    citationReferenceHoveredColor,
    citationReferenceHoveredTextColor,
  };
};

export const chatSizeVariants = {
  header: {
    withIcon: {
      xs: 'px-3 py-[9px] gap-2',
      sm: 'px-3 py-[8.8px] gap-3',
      md: 'px-4 py-[10.5px] gap-4',
      lg: 'px-4 py-[8.5px] gap-4',
      xl: 'px-4 py-[10px] gap-4',
    },
    regular: {
      xs: 'p-3 gap-2',
      sm: 'px-3 py-3.5 gap-3',
      md: 'px-4 py-3.5 gap-4',
      lg: 'px-4 py-3.5 gap-4',
      xl: 'px-4 py-3.5 gap-4',
    },
    profileIcon: {
      xs: 'h-[34px] w-[34px] mr-[6px]',
      sm: 'h-[37px] w-[37px] mr-[7px]',
      md: 'h-[40px] w-[40px] mr-[8px]',
      lg: 'h-[43px] w-[43px] mr-[9px]',
      xl: 'h-[46px] w-[46px] mr-[10px]',
    },
    profileName: {
      xs: 'text-sm',
      sm: 'text-[15px]',
      md: 'text-base',
      lg: 'text-[17px]',
      xl: 'text-lg',
    },
    buttonIcons: {
      xs: 'w-[15px] h-[15px]',
      sm: 'w-[18px] h-[18px]',
      md: 'w-[20px] h-[20px]',
      lg: 'w-[22px] h-[22px]',
      xl: 'w-[25px] h-[25px]',
    },
  },
  chat: {
    container: {
      xs: 'p-2 gap-[13px]',
      sm: 'p-3 gap-[15px]',
      md: 'p-4 gap-[16px]',
      lg: 'p-4 gap-[17px]',
      xl: 'p-4 gap-[19px]',
    },
    initialMessageGap: {
      xs: 'gap-[13px]',
      sm: 'gap-[15px]',
      md: 'gap-[16px]',
      lg: 'gap-[17px]',
      xl: 'gap-[19px]',
    },
    message: {
      xs: 'px-3 py-[9px] text-sm',
      sm: 'px-4 py-3 text-[15px]',
      md: 'px-4 py-3 text-base',
      lg: 'px-4 py-3 text-[17px]',
      xl: 'px-4 py-3 text-lg',
    },
    messageAvatarContainer: {
      xs: 'w-[26px] h-[26px]',
      sm: 'w-[29px] h-[29px]',
      md: 'w-[32px] h-[32px]',
      lg: 'w-[35px] h-[35px]',
      xl: 'w-[38px] h-[38px]',
    },
    messageAvatarIcon: {
      xs: 'w-4 w-4',
      sm: 'w-4 w-4',
      md: 'w-5 w-5',
      lg: 'w-6 w-6',
      xl: 'w-7 w-7',
    },
    messageCitation: {
      key: {
        xs: 'h-[20px] text-xs',
        sm: 'h-[20px] text-[13px]',
        md: 'h-[20px] text-[13px]',
        lg: 'h-[20px] text-[14px]',
        xl: 'h-[20px] text-[14px]',
      },
      container: {
        xs: 'mt-2 py-2 px-2',
        sm: 'mt-2 py-2 px-2',
        md: 'mt-4 py-2 px-4',
        lg: 'mt-4 py-2 px-4',
        xl: 'mt-4 py-2 px-4',
      },
      text: {
        xs: 'text-xs',
        sm: 'text-[13px]',
        md: 'text-sm',
        lg: 'text-[15px]',
        xl: 'text-base',
      },
      icon: {
        xs: 'w-[14px] h-[14px] min-w-[14px]',
        sm: 'w-4 h-4 min-w-[16px]',
        md: 'w-4 h-4 min-w-[16px]',
        lg: 'w-5 h-5 min-w-[20px]',
        xl: 'w-5 h-5 min-w-[20px]',
      },
      // for markdown links
      link: {
        xs: 'h-[18px] min-w-[18px] px-[0.3em] text-[10px]',
        sm: 'h-[18px] min-w-[18px] px-[0.3em] text-[11px]',
        md: 'h-[18px] min-w-[18px] px-[0.3em] text-[11px]',
        lg: 'h-[18px] min-w-[18px] px-[0.4em] text-[12px]',
        xl: 'h-[18px] min-w-[18px] px-[0.4em] text-[13px]',
      },
    },
    streamingResponseHeight: {
      xs: 'h-[21px]',
      sm: 'h-[22.5px]',
      md: 'h-[24px]',
      lg: 'h-[25.5px]',
      xl: 'h-[27px]',
    },
  },
  suggestedPrompts: {
    container: {
      xs: 'mx-2 py-2 gap-1',
      sm: 'mx-3 py-2 gap-1',
      md: 'mx-4 py-2 gap-1',
      lg: 'mx-4 py-2 gap-2',
      xl: 'mx-4 py-2 gap-2',
    },
    prompt: {
      xs: 'h-auto px-2 py-1 text-xs',
      sm: 'h-auto px-2 py-1 text-[13px]',
      md: 'h-auto px-4 py-1 text-sm',
      lg: 'h-auto px-4 py-1 text-[15px]',
      xl: 'h-auto px-4 py-1 text-base',
    },
    icon: {
      xs: 'mr-1 h-3 w-3',
      sm: 'mr-1 h-4 w-4',
      md: 'mr-2 h-4 w-4',
      lg: 'mr-2 h-4 w-4',
      xl: 'mr-2 h-5 w-5',
    },
  },
  input: {
    container: {
      xs: 'p-2 pt-[2px]',
      sm: 'p-3 pt-[2px]',
      md: 'p-4 pt-[2px]',
      lg: 'p-4 pt-[2px]',
      xl: 'p-4 pt-[2px]',
    },
    secondaryContainer: {
      xs: 'py-[8px] pl-2 gap-2',
      sm: 'py-[10px] pl-2 gap-2',
      md: 'py-[14px] pl-4 gap-4',
      lg: 'py-[14px] pl-4 gap-4',
      xl: 'py-[14px] pl-4 gap-4',
    },
    input: {
      xs: 'max-h-[90px] pr-14 h-[18px] text-[12px]',
      sm: 'max-h-[90px] pr-14 h-[20px] text-[13px]',
      md: 'max-h-[100px] pr-14 h-[21px] text-[14px]',
      lg: 'max-h-[110px] pr-14 h-[23px] text-[15px]',
      xl: 'max-h-[120px] pr-14 h-[24px] text-[16px]',
    },
    textAreaScrollHeight: {
      xs: 18,
      sm: 20,
      md: 21,
      lg: 23,
      xl: 24,
    },
    button: {
      xs: 'sm:w-[15px] sm:h-[15px] w-[20px] h-[20px] right-[1rem] sm:bottom-[10px] bottom-[15px]',
      sm: 'sm:w-[18px] sm:h-[18px] w-[20px] h-[20px] right-[1rem] sm:bottom-[12px] bottom-[15px]',
      md: 'w-[20px] h-[20px] right-[1rem] bottom-[15px]',
      lg: 'w-[22px] h-[22px] right-[1rem] bottom-[15px]',
      xl: 'w-[25px] h-[25px] right-[1rem] bottom-[15px]',
    },
  },
  poweredByText: {
    xs: 'text-[10px]',
    sm: 'text-[11px]',
    md: 'text-xs',
    lg: 'text-xs',
    xl: 'text-xs',
  },
  poweredByLogo: {
    xs: 'w-[16px] h-[16px] mr-1',
    sm: 'w-[16px] h-[16px] mr-1',
    md: 'w-[18px] h-[18px] mr-1',
    lg: 'w-[20px] h-[20px] mr-2',
    xl: 'w-[20px] h-[20px] mr-2',
  },
  form: {
    container: {
      xs: 'p-2 text-xs',
      sm: 'p-3 text-sm',
      md: 'p-4 text-sm',
      lg: 'p-4 text-base',
      xl: 'p-4 text-base',
    },
    button: {
      xs: 'mt-2',
      sm: 'mt-3',
      md: 'mt-4',
      lg: 'mt-4',
      xl: 'mt-4',
    },
    headerContainer: {
      xs: 'mb-2 gap-3',
      sm: 'mb-3 gap-4',
      md: 'mb-4 gap-4',
      lg: 'mb-4 gap-4',
      xl: 'mb-4 gap-4',
    },
    description: {
      xs: 'mb-2 text-xs',
      sm: 'mb-3 text-[13px]',
      md: 'mb-4 text-sm',
      lg: 'mb-4 text-[15px]',
      xl: 'mb-4 text-base',
    },
    title: {
      xs: 'text-sm',
      sm: 'text-[15px]',
      md: 'text-base',
      lg: 'text-[17px]',
      xl: 'text-lg',
    },
    closeIcon: {
      xs: 'w-[14px] h-[14px] min-w-[14px] mt-[0.3rem]',
      sm: 'w-4 h-4 min-w-[16px] mt-[0.4rem]',
      md: 'w-4 h-4 min-w-[16px] mt-[0.4rem]',
      lg: 'w-5 h-5 min-w-[20px] mt-[0.4rem]',
      xl: 'w-5 h-5 min-w-[20px] mt-[0.4rem]',
    },
    labelSize: {
      xs: 'text-xs',
      sm: 'text-[13px]',
      md: 'text-sm',
      lg: 'text-[15px]',
      xl: 'text-base',
    },
    inputGap: {
      xs: 'gap-2',
      sm: 'gap-2',
      md: 'gap-4',
      lg: 'gap-4',
      xl: 'gap-4',
    },
    input: {
      xs: 'text-xs px-2 py-1 h-9',
      sm: 'text-[13px] px-2 py-2 h-10',
      md: 'text-sm px-3 py-2 h-10',
      lg: 'text-[15px] px-3 py-2 h-10',
      xl: 'text-base px-3 py-2 h-11',
    },
    label: {
      xs: 'text-xs',
      sm: 'text-[13px]',
      md: 'text-sm',
      lg: 'text-[15px]',
      xl: 'text-base',
    },
    asterisk: {
      xs: 'w-[11px] h-[11px]',
      sm: 'w-3 h-3',
      md: 'w-3 h-3',
      lg: 'w-3 h-3',
      xl: 'w-[14px] h-[14px]',
    },
    checkbox: {
      xs: 'h-4 w-4',
      sm: 'h-4 w-4',
      md: 'h-5 w-5',
      lg: 'h-[18px] w-[18px]',
      xl: 'h-5 w-5',
    },
    textArea: {
      xs: 'text-xs px-2 py-1 h-[80px]',
      sm: 'text-[13px] px-2 py-2 h-[100px]',
      md: 'text-sm px-3 py-2 h-[120px]',
      lg: 'text-[15px] px-3 py-2 h-[150px]',
      xl: 'text-base px-3 py-2 h-[150px]',
    },
  },
  disclaimer: {
    container: {
      xs: 'p-2 text-xs',
      sm: 'p-3 text-sm',
      md: 'p-4 text-sm',
      lg: 'p-4 text-base',
      xl: 'p-4 text-base',
    },
    button: {
      xs: 'mt-3',
      sm: 'mt-4',
      md: 'mt-6',
      lg: 'mt-6',
      xl: 'mt-6',
    },
  },
  secondaryActionsText: {
    xs: 'text-xs',
    sm: 'text-[13px]',
    md: 'text-sm',
    lg: 'text-[15px]',
    xl: 'text-base',
  },
  secondaryActionIcons: {
    xs: 'w-[14px] h-[14px] min-w-[14px]',
    sm: 'w-4 h-4 min-w-[16px]',
    md: 'w-4 h-4 min-w-[16px]',
    lg: 'w-5 h-5 min-w-[20px]',
    xl: 'w-5 h-5 min-w-[20px]',
  },
};

export const loaderSizeVariant = {
  xs: 'h-2 w-2',
  sm: 'h-2 w-2',
  md: 'h-2 w-2',
  lg: 'h-[10px] w-[10px]',
  xl: 'h-3 w-3',
};
