/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import { cn } from 'utils/cn';
import { alerts } from 'utils/alert';
import { Button } from 'components/ui/button';
import integrationService from 'api/integrations';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import ConfirmMessengerDeletionDialog from 'components/Dialogs/Integrations/ConfirmMessengerDeletionDialog';
import { Layers, Loader2 } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';

const MessengerIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const { appTitle } = useWhitelabelData();
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [open, setOpen] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [connectingFacebook, setConnectingFacebook] = useState<boolean>(false);
  const [disconnectIntegration, setDisconnectIntegration] = useState<boolean>(false);
  const [facebookPages, setFacebookPages] = useState<any[] | undefined>(undefined);
  const [selectedFacebookPage, setSelectedFacebookPage] = useState<any>(undefined);
  const integrationDataQueryKey = ['messenger-integration', chatbot.uuid];

  const { data: integrationData, isLoading: isIntegrationLoading } = useQuery({
    queryKey: integrationDataQueryKey,
    queryFn: () => integrationService.getMessengerIntegration(chatbot.uuid),
    enabled: open,
  });

  const integrationMeta = useMemo(() => {
    if (integrationData) {
      return JSON.parse(integrationData?.meta_json || '{}');
    }
    return undefined;
  }, [integrationData]);

  // when showing integration append script that connects facebook to our app
  useEffect(() => {
    if (show) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);

      window.fbAsyncInit = () => {
        FB.init({
          appId: '430325189614145', // Your Meta App ID
          version: 'v20.0', // Meta Graph API version
        });
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [show]);

  // if facebook account was successfully connected with existing pages and no selected facebook pages
  // selected default page for user
  useEffect(() => {
    if (facebookPages && facebookPages?.length > 0 && !selectedFacebookPage) {
      setSelectedFacebookPage(facebookPages[0]);
    }
  }, [facebookPages]);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  // getting response from facebook auth route
  // if token exists connect to user's pages
  const authChangeEvent = (response: any) => {
    const token = response?.authResponse?.accessToken;
    if (token) {
      window.FB.api(`/me/accounts?access_token=${token}`, ({ data }) => {
        setFacebookPages(data);
        setConnecting(false);
      });
    } else {
      setConnecting(false);
      addAlert({
        severity: 'error',
        message: alerts.FACEBOOK_ACCOUNT_CONNECTION_ERROR,
      });
    }
  };

  // render embedded facebook auth flow
  // allow user to connect to business pages
  const launchEmbeddedSignup = () => {
    setConnecting(true);
    FB.Event.subscribe('auth.authResponseChange', authChangeEvent);
    FB.login(authChangeEvent, {
      config_id: '699381938980280',
      auth_type: 'rerequest', // avoids "user is already logged" in errors if users click the button again before refreshing the page
      response_type: 'token',
      override_default_response_type: true,
    });
  };

  const connectFacebookIntegration = () => {
    if (!selectedFacebookPage) return;
    setConnectingFacebook(true);
    integrationService
      .createMessengerIntegration({
        chatbot_uuid: chatbot.uuid,
        page_id: selectedFacebookPage.id,
        user_access_token: FB.getAuthResponse()?.accessToken || '',
      })
      .then((integration) => {
        addAlert({
          severity: 'success',
          message: alerts.MESSENGER_SETUP_SUCCESS,
        });
        queryClient.setQueryData(integrationDataQueryKey, integration);
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          // duplication, already exists in another chatbot
          addAlert({
            severity: 'error',
            message: alerts.MESSENGER_DUPLICATE_ERROR,
            timeout: 10000,
          });
        } else {
          addAlert({
            severity: 'error',
            message: alerts.MESSENGER_SETUP_ERROR,
          });
        }
      })
      .finally(() => setConnectingFacebook(false));
  };

  if (!show) {
    return null;
  }

  const loginWithFacebookButton = (text: string) => {
    return (
      <Button
        disabled={connecting}
        onClick={launchEmbeddedSignup}
        className="hover:opacity-90 transition-all"
        style={{
          backgroundColor: '#2C64F6',
        }}
      >
        {connecting ? (
          <Loader2 className="transition-all animate-spin w-6 h-6 min-w-[24px] mr-2" strokeWidth={1.75} />
        ) : (
          <img
            src="/img/integrations/facebook.svg"
            className="w-6 h-6 min-w-[24px] mr-2"
            alt="Facebook Logo"
          />
        )}
        {text}
      </Button>
    );
  };

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <img
            className="w-[40px] h-[40px] rounded-md"
            src="/img/integrations/messenger.png"
            alt="Facebook Messenger"
          />
          <h1 className="text-xl font-semibold">Add to Messenger</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs
        className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6 flex-1 flex flex-col"
        defaultValue="account"
      >
        <TabsList className="w-fit">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="guide">Guide</TabsTrigger>
        </TabsList>
        <TabsContent value="account" className="flex-1">
          {!isIntegrationLoading ? (
            <>
              {integrationData ? (
                <>
                  <Card className="mt-4">
                    <CardHeader>
                      <CardTitle>Connected Facebook Page</CardTitle>
                      <CardDescription>
                        This Facebook account is currently selected as the active account for your chatbot.
                        All automated messages and interactions will be handled through this account. If this
                        is not the correct account, you may disconnect it and select another Facebook account
                        from the integration settings.
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-6">
                      <div className="flex items-center justify-between space-x-4">
                        <div className="flex items-center space-x-4">
                          <Layers className="w-8 h-8" strokeWidth={1.2} />
                          <div>
                            <p className="text-sm font-medium leading-none">
                              {integrationMeta?.facebook_page_name}
                            </p>
                          </div>
                        </div>
                        <Button
                          variant="outline"
                          onClick={() => setDisconnectIntegration(true)}
                          className="ml-auto hover:bg-destructive/5 hover:border-destructive hover:text-destructive"
                        >
                          Disconnect
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                  <ConfirmMessengerDeletionDialog
                    open={disconnectIntegration}
                    close={() => setDisconnectIntegration(false)}
                  />
                </>
              ) : (
                <>
                  {!facebookPages ? (
                    <>
                      <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
                        To connect your Facebook account to {appTitle}, you need to create a Facebook Business
                        Page. If you already have a Facebook Business Page, please click the{' '}
                        <strong>&quot;Login with Facebook&quot;</strong> button below. If you don&apos;t have
                        a Facebook Business Page, please follow our guide on how to set up everything.
                      </div>
                      {loginWithFacebookButton('Login with Facebook')}
                    </>
                  ) : (
                    <>
                      {facebookPages.length > 0 ? (
                        <div className="my-4">
                          {!integrationData && (
                            <>
                              <div className="mb-4">{loginWithFacebookButton('Reconnect Account')}</div>
                              <Card className="mt-4">
                                <CardHeader>
                                  <CardTitle>Confirm Facebook Page</CardTitle>
                                  <CardDescription>
                                    We have identified Facebook pages associated with your account. To
                                    continue, please select a page and click &quot;Connect&quot; to link it to
                                    your chatbot.
                                  </CardDescription>
                                </CardHeader>
                                <CardContent className="grid gap-6">
                                  <div className="flex items-center justify-between space-x-4">
                                    <div className="flex items-center space-x-4 flex-1">
                                      <Layers className="w-8 h-8" strokeWidth={1.2} />
                                      <Select value={selectedFacebookPage?.name}>
                                        <SelectTrigger className="max-w-[350px] w-full">
                                          <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                          {facebookPages.map((page, idx) => {
                                            return (
                                              <SelectItem
                                                onClick={() => {
                                                  setSelectedFacebookPage(facebookPages[idx]);
                                                }}
                                                key={page.id}
                                                value={page.name}
                                              >
                                                {page.name}
                                              </SelectItem>
                                            );
                                          })}
                                        </SelectContent>
                                      </Select>
                                    </div>
                                    <Button
                                      disabled={connectingFacebook}
                                      onClick={connectFacebookIntegration}
                                      className="ml-auto"
                                    >
                                      {connectingFacebook && (
                                        <Loader2
                                          className="w-4 h-4 min-w-[16px] mr-2 animate-spin transition-all"
                                          strokeWidth={1.75}
                                        />
                                      )}
                                      {connectingFacebook ? 'Connecting' : 'Connect'}
                                    </Button>
                                  </div>
                                </CardContent>
                              </Card>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="h-full flex items-center justify-center">
                          <div className="w-full bg-background p-6 rounded-md shadow-sm border max-w-[470px] flex flex-col">
                            <h1 className="text-lg font-semibold text-center">No Business Pages Found</h1>
                            <p className="text-muted-foreground text-sm mt-2 text-center mb-4">
                              It seems that your account doesn&apos;t have any business pages available to
                              use. You can try reconnecting with another account or follow our guide to create
                              and set up a Facebook Business Page.
                            </p>
                            <div className="mx-auto">{loginWithFacebookButton('Login with Facebook')}</div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <p className="mt-4 text-sm text-muted-foreground flex items-center">
              <Loader2
                className="w-4 h-4 min-w-[16px] text-secondary mr-2 animate-spin transition-all"
                strokeWidth={1.75}
              />
              Please wait while we retrieve the Messenger integration data for this chatbot.
            </p>
          )}
        </TabsContent>
        <TabsContent value="guide">
          <p className="font-normal text-muted-foreground text-base my-4">
            In this guide, you&apos;ll discover how to automate your Instagram DM conversations effectively.
            Follow these step-by-step instructions to set up your Instagram account and integrate it with{' '}
            {appTitle}.
          </p>
          <div className="mb-6">
            <h2 className="text-base font-semibold mb-2">What You’ll Need:</h2>
            <ul className="list-disc list-inside text-muted-foreground">
              <li>A Facebook Business Page</li>
            </ul>
          </div>
          <div className="mb-6">
            <h2 className="text-base font-semibold mb-2">Step 1: How to create a Facebook Business Page</h2>
            <p className="mb-2 text-muted-foreground">
              To create a Facebook page, go to{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/pages/"
                className="text-secondary font-semibold hover:underline"
              >
                https://www.facebook.com/pages/
              </a>
              .
            </p>
            <p className="mb-2 text-muted-foreground">
              Ensure you are signed in with your Facebook account and then select{' '}
              <strong>&quot;Create New Page&quot;</strong>.
            </p>
            <img
              className="rounded-md border my-4 mx-auto"
              src="https://app.gpt-trainer.com/blog-assets/facebook_integration/integration1.JPG"
              alt="Create New Page"
              loading="lazy"
              decoding="async"
            />
            <p className="mb-2 text-muted-foreground">
              The page should automatically be a <strong>&quot;Business&quot;</strong> when you create it.
            </p>
            <p className="mb-2 text-muted-foreground">
              Next, fill out the information it requires about your business.
            </p>
            <img
              className="rounded-md border my-4 mx-auto"
              src="https://app.gpt-trainer.com/blog-assets/facebook_integration/integration2.JPG"
              alt="Fill Out Information"
              loading="lazy"
              decoding="async"
            />
            <p className="mb-2 text-muted-foreground">
              Then fill out these optional questions to{' '}
              <strong>&quot;Finish Setting up your page&quot;</strong>.{' '}
              <span className="font-medium text-warning">(You can skip any step you would like)</span>.
            </p>
            <img
              className="rounded-md border my-4 mx-auto"
              src="https://app.gpt-trainer.com/blog-assets/facebook_integration/integration3.JPG"
              alt="Finish Filling Out Information"
              loading="lazy"
              decoding="async"
            />
            <p className="mb-2 text-muted-foreground">
              It should direct you to your new Facebook Page’s URL. If not, you should go back to{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/pages/"
                className="text-secondary font-semibold hover:underline"
              >
                https://www.facebook.com/pages/
              </a>{' '}
              and select it.
            </p>
            <img
              className="rounded-md border my-4 mx-auto"
              src="https://app.gpt-trainer.com/blog-assets/facebook_integration/integration4.JPG"
              alt="Account Pages"
              loading="lazy"
              decoding="async"
            />
          </div>
          <div className="mb-6">
            <h2 className="font-semibold mb-2">You&apos;re Ready!</h2>
            <p className="mb-2 text-muted-foreground">
              That&apos;s it! You are now ready to connect your account with {appTitle}. Please navigate back
              to the <strong>Account tab</strong> and try to connect everything.
            </p>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default MessengerIntegration;
