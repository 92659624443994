/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Area } from 'react-easy-crop';
import { hashStringToColor } from './colors';

export const renderAvatarStyles = (email: string) => {
  const namePart = email.split('@')[0];
  let initials = namePart
    .split(/[\.\+]/)
    .filter(Boolean)
    .map((part) => part[0])
    .join('')
    .toUpperCase();

  if (initials.length > 2) {
    initials = initials.substring(0, 2);
  } else if (initials.length < 2 && namePart.length > 1) {
    initials = namePart.substring(0, Math.min(2, namePart.length)).toUpperCase();
  }

  const { backgroundColor, color } = hashStringToColor(initials);

  return {
    initials,
    backgroundColor,
    color,
  };
};

const createImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
    image.src = url;
  });
};

export const generateCroppedImage = async (imageSrc: string, pixelCrop: Area) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Unable to get canvas context');
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Clear the canvas to ensure transparency
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw the image within the specified rectangular crop area
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return canvas;
};
