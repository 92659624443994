import React, { useMemo, useState } from 'react';
import { Separator } from 'components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Blend, Scale, SquareGanttChart } from 'lucide-react';
import { AIAgentFunctionList, AIAgentList, AIAgentVariable, Chatbot } from 'models/api/response.types';
import { useQuery } from '@tanstack/react-query';
import agentService from 'api/agent';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import RuleTab from './RuleTab/RuleTab';
import IntentsTab from './IntentsTab/IntentsTab';
import BiasStickinessTab from './BiasStickinessTab';

type Tab = 'rules' | 'intents' | 'bias-stickiness';

const SupervisorSettings: React.FC<{ opened: boolean; agents: AIAgentList }> = ({ opened, agents }) => {
  const { canUseSupervisorSettings } = useSubscriptionInfo();
  const selectedChatbot = useSelector(getSelectedChatbot) as Chatbot;
  const [currentTab, setCurrentTab] = useState<Tab>('rules');

  const { data: agentRules } = useQuery({
    queryKey: ['agent-rules', selectedChatbot.uuid],
    queryFn: () => agentService.getAgentRules(selectedChatbot.uuid),
  });

  const agentsCanBeShown = useMemo(() => {
    return agents.filter((a) => a.type !== 'background' && a.active);
  }, [agents]);

  const { agentData, agentFunctions, agentVariables } = useMemo(() => {
    const { functionList, variableList, agentList } = agents.reduce(
      (acc, agent) => {
        const { tool_functions, variables_json, name, uuid, active, enabled } = agent;
        if (!!active && !!enabled) {
          const functions = tool_functions;
          const variables = JSON.parse(variables_json || '[]');
          acc.functionList.push(...functions);
          acc.variableList.push(...variables);
          if (agent.type !== 'background') {
            acc.agentList.push({ name, uuid });
          }
        }
        return acc;
      },
      {
        functionList: [] as AIAgentFunctionList,
        variableList: [] as AIAgentVariable[],
        agentList: [] as { name: string; uuid: string }[],
      },
    );
    return {
      agentData: agentList,
      agentFunctions: functionList,
      agentVariables: variableList,
    };
  }, [agents]);

  if (!agents || !canUseSupervisorSettings) {
    return null;
  }

  return (
    <div className="flex-1 w-full xl:w-[800px] flex flex-col overflow-hidden">
      <div className="flex items-center text-lg font-semibold text-foreground px-6 pt-4">
        <h1>Supervisor Settings</h1>
      </div>
      <Separator className="my-4" />
      <Tabs id="tabs" value={currentTab} className="flex-1 flex flex-col overflow-auto px-6 pb-4">
        <TabsList className="grid w-full grid-cols-3 h-auto mb-4">
          <TabsTrigger
            value="rules"
            onClick={() => {
              setCurrentTab('rules');
            }}
          >
            <div className="relative flex items-center">
              <Scale strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
              Rules
            </div>
          </TabsTrigger>
          <TabsTrigger
            value="intents"
            onClick={() => {
              setCurrentTab('intents');
            }}
          >
            <div className="relative flex items-center">
              <SquareGanttChart strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
              Intents
            </div>
          </TabsTrigger>
          <TabsTrigger
            value="bias-stickiness"
            onClick={() => {
              setCurrentTab('bias-stickiness');
            }}
          >
            <div className="relative flex items-center">
              <Blend strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
              Bias/Stickiness
            </div>
          </TabsTrigger>
        </TabsList>
        {agentRules && (
          <RuleTab
            opened={opened}
            rules={agentRules}
            agents={agentData}
            functions={agentFunctions}
            variables={agentVariables}
          />
        )}
        {opened && (
          <>
            <IntentsTab agents={agentsCanBeShown} />
            <BiasStickinessTab agents={agentsCanBeShown} />
          </>
        )}
      </Tabs>
    </div>
  );
};

export default SupervisorSettings;
