import React, { Fragment, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';
import { DataSource } from 'models/api/response.types';
import { Input } from 'components/ui/input';
import { Loader2 } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import sourceService from 'api/source';
import useDataSources from 'hooks/useDataSources';

const EditQaSourceDialog: React.FC<{
  source: DataSource;
  close: () => void;
}> = ({ source, close }) => {
  const { sources, setSourceData } = useDataSources();
  const meta = JSON.parse(source?.meta_json || '{}');
  const [loading, setLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState(meta?.question || '');
  const [answer, setAnswer] = useState(meta?.answer || '');

  const updateQADataSourceMutation = useMutation({
    mutationFn: (payload: { uuid: string; question: string; answer: string }) =>
      sourceService.updateQADataSource(payload.uuid, payload.question, payload.answer),
    onSuccess: () => {
      if (sources) {
        const newSources = sources.map((src) => {
          if (src.uuid === (source?.uuid as string) && source) {
            return {
              ...src,
              meta_json: JSON.stringify({
                ...meta,
                question,
                answer,
              }),
            };
          }
          return src;
        });
        setSourceData(newSources);
      }
      close();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const handleEdit = () => {
    setLoading(true);
    updateQADataSourceMutation.mutate({
      uuid: source?.uuid as string,
      question,
      answer,
    });
  };

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Edit Q&A
                </DialogTitle>
                <div className="mt-6">
                  <div className="flex flex-col gap-2">
                    <Label className="" htmlFor="question">
                      Question
                    </Label>
                    <Input
                      id="question"
                      type="text"
                      disabled={loading}
                      value={question}
                      onChange={(e) => {
                        const { value } = e.target;
                        setQuestion(value);
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-2 mt-4">
                    <div className="flex items-center">
                      <Label className="" htmlFor="answer">
                        Pre-canned answer
                      </Label>
                    </div>
                    <Textarea
                      disabled={loading}
                      id="answer"
                      value={answer}
                      onChange={(e) => {
                        const { value } = e.target;
                        setAnswer(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button onClick={close} variant="outline">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleEdit}
                    className="self-start"
                    disabled={question.length === 0 || answer.length === 0 || loading}
                  >
                    {loading && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
                    {loading ? 'Editing' : 'Edit'} Q&A
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditQaSourceDialog;
