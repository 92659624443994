/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Button } from 'components/ui/button';
import {
  ChatBotMeta,
  IChatMainColors,
  SuggestedPrompt,
  SuggestedPromptType,
  WidgetSize,
  chatSizeVariants,
} from 'utils/bot';
import { cn } from 'utils/cn';
import { LinkIcon, Mail, MessageSquare } from 'lucide-react';
import { QueryToStream } from 'models/api/chat.types';

const SuggestedPrompts: React.FC<{
  botMeta: ChatBotMeta;
  size: WidgetSize;
  suggestedPrompts: SuggestedPrompt[];
  disabled: boolean;
  generateMessage: (message: QueryToStream) => void;
  chatMainColors?: IChatMainColors;
  canShowIcons?: boolean;
  hidden?: boolean;
}> = ({
  botMeta,
  size,
  suggestedPrompts,
  generateMessage,
  disabled,
  canShowIcons,
  // part of Bot Widget
  chatMainColors,
  hidden,
}) => {
  const [buttonHoverIndex, setButtonHoverIndex] = useState<number | undefined>(undefined);
  const alignmentView = (chatMainColors && botMeta?.suggested_prompts_view) || 'horizontal-single';

  const triggerSuggestedPrompt = (prompt: SuggestedPrompt) => {
    if (prompt.type === 'query' && !disabled) {
      generateMessage({
        value: prompt.data,
        ...(prompt.pre_canned_response && {
          pre_canned_response: prompt.pre_canned_response,
        }),
      });
    }
    if (prompt.type === 'link') {
      window.open(prompt.data, '_blank');
    }
    if (prompt.type === 'email') {
      window.location.href = `mailto:${prompt.data}`;
    }
  };

  const generateSuggestedPromptIcon = (type: SuggestedPromptType, styles: string) => {
    if (type === 'email') {
      return <Mail strokeWidth={1.75} className={cn(styles)} />;
    }
    if (type === 'link') {
      return <LinkIcon strokeWidth={1.75} className={cn(styles)} />;
    }
    return <MessageSquare strokeWidth={1.75} className={cn(styles)} />;
  };

  return (
    <div
      className={cn(
        'suggested-prompts flex transition-opacity',
        alignmentView.includes('horizontal')
          ? alignmentView === 'horizontal-single'
            ? 'overflow-x-auto'
            : 'flex-wrap'
          : '',
        alignmentView.includes('vertical') ? 'flex-col' : '',
        alignmentView.includes('vertical')
          ? alignmentView === 'vertical-start'
            ? 'items-start'
            : 'items-end'
          : '',
        hidden && 'opacity-0',
        chatSizeVariants.suggestedPrompts.container[size],
      )}
    >
      {suggestedPrompts.map((prompt, index) => (
        <Button
          disabled={hidden}
          variant="outline"
          key={index}
          className={cn(
            'font-normal shadow-none rounded-lg text-left outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent whitespace-nowrap',
            disabled && 'cursor-default',
            chatSizeVariants.suggestedPrompts.prompt[size],
          )}
          onMouseEnter={() => {
            setButtonHoverIndex(index);
          }}
          onMouseLeave={() => {
            setButtonHoverIndex(undefined);
          }}
          style={
            chatMainColors
              ? {
                  color: chatMainColors.text,
                  borderColor: chatMainColors.border,
                  background:
                    buttonHoverIndex === index
                      ? chatMainColors.hoverBackground
                      : chatMainColors.suggestedPrompt,
                }
              : {
                  borderColor: '#e5e7eb',
                  background: buttonHoverIndex === index ? '#E5E7EB' : 'transparent',
                }
          }
          onClick={() => {
            setButtonHoverIndex(undefined);
            triggerSuggestedPrompt(prompt);
          }}
        >
          {canShowIcons && (
            <>{generateSuggestedPromptIcon(prompt.type, chatSizeVariants.suggestedPrompts.icon[size])}</>
          )}
          {prompt.label}
        </Button>
      ))}
    </div>
  );
};

export default SuggestedPrompts;
