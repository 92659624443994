import React, { useMemo } from 'react';
import { cn } from 'utils/cn';
import {
  ChatBotMeta,
  IChatMainColors,
  IChatbotMessageColors,
  IUserMessageColors,
  WidgetSize,
  chatSizeVariants,
  defaultChatDarkTextColor,
} from 'utils/bot';
import { RealtimeChatMessage as RealtimeChatMessageType } from 'models/api/response.types';
import { motion } from 'framer-motion';
import { messageAnimation } from 'utils/chatMessage';
import { Separator } from 'components/ui/separator';
import ReactMarkdown from 'react-markdown';
import ChatAvatar from './ChatAvatar';

const RealtimeChatMessage: React.FC<{
  message: RealtimeChatMessageType;
  size: WidgetSize;
  chatbotMeta: ChatBotMeta;
  reversed?: boolean;
  userMessageColors?: IUserMessageColors;
  chatMainColors?: IChatMainColors;
  isPreviousSameType?: boolean;
  isNextSameType?: boolean;
  chatBotMessageColors?: IChatbotMessageColors;
}> = ({
  message,
  size,
  reversed,
  chatbotMeta,
  userMessageColors,
  isPreviousSameType,
  isNextSameType,
  chatMainColors,
  chatBotMessageColors,
}) => {
  const isRecentMessage = useMemo(() => {
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - new Date(message.created_at).getTime();
    return timeDifference < 2000;
  }, [message]);

  const { showAvatar, avatarEnabled } = useMemo(() => {
    return {
      showAvatar: chatbotMeta?.message_avatar?.enabled && message.type === 'admin',
      avatarEnabled: !isPreviousSameType,
    };
  }, [isPreviousSameType]);

  const messageMeta = useMemo(() => {
    return message?.meta_json ? JSON.parse(message?.meta_json) : {};
  }, []);

  const renderSeparator = () => {
    return (
      <Separator
        style={
          chatMainColors
            ? {
                backgroundColor: chatMainColors.border,
              }
            : {
                backgroundColor: '#e5e7eb',
              }
        }
        className="w-auto flex-1"
        orientation="horizontal"
      />
    );
  };

  switch (message.type) {
    case 'user':
      return (
        <motion.div
          initial={isRecentMessage ? 'hidden' : 'vissible'}
          animate="visible"
          variants={messageAnimation}
          className={cn(
            'w-fit max-w-[95%] rounded-lg  break-words',
            chatSizeVariants.chat.message[size],
            reversed ? 'mr-auto' : 'ml-auto',
            !isNextSameType && (reversed ? 'rounded-bl-none' : 'rounded-br-none'),
          )}
          style={
            userMessageColors
              ? {
                  background: userMessageColors.backgroundColor,
                  color: userMessageColors.textColor,
                }
              : {
                  background: '#367DF1',
                  color: '#fff',
                }
          }
        >
          <ReactMarkdown
            className={cn('prose leading-normal text-inherit', size)}
            allowedElements={['p', 'span', 'li', 'ul', 'ol']}
          >
            {message.message}
          </ReactMarkdown>
        </motion.div>
      );
    case 'admin':
      return (
        <div className={cn('flex justify-start gap-1', reversed ? 'flex-row-reverse' : 'flex-row')}>
          {showAvatar && (
            <motion.div
              initial="hidden"
              animate={avatarEnabled ? 'visible' : 'hidden'}
              variants={messageAnimation}
            >
              <ChatAvatar
                imgUrlPath={messageMeta?.admin_image_src}
                isHumanMessage
                size={size}
                userMessageColors={userMessageColors}
              />
            </motion.div>
          )}
          <motion.div
            custom={0.2}
            initial={isRecentMessage ? 'hidden' : 'vissible'}
            animate="visible"
            variants={messageAnimation}
            className={cn(
              'w-fit max-w-[95%] rounded-lg  relative group break-words',
              chatSizeVariants.chat.message[size],
              reversed ? 'ml-auto' : 'mr-auto',
              !isPreviousSameType && (reversed ? 'rounded-tr-none' : 'rounded-tl-none'),
            )}
            style={{
              ...(chatBotMessageColors
                ? {
                    background: chatBotMessageColors.backgroundColor,
                    color: chatBotMessageColors.textColor,
                  }
                : {
                    background: '#F2F5F9',
                    color: defaultChatDarkTextColor,
                  }),
            }}
          >
            <ReactMarkdown
              className={cn('prose leading-normal text-inherit', size)}
              allowedElements={['p', 'span', 'li', 'ul', 'ol']}
            >
              {message.message}
            </ReactMarkdown>
          </motion.div>
        </div>
      );
    case 'announcement':
      return (
        <motion.div
          initial={isRecentMessage ? 'hidden' : 'vissible'}
          animate="visible"
          variants={messageAnimation}
          className={cn('flex gap-2 items-center my-4')}
        >
          {renderSeparator()}
          <div
            className={cn(
              'border px-4 py-1 rounded-lg bg-transparent max-w-[90%] overflow-hidden text-ellipsis text-center',
              chatSizeVariants.suggestedPrompts.prompt[size],
            )}
            style={
              chatMainColors
                ? {
                    color: chatMainColors.text,
                    borderColor: chatMainColors.border,
                  }
                : { borderColor: '#e5e7eb' }
            }
          >
            {message.message}
          </div>
          {renderSeparator()}
        </motion.div>
      );
    default:
      return null;
  }
};

export default RealtimeChatMessage;
