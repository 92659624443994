import { addWeeks } from 'date-fns';

export const deleteSessionCookie = (cookieSessionKey: string, sessionId?: string) => {
  const cookie = document.cookie.split(';').some((c) => {
    return c.trim().startsWith(cookieSessionKey);
  });
  if (cookie) {
    document.cookie = `${cookieSessionKey}${sessionId}; expires=${new Date().toUTCString()}; path=/`;
  }
};

// expiration based on amount of weeks
export const addCookie = (cookieKey: string, weeks: number, data?: any, domain?: string) => {
  const expirationTime = addWeeks(new Date(), weeks);
  document.cookie = `${cookieKey}${data ? `=${data}` : ''}; expires=${expirationTime.toUTCString()}; path=/;${domain ? ` domain=${domain};` : ''} SameSite=None; Secure`;
};

export const findCookieByKey = (cookieKey: string) => {
  return document.cookie.split('; ').find((row) => row.startsWith(cookieKey));
};
