import axios from 'axios';
import {
  ChatMessage,
  ChatMessages,
  ChatSession,
  ChatSessionCreateBody,
  ChatSessionUpdateBody,
  ChatSessions,
  DashboardSessionData,
  UpdateChatMessageBody,
} from 'models/api/response.types';
import { DateRange } from 'react-day-picker';
import { convertSessionsDate } from 'utils/sessions';

const createChatbotSession = (uuid: string, data: ChatSessionCreateBody) =>
  axios.post<ChatSession>(`/api/chatbot/${uuid}/create-session`, data).then((res) => res.data);

const getChatbotSessions = (
  uuid: string,
  defaultDate: DateRange,
  date?: DateRange,
  searchString?: string,
) => {
  const dateToUse = date?.to || date?.from ? convertSessionsDate(date) : undefined;
  let apiString = `/api/chatbot/${uuid}/chat-sessions?order_by=latest_message_at`;
  if (searchString) {
    apiString = apiString.concat(`&search_value=${searchString}`);
  }
  if (dateToUse) {
    apiString = apiString.concat(`&start_timestamp=${dateToUse.from}&end_timestamp=${dateToUse.to}`);
  }
  if (!searchString && !dateToUse) {
    apiString = apiString.concat(
      `&start_timestamp=${defaultDate.from?.toISOString()}&end_timestamp=${defaultDate.to?.toISOString()}`,
    );
  }
  return axios.get<ChatSessions>(apiString).then((res) => res.data);
};

const getChatbotUnreadSessionCount = (uuid: string) =>
  axios.get<number>(`/api/chatbot/${uuid}/unread-session-count`).then((res) => res.data);

const deleteChatbotSessions = (uuids: string[]) =>
  axios.post(`/api/session/delete`, { uuids }).then((res) => res.data);

const getSessionMessages = (uuid: string) =>
  axios.get<ChatMessages>(`/api/session/${uuid}/messages`).then((res) => res.data);

const updateSessionMessage = (uuid: string, body: UpdateChatMessageBody) =>
  axios.post<ChatMessage>(`/api/message/${uuid}/update`, body).then((res) => res.data);

const updateChatbotSession = (uuid: string, data: ChatSessionUpdateBody) =>
  axios.post(`/api/session/${uuid}/update`, data).then((res) => res.data);

const deleteChatMessages = (uuids: string[]) =>
  axios.post<ChatMessages>(`/api/message/delete`, { uuids }).then((res) => res.data);

const markAllSessionsAsRead = (uuid: string) =>
  axios.post<ChatMessages>(`/chatbot/${uuid}/mark-all-sessions-read`).then((res) => res.data);

const sessionDashboardChartData = (uuid: string, range: string) => {
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios
    .get<DashboardSessionData>(`/api/chatbot/${uuid}/session-count?time_range=${range}&timezone=${timezone}`)
    .then((res) => res.data);
};

const sessionDashboardComparisonData = (uuid: string) => {
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.get(`/api/chatbot/${uuid}/monthly-session-count?timezone=${timezone}`).then((res) => res.data);
};

const getSessionById = (uuid: string) =>
  axios.get<ChatSession>(`/api/session/${uuid}`).then((res) => res.data);

const sessionService = {
  createChatbotSession,
  getChatbotSessions,
  getChatbotUnreadSessionCount,
  markAllSessionsAsRead,
  getSessionMessages,
  updateChatbotSession,
  deleteChatMessages,
  deleteChatbotSessions,
  sessionDashboardChartData,
  sessionDashboardComparisonData,
  updateSessionMessage,
  getSessionById,
};

export default sessionService;
