/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from 'utils/cn';

const TooltipProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <TooltipPrimitive.Provider delayDuration={0}>{children}</TooltipPrimitive.Provider>;
};

const Tooltip: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => {
  const [trigger, setTrigger] = React.useState<'mouse' | 'touch' | undefined>(undefined);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    let triggerTimeout: NodeJS.Timeout;
    if (trigger) {
      triggerTimeout = setTimeout(
        () => {
          setOpen(true);
        },
        trigger === 'touch' ? 0 : 300,
      );
    } else {
      setOpen(false);
    }
    return () => {
      clearTimeout(triggerTimeout);
    };
  }, [trigger]);

  React.useEffect(() => {
    const handleTouchStart = (event: any) => {
      const spanElement = document.getElementById('your-span-id');
      if (spanElement && !spanElement.contains(event.target)) {
        setTrigger(undefined);
        setOpen(false);
      }
    };

    document.body.addEventListener('touchstart', handleTouchStart);

    return () => {
      document.body.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  return (
    <span
      className={className}
      id="tooltip-trigger"
      onMouseEnter={() => setTrigger('mouse')}
      onMouseLeave={() => setTrigger(undefined)}
      onTouchStart={() => setTrigger('touch')}
    >
      <TooltipPrimitive.Root open={open}>{children}</TooltipPrimitive.Root>
    </span>
  );
};

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-[500] max-w-[350px] overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1',
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
