import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Loader2 } from 'lucide-react';
import { AIAgentFunction } from 'models/api/response.types';
import agentService from 'api/agent';
import useAiAgents from 'hooks/useAiAgents';

const DeleteAIAgentFunctionDialog: React.FC<{
  agentId: string;
  functionCall?: AIAgentFunction;
  close: () => void;
}> = ({ agentId, functionCall, close }) => {
  const { updateAgent, refetchAgents, agents } = useAiAgents();
  const [show, setShow] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (functionCall) {
      setShow(true);
    }
  }, [functionCall]);

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  const deleteFunction = () => {
    if (functionCall) {
      setDeleting(true);
      agentService
        .deleteAgentFunction(functionCall.uuid)
        .then(() => {
          const agent = agents?.find((ag) => ag.uuid === agentId);
          if (agent) {
            updateAgent({
              ...agent,
              tool_functions: agent.tool_functions.filter((func) => func.uuid !== functionCall.uuid),
            });
          } else {
            refetchAgents();
          }
          setDeleting(false);
          hide();
        })
        .catch(() => {
          setDeleting(false);
          hide();
        });
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Delete {functionCall?.name}
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Are you sure you wish to delete this function from the agent? Please be aware that this
                    action is irreversible. Once deleted, the agent will lose any existing connections to this
                    function, which will affect its functionality.
                  </p>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={hide}>
                    Cancel
                  </Button>
                  <Button disabled={deleting} variant="destructive" onClick={deleteFunction}>
                    {deleting && <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />}
                    Delete
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteAIAgentFunctionDialog;
