import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'utils/cn';
import { FileCode2 } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import ChatBubbleIntegration from './ChatBubbleIntegration';
import IframeIntegaration from './IframeIntegration';

const SiteIntegration: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'h-full max-w-[800px] bg-background transition-all duration-300 ease-out absolute right-0 top-0 w-full flex flex-col',
        open ? 'translate-x-[0px] shadow' : 'translate-x-full',
      )}
    >
      <div className="flex items-center justify-between px-4 pt-4 pb-0 lg:px-6 lg:pt-6">
        <div className="flex items-center gap-4">
          <FileCode2 strokeWidth={1.75} className="w-8 h-8 text-green-700" />
          <h1 className="text-xl font-semibold">Embed on site</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button size="default" variant="link" onClick={close}>
            Back
          </Button>
        </div>
      </div>
      <Tabs className="mt-6 overflow-auto px-4 pb-4 pt-0 lg:px-6 lg:pb-6" defaultValue="iframe">
        <TabsList>
          <TabsTrigger value="iframe">Embed to page</TabsTrigger>
          <TabsTrigger value="bubble">Chat bubble</TabsTrigger>
        </TabsList>
        <div className="my-4 text-sm py-2 px-4 bg-warning/10 border border-warning rounded-md">
          In order to experience the fully functional capabilities of the chatbot within your development
          environment, we recommend enabling the <strong>&quot;Allow all domains&quot;</strong> option within
          the Chatbot settings. This will allow you to explore and test the chatbot&apos;s functionalities
          seamlessly. Feel free to disable this feature once your website is ready for deployment.
        </div>
        <TabsContent value="iframe">
          <p className="font-normal text-muted-foreground text-base my-4">
            To add the chatbot anywhere on your website, include the following iframe in your HTML:
          </p>
          <IframeIntegaration />
          <div className="my-4 text-sm py-2 px-4 bg-blue-50 border border-blue-200 rounded-md">
            You can change the height value (e.g., 800px for taller or 300px for shorter) to fit your design.
            Experiment until it looks perfect.
          </div>
          <img src="/img/integrations/site/iframe.png" alt="Paste code to HTML" />
        </TabsContent>
        <TabsContent value="bubble">
          <p className="font-normal text-muted-foreground text-base my-4">
            To add a chat bubble to your website add this script tag to your HTML:
          </p>
          <ChatBubbleIntegration />
          <img className="mt-4" src="/img/integrations/site/widget.png" alt="Paste code to HTML" />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SiteIntegration;
