import { useMutation } from '@tanstack/react-query';
import sourceService from 'api/source';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';
import useDataSources from 'hooks/useDataSources';
import { CirclePlus, Loader2 } from 'lucide-react';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import React, { useState } from 'react';
import { alerts } from 'utils/alert';

const QAUploader: React.FC<{ chatbot: Chatbot; close: () => void }> = ({ chatbot, close }) => {
  const { addAlert } = useAlerts();
  const { refetchSources } = useDataSources();
  const [loading, setLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const createQADataSourceMutation = useMutation({
    mutationFn: (payload: { uuid: string; question: string; answer: string }) =>
      sourceService.createQADataSource(payload.uuid, payload.question, payload.answer),
    onSuccess: () => {
      refetchSources();
      close();
    },
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        addAlert({
          severity: 'error',
          message: alerts.URL_EXISTS_ERROR,
        });
      }
      setLoading(false);
    },
  });

  const handleCreate = () => {
    setLoading(true);
    createQADataSourceMutation.mutate({
      uuid: chatbot.uuid,
      question,
      answer,
    });
  };

  return (
    <div className="w-full max-h-full overflow-auto rounded-md bg-background p-6 text-left align-middle shadow-sm border">
      <h2 className="text-lg font-medium leading-6 text-gray-900">Add Q&A</h2>
      <div className="mt-6">
        <div className="flex flex-col gap-2">
          <Label className="" htmlFor="question">
            Question
          </Label>
          <Input
            id="question"
            type="text"
            disabled={loading}
            value={question}
            onChange={(e) => {
              const { value } = e.target;
              setQuestion(value);
            }}
          />
        </div>
        <div className="flex flex-col gap-2 mt-4">
          <div className="flex items-center">
            <Label className="" htmlFor="answer">
              Pre-canned answer
            </Label>
          </div>
          <Textarea
            disabled={loading}
            id="answer"
            className="min-h-[150px] lg:min-h-[300px]"
            value={answer}
            onChange={(e) => {
              const { value } = e.target;
              setAnswer(value);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end mt-6 gap-4">
        <Button
          onClick={() => {
            handleCreate();
          }}
          className="self-start"
          disabled={question.length === 0 || answer.length === 0 || loading}
        >
          {loading ? (
            <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <CirclePlus strokeWidth={1.75} className="mr-2 h-4 w-4" />
          )}
          {loading ? 'Adding' : 'Add'}
        </Button>
      </div>
    </div>
  );
};

export default QAUploader;
