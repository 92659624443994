/* eslint-disable import/prefer-default-export */
import { IPopularSource } from 'models/api/response.types';
import * as XLSX from 'xlsx';
import { formatBytes } from './formatting';

export const exportPopularSources = (sources: IPopularSource[]) => {
  const rowData: string[][] = [];
  rowData.push(['Title', 'Link', 'Source Size', 'Tokens', 'Count']);
  sources.forEach((source) => {
    const { title, meta_json, type, file_name, file_size, tokens, count } = source;
    const { question, answer, url } = JSON.parse(meta_json);
    rowData.push([
      title,
      type === 'qa' ? JSON.stringify({ question, answer }) : type === 'url' ? url : file_name,
      formatBytes(file_size),
      tokens,
      count,
    ]);
  });
  const worksheet = XLSX.utils.json_to_sheet(rowData, {
    skipHeader: true,
  });
  const workbook = XLSX.utils.book_new();
  worksheet['!cols'] = [{ wpx: 200 }];
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `popular-sources.xlsx`);
};
