import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { Chatbot, DataSource, ReTrainFrequency } from 'models/api/response.types';
import React, { Fragment, useEffect, useState } from 'react';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import useDataSources from 'hooks/useDataSources';
import { Loader2 } from 'lucide-react';
import sourceService from 'api/source';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';
import SubscriptionLimitsExceededDialog from '../Subscription/SubscriptionLimitsExceededDialog';

const AutoRetrainSourcesDialog: React.FC<{
  sources: DataSource[];
  hide: () => void;
}> = ({ sources, hide }) => {
  const { addAlert } = useAlerts();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { refetchSources } = useDataSources();
  const { canUseAutoRetrain } = useSubscriptionInfo();
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [showLimitExceededDialog, setShowLimitExceededDialog] = useState<boolean>(false);
  const [reTrainValue, setReTrainValue] = useState<ReTrainFrequency>(30);
  const sourceText = sources.length > 1 ? 'sources' : 'source';

  useEffect(() => {
    if (sources.length > 0) {
      setShow(true);
    }
  }, [sources]);

  const close = () => {
    setShow(false);
    setTimeout(() => {
      hide();
    }, 300);
  };

  const setAutoRetrain = () => {
    setSaving(true);
    const sourceIds = sources.map((s) => s.uuid);
    sourceService
      .updateBatchDataSources(chatbot.uuid, {
        uuids: sourceIds,
        updates: { retrain_frequency: reTrainValue },
      })
      .then(() => {
        refetchSources();
        close();
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Auto Re-train{' '}
                  {sources.length > 1 ? `${sources.length} ${sourceText}` : `selected ${sourceText}`}
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    Set the auto re-train frequency for the selected {sourceText} This will automatically
                    re-train {sourceText} according to the frequency you set.
                  </p>
                  <Select
                    value={reTrainValue.toString()}
                    onValueChange={(value) => {
                      setReTrainValue(parseInt(value, 10) as ReTrainFrequency);
                    }}
                  >
                    <SelectTrigger className="mt-2">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="z-[1001]">
                      <SelectItem value="7">Weekly</SelectItem>
                      <SelectItem value="14">Biweekly</SelectItem>
                      <SelectItem value="30">Monthly</SelectItem>
                      <SelectItem value="0">No Re-train</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    disabled={saving}
                    onClick={() => {
                      if (canUseAutoRetrain) {
                        setAutoRetrain();
                      } else {
                        setShowLimitExceededDialog(true);
                      }
                    }}
                  >
                    {saving && <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />}
                    Save
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
        <SubscriptionLimitsExceededDialog
          type="auto-retrain"
          show={!!showLimitExceededDialog}
          close={() => setShowLimitExceededDialog(false)}
        />
      </Dialog>
    </Transition>
  );
};

export default AutoRetrainSourcesDialog;
