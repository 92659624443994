/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { cn } from 'utils/cn';

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextareaAutoresize: React.FC<TextareaProps & { adjustHeightSignal?: boolean }> = ({
  className,
  adjustHeightSignal,
  ...props
}) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [props.value, adjustHeightSignal]);

  return (
    <textarea
      className={cn(
        'flex min-h-[110px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 resize-none',
        className,
      )}
      ref={textAreaRef}
      onInput={adjustHeight}
      {...props}
    />
  );
};

export { TextareaAutoresize };
