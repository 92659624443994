import { Label } from 'components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import React from 'react';
import { SketchPicker } from 'react-color';
import { ChatBotMeta, defaultChatDarkTextColor, defaultChatLightTextColor } from 'utils/bot';
import { hexToHSL } from 'utils/colors';

export const WidgetButtonColorAppearance: React.FC<{
  isPlatformView?: boolean;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}> = ({ isPlatformView, chatInterface, setChatInterface }) => {
  return (
    <div className="flex flex-col gap-2">
      <Label
        className="text-md font-medium leading-none tracking-tight flex items-center"
        htmlFor="user-message-color"
      >
        {isPlatformView ? 'Color' : 'Button Color'}
      </Label>
      {!isPlatformView && (
        <p className="text-sm text-muted-foreground">Select a unique color for your widget button.</p>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <div className="flex items-center gap-4 w-fit">
            <div
              className="p-1 w-[110px] rounded-md border bg-background flex items-center gap-2 cursor-pointer hover:scale-[1.02] transition-all"
              style={{
                borderColor: chatInterface.chat_bubble_color,
              }}
            >
              <div
                id="chatbot-message-color"
                className="w-[25px] h-[25px] rounded-md"
                style={{
                  backgroundColor: chatInterface.chat_bubble_color,
                }}
              />
              <p className="text-sm font-normal text-muted-foreground">{chatInterface.chat_bubble_color}</p>
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className="p-0 w-auto z-[1001]">
          <SketchPicker
            color={chatInterface.chat_bubble_color}
            onChange={(color: { hex: string }) => {
              const { l: luminosity } = hexToHSL(chatInterface.chat_bubble_color);
              setChatInterface({
                ...chatInterface,
                chat_bubble_color: color.hex,
                chat_bubble_text_color:
                  luminosity < 60 ? defaultChatLightTextColor : defaultChatDarkTextColor,
              });
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default WidgetButtonColorAppearance;
