import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { ChatMessage, Chatbot, UpdateChatMessageBody } from 'models/api/response.types';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import sourceService from 'api/source';
import { Loader2 } from 'lucide-react';
import sessionService from 'api/session';
import { useAlerts } from 'providers/AlertProvider';
import useDataSources from 'hooks/useDataSources';
import { alerts } from 'utils/alert';

const ReviseMessageDialog: React.FC<{
  sessionId: string;
  message?: ChatMessage;
  close: () => void;
}> = ({ sessionId, message, close }) => {
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { addAlert } = useAlerts();
  const { refetchSources } = useDataSources();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<string>('');
  const sessionMessagesQueryKey = ['sessionMessages', sessionId];

  useEffect(() => {
    if (message) {
      const citationRegex = /\[(\d+\.[0-9]+)\]/g;
      setResponse(message?.response.replace(citationRegex, '').replace(/\s+\./g, '.'));
      setOpen(true);
    }
  }, [message]);

  const hide = () => {
    setOpen(false);
    setTimeout(() => {
      setLoading(false);
      close();
    }, 300);
  };

  const updateMessageMutation = useMutation({
    mutationFn: (payload: { uuid: string; body: UpdateChatMessageBody }) =>
      sessionService.updateSessionMessage(payload.uuid, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: sessionMessagesQueryKey });
      addAlert({
        severity: 'success',
        message: alerts.REVISE_ANSWER_UPDATE,
      });
      hide();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const createQADataSourceMutation = useMutation({
    mutationFn: (payload: { uuid: string; question: string; answer: string }) =>
      sourceService.createQADataSource(payload.uuid, payload.question, payload.answer),
    onSuccess: () => {
      refetchSources();
      const messageMeta = JSON.parse(message?.meta_json || '{}');
      updateMessageMutation.mutate({
        uuid: message?.uuid as string,
        body: {
          meta_json: JSON.stringify({
            ...messageMeta,
            revised_answer: true,
          }),
        },
      });
    },
    onError: () => {
      setLoading(false);
    },
  });

  const reviseAnswer = () => {
    setLoading(true);
    createQADataSourceMutation.mutate({
      uuid: chatbot.uuid,
      question: message?.query as string,
      answer: response,
    });
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={hide}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                  Update Answer
                </DialogTitle>
                <div className="mt-2">
                  <p className="font-normal text-muted-foreground text-sm">
                    If you&apos;re not satisfied with how your chatbot answered a particular answered a
                    particular query, you can use this feature to alter the response to fix it for the future.
                    The responses you revise will appear in your source list.
                  </p>
                  <div className="flex flex-col gap-2 mt-4">
                    <Label htmlFor="user-query">User Query</Label>
                    <Input id="user-query" autoComplete="off" disabled value={message?.query} />
                  </div>
                  <div className="flex flex-col gap-2 mt-4">
                    <Label htmlFor="expected-response">Expected Response</Label>
                    <Textarea
                      autoFocus
                      className="min-h-[150px]"
                      id="expected-response"
                      value={response}
                      placeholder="Expected Response"
                      onChange={(e) => {
                        setResponse(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && response.length > 0) {
                          e.preventDefault();
                          reviseAnswer();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6 gap-4">
                  <Button variant="outline" onClick={() => hide()}>
                    Cancel
                  </Button>
                  <Button disabled={response.length === 0 || loading} onClick={reviseAnswer}>
                    {loading && <Loader2 strokeWidth={1.75} className="w-4 h-4 animate-spin mr-2" />}
                    Revise answer
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ReviseMessageDialog;
