/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { TabsContent } from 'components/ui/tabs';
import { Label } from 'components/ui/label';
import { AIAgentEditData } from 'models/api/response.types';
import { Button } from 'components/ui/button';
import { PlusCircle, X } from 'lucide-react';
import { Input } from 'components/ui/input';
import { defaultHumanEscalationSettings } from 'utils/agent';

const HumanEscalationMonitoringTab: React.FC<{
  data: AIAgentEditData;
  setData: (data: AIAgentEditData) => void;
}> = ({ data, setData }) => {
  // as HE settings are optional and  don't exist in all agents, need to make such check
  // to prevent TS errors
  const { human_escalation_settings = defaultHumanEscalationSettings } = data;

  const [newMessage, setNewMessage] = useState<string>('');

  return (
    <TabsContent className="flex flex-col gap-6 mt-0" value="human_escalation_monitoring">
      <p className="text-sm text-muted-foreground">
        Stay on top of your AI-generated interactions by adding specific messages for the AI to monitor. If
        the AI generates a response similar to one of your monitored messages, you&apos;ll receive an in-app
        notification. Quickly access the relevant chat to review and ensure the response aligns with your
        standards, allowing for timely intervention when needed.
      </p>
      {(!human_escalation_settings?.monitored_messages ||
        human_escalation_settings?.monitored_messages.length < 10) && (
        <div className="flex flex-col gap-2">
          <p className="text-sm text-muted-foreground">
            Enter the message you want to add for monitoring. You can add up to 10 messages for monitoring.
          </p>
          <div className="flex items-center gap-6">
            <Input
              id="agent-title"
              type="text"
              placeholder="Sorry, I don't know the answer."
              maxLength={250}
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
              }}
            />
            <Button
              disabled={newMessage.length === 0}
              onClick={() => {
                setData({
                  ...data,
                  human_escalation_settings: {
                    ...human_escalation_settings,
                    monitored_messages: human_escalation_settings.monitored_messages
                      ? [newMessage, ...human_escalation_settings.monitored_messages]
                      : [newMessage],
                  },
                });
                setNewMessage('');
              }}
            >
              <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
              Add new
            </Button>
          </div>
        </div>
      )}
      {human_escalation_settings?.monitored_messages?.length > 0 && (
        <div className="flex flex-col gap-4">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center"
            htmlFor="agent-title"
          >
            Messages to Monitor
          </Label>
          {human_escalation_settings.monitored_messages.map((message, index) => (
            <div
              key={index}
              className="w-fit flex items-start justify-between break-all max-w-full overflow-hidden space-x-4 rounded-lg rounded-bl-none shadow-sm border px-3 py-[9px] text-sm"
              style={{ background: '#F2F5F9', color: '#303030' }}
            >
              <p>{message}</p>
              <button
                type="button"
                onClick={() => {
                  setData({
                    ...data,
                    human_escalation_settings: {
                      ...human_escalation_settings,
                      monitored_messages: human_escalation_settings.monitored_messages.filter(
                        (_item, currentIndex) => currentIndex !== index,
                      ),
                    },
                  });
                }}
                className="mt-[5px]"
              >
                <X
                  strokeWidth={1.75}
                  className="w-4 min-w-[16px] transition-all h-4 hover:text-destructive cursor-pointer"
                />
              </button>
            </div>
          ))}
        </div>
      )}
    </TabsContent>
  );
};

export default HumanEscalationMonitoringTab;
