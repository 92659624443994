/* eslint-disable import/prefer-default-export */
export const fonts = [
  { label: 'Alegreya', style: 'Alegreya' },
  { label: 'Anton', style: 'Anton' },
  { label: 'Arimo', style: 'Arimo' },
  { label: 'Arial', style: 'Arial' },
  { label: 'Bitter', style: 'Bitter' },
  { label: 'Cabin', style: 'Cabin' },
  { label: 'Dancing Script', style: '"Dancing Script"' },
  { label: 'Fira Sans', style: '"Fira Sans"' },
  { label: 'Helvetica', style: 'Helvetica' },
  { label: 'Inconsolata', style: 'Inconsolata' },
  { label: 'Inter', style: 'Inter' },
  { label: 'Josefin Sans', style: '"Josefin Sans"' },
  { label: 'Karla', style: 'Karla' },
  { label: 'Lato', style: 'Lato' },
  { label: 'Lora', style: 'Lora' },
  { label: 'Merriweather', style: 'Merriweather' },
  { label: 'Montserrat', style: 'Montserrat' },
  { label: 'Mulish', style: 'Mulish' },
  { label: 'Nunito', style: 'Nunito' },
  { label: 'Noto Sans', style: '"Noto Sans"' },
  { label: 'Open Sans', style: '"Open Sans"' },
  { label: 'Oswald', style: 'Oswald' },
  { label: 'Playfair Display', style: '"Playfair Display"' },
  { label: 'Poppins', style: 'Poppins' },
  { label: 'PT Sans', style: '"PT Sans"' },
  { label: 'Quicksand', style: 'Quicksand' },
  { label: 'Raleway', style: 'Raleway' },
  { label: 'Roboto', style: 'Roboto' },
  { label: 'Rubik', style: 'Rubik' },
  { label: 'Segoe UI', style: '"Segoe UI"' },
  { label: 'Source Sans Pro', style: '"Source Sans Pro"' },
  { label: 'Ubuntu', style: 'Ubuntu' },
  { label: 'Work Sans', style: '"Work Sans"' },
];
