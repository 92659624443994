/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { WidgetSize } from 'utils/bot';
import { cn } from 'utils/cn';

const Image: React.FC<{
  data: any;
  size: WidgetSize;
}> = ({ data, size }) => {
  const { alt, src } = data;

  return (
    <div className="w-fit group relative rounded-lg">
      <img
        className={cn(
          `transition-opacity duration-300 mb-0 my-2 h-full object-contain rounded-lg aspect-auto`,
          ['sm', 'xs'].includes(size)
            ? 'max-h-[250px]'
            : ['md', 'lg'].includes(size)
              ? 'max-h-[300px]'
              : 'max-h-[400px]',
        )}
        src={src}
        alt={alt}
      />
    </div>
  );
};

export default Image;
