import axios from 'axios';
import {
  AIAgent,
  AIAgentUpdateBody,
  AIAgentCreateBody,
  AIAgentFunction,
  AIAgentFunctionBody,
  AIAgentList,
  AIAgentFunctionCreateBody,
  AgentRuleCreateBody,
  AgentRule,
  AgentRuleList,
  AgentUpdateBody,
  AIAgentTemplateCreateBody,
  HumanEscalationAgentEnabledResponse,
} from 'models/api/response.types';

const getAIAgents = (uuid: string) =>
  axios.get<AIAgentList>(`/api/chatbot/${uuid}/agents`).then((res) => res.data);

const getAgentRules = (uuid: string) =>
  axios.get<AgentRuleList>(`/api/chatbot/${uuid}/agent-rules`).then((res) => res.data);

const deleteAIAgent = (uuid: string) => axios.post(`/api/agent/${uuid}/delete`).then((res) => res.data);

const updateAIAgent = (uuid: string, data: AIAgentUpdateBody) =>
  axios.post<AIAgent>(`/api/agent/${uuid}/update`, data).then((res) => res.data);

const createAIAgent = (body: AIAgentCreateBody) =>
  axios.post<AIAgent>(`/api/agent/create`, body).then((res) => res.data);

const createAIAgentTemplate = (chatbotUUID: string, body: AIAgentTemplateCreateBody) =>
  axios.post(`/api/chatbot/${chatbotUUID}/agents/create`, body).then((res) => res.data);

const isHumanEscalationAgentEnabled = (chatbotUUID: string) =>
  axios
    .get<HumanEscalationAgentEnabledResponse>(`/api/chatbot/${chatbotUUID}/human-escalation-enabled`)
    .then((res) => res.data);

const createAgentRule = (uuid: string, body: AgentRuleCreateBody) =>
  axios.post<AgentRule>(`/api/chatbot/${uuid}/agent-rule/create`, body).then((res) => res.data);

const updateAgentRule = (uuid: string, data: AgentUpdateBody) =>
  axios.post<AgentRule>(`/api/agent-rule/${uuid}/update`, data).then((res) => res.data);

const deleteAgentRule = (uuid: string) =>
  axios.post(`/api/agent-rule/${uuid}/delete`).then((res) => res.data);

const createAgentFunction = (data: AIAgentFunctionCreateBody) =>
  axios.post<AIAgentFunction>(`/api/toolfunction/create`, data).then((res) => res.data);

const updateAgentFunction = (uuid: string, data: AIAgentFunctionBody) =>
  axios.post<AIAgentFunction>(`/api/toolfunction/${uuid}/update`, data).then((res) => res.data);

const deleteAgentFunction = (uuid: string) =>
  axios.post(`/api/toolfunction/${uuid}/delete`).then((res) => res.data);

const agentService = {
  getAIAgents,
  deleteAIAgent,
  updateAIAgent,
  createAgentFunction,
  updateAgentFunction,
  createAIAgent,
  deleteAgentFunction,
  getAgentRules,
  createAgentRule,
  updateAgentRule,
  deleteAgentRule,
  createAIAgentTemplate,
  isHumanEscalationAgentEnabled,
};

export default agentService;
