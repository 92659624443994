/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { Code, Loader2, RefreshCcw, X } from 'lucide-react';
import { cn } from 'utils/cn';
import { ChatBotMeta, IChatMainColors, WidgetSize, chatSizeVariants } from 'utils/bot';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { addCookie, findCookieByKey } from 'utils/cookies';

const ChatHeader: React.FC<{
  isPreview?: boolean;
  metadata: ChatBotMeta;
  canSeeProfilePicture: boolean;
  chatReset: () => void;
  isActionsDisabled: boolean;
  size: WidgetSize;
  chatMainColors?: IChatMainColors;
  isWidget?: string;
  debugMode?: boolean;
  canUseDebugMode?: boolean;
  setDebugMode?: (mode: boolean) => void;
  isDebugModeInProgress?: boolean;
  chatId?: string;
}> = ({
  isPreview,
  metadata,
  canSeeProfilePicture,
  chatReset,
  size,
  isActionsDisabled,
  // part of Bot Widget
  chatMainColors,
  isWidget,
  chatId,
  // part for Peview
  debugMode = false,
  canUseDebugMode = false,
  setDebugMode = () => {},
  isDebugModeInProgress = false,
}) => {
  const showProfilePicture = metadata.profile_picture && canSeeProfilePicture;

  useEffect(() => {
    // if chatbot open by default send a post message
    // to open it from script, ignore mobile version
    // otherwise check for initial messages
    if (isWidget && !isMobileOnly) {
      if (metadata.open_by_default !== 'off') {
        if (
          !findCookieByKey(`${chatId}-chatbot-hide-auto-open`) &&
          (metadata.open_by_default === 'on' ||
            (metadata.open_by_default === 'computer' && !isMobile) ||
            (metadata.open_by_default === 'mobile' && isMobile))
        )
          window.parent.postMessage('chatbot-widget-trigger-iframe', '*');
      } else if (chatId && metadata.initial_messages.length > 0 && metadata.show_initial_messages_time > -1) {
        window.parent.postMessage('chatbot-widget-append-initial-messages', '*');
      }
    }
  }, []);

  const hideChat = () => {
    if (metadata.open_by_default !== 'off') {
      addCookie(`${chatId}-chatbot-hide-auto-open`, 1);
    }
    window.parent.postMessage('chatbot-widget-trigger-iframe', '*');
  };

  return (
    <div
      className={cn(
        'border-b-[1px] flex justify-between items-center rounded-t-[0.75rem]',
        showProfilePicture ? chatSizeVariants.header.withIcon[size] : chatSizeVariants.header.regular[size],
        isPreview ? 'min-h-[53px]' : '',
      )}
      style={
        chatMainColors
          ? {
              borderColor: chatMainColors.border,
              color: chatMainColors.text,
              backgroundColor: chatMainColors.background,
            }
          : {}
      }
    >
      <div className="flex items-center">
        {showProfilePicture && (
          <img
            className={cn('rounded-full', chatSizeVariants.header.profileIcon[size])}
            src={metadata.profile_picture}
            alt="Profile"
          />
        )}
        {metadata.display_name && (
          <p className={cn('font-semibold transition-all', chatSizeVariants.header.profileName[size])}>
            {metadata.display_name}
          </p>
        )}
      </div>
      <button
        type="button"
        className="outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparent ml-auto"
        disabled={isActionsDisabled}
        onClick={chatReset}
      >
        <RefreshCcw strokeWidth={1.75} className={chatSizeVariants.header.buttonIcons[size]} />
      </button>
      {canUseDebugMode && (
        <button
          disabled={isActionsDisabled}
          type="button"
          className={cn(
            'preview-mode-switcher w-[44px] h-[24px] rounded-full relative flex items-center',
            isActionsDisabled ? 'cursor-not-allowed bg-input' : !debugMode ? 'bg-input' : 'bg-primary',
          )}
          onClick={() => setDebugMode(!debugMode)}
        >
          <div
            className={cn(
              'w-[20px] h-[20px] bg-background rounded-full shadow-lg ml-[2px] transition-all border flex items-center justify-center',
              debugMode ? 'translate-x-[20px]' : '',
            )}
          >
            {isDebugModeInProgress ? (
              <Loader2 className="w-3 h-3 animate-spin transition-all" />
            ) : (
              <Code className="w-3 h-3" />
            )}
          </div>
        </button>
      )}
      {isWidget && (
        <button
          type="button"
          className="outline-none ring-0 focus-visible:ring-0 focus-visible:ring-transparen"
          onClick={hideChat}
        >
          <X strokeWidth={1.75} className={chatSizeVariants.header.buttonIcons[size]} />
        </button>
      )}
    </div>
  );
};

export default ChatHeader;
