import { DropdownMenu, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { Button } from 'components/ui/button';
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator } from 'components/ui/dropdown-menu';
import { key } from 'localforage';
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon, EyeOff } from 'lucide-react';
import React from 'react';
import { cn } from 'utils/cn';

const DataTableColumnHeader: React.FC<{
  title: string;
  sortOrder: {
    key: string;
    column: any;
    order: 'asc' | 'desc';
  };
  canSort?: boolean;
  className?: string;
  setOrder?: (order: 'asc' | 'desc') => void;
  setColumn?: (column: string) => void;
  hideColumn?: () => void;
}> = ({ canSort, title, sortOrder, className, setOrder, setColumn, hideColumn }) => {
  if (!canSort || !sortOrder || !setOrder || !setColumn || !key) {
    return <div className={cn(className, 'text-xs')}>{title}</div>;
  }

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="-ml-3 h-8 focus-visible:ring-0 overflow-hidden">
            <span className="overflow-hidden text-ellipsis">{title}</span>
            {sortOrder.key === sortOrder.column ? (
              <>
                {sortOrder.order === 'desc' ? (
                  <ArrowDownIcon className="ml-2 h-4 w-4" />
                ) : (
                  <ArrowUpIcon className="ml-2 h-4 w-4" />
                )}
              </>
            ) : (
              <ArrowUpDownIcon className="ml-2 h-4 w-4" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem
            onClick={() => {
              setOrder('asc');
              if (sortOrder.column !== sortOrder.key) {
                setColumn(sortOrder.key);
              }
            }}
          >
            <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setOrder('desc');
              if (sortOrder.column !== sortOrder.key) {
                setColumn(sortOrder.key);
              }
            }}
          >
            <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Desc
          </DropdownMenuItem>
          {hideColumn && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={hideColumn}>
                <EyeOff className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                Hide
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default DataTableColumnHeader;
