import { Button } from 'components/ui/button';
import { Loader2 } from 'lucide-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { getHasUnsavedChanges } from 'store/reducers/ui';
import { cn } from 'utils/cn';

const SaveChangesHeader: React.FC<{
  saving: boolean;
  discardChanges: () => void;
  saveChanges: () => void;
}> = ({ saving, discardChanges, saveChanges }) => {
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);

  return (
    <div
      id="save-changes-banner"
      className={cn(
        'fixed top-0 left-0 w-full bg-primary z-[1000] border-b-[1px] h-[58px] flex items-center justify-center p-4 lg:px-8 transition-all',
        hasUnsavedChanges ? 'translate-y-[0px]' : '-translate-y-full',
      )}
    >
      <div className="max-w-7xl flex items-center justify-between flex-1">
        <h2 className="text-base font-semibold text-text-primary">Unsaved changes</h2>
        <div className="flex items-center">
          <Button
            className="bg-background"
            disabled={saving}
            size="default"
            variant="ghost"
            onClick={discardChanges}
          >
            Discard
          </Button>
          <Button
            id="save-changes"
            disabled={saving}
            size="default"
            className="ml-2"
            variant="secondary"
            onClick={saveChanges}
          >
            {saving && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SaveChangesHeader;
