/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
import React, { useMemo, useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { Toggle } from 'components/ui/toggle';
import AceEditor from 'react-ace';
import { AIAgentFunction } from 'models/api/response.types';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-textmate';
import { Asterisk } from 'lucide-react';
import agentService from 'api/agent';
import { useAlerts } from 'providers/AlertProvider';
import { standardHeaders } from 'utils/agent';
import { Switch } from 'components/ui/switch';
import useAiAgents from 'hooks/useAiAgents';
import { alerts } from 'utils/alert';

const defaultHeader = `{
  "Authorization": "Bearer YOUR_TOKEN"
}`;

const defaultPayload = `{
  "type": "object",
  "properties": {
    "location": {
      "type": "string",
      "description": "The city and state, e.g. San Francisco, CA"
    },
    "unit": {
      "type": "string",
      "enum": ["celsius", "fahrenheit"]
    }
  },
  "required": ["location"]
}`;

const ignoredPayload = `{
  "type": "object",
  "properties": {}
}`;

const CreateEditFunctionCard: React.FC<{
  agentId: string;
  functionCall?: AIAgentFunction;
  close: () => void;
}> = ({ agentId, functionCall, close }) => {
  const { addAlert } = useAlerts();
  const { agents, updateAgent, refetchAgents } = useAiAgents();
  const [title, setTitle] = useState<string>(functionCall?.name || '');
  const [description, setDescription] = useState<string>(functionCall?.description || '');
  const [method, setMethod] = useState<'GET' | 'POST'>(functionCall?.method || 'GET');
  const [url, setURL] = useState<string>(functionCall?.external_url || '');
  const [headers, setHeaders] = useState<string>(
    functionCall?.headers ? JSON.stringify(JSON.parse(functionCall.headers), null, 2) : defaultHeader,
  );
  const [meta, setMeta] = useState<{ [key: string]: any }>(JSON.parse(functionCall?.meta_json || '{}'));
  const [headersError, setHeadersError] = useState<string>('');
  const [parameters, setParameters] = useState<string>(
    functionCall?.parameters ? JSON.stringify(JSON.parse(functionCall.parameters), null, 2) : defaultPayload,
  );
  const [parametersAnnotations, setParametersAnnotations] = useState<any>([]);
  const [headersAnnotations, setHeadersAnnotations] = useState<any>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const validateJSON = (inputText: string, type: 'headers' | 'parameters') => {
    try {
      const result = Object.keys(JSON.parse(inputText));
      if (type === 'headers') {
        const headerFound = standardHeaders.find((h) =>
          result.some((key) => key.toLocaleLowerCase() === h.toLocaleLowerCase()),
        );
        if (headerFound) {
          setHeadersError(headerFound);
        } else if (headersError) {
          setHeadersError('');
        }
        setHeadersAnnotations([]);
      } else {
        setParametersAnnotations([]);
      }
    } catch (e: any) {
      if (type === 'headers') {
        setHeadersError('');
      }
      const lineMatch = e.message.match(/line (\d+)/);
      const line = lineMatch ? parseInt(lineMatch[1], 10) - 1 : 0;
      const errorData = {
        row: line,
        column: 0,
        text: e.message,
        type: 'error',
      };
      if (type === 'headers') {
        setHeadersAnnotations([errorData]);
      } else {
        setParametersAnnotations([errorData]);
      }
    }
  };

  const sendError = () => {
    addAlert({
      severity: 'error',
      message: alerts.SOMETHING_WRONG,
      timeout: 5000,
    });
    setSaving(false);
  };

  const isValidApiEndpoint = useMemo(() => {
    if (url.length > 0) {
      const pattern = /^https?:\/\/[\w.-]+(?:\:[0-9]+)?\/[^\s]+$/;
      return pattern.test(url);
    }
    return true;
  }, [url]);

  const isValidApiTitle = useMemo(() => {
    if (title.length > 0) {
      const pattern = /^[a-zA-Z0-9_-]{1,64}$/;
      return pattern.test(title);
    }
    return true;
  }, [title]);

  const canTriggerUpdate = useMemo(() => {
    if (functionCall) {
      const {
        name: funcTitle,
        description: funcDescription,
        external_url: funcUrl,
        headers: funcHeaders,
        method: funcMethod,
        meta_json: funcMeta,
        parameters: funcParamenters,
      } = functionCall;

      return (
        !!title &&
        isValidApiTitle &&
        !!description &&
        !!url &&
        isValidApiEndpoint &&
        (title !== funcTitle ||
          funcMeta !== JSON.stringify(meta) ||
          description !== funcDescription ||
          url !== funcUrl ||
          headers !== funcHeaders ||
          JSON.stringify(JSON.parse(funcParamenters), null, 2) !== parameters ||
          funcMethod !== method) &&
        parametersAnnotations.length === 0 &&
        headersAnnotations.length === 0
      );
    }
    return (
      !!title &&
      isValidApiTitle &&
      !!description &&
      !!url &&
      isValidApiEndpoint &&
      parametersAnnotations.length === 0 &&
      headersAnnotations.length === 0
    );
  }, [title, description, url, isValidApiEndpoint, parametersAnnotations, headersAnnotations, method, meta]);

  const finishCompleteResponse = (res: AIAgentFunction, type: 'create' | 'edit') => {
    const currentAgent = agents?.find((ag) => ag.uuid === agentId);
    if (currentAgent) {
      updateAgent({
        ...currentAgent,
        tool_functions:
          type === 'create'
            ? [...currentAgent.tool_functions, res]
            : currentAgent.tool_functions.map((func) => (func.uuid === res.uuid ? res : func)),
      });
    } else {
      refetchAgents();
    }
    setSaving(false);
    close();
  };

  const editAgentFunction = () => {
    if (functionCall) {
      setSaving(true);
      agentService
        .updateAgentFunction(functionCall.uuid, {
          name: title,
          description,
          method,
          meta_json: JSON.stringify(meta),
          external_url: url,
          parameters: parameters.replace(/\n/g, ''),
          headers: headers.replace(/\n/g, ''),
        })
        .then((res) => finishCompleteResponse(res, 'edit'))
        .catch(() => sendError());
    }
  };

  const createNewAgentFunction = () => {
    setSaving(true);
    agentService
      .createAgentFunction({
        name: title,
        description,
        method,
        meta_json: JSON.stringify(meta),
        external_url: url,
        parameters: parameters.replace(/\n/g, ''),
        headers: headers.replace(/\n/g, ''),
        agent_uuid: agentId,
      })
      .then((res) => finishCompleteResponse(res, 'create'))
      .catch(() => sendError());
  };

  return (
    <Card className="flex-1 flex flex-col">
      <CardHeader>
        <CardTitle>{functionCall ? 'Edit function' : 'Add function'}</CardTitle>
        <CardDescription>
          {functionCall ? 'Create and configuring' : 'Configure'} a function call for your AI Agent
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-1 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center"
            htmlFor="function-name"
          >
            Name
            <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
          </Label>
          <p className="text-sm text-muted-foreground">
            Enter a name for the function that succinctly describes its purpose or action. This name should be
            unique and easily identifiable, serving as a reference to the specific functionality it provides
          </p>
          <Input
            id="function-name"
            type="text"
            maxLength={250}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          {!isValidApiTitle && (
            <p className="text-xs text-destructive ml-1 mt-1">
              Invalid function name. It must be 1-64 characters long and can only contain letters, numbers,
              underscores, and hyphens.
            </p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center"
            htmlFor="func-description"
          >
            Description
            <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
          </Label>
          <p className="text-sm text-muted-foreground">
            Provide a description of the function, detailing what it does and how it contributes to the AI
            agent&apos;s capabilities. This should include information on the function&apos;s role, expected
            outcomes, and any important context that clarifies its utility.
          </p>
          <Textarea
            className="min-h-[90px]"
            id="func-description"
            rows={3}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center"
            htmlFor="func-url"
          >
            API Endpoint
            <Asterisk strokeWidth={1.75} className="w-4 h-4 text-destructive inline ml-1" />
          </Label>
          <p className="text-sm text-muted-foreground">
            Specify the URL for the API call that this function will make. This endpoint should be the target
            address where the function sends its request, enabling the agent to retrieve data or trigger
            actions externally.
          </p>
          <Input
            id="function-url"
            type="text"
            value={url}
            onChange={(e) => {
              setURL(e.target.value);
            }}
          />
          {!isValidApiEndpoint && <p className="text-xs text-destructive ml-1 mt-1">Invalid API Endpoint.</p>}
        </div>
        <div className="flex flex-col gap-2">
          <Label className="text-md font-medium leading-none tracking-tight" htmlFor="func-method">
            Method
          </Label>
          <p className="text-sm text-muted-foreground">
            Select the HTTP method that corresponds to the API endpoint&apos;s requirements.
          </p>
          <div id="func-method" className="flex items-center gap-2">
            <Toggle
              aria-label="GET"
              pressed={method === 'GET'}
              onPressedChange={() => {
                setMethod('GET');
              }}
            >
              GET
            </Toggle>
            <Toggle
              aria-label="POST"
              pressed={method === 'POST'}
              onPressedChange={() => {
                setMethod('POST');
              }}
            >
              POST
            </Toggle>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center gap-4"
            htmlFor="func-headers"
          >
            Headers
            <Button
              onClick={() => {
                setHeaders(`{}`);
                setHeadersAnnotations([]);
                setHeadersError('');
              }}
              variant="outline"
              className="ml-auto"
            >
              Ignore Headers
            </Button>
          </Label>
          <p className="text-sm text-muted-foreground">
            Add custom headers to your APIs. This includes the Authorization header for authentication
            purposes, as well as any other headers you may need to configure for your API requests.
          </p>
          <div className="mt-4">
            <AceEditor
              mode="json"
              theme="textmate"
              name="jsonEditor"
              value={headers}
              onChange={(newText) => {
                setHeaders(newText);
                validateJSON(newText, 'headers');
              }}
              annotations={headersAnnotations}
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine
              setOptions={{
                useWorker: false, // It's important to disable the default worker to manage errors manually
                showLineNumbers: true,
                tabSize: 2,
              }}
              style={{ width: '100%', height: '150px' }}
            />
          </div>
          {headersError ? (
            <p className="ml-1 mt-1 text-destructive text-xs">
              <strong>{headersError}</strong> is a default header and can&apos;t be updated
            </p>
          ) : (
            <p className="ml-1 mt-1 text-warning text-xs">
              Please note that you are not able to customize default API headers for security purposes. All
              standard headers are disabled for this feature.
            </p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center gap-4"
            htmlFor="func-parameters"
          >
            Parameters
            <Button
              onClick={() => {
                setParameters(ignoredPayload);
                setParametersAnnotations([]);
              }}
              variant="outline"
              className="ml-auto"
            >
              Ignore Parameters
            </Button>
          </Label>
          <p className="text-sm text-muted-foreground">
            Define the inputs for your custom functions with parameters that specify the arguments to be
            extracted by AI from user conversations. This ensures tailored responses. The AI identifies and
            uses values from user queries to execute your function accordingly. Each parameter must include a
            type (to ensure accurate processing), properties and required fields. For guidance on setting up
            parameters specifically for AI models, refer to{' '}
            <a
              className="font-bold text-secondary underline"
              rel="noreferrer"
              href="https://cookbook.openai.com/examples/how_to_call_functions_with_chat_models"
              target="_blank"
            >
              How to Call Functions with Chat Models
            </a>{' '}
            for examples and best practices on configuring your function&apos;s parameters correctly.
          </p>
          <div className="mt-4 relative">
            <AceEditor
              mode="json"
              theme="textmate"
              name="jsonEditor"
              value={parameters}
              onChange={(newText) => {
                setParameters(newText);
                validateJSON(newText, 'parameters');
              }}
              annotations={parametersAnnotations}
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine
              setOptions={{
                useWorker: false, // It's important to disable the default worker to manage errors manually
                showLineNumbers: true,
                tabSize: 2,
              }}
              style={{ width: '100%', height: '350px' }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label
            className="text-md font-medium leading-none tracking-tight flex items-center gap-4"
            htmlFor="func-parameters"
          >
            Include Default Metadata
          </Label>
          <p className="text-sm text-muted-foreground">
            Function includes chat session uuid, chat message uuid, and user&apos;s input query as part of
            JSON payload for each interaction.
          </p>
          <Switch
            id="default-parameters"
            checked={meta?.allow_gpt_trainer_properties}
            onCheckedChange={(enable) => {
              setMeta({
                ...meta,
                allow_gpt_trainer_properties: enable ? 1 : 0,
              });
            }}
          />
        </div>
      </CardContent>
      <CardFooter className="flex justify-end">
        <Button disabled={saving} onClick={close} variant="outline">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setSaving(true);
            if (functionCall) {
              editAgentFunction();
            } else {
              createNewAgentFunction();
            }
          }}
          disabled={!canTriggerUpdate || saving}
          className="ml-4"
        >
          {functionCall ? 'Save' : 'Add'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default CreateEditFunctionCard;
