/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Settings2 } from 'lucide-react';

type DataTableViewColumnFilterProps<T> = {
  columns: { title: T; checked: boolean }[];
  hideColumn: (column: T) => void;
  enableColumn: (column: T) => void;
};

const DataTableViewColumnFilter = <T extends {}>(
  props: DataTableViewColumnFilterProps<T>,
): React.ReactElement => {
  const { columns, hideColumn, enableColumn } = props;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-auto hidden lg:flex bg-background">
          <Settings2 className="mr-2 h-4 w-4" />
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[150px]">
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {columns.map((column, idx) => {
          return (
            <DropdownMenuCheckboxItem
              key={idx}
              className="capitalize"
              checked={column.checked}
              onCheckedChange={(value) => {
                if (value) {
                  enableColumn(column.title);
                } else {
                  hideColumn(column.title);
                }
              }}
            >
              {String(column.title)}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DataTableViewColumnFilter;
